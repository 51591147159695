import { UseQueryResult, useQuery } from 'react-query';
import { CriteriaTypeService } from '../../services/wealthlane-tax-services';
import { OptionType } from '../../types/common';

const useGetTaxUserDefinedOptions = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>
): UseQueryResult<
  // eslint-disable-next-line camelcase
  OptionType[] | undefined,
  unknown
> => {
  const taxUserDefinedOptions = useQuery(
    ['getTaxUserDefinedOptions'],
    async () => {
      return await handleRequest(
        CriteriaTypeService.getApiTaxApiAppCriteriaTypeCriteriaTypesListAync()
      );
    },
    {
      select: (data) => {
        return data?.map((item) => {
          return {
            label: item.displayName || '',
            value: item.systemName || '',
          };
        });
      },
      cacheTime: 1000 * 60 * 5,
    }
  );

  return taxUserDefinedOptions;
};

export default useGetTaxUserDefinedOptions;
