/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Wealthlane_TaxService_Dtos_City_CityDto } from '../models/Wealthlane_TaxService_Dtos_City_CityDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CityService {

    /**
     * @returns Wealthlane_TaxService_Dtos_City_CityDto Success
     * @throws ApiError
     */
    public static getApiTaxApiAppCityCities(): CancelablePromise<Array<Wealthlane_TaxService_Dtos_City_CityDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/tax/api/app/city/cities',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param stateId 
     * @returns Wealthlane_TaxService_Dtos_City_CityDto Success
     * @throws ApiError
     */
    public static getApiTaxApiAppCityCityByState(
stateId: string,
): CancelablePromise<Array<Wealthlane_TaxService_Dtos_City_CityDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/tax/api/app/city/city-by-state/{StateId}',
            path: {
                'StateId': stateId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

}
