/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Wealthlane_TaxService_Dtos_EquityCompensationLevel_EquityCompensationLevelDto } from '../models/Wealthlane_TaxService_Dtos_EquityCompensationLevel_EquityCompensationLevelDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class EquityCompensationLevelService {

    /**
     * @returns Wealthlane_TaxService_Dtos_EquityCompensationLevel_EquityCompensationLevelDto Success
     * @throws ApiError
     */
    public static getApiTaxApiAppEquityCompensationLevelEquityCompensationList(): CancelablePromise<Array<Wealthlane_TaxService_Dtos_EquityCompensationLevel_EquityCompensationLevelDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/tax/api/app/equity-compensation-level/equity-compensation-list',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param equityCompensationLevelId 
     * @returns Wealthlane_TaxService_Dtos_EquityCompensationLevel_EquityCompensationLevelDto Success
     * @throws ApiError
     */
    public static getApiTaxApiAppEquityCompensationLevelEquityCompensationLevel(
equityCompensationLevelId: string,
): CancelablePromise<Wealthlane_TaxService_Dtos_EquityCompensationLevel_EquityCompensationLevelDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/tax/api/app/equity-compensation-level/equity-compensation-level/{EquityCompensationLevelId}',
            path: {
                'EquityCompensationLevelId': equityCompensationLevelId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

}
