import React from 'react';
import { ErrorMessage, type FormikErrors, type FormikTouched } from 'formik';
import Select, { components } from 'react-select';
import { FormWrapper, Label } from '../input-text/input.styled';
import { DropdownContainer } from './dropdown.styled';
import { COLORS } from '../../constants/style/color';
import { type OptionType } from '../../types/common';
import { getRgbaValue, rem } from '../../utils/style';
import Tooltip from '../tooltip';
import CheckFieldLevelPermissions from '../../utils/Permissions/checkFieldLevelPermission';

interface FormSelectProps {
  containerClassName?: string;
  label?: string;
  name: string;
  type?: string;
  placeholder?: string;
  onChange?: (value: any) => void;
  errors?: FormikErrors<any> | undefined;
  touched?: FormikTouched<any> | undefined;
  required?: boolean;
  disabled?: boolean;
  multiple?: boolean;
  options: OptionType[] | undefined;
  allOptions?: OptionType[] | undefined;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  values?: any;
  setFieldValue?: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  arrValue?: string | number | string[] | undefined | any;
  defaultValue?: OptionType;
  errorValue?: string | FormikErrors<any> | Array<FormikErrors<any>> | string[] | undefined;
  touchedValue?: boolean | FormikTouched<any> | Array<FormikTouched<any>> | undefined;
  isClearable?: boolean | undefined;
  isFormik?: boolean;
  isTable?: boolean;
  inputType?: 'View' | string;
  viewName?: string;
  width?: string;
  menuWidth?: string;
  tooltipText?: string;
  fieldLabelName?: string;
  isEdit?: boolean;
}

// interface Styles {
//   borderRadius: number;
//   fontWeight: number;
//   fontSize: string;
//   height: string;
//   transition: string;
//   border: string;
//   boxShadow: string;
//   width: string;
// }

interface State {
  isFocused: boolean;
}

const FormSelect = React.memo((props: FormSelectProps) => {
  const {
    containerClassName,
    label,
    name,
    type,
    errors,
    touched,
    onChange,
    required,
    options,
    allOptions,
    placeholder,
    disabled,
    values,
    onBlur,
    setFieldValue,
    multiple,
    arrValue,
    errorValue,
    touchedValue,
    defaultValue,
    isClearable,
    isFormik = true,
    isTable = false,
    inputType,
    viewName,
    width,
    tooltipText,
    fieldLabelName,
    isEdit,
    menuWidth,
    ...rest
  } = props;

  const selectStyles = {
    control: (styles: any, state: State) => {
      return {
        ...styles,
        borderRadius: 6,
        fontWeight: 400,
        fontSize: `${rem(15)}`,
        height: `auto`,
        transition: `all .2s linear`,
        border: state.isFocused
          ? `1px solid ${COLORS.SpanishGray} !important`
          : errors != null &&
            Boolean(errors[name] ?? errorValue) &&
            touched != null &&
            Boolean(touched[name] ?? touchedValue)
          ? `1px solid ${COLORS.Red} !important`
          : `${rem(1)} solid ${COLORS.LightGray} !important`,
        boxShadow: `${rem(0)} ${rem(2)} ${rem(4)} ${COLORS.DefaultShadow}`,
        width: width ?? '',
      };
    },
    valueContainer: (styles: any, state: any) => {
      return {
        ...styles,
        height: state.children[0]?.length > 0 ? 'auto' : 38,
        paddingLeft: multiple ?? false ? 4 : 16,
        paddingRight: 5,
        gap: 4,
      };
    },
    singleValue: (styles: any) => {
      return {
        ...styles,
        color: `${COLORS.Black}`,
      };
    },
    placeholder: (styles: any, state: any) => {
      return {
        ...styles,
        padding: 0,
        color:
          fieldLabelName && Boolean(CheckFieldLevelPermissions(fieldLabelName, isEdit, disabled))
            ? `${COLORS.Black}`
            : state.focused != null
            ? `${COLORS.Black}`
            : `${COLORS.SpanishGray}`,
        whiteSpace: 'nowrap',
      };
    },
    menuList: (styles: any) => {
      return {
        ...styles,
        maxHeight: `${rem(256)}`,
        fontSize: `${rem(14)}`,
        padding: `${rem(10)}`,
        borderRadius: `${rem(11)}`,
        boxShadow: `${rem(0)} ${rem(2)} ${rem(20)} ${COLORS.SoftShadow}`,
        '::-webkit-scrollbar': {
          width: '5px',
        },

        '::-webkit-scrollbar-track': {
          background: '#f1f1f1',
          borderRadius: '7px',
        },

        '::-webkit-scrollbar-thumb': {
          background: '#969696',
          borderRadius: '7px',
        },

        '::-webkit-scrollbar-thumb:hover': {
          background: '#555',
        },
      };
    },
    indicatorsContainer: (styles: any) => {
      return {
        ...styles,
        height: 38,
        minHeight: 38,
      };
    },
    indicatorSeparator: (styles: any) => {
      return {
        ...styles,
        height: 15,
        marginTop: 4,
        display: 'none',
      };
    },
    option: (styles: any, state: State) => {
      return {
        ...styles,
        backgroundColor: state.isFocused ? `${getRgbaValue('#f3f3f3cc', 0.8)}` : null,
        color: state.isFocused ? `${COLORS.Blue}` : `${COLORS.Black}`,
        fontWeight: state.isFocused ? 500 : 400,
        borderRadius: `${rem(4)}`,
      };
    },
    multiValue: (styles: any) => {
      return {
        ...styles,
        height: `{auto}`,
        marginBottom: 0,
        marginTop: 0,
        marginLeft: 0,
        borderRadius: 4,
        // marginRight: 8,
      };
    },
    menuPortal: (styles: any) => ({
      ...styles,
      zIndex: 99999,
      width: menuWidth != null ? menuWidth : styles?.width,
    }),
    multiValueLabel: (styles: any) => {
      return {
        ...styles,
        fontSize: 16,
        lineHeight: `${rem(20)}`,
        padding: `${rem(6)} ${rem(4)}`,
      };
    },
  };

  const DropdownIndicator = (props: any): JSX.Element => {
    return (
      <components.DropdownIndicator {...props}>
        <span className='material-symbols-rounded'>arrow_drop_down</span>
      </components.DropdownIndicator>
    );
  };
  return (
    <FormWrapper className={`${containerClassName ?? ''} `}>
      {label != null && (
        <Label
          htmlFor={name}
          className={`flex flex-row items-center gap-x-3 FormTemplateEight-tooltip ${
            inputType === 'View' ? 'label-view' : ''
          } `}
        >
          {label} {!(required ?? false) && <span>(Optional)</span>}
          {tooltipText != null && (
            <Tooltip tooltipText={tooltipText} variant='dark' size='sm'>
              <span className='material-symbols-sharp'>info</span>
            </Tooltip>
          )}
        </Label>
      )}
      <DropdownContainer style={{ width: width ?? 'auto' }}>
        {multiple === true ? (
          <Select
            {...rest}
            id='color'
            menuPosition='fixed'
            menuPlacement='auto'
            // menuPosition='absolute'
            menuShouldBlockScroll
            styles={selectStyles}
            name={name}
            placeholder={placeholder}
            options={options}
            closeMenuOnSelect={true}
            isClearable={false}
            onChange={(option) => {
              const selectIds = option.map((item) => item?.value);
              setFieldValue?.(name, selectIds);
              onChange?.(selectIds);
            }}
            components={{ DropdownIndicator }}
            value={options?.filter((obj) => values?.includes(obj?.value))}
            isMulti={multiple}
            isDisabled={disabled}
            defaultValue={defaultValue}
            menuPortalTarget={document.body}
            minMenuHeight={200}
            maxMenuHeight={300}
          />
        ) : (
          <>
            {inputType === 'View' ? (
              <Select
                {...rest}
                id='color'
                menuPosition='fixed'
                menuPlacement='auto'
                // menuPosition='absolute'
                menuShouldBlockScroll
                // menuShouldScrollIntoView
                placeholder={
                  fieldLabelName &&
                  Boolean(CheckFieldLevelPermissions(fieldLabelName, isEdit, disabled))
                    ? arrValue === null
                      ? 'xxxxx'
                      : placeholder
                    : placeholder
                }
                styles={selectStyles}
                name={name}
                isClearable={isClearable}
                options={options}
                components={{ DropdownIndicator }}
                isDisabled={
                  fieldLabelName
                    ? Boolean(CheckFieldLevelPermissions(fieldLabelName, isEdit, disabled))
                    : disabled
                }
                value={
                  arrValue || values?.[name]
                    ? options?.find((obj) => obj?.value === (arrValue ? arrValue : values?.[name]))
                    : null
                }
                onChange={(option) => {
                  setFieldValue?.(name, option?.value);
                  onChange?.(option?.value);
                }}
                menuPortalTarget={document.body}
                minMenuHeight={200}
                maxMenuHeight={300}
              />
            ) : (
              <Select
                {...rest}
                id='color'
                menuPosition='fixed'
                menuPlacement='auto'
                menuShouldBlockScroll
                placeholder={
                  fieldLabelName &&
                  Boolean(CheckFieldLevelPermissions(fieldLabelName, isEdit, disabled))
                    ? arrValue === null
                      ? 'xxxxx'
                      : placeholder
                    : placeholder
                }
                styles={selectStyles}
                name={name}
                isClearable={isClearable}
                options={options}
                components={{ DropdownIndicator }}
                isDisabled={
                  fieldLabelName
                    ? Boolean(CheckFieldLevelPermissions(fieldLabelName, isEdit, disabled))
                    : disabled
                }
                value={
                  allOptions !== undefined && arrValue !== undefined
                    ? allOptions?.find((obj) => obj?.value === (arrValue ?? values?.[name]))
                    : // : arrValue != null ?? values?.[name]
                    arrValue ?? values?.[name]
                    ? options?.find((obj) => obj?.value === (arrValue ?? values?.[name]))
                    : null
                }
                onChange={(option) => {
                  setFieldValue?.(name, option?.value);
                  onChange?.(option?.value);
                }}
                menuPortalTarget={document.body}
                defaultValue={defaultValue}
                minMenuHeight={200}
                maxMenuHeight={300}
              />
            )}
          </>
        )}
        {!isTable && isFormik && (
          <ErrorMessage name={name} component='div' className='field-error-message mt-1' />
        )}
      </DropdownContainer>
    </FormWrapper>
  );
});

export default FormSelect;
