import { type UseQueryResult, useQuery } from 'react-query';
import {
  VendorUserService,
  // eslint-disable-next-line camelcase
  type Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_AdministrationService_Dtos_VendorUser_VendorUserViewDto_Wealthlane_AdministrationService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null,
} from '../../services/wealthlane-administration-services';
import { type TableParamsType } from '../../types/common';

const useGetAllAdminUserList = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  tableInfo: TableParamsType,
  status?: number[],
  userStatus?: string
): UseQueryResult<
  // eslint-disable-next-line camelcase
  | Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_AdministrationService_Dtos_VendorUser_VendorUserViewDto_Wealthlane_AdministrationService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null
  | undefined,
  unknown
> => {
  const { filter, sorting, skipCount, maxResultCount } = tableInfo;

  const newUserStatus =
    userStatus === 'active' ? true : userStatus === 'deactivated' ? false : undefined;
  const allAdminUserList = useQuery(
    ['getAllAdminUserList', status, userStatus, filter, sorting, skipCount, maxResultCount],
    async () => {
      return await handleRequest(
        VendorUserService.getApiAdministrationApiAppVendorUserVendorUserList(
          status,
          newUserStatus,
          filter,
          sorting,
          skipCount,
          maxResultCount
        )
      );
    }
  );
  if (allAdminUserList.status === 'error') {
    console.log('Error occurred:', allAdminUserList.error);
  }
  return allAdminUserList;
};

export default useGetAllAdminUserList;
