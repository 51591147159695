import { COLORS } from './constants/style/color';
import { createGlobalStyle } from 'styled-components/macro';
import { DEFAULT_FONT_SIZE } from './constants/common';
import { getViewHeight, rem } from './utils/style';

export default createGlobalStyle`

  *,
  *::before,
  *::after {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: 'HK Grotesk', sans-serif;
    font-weight: 400;
  }

  html {
    font-size: ${DEFAULT_FONT_SIZE}px; /* 10px or 62.5% */
    -webkit-text-size-adjust: 100%;
    text-rendering:optimizeLegibility;
  }

  body {
    color: ${COLORS.Black};
    background-color: ${COLORS.White};
    overflow-x: hidden;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    
  }

  .h-normal-screen {
    height: ${getViewHeight('67px')};
  }

  .material-symbols-outlined, .material-symbols-rounded, .material-symbols-sharp {
    font-variation-settings: 'FILL' 0, 'wght' 400, 'GRAD' 0, 'opsz' 48;
  }

  .material-symbols-outlined-filled, .material-symbols-rounded-filled, .material-symbols-sharp-filled {
    font-variation-settings: 'FILL' 1, 'wght' 400, 'GRAD' 0, 'opsz' 48;
  }

  .toast-container {
    background: ${COLORS.White};
    border-radius: ${rem(15)};
    color: ${COLORS.Gray30};
    border-radius: 15px;

    box-shadow: inset -4px -1px 5px rgba(232, 232, 232, 0.23);
    padding: ${rem(14)} ${rem(18)};
    display: flex;
    align-items: center;
    font-size: ${rem(12)};
  }

  .Toastify__toast--success {
    filter: drop-shadow(0px -1px 7px rgba(5, 185, 55, 0.2));
  }
  .Toastify__toast--error {
    filter: drop-shadow(0px -1px 7px rgba(221, 37, 37, 0.2));
  }
  .Toastify__toast--info {
    filter: drop-shadow(0px -1px 7px rgba(28, 133, 231, 0.2));
  }
  .Toastify__close-button {
  }
  .Toastify__toast-body {
    margin: 0;
    padding: 0;
  }

  .form-gap {
    margin-bottom: ${rem(14)};
  }
  fieldset {
    margin-bottom: ${rem(14)};
  }
  .formGroup {
    margin-bottom: ${rem(18)};
  }

  /* animation sidebar */
  .sideBar {
    z-index: 999999;
    opacity: 0;
    animation: 500ms fadeOut ease-in-out;
    &-entered {
      animation: 500ms fadeIn ease-in-out;
      display: block;
      opacity: 1;
      height: 100%;
    }
    &-entering {
    }
    &-exited {
      animation: 500ms fadeOut ease-in-out;
      opacity: 0;
    }
  }
  .alertPopup {
    z-index: 999999;
    opacity: 0;
    animation: 500ms fadeOut ease-in-out;
    &-entered {
      animation: 500ms fadeIn ease-in-out;
      display: block;
      opacity: 1;
    }
    &-entering {
    }
    &-exited {
      animation: 500ms fadeOut ease-in-out;
      opacity: 0;
    }
  }
  .modalBackdrop {
    background: rgba(0, 0, 0, 0);
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    /* transition: all 500ms ease-in-out; */
    z-index: -100;
    opacity: 0;
    visibility: hidden;
    &-entered {
      animation: 300ms backdropFadeIn ease-in-out;
      background: rgba(22, 22, 22, 0.85);
      /* z-index: 100; */
      z-index: 999;
      opacity: 1;
      visibility: visible;
    }
    &-exited {
      animation: 300ms backdropFadeOut ease-in-out;
      background-color: rgba(0, 0, 0, 0);
      z-index: -100;
      opacity: 0;
      visibility: hidden;
    }
  }

  @keyframes backdropFadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes backdropFadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  @keyframes fadeIn {
    0% {
      opacity: 0;
      transform: translateX(20rem);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }

  @keyframes fadeOut {
    0% {
      opacity: 1;
      transform: translateX(0);
    }
    100% {
      opacity: 0;
      transform: translateX(20rem);
    }
  }

  /* main layout */
  .wrapperContainer {
    display: flex;
    background:white;
    .aside {
      width: 240px;
    }
  }
  .profile-header{
      button {
    /* padding: 6px 8px; */
    border:none;
  }
      
  }

  
.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  opacity: 1;
  transition: opacity 400ms ease-in-out;
}
.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
  transition: opacity 400ms ease-in-out;
}

.needHelp {
    width: 100%;
    img {
      margin-right: 12px;
    }
    padding: 18px 24px;
    background: linear-gradient(90.44deg, rgba(255, 255, 255, 0.65) 39.11%, #ffffff 96.9%);
    border-radius: 10px;
    display: flex;
    align-items: center;
  }

    .delete-label {
    color: ${COLORS.Red};
  }

  .description-textarea{
    padding: 7px;
    border: 1px solid #D7D7D7;
    font-size: 16px;
    border-radius: 0.6rem;
    resize: none;
    margin: 5px 0px;
    width: 100%;

    &:disabled{
      background-color: #F3F3F3;
    border: 0.1rem solid #D7D7D7;
    }
  }


  .methodTable{
  background:#f3f3f380;
  border-radius: 6px;
  padding:17px 20px 10px 24px;
  font-size: 14px;
  max-height: 400px;
  overflow: auto;

  ::-webkit-scrollbar {
        height: 5px;
        width: 5px;
      }

      /* Track */
      ::-webkit-scrollbar-track {
        background: #f1f1f1;
        border-radius: 7px;
      }

      /* Handle */
      ::-webkit-scrollbar-thumb {
        background: ${COLORS.SpanishGray};
        border-radius: 7px;
      }

      /* Handle on hover */
      ::-webkit-scrollbar-thumb:hover {
        background: #555;
      }
  &:not(:last-child){
    margin-bottom:12px;

  }
  &BoxTitle{
    margin-bottom:18px;
  }
  &Grid{
    grid-column-gap:12.4rem ;
  }
  &Tag{
    margin-left: ${rem(5)} ;
  }
  table{
    width:100%;
    thead{
      tr{
        td{
          font-weight: 900;
          padding-bottom:15px;
        }
      }
    }
  }
  tbody{
    tr{
      &:first-child{
        td{

          padding-top:14;
        }
      }
      &:not(:last-child){
        border-bottom: 1px solid #D7D7D7;
      }
      
      td{
        width: 200px;
        padding:14px 0;
        span{
          color:#595959;
        }
        &:last-child{
          span{
            justify-content:flex-end ;
          }
        }
      }
      &.bold{
         td{
            span{
              font-weight:600;
              color:${COLORS.Black}
            }
          }
      }
    }
  }
}

.notification-delete {
  color: ${COLORS.Red};
}
`;
