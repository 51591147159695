import { getViewHeight } from '../../utils/style';
import styled from 'styled-components';
import { COLORS } from '../../constants/style/color';
import { rem } from '../../utils';

interface OuterNavbarContainerType {
  hasNotify?: boolean;
}

export const OuterNavbarContainer = styled.div<OuterNavbarContainerType>`
  height: 67px;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  position: sticky;
  top: ${(props) => (props.hasNotify ?? false ? '34px' : '0px')};
  z-index: 1200;
  background: ${COLORS.White};
  position: fixed;
  width: 100vw;
`;

export const NavMenuContainer = styled.div`
  display: none;
  justify-content: center;
  align-items: center;
  gap: 10px;

  .navMenuItem {
    height: 67px;
    position: relative;
  }

  @media (min-width: 768px) {
    display: flex;
    gap: 2px;
  }

  @media (min-width: 1200px) {
    font-size: ${rem(15)};
    gap: 10px;
  }
`;

export const NavMenuCon = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  pointer-events: auto;
  cursor: pointer;
  margin: 0 5px;

  > a {
    font-weight: 400;
    font-size: ${rem(16)};
    line-height: ${rem(22)};
    color: ${COLORS.Black};
    display: inline-flex;
    align-items: center;
    transition: all 0.2s linear;
    cursor: pointer;
    height: 100%;
    position: relative;

    &:hover {
      &:after {
        content: '';
        height: 3px;
        width: 100%;
        background: ${COLORS.Purple};
        position: absolute;
        left: 0;
        bottom: 0;
        transition: all 0.2s linear;
      }
    }

    &:after {
      content: '';
      height: 3px;
      width: 0;
      background: ${COLORS.Purple};
      position: absolute;
      left: 0;
      bottom: 0;
      transition: all 0.2s linear;
    }

    &.active {
      color: ${COLORS.Purple};
      &:after {
        content: '';
        width: 100%;
      }
    }

    @media (min-width: 1500px) {
      font-size: ${rem(16)};
    }
  }
  .submenu {
    display: none;
  }
  :hover {
    .submenu {
      display: flex;
    }
  }
`;

// Profile Dropdown
export const ProfileDropdownContainer = styled.div`
  gap: 7px;
  display: none;
  align-items: center;
  font-size: 15px;
  color: ${COLORS.Gray60};
  width: auto;
  height: 100%;

  .bellIcon {
    font-size: 34px;
    color: ${COLORS.SpanishGray};
  }
  span {
    cursor: pointer;
  }

  .title {
    display: block;
    font-weight: 500;
    font-size: 8px;
    line-height: 22px;
    padding: 12px 12px;

    color: ${COLORS.Black};

    @media (min-width: 768px) {
      font-size: 9px;
    }

    @media (min-width: 992px) {
      font-size: 16px;
    }
  }

  @media (min-width: 768px) {
    display: flex;
  }

  .profile-dropdown {
    margin-left: 4px;
    color: ${COLORS.Blue};
  }
`;

export const ProfileDropWrapper = styled.div`
  position: relative;
`;

export const ProfileDrop = styled.div`
  display: flex;
  height: 100%;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  flex-grow: 1;
  border: 1px solid ${COLORS.LightGray};
  border-radius: 6px;

  .downIcon {
    color: ${COLORS.Gray};
    margin-right: 7px;
    margin-top: 5px;
  }
`;

export const ProfileDropdownMenu = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 245px;
  right: 0;
  top: 64px;
  z-index: 1;
  background: ${COLORS.White};

  box-shadow: 0px 2px 20px ${COLORS.SoftShadow};
  border-radius: 11px;
  padding: 10px 13px;

  &.nav-subMenu {
    left: 0;
    top: 73px;
    right: reset;
    justify-content: flex-start;

    a {
      width: 100%;
      justify-content: flex-start;
      color: ${COLORS.SpanishGray};
      font-size: ${rem(14)};
    }
  }
`;

export const DropDownMenuSuperAdmin = styled.div`
  color: ${COLORS.Black};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-weight: 400;
  border-radius: 4px;
  padding: 10px 14px;
  transition: all 0.2s linear;
  cursor: pointer;
  font-size: 1.4rem;
  line-height: 1.8rem;

  &.logoutBtn {
    color: ${COLORS.SpanishGray};
  }

  :hover {
    background: ${COLORS.LightPurple};
    color: ${COLORS.Purple};
    font-weight: 500;
  }
`;

// Task Menu
export const TaskSubMenuDropDown = styled.div`
  z-index: 100;
  position: absolute;
  padding: 4px;
  top: 64px;
  background: rgba(255, 255, 255, 0.98);
  box-shadow: 0px 2px 20px ${COLORS.SoftShadow};
  border-radius: 5px;
  text-align: left;
  right: 0;

  span {
    font-size: 12px;
  }
`;

export const DropDownMenuAdmin = styled.div`
  display: flex;
  align-items: center;
  color: ${COLORS.Blue};
  cursor: pointer;
  border-bottom: 0.5px solid ${COLORS.Gray80};

  a {
    font-size: 12px;
    white-space: nowrap;

    color: ${COLORS.Black};
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    border-radius: 4px;
    padding: 10px 14px;
    transition: all 0.2s linear;
    cursor: pointer;
    &.logoutBtn {
      color: ${COLORS.SpanishGray};
    }
    :hover {
      background: ${COLORS.LightPurple};
      color: ${COLORS.Purple};
      font-weight: 500;
    }
  }

  &:last-child {
    border-bottom: none;
  }
`;

export const HeaderNotice = styled.div`
  background-color: ${COLORS.CulturedWhite};
  padding: ${rem(0)} ${rem(20)};
  min-height: 34px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: ${rem(48)};
  position: fixed;
  z-index: 1200;
  width: 100%;
  top: 0;
  transition: all 0.2s linear;

  .closeIcon {
    color: ${COLORS.SpanishGray};
    position: absolute;
    top: 50%;
    right: 24px;
    transform: translateY(-50%);
    cursor: pointer;
  }

  @media (max-width: 512px) {
    .notice-header {
      font-size: ${rem(12)};
      line-height: ${rem(14)};
    }
  }
`;

// new navbar design
export const NavbarContainerMain = styled.nav<OuterNavbarContainerType>`
  height: 67px;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  top: 0;
  z-index: 1200;
  background: ${COLORS.White};
  position: fixed;
  width: 100vw;
  padding: ${rem(0)} ${rem(20)};

  display: flex;
  align-items: center;
  justify-content: space-between;

  .nav {
    &-brand {
      width: min(191px, 90%);
    }

    &-mobile {
      display: flex;

      @media (min-width: 768px) {
        display: none;
      }

      .nav-menu {
        border-radius: 30px;
        padding: ${rem(7)} ${rem(12)};

        span {
          padding-right: ${rem(7)};
          font-size: ${rem(20)};
        }
      }

      &--heading {
        color: rgb(119 119 119 / 60%);
        text-transform: uppercase;
        font-weight: 500;
        margin-bottom: ${rem(27)};
      }

      .nav-close {
        position: absolute;
        bottom: 18px;
        left: 50%;
        transform: translate(-50%, 0px);
      }

      &--close {
        border-radius: 30px;
        border: none;
        padding: ${rem(16)} ${rem(24)};

        font-weight: 400;
        font-size: ${rem(14)};
        line-height: ${rem(18)};

        span {
          padding-right: ${rem(7)};
          font-size: ${rem(12)};
        }
      }
    }
  }
`;

export const NavMenuMobileContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  width: 100%;
  background: ${COLORS.OffWhite};
  transition: 0.3s;
  overflow-y: auto;
  height: ${getViewHeight()};
  top: 0;
  left: 0;

  @media (min-width: 768px) {
    width: 40%;
  }

  @media (min-width: 1024px) {
    display: none;
  }
`;

export const MobileNavItem = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 100%;
  align-items: center;
  padding: ${rem(37)} ${rem(20)};
  border-bottom: 1px solid rgba(215, 215, 215, 50%);

  .nav-items {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    row-gap: ${rem(26)};

    & a:hover span {
      color: ${COLORS.Purple};
    }
  }

  a {
    font-weight: 600;
    font-size: 15px;
    line-height: 19px;
    color: ${COLORS.Blue};
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .submenu {
    display: block;
  }

  a.active {
    background: ${COLORS.Blue};
    color: ${COLORS.White};
    padding-left: 20px;

    @media (max-width: 768px) {
      background: transparent;

      span {
        font-weight: 500;
        color: ${COLORS.Blue};
      }
    }
  }
`;
