import { type UseQueryResult, useQuery } from 'react-query';
import {
  VendorUserApprovalProcessService,
  // eslint-disable-next-line camelcase
  type Wealthlane_AdministrationService_Dtos_VendorUserApproval_ApprovalProcessDto,
} from '../../services/wealthlane-administration-services';

const useGetApprovalProcessList = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>
): UseQueryResult<
  // eslint-disable-next-line camelcase
  Wealthlane_AdministrationService_Dtos_VendorUserApproval_ApprovalProcessDto[] | undefined,
  unknown
> => {
  const approvalProcessList = useQuery(['getApprovalProcessList'], async () => {
    return await handleRequest(
      VendorUserApprovalProcessService.getApiAdministrationApiAppVendorUserApprovalProcessApprovalProcess()
    );
  });

  if (approvalProcessList.status === 'error') {
    console.log('Error occurred:', approvalProcessList.error);
  }

  return approvalProcessList;
};

export default useGetApprovalProcessList;
