import React, { useState } from 'react';
import { PageHeader } from '../../../../constants/style/common.styled';
import { Heading } from '../../../../components';
import Table from '../../../../components/table';
import { COLORS } from '../../../../constants/style/color';
import { companyTaxSetupListHeader } from '../../../../constants/table-headers/companyTaxSetupListHeader';
import { useGetCompanyTaxSetupList, useWealthlaneTaxApi } from '../../../../hooks';
import { TableParamsType } from '../../../../types/common';
import InitialValuesForm from '../../../../constants/initialValues';

const CompanyTaxSetupList = () => {
  const { handleRequest } = useWealthlaneTaxApi();
  const [tableInfo, setTableInfo] = useState<TableParamsType>(InitialValuesForm.tableParams);
  const { data, status } = useGetCompanyTaxSetupList(handleRequest, tableInfo);

  return (
    <div>
      <div className='flex justify-between items-center'>
        <PageHeader>
          <Heading variant='h3' title={`Tax Period`} />
          <Heading
            variant='body-s'
            title={`View all the users present in your company.`}
            color={`${COLORS.Gray}`}
          />
        </PageHeader>
      </div>
      <Table
        columns={companyTaxSetupListHeader()}
        data={data?.items ?? []}
        loading={status}
        totalCounts={data?.totalCount ?? 10}
        showPagination={true}
        tableInfo={tableInfo}
        setTableInfo={setTableInfo}
        // setIsHovering={setIsHovering}
      />
    </div>
  );
};

export default CompanyTaxSetupList;
