import { commonValidation } from './validationRule';

export const GlobalValidation = {
  Name: commonValidation({
    required: true,
    noBlank: true,
    doubleSpace: true,
    textLimit: 100,
  }),
  Email: commonValidation({ required: true, email: true }),
  Phone: commonValidation({
    required: true,
    numericPhone: true,
    noBlank: true,
  }),
  Description: commonValidation({ required: false, textLimit: 1000 }),
  ZipCode: commonValidation({ required: true, digits: true, noBlank: true, min: 5, max: 5 }),
  AddressLine1: commonValidation({
    required: true,
    noBlank: true,
    doubleSpace: true,
    alphanumericDot: true,
  }),
  AddressLine2: commonValidation({
    required: false,
    noBlank: true,
    doubleSpace: true,
    alphanumericDot: true,
  }),
  DigitWithComma: commonValidation({
    required: true,
    digitsWithComma: true,
    noBlank: true,
  }),
  RequiredNoBlank: commonValidation({ required: true, noBlank: true }),
  RequiredNoBlankAlphanumeric: commonValidation({
    required: true,
    alphanumeric: true,
    noBlank: true,
  }),
  Date: commonValidation({
    required: true,
    isDate: true,
  }),
};
