import type React from 'react';
import { useEffect } from 'react';
import { type TableParamsType } from '../../types/common';
import { SearchContainer } from './search.style';
interface SearchType {
  placeholder?: string;
  onSearch?: any;
  className?: string;
  searchValue?: string;
  setTableInfo?: any;
  tableInfo?: TableParamsType;
}

const Search = ({
  placeholder,
  onSearch,
  className,
  searchValue = '',
  setTableInfo,
  tableInfo,
  ...rest
}: SearchType): JSX.Element => {
  const handleSearch = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    if (event.key === 'Enter' && onSearch !== undefined) {
      onSearch(searchValue);
    }
  };

  const handleClickSearch = (): void => {
    if (onSearch !== undefined && typeof searchValue === 'string') {
      onSearch(searchValue);
    } else if (onSearch === true && searchValue === '') {
      onSearch(' ');
    }
  };

  const handleChangeSearch = (value: string): void => {
    onSearch(value);
  };

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      if (setTableInfo !== undefined) {
        await setTableInfo({
          ...tableInfo,
          skipCount: 0,
          filter: searchValue,
        });
      }
    };

    const timer = setTimeout(() => {
      fetchData().catch((error) => {
        // Handle the error here
        console.error(error);
      });
    }, 500);

    return () => {
      clearTimeout(timer);
    };
  }, [searchValue]);

  return (
    <SearchContainer {...rest}>
      <input
        autoComplete='off'
        name='search'
        className={className}
        placeholder={placeholder}
        type='text'
        value={searchValue}
        onKeyUp={handleSearch}
        onChange={(e) => {
          handleChangeSearch(e.target.value);
        }}
      />

      {searchValue !== '' ? (
        <span
          className='material-symbols-outlined close'
          onClick={() => {
            onSearch('');
          }}
        >
          close
        </span>
      ) : (
        <span className='material-symbols-outlined search' onClick={handleClickSearch}>
          search
        </span>
      )}
    </SearchContainer>
  );
};
export default Search;
