/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Wealthlane_TaxService_Dtos_State_StateDto } from '../models/Wealthlane_TaxService_Dtos_State_StateDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class StateService {

    /**
     * @returns Wealthlane_TaxService_Dtos_State_StateDto Success
     * @throws ApiError
     */
    public static getApiTaxApiAppStateState(): CancelablePromise<Array<Wealthlane_TaxService_Dtos_State_StateDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/tax/api/app/state/state',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param countryId 
     * @returns Wealthlane_TaxService_Dtos_State_StateDto Success
     * @throws ApiError
     */
    public static getApiTaxApiAppStateStateByCountry(
countryId: string,
): CancelablePromise<Array<Wealthlane_TaxService_Dtos_State_StateDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/tax/api/app/state/state-by-country/{CountryId}',
            path: {
                'CountryId': countryId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param jurisdictionId 
     * @param countryId 
     * @returns Wealthlane_TaxService_Dtos_State_StateDto Success
     * @throws ApiError
     */
    public static getApiTaxApiAppStateStateByJurisdiction(
jurisdictionId?: string,
countryId?: string,
): CancelablePromise<Array<Wealthlane_TaxService_Dtos_State_StateDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/tax/api/app/state/state-by-jurisdiction',
            query: {
                'JurisdictionId': jurisdictionId,
                'CountryId': countryId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

}
