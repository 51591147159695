import { Form, Formik } from 'formik';

import useWealthlaneTaxApi from '../../../../hooks/useWealthlaneTaxApi';
import CustomDatePicker from '../../../../components/date-picker/customDatePicker';
import { ModalStatusType } from '../../../../types/common';
import { TAX_PERIOD_VALIDATION } from '../../../../utils/validations/validations';

import _ from 'lodash';
import ModalBodyComponent from '../../../../components/modal/modalBodyComponent';
import InputText from '../../../../components/input-text/inputText';
import ModalComponent from '../../../../components/modal/modal';
import ModalFooterComponent from '../../../../components/modal/modalFooterComponent';
import usePostTaxPeriod from '../../../../hooks/post/usePostTaxPeriod';

interface TaxYearType {
  isModalOpen: boolean;
  closeModal: () => void;
  modalStatus: ModalStatusType;
  setModalStatus: (value: ModalStatusType) => void;
  loading?: boolean;
  initialValue: any;
  setInitialValue: any;
}

const TaxYearModal = ({
  isModalOpen,
  closeModal,
  modalStatus,
  setModalStatus,
  loading,
  initialValue,
  setInitialValue,
}: TaxYearType) => {
  const { handleRequest } = useWealthlaneTaxApi();
  const { mutate, isLoading } = usePostTaxPeriod(handleRequest, closeModal, initialValue?.id);
  const handleSubmit = (values: any) => {
    mutate(values);
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValue}
      validationSchema={TAX_PERIOD_VALIDATION}
      onSubmit={(values) => handleSubmit(values)}
    >
      {({ touched, handleChange, errors, setFieldValue, setFieldTouched, values }) => {
        return (
          <ModalComponent
            show={isModalOpen}
            closeModal={closeModal}
            loading={loading}
            valueEdited={_.isEqual(values, initialValue)}
            size='sm'
            // title={`Edit Federal Tax`}
            title={`${modalStatus === 'Add' ? 'Create' : modalStatus} Tax Period`}
            children={
              <Form>
                <ModalBodyComponent>
                  <div className=''>
                    <div className=''>
                      <InputText
                        type='text'
                        label='Tax Period Name'
                        name='name'
                        onChange={handleChange}
                        errors={errors}
                        touched={touched}
                        inputType={modalStatus}
                        disabled={modalStatus === 'View' ? true : false}
                        required
                      />
                    </div>
                    <div className='flex gap-x-8'>
                      <CustomDatePicker
                        label='Start Date'
                        arrValue={values?.effectiveFrom}
                        name={`effectiveFrom`}
                        onChange={handleChange}
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                        errors={errors}
                        touched={touched}
                        errorValue={errors?.effectiveFrom}
                        touchedValue={touched?.effectiveFrom}
                        defaultValue={new Date()}
                        disabled={modalStatus === 'View' ? true : false}
                        fieldLabelName='test'
                        required
                      />
                      <CustomDatePicker
                        label='End Date'
                        name='effectiveTill'
                        onChange={handleChange}
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                        errors={errors}
                        touched={touched}
                        errorValue={errors?.effectiveTill}
                        touchedValue={touched?.effectiveTill}
                        arrValue={values?.effectiveTill}
                        // values={values}
                        min={values?.effectiveFrom}
                        defaultValue={new Date()}
                        disabled={modalStatus && modalStatus === 'View' ? true : false}
                        fieldLabelName='test'
                        required
                      />
                    </div>
                  </div>
                </ModalBodyComponent>
                <ModalFooterComponent
                  modalStatus={modalStatus}
                  loading={isLoading}
                  // titleCollection={{ reviewTitle: 'Review & Confirm', cancelTitle: 'Go Back' }}
                  onCloseModal={() => {
                    setInitialValue('');
                    closeModal();
                  }}
                  valueEdited={_.isEqual(values, initialValue)}
                  onEditModal={() => setModalStatus('Edit')}
                />
              </Form>
            }
          />
        );
      }}
    </Formik>
  );
};

export default TaxYearModal;
