import styled from 'styled-components';
import { COLORS } from '../../../constants/style/color';
import { rem } from '../../../utils/style';

export const SystemUserContainer = styled.div`
  h3 {
    margin-bottom: ${rem(5)};
  }

  .search-wrap {
    width: 100%;

    @media (min-width: 715px) {
      width: ${rem(350)};
    }
  }
`;
export const SettingSystemUserHeader = styled.div`
  margin-bottom: ${rem(25)};
  h3 {
    margin-bottom: ${rem(5)};
  }
`;
export const SettingSystemUserButton = styled.div`
  display: flex;
  justify-content: end;
  margin-bottom: ${rem(24)};
`;
export const Note = styled.div`
  margin-top: ${rem(12)};
  /* padding-bottom: ${rem(24)}; */
  /* border-bottom: ${rem(1)} solid ${COLORS.CulturedWhite}; */
`;
