import Badge from '../../badge';

interface NotificationType {
  isModalOpen: boolean;
  openModal: () => void;
  NotificationCount?: number;
}

const Notification = ({
  NotificationCount,
  isModalOpen,
  openModal,
}: NotificationType): JSX.Element => {
  return (
    <Badge
      variant='primary'
      icon='notifications'
      count={NotificationCount}
      isActive={isModalOpen}
      onClick={() => {
        openModal();
      }}
    />
  );
};

export default Notification;
