import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { TaxPeriodService } from '../../services/wealthlane-tax-services';
import ToastComponent from '../../components/toast';

const usePostTaxPeriod = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  closeModal: () => void,
  id?: string
) => {
  const queryClient = useQueryClient();
  return useMutation(
    async (payload: any) => {
      if (id) {
        return await handleRequest(TaxPeriodService.putApiTaxApiAppTaxPeriodTaxPeriodForVendor(payload));
      } else {
        return await handleRequest(TaxPeriodService.postApiTaxApiAppTaxPeriodTaxPeriodForVendor(payload));
      }
    },
    {
      onSuccess: (response: any) => {
        if (response) {
          queryClient.invalidateQueries('getTaxPeriodTableList');
          closeModal();
          toast.success(
            <ToastComponent label='Success' message={`Tax period ${id ? 'edited' : 'added'}`} />
          );
        }
      },
      onError: (err) => {
        console.log('error', err);
      },
    }
  );
};

export default usePostTaxPeriod;
