import { useQuery } from 'react-query';
import { TableParamsType } from '../../types/common';
import { VendorTaxItemService } from '../../services/wealthlane-tax-services';

const useGetTaxSetupList = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  tableInfo: TableParamsType
) => {
  const { taxPeriodId, taxApprovalStatusId, filter, sorting, skipCount, maxResultCount } =
    tableInfo;
  const taxSetupList = useQuery(['getTaxSetupList', tableInfo], async () => {
    return await handleRequest(
      VendorTaxItemService.getApiTaxApiAppVendorTaxItemTaxItemList(
        taxPeriodId,
        taxApprovalStatusId,
        filter,
        sorting,
        skipCount,
        maxResultCount
      )
    );
  });

  return taxSetupList;
};

export default useGetTaxSetupList;
