import { type UseMutationResult, useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import ToastComponent from '../../components/toast';
import { VendorUserApprovalProcessService } from '../../services/wealthlane-administration-services';

const usePostVendorApprovalConfig = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  isCurrentUser: boolean
): UseMutationResult => {
  const queryClient = useQueryClient();
  return useMutation(
    async (payload: any) => {
      return await handleRequest(
        VendorUserApprovalProcessService.putApiAdministrationApiAppVendorUserApprovalProcessVendorApprovalProcess(
          payload
        )
      );
    },
    {
      onSuccess: async (response: any) => {
        if (response !== null && response !== undefined) {
          void queryClient.resetQueries('getAllVendorApprovalProcess');
          toast.success(<ToastComponent label='Success' message='System approvers saved' />);
          void queryClient.invalidateQueries('getVendorUserList');
          void queryClient.invalidateQueries('getAllVendorApprovalProcess');
          void queryClient.invalidateQueries('getCustomerUserCustomerUserList');
          void queryClient.invalidateQueries('getApprovalProcessList');

          if (isCurrentUser) {
            window.dispatchEvent(new CustomEvent('PERMISSION_CHANGED', { detail: 'RELOAD' }));
          }
        }
      },
    }
  );
};

export default usePostVendorApprovalConfig;
