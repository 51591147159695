import styled from 'styled-components';
import { COLORS } from '../../constants/style/color';
import { rem } from '../../utils/style';

interface ToastType {
  borderColor?: string;
  labelType?: 'Success' | 'Error' | 'Info' | '';
}

export const NotificationWrapper: any = styled.div<ToastType>`
  display: flex;
  gap: ${rem(10)};
  align-items: center;
  justify-content: space-between;

  span {
    font-size: ${rem(24)};
    color: ${(props) =>
      props.labelType === 'Success'
        ? `#05B937`
        : props.labelType === 'Error'
        ? `#DD2525`
        : `#1C85E7`};
  }

  .closeIcon {
    color: ${COLORS.LightGray};
    font-size: ${rem(20)};
  }
`;

export const NotificationContent: any = styled.div<ToastType>`
  .notification-title {
    display: flex;
    align-items: center;
    font-size: ${rem(16)};
    font-weight: 500;
    line-height: ${rem(18)};
    padding-bottom: ${rem(3)};
    color: ${(props) =>
      props.labelType === 'Success'
        ? `#05B937`
        : props.labelType === 'Error'
        ? `#DD2525`
        : `#1C85E7`};
  }
  .notification-message {
    display: flex;
    align-items: center;
    font-size: ${rem(14)};
    font-weight: 400;
    line-height: ${rem(18)};
  }
`;
