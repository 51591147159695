import { isEmpty } from 'lodash';
import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import ToastComponent from '../components/toast';
import { selectToken } from '../auth/authSlice';
import { type ApiError, OpenAPI } from '../services/wealthlane-administration-services';

const useWealthlaneAdministrationApi = (): {
  dismissError: () => void;
  error: ApiError | undefined;
  isLoading: boolean;
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>;
} => {
  const token = useSelector(selectToken);
  const [error, setError] = useState<ApiError | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  OpenAPI.BASE = process.env.REACT_APP_WEALTHLANE_API_ENDPOINT ?? '';
  OpenAPI.TOKEN = token?.toString();

  const handleRequest = useCallback(async function <T>(request: Promise<T>) {
    setIsLoading(true);
    try {
      const response = await request;
      setError(undefined);
      // if (response === null || response === undefined) {
      //   toast.error(<ToastComponent label='Error' message={'Unable to fetch data'} />, {
      //     toastId: 2,
      //   });
      // }
      return response;
    } catch (exception: any) {
      setError(exception);

      if (Array.isArray(exception?.body?.error?.validationErrors)) {
        const error = exception?.body?.error?.validationErrors?.map((error: any) => {
          return error.message;
        });
        toast.error(<ToastComponent label='Error' message={error.join(', ')} />, {
          toastId: 1,
        });
      } else if (
        exception?.body?.error?.message?.includes('Index was outside the bounds of the array.') ===
        true
      ) {
        toast.error(<ToastComponent label='Error' message='Invalid data format' />);
      } else if (exception?.body?.error !== null && exception?.body?.error !== undefined) {
        toast.error(
          <ToastComponent label='Error' message={`${exception?.body?.error?.message as string}`} />
        );
      } else if (
        exception.status === 401 ||
        (exception.status === 403 && isEmpty(exception?.body))
      ) {
        // Handle specific cases if needed
      } else {
        toast.error(<ToastComponent label='Error' message={'Unable to fetch data'} />, {
          toastId: 2,
        });
      }
    } finally {
      setIsLoading(false);
    }
  }, []);

  function dismissError(): void {
    setError(undefined);
  }

  return { dismissError, error, isLoading, handleRequest };
};

export default useWealthlaneAdministrationApi;
