/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_AwardService_Dtos_Grant_GrantListDto_Wealthlane_AwardService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null } from '../models/Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_AwardService_Dtos_Grant_GrantListDto_Wealthlane_AwardService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null';
import type { Wealthlane_AwardService_Dtos_Grant_GetGrantBlobRequestDto } from '../models/Wealthlane_AwardService_Dtos_Grant_GetGrantBlobRequestDto';
import type { Wealthlane_AwardService_Dtos_Grant_GrantBlobDto } from '../models/Wealthlane_AwardService_Dtos_Grant_GrantBlobDto';
import type { Wealthlane_AwardService_Dtos_Grant_GrantDto } from '../models/Wealthlane_AwardService_Dtos_Grant_GrantDto';
import type { Wealthlane_AwardService_Dtos_Grant_GrantListDto } from '../models/Wealthlane_AwardService_Dtos_Grant_GrantListDto';
import type { Wealthlane_AwardService_Dtos_Grant_GrantTermsAndConditionsBlobDto } from '../models/Wealthlane_AwardService_Dtos_Grant_GrantTermsAndConditionsBlobDto';
import type { Wealthlane_AwardService_Dtos_Grant_GrantTermsAndConditionsDto } from '../models/Wealthlane_AwardService_Dtos_Grant_GrantTermsAndConditionsDto';
import type { Wealthlane_AwardService_Dtos_Grant_GrantUpdateDocumentDto } from '../models/Wealthlane_AwardService_Dtos_Grant_GrantUpdateDocumentDto';
import type { Wealthlane_AwardService_Dtos_Grant_PlanNameDto } from '../models/Wealthlane_AwardService_Dtos_Grant_PlanNameDto';
import type { Wealthlane_AwardService_Dtos_Grant_PlanTypeDto } from '../models/Wealthlane_AwardService_Dtos_Grant_PlanTypeDto';
import type { Wealthlane_AwardService_Dtos_Response_ResponseDto } from '../models/Wealthlane_AwardService_Dtos_Response_ResponseDto';
import type { Wealthlane_AwardService_Dtos_RSUAward_GetValuesDTO } from '../models/Wealthlane_AwardService_Dtos_RSUAward_GetValuesDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class GrantService {

    /**
     * @returns Wealthlane_AwardService_Dtos_Grant_PlanTypeDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppGrantPlanTypes(): CancelablePromise<Array<Wealthlane_AwardService_Dtos_Grant_PlanTypeDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/grant/plan-types',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param planId 
     * @returns Wealthlane_AwardService_Dtos_Grant_PlanTypeDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppGrantPlanTypesByPlanId(
planId: string,
): CancelablePromise<Array<Wealthlane_AwardService_Dtos_Grant_PlanTypeDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/grant/plan-types-by-plan-id/{PlanId}',
            path: {
                'PlanId': planId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param formData 
     * @returns Wealthlane_AwardService_Dtos_Grant_GrantDto Success
     * @throws ApiError
     */
    public static postApiAwardApiAppGrantGrant(
formData?: {
PlanId: string;
PlanTypeId: string;
GrantName: string;
GrantDocument: Array<Blob>;
VestingScheduleId: string;
ApprovedDate: string;
TermsAndConditionsDocumentContent?: string;
IsActive: boolean;
TermsAndConditionsDocument?: Blob;
TermsAndConditionsAgreementStatement: string;
},
): CancelablePromise<Wealthlane_AwardService_Dtos_Grant_GrantDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/award/api/app/grant/grant',
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param formData 
     * @returns Wealthlane_AwardService_Dtos_Grant_GrantDto Success
     * @throws ApiError
     */
    public static putApiAwardApiAppGrantGrant(
formData?: {
PlanId: string;
PlanTypeId: string;
GrantName: string;
GrantDocumentDetails?: Array<Wealthlane_AwardService_Dtos_Grant_GrantUpdateDocumentDto>;
GrantDocument?: Array<Blob>;
Id?: string;
VestingScheduleId: string;
ApprovedDate: string;
TermsAndConditionsDocumentContent?: string;
IsActive: boolean;
TermsAndConditionsDocument?: Blob;
TermsAndConditionsAgreementStatement: string;
IsTermsAndConditionsDocumentRemoved: boolean;
},
): CancelablePromise<Wealthlane_AwardService_Dtos_Grant_GrantDto> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/award/api/app/grant/grant',
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Wealthlane_AwardService_Dtos_Grant_GrantBlobDto Success
     * @throws ApiError
     */
    public static postApiAwardApiAppGrantDownloadGrantDocument(
requestBody?: Wealthlane_AwardService_Dtos_Grant_GetGrantBlobRequestDto,
): CancelablePromise<Wealthlane_AwardService_Dtos_Grant_GrantBlobDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/award/api/app/grant/download-grant-document',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param planId 
     * @returns Wealthlane_AwardService_Dtos_Grant_GrantListDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppGrantGrantsByPlanId(
planId: string,
): CancelablePromise<Array<Wealthlane_AwardService_Dtos_Grant_GrantListDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/grant/grants-by-plan-id/{PlanId}',
            path: {
                'PlanId': planId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param id 
     * @returns Wealthlane_AwardService_Dtos_Grant_GrantDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppGrantGrantById(
id: string,
): CancelablePromise<Wealthlane_AwardService_Dtos_Grant_GrantDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/grant/grant-by-id/{Id}',
            path: {
                'Id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param id 
     * @returns Wealthlane_AwardService_Dtos_Grant_GrantDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppGrantGrantByIdForUpdate(
id: string,
): CancelablePromise<Wealthlane_AwardService_Dtos_Grant_GrantDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/grant/grant-by-id-for-update/{Id}',
            path: {
                'Id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param planId 
     * @param grantTypeId 
     * @param filter 
     * @param sorting 
     * @param skipCount 
     * @param maxResultCount 
     * @returns Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_AwardService_Dtos_Grant_GrantListDto_Wealthlane_AwardService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null Success
     * @throws ApiError
     */
    public static getApiAwardApiAppGrantGrantList(
planId?: string,
grantTypeId?: string,
filter?: string,
sorting?: string,
skipCount?: number,
maxResultCount?: number,
): CancelablePromise<Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_AwardService_Dtos_Grant_GrantListDto_Wealthlane_AwardService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/grant/grant-list',
            query: {
                'PlanId': planId,
                'GrantTypeId': grantTypeId,
                'Filter': filter,
                'Sorting': sorting,
                'SkipCount': skipCount,
                'MaxResultCount': maxResultCount,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_AwardService_Dtos_Grant_GrantListDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppGrantGrantAllList(): CancelablePromise<Array<Wealthlane_AwardService_Dtos_Grant_GrantListDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/grant/grant-all-list',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param id 
     * @returns Wealthlane_AwardService_Dtos_Response_ResponseDto Success
     * @throws ApiError
     */
    public static deleteApiAwardApiAppGrantGrant(
id: string,
): CancelablePromise<Wealthlane_AwardService_Dtos_Response_ResponseDto> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/award/api/app/grant/grant/{Id}',
            path: {
                'Id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_AwardService_Dtos_RSUAward_GetValuesDTO Success
     * @throws ApiError
     */
    public static getApiAwardApiAppGrantVestingSchedule(): CancelablePromise<Array<Wealthlane_AwardService_Dtos_RSUAward_GetValuesDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/grant/vesting-schedule',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_AwardService_Dtos_Grant_PlanNameDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppGrantPlans(): CancelablePromise<Array<Wealthlane_AwardService_Dtos_Grant_PlanNameDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/grant/plans',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param grantTermsAndConditionsId 
     * @returns Wealthlane_AwardService_Dtos_Grant_GrantTermsAndConditionsBlobDto Success
     * @throws ApiError
     */
    public static postApiAwardApiAppGrantDownloadTermsAndConditionsDocument(
grantTermsAndConditionsId: string,
): CancelablePromise<Wealthlane_AwardService_Dtos_Grant_GrantTermsAndConditionsBlobDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/award/api/app/grant/download-terms-and-conditions-document/{grantTermsAndConditionsId}',
            path: {
                'grantTermsAndConditionsId': grantTermsAndConditionsId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param grantTermsAndConditionsId 
     * @returns Wealthlane_AwardService_Dtos_Grant_GrantTermsAndConditionsBlobDto Success
     * @throws ApiError
     */
    public static postApiAwardApiAppGrantDownloadTermsAndConditionsDocumentByParticipant(
grantTermsAndConditionsId: string,
): CancelablePromise<Wealthlane_AwardService_Dtos_Grant_GrantTermsAndConditionsBlobDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/award/api/app/grant/download-terms-and-conditions-document-by-participant/{grantTermsAndConditionsId}',
            path: {
                'grantTermsAndConditionsId': grantTermsAndConditionsId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param grantId 
     * @returns Wealthlane_AwardService_Dtos_Grant_GrantTermsAndConditionsDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppGrantActiveTermsAndConditions(
grantId: string,
): CancelablePromise<Wealthlane_AwardService_Dtos_Grant_GrantTermsAndConditionsDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/grant/active-terms-and-conditions/{grantId}',
            path: {
                'grantId': grantId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param planId 
     * @param input 
     * @returns Wealthlane_AwardService_Dtos_RSUAward_GetValuesDTO Success
     * @throws ApiError
     */
    public static getApiAwardApiAppGrantGrantsByPlanForTax(
planId: string,
input?: string,
): CancelablePromise<Array<Wealthlane_AwardService_Dtos_RSUAward_GetValuesDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/grant/grants-by-plan-for-tax/{PlanId}',
            path: {
                'PlanId': planId,
            },
            query: {
                'input': input,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param grantId 
     * @returns Wealthlane_AwardService_Dtos_Grant_GrantDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppGrantGrantByForTaxId(
grantId: string,
): CancelablePromise<Wealthlane_AwardService_Dtos_Grant_GrantDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/grant/grant-by-for-tax-id/{GrantId}',
            path: {
                'GrantId': grantId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

}
