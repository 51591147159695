import { css } from 'styled-components';
import { COLORS } from '../constants/style/color';
import { DEFAULT_FONT_SIZE, initialHeight } from './../constants/common';

interface ShadowType {
  variant:
    | 'default'
    | 'soft'
    | 'soft-wide'
    | 'radio'
    | 'tag'
    | 'tag-white'
    | 'floating'
    | 'pressed'
    | 'floating-gray'
    | 'pressed-gray'
    | 'card';
}

// Converts px to rem
export const rem = (size: number): string => {
  const remSize = size / DEFAULT_FONT_SIZE; // Default font size on html element is 100%, equivalent to 16px;
  return `${remSize}rem`;
};

// Converts Hex to Rgba Colors
export const getRgbaValue = (color: string, opacity?: number): string => {
  if (opacity !== undefined) {
    const rgbaColor = `${parseInt(color.substring(1, 3), 16)},${parseInt(
      color.substring(3, 5),
      16
    )},${parseInt(color.substring(5, 7), 16)},${opacity}`;

    return `rgba(${rgbaColor})`;
  } else {
    return color;
  }
};

export const getViewHeight = (value?: string): string => {
  const updateHeight = (): void => {
    const vh = window.innerHeight * 0.01; // Calculate 1vh value in pixels
    const fullHeight = String(vh * 100) + 'px'; // 100vh in px
    const updatedHeight = `calc(${fullHeight} - ${value ?? '0px'})`;
    document.documentElement.style.setProperty('--view-height', updatedHeight);
  };

  // Initial height calculation
  updateHeight();

  // Attach the event listener to recalculate height on window resize
  window.addEventListener('resize', updateHeight);

  // Return the initial height value
  return `var(--view-height, 100vh)`;
};

export const getScreenViewHeight = (value?: string): string => {
  // Calculate 1vh value in pixels
  // based on window inner height
  const vh = initialHeight * 0.01; // Which is equal to 1vh
  const fullHeight = `${vh * 100}px`; // 100vh in px
  const subtractValue = value ?? '0px'; // Use nullish coalescing operator (??) to handle nullish/empty cases

  return `calc(${fullHeight} - ${subtractValue})`;
};

export const getShadow = ({ variant }: ShadowType): ReturnType<typeof css> => {
  if (variant === 'soft') {
    return css`
      -web-box-shadow: ${rem(0)} ${rem(2)} ${rem(3)} ${COLORS.DefaultShadow};
      box-shadow: ${rem(0)} ${rem(2)} ${rem(3)} ${COLORS.DefaultShadow};
    `;
  } else if (variant === 'soft-wide') {
    return css`
      -web-box-shadow: ${rem(0)} ${rem(2)} ${rem(20)} ${COLORS.SoftShadow};
      box-shadow: ${rem(0)} ${rem(2)} ${rem(20)} ${COLORS.SoftShadow};
    `;
  } else if (variant === 'radio') {
    return css`
      -web-box-shadow: ${rem(0)} ${rem(2)} ${rem(4)} ${COLORS.RadioShadow};
      box-shadow: ${rem(0)} ${rem(2)} ${rem(4)} ${COLORS.RadioShadow};
    `;
  } else if (variant === 'tag') {
    return css`
      -web-box-shadow: ${rem(0)} ${rem(1)} ${rem(2)} ${COLORS.TagShadow};
      box-shadow: ${rem(0)} ${rem(1)} ${rem(2)} ${COLORS.TagShadow};
    `;
  } else if (variant === 'floating') {
    return css`
      -web-box-shadow: ${rem(0)} ${rem(4)} ${rem(6)} ${COLORS.FloatingButtonShadow};
      box-shadow: ${rem(0)} ${rem(4)} ${rem(6)} ${COLORS.FloatingButtonShadow};
    `;
  } else if (variant === 'pressed') {
    return css`
      -web-box-shadow: ${rem(0)} ${rem(1)} ${rem(4)} ${COLORS.PressedButtonShadow};
      box-shadow: ${rem(0)} ${rem(1)} ${rem(4)} ${COLORS.PressedButtonShadow};
    `;
  } else if (variant === 'floating-gray') {
    return css`
      -web-box-shadow: ${rem(0)} ${rem(4)} ${rem(6)} ${COLORS.FloatingButtonGrayShadow};
      box-shadow: ${rem(0)} ${rem(4)} ${rem(6)} ${COLORS.FloatingButtonGrayShadow};
    `;
  } else if (variant === 'pressed-gray') {
    return css`
      -web-box-shadow: ${rem(0)} ${rem(1)} ${rem(4)} ${COLORS.PressedButtonGrayShadow};
      box-shadow: ${rem(0)} ${rem(1)} ${rem(4)} ${COLORS.PressedButtonGrayShadow};
    `;
  } else if (variant === 'card') {
    return css`
      -web-box-shadow: ${rem(0)} ${rem(2)} ${rem(7)} ${COLORS.CardShadow};
      box-shadow: ${rem(0)} ${rem(2)} ${rem(7)} ${COLORS.CardShadow};
    `;
  } else if (variant === 'tag-white') {
    return css`
      -web-box-shadow: ${rem(0)} ${rem(2)} ${rem(7)} ${COLORS.White};
      box-shadow: ${rem(0)} ${rem(2)} ${rem(7)} ${COLORS.White};
    `;
  } else {
    return css`
      -web-box-shadow: ${rem(0)} ${rem(2)} ${rem(4)} ${COLORS.DefaultShadow};
      box-shadow: ${rem(0)} ${rem(2)} ${rem(4)} ${COLORS.DefaultShadow};
    `;
  }
};
