const InitialValuesForm = {
  companyStepOneForm: {
    name: '',
    ticker: '',
    industrySectorId: '',
    taxId: '',
    countryOfIncorporationId: '',
    stateOfIncorporationText: '',
    stateOfIncorporationId: '',
    primaryStockExchangeId: '',
    dateOfIncorporation: '',
    businessDescription: '',
    websiteUrl: '',
    domainNames: [''],
    headQuarterCityText: '',
    headQuarterStateText: '',
    headQuarterCountryId: '',
    headQuarterAddressLine1: '',
    headQuarterAddressLine2: '',
    headQuarterStateId: '',
    headQuarterZipCode: '',
    companyAddressList: [],
    additionalAddress: false,
    isEnabled: true,
    cusip: '',
    sicCode: '',
  },
  companyStepTwoForm: {
    ADMIN: {
      email: '',
      phone: '',
      firstName: '',
      middleName: '',
      lastName: '',
      countryCallingCodeId: '',
    },
    // APPROVAL: {
    //   email: '',
    //   phone: '',
    //   firstName: '',
    //   middleName: '',
    //   lastName: '',
    //   countryCallingCodeId: '',
    // },
    vendorDesignationUsers: [
      {
        roleId: '',
        vendorUserIds: [],
      },
    ],
    isEnabled: true,
  },
  addSystemUserForm: {
    workEmail: '',
    firstName: '',
    middleName: '',
    lastName: '',
    vendorTitle: '',
    employeeId: '',
    vendorDepartment: '',
    vendorRoleId: '',
    contactNumber: '',
    countryCallingCodeId: '',
    phoneIsOtherCountry: false,
    isEnabled: true,
  },
  addSystemRoleForm: {
    roleName: '',
    description: '',
    isEnabled: true,
    permissions: {},
    fieldLevelPermissions: {},
  },
  tableParams: {
    sorting: '',
    filter: '',
    skipCount: 0,
    maxResultCount: 10,
  },
  changePassword: {
    currentPassword: '',
    newPassword: '',
  },
  federalTaxForm: {
    year: '',
    addTaxSetup: [
      {
        financialYearTaxId: '',
        taxSubTypeId: '',
        rate: null,
        lowerRange: null,
        upperRange: null,
      },
    ],
  },

  addStateTaxSetup: {
    stateId: '',
    rate: null,
    taxStructureId: null,
    description: null,
  },

  taxPeriod: {
    name: '',
    effectiveFrom: '',
    effectiveTill: '',
  },

  taxSetup: {
    name: '',
    jurisdictionLevelId: '',
    taxPeriodId: '',
    taxCode: '',
    equityCompensationLevelId: '',
    equityCompensationLevelName: '',
    countryId: null,
    stateId: null,
    stateText: '',
    cityId: null,
    participantId: null,
    planId: null,
    grantId: null,
    awardId: null,
    transactionId: null,
    userDefinedCriteria: {
      LogicalOperatorType: 'And',
      Conditions: [
        {
          Hash: null,
          EqualityOperatorType: null,
          Field: null,
          Value: null,
          Name: null,
          SubCriteria: {
            LogicalOperatorType: null,
            Conditions: [
              {
                Hash: null,
                EqualityOperatorType: null,
                Field: null,
                Value: '',
                Name: null,
                SubCriteria: null,
              },
            ],
          },
        },
      ],
    },
    taxBrackets: [
      {
        taxRate: '',
        lowerLimit: '',
        upperLimit: '',
      },
    ],
    // userDefinedGroup: [
    //   {
    //     condition: '',
    //     userDefinedRules: [
    //       {
    //         rule: '',
    //         subRule: '',
    //       },
    //     ],
    //   },
    // ],
  },
  taxOverride: {
    id: '',
    name: '',
    jurisdictionLevelId: '',
    taxPeriodId: '',
    taxCode: '',
    equityCompensationLevelId: '',
    countryId: null,
    stateId: null,
    stateText: '',
    cityId: null,
    participantId: null,
    planId: null,
    grantId: null,
    transactionId: null,

    taxBrackets: [
      {
        taxRate: '',
        lowerLimit: '',
        upperLimit: '',
      },
    ],
  },
};

export default InitialValuesForm;
