import { useState, useRef } from 'react';
import Button from '../button';
import PopperContainer from './popperContainer';
import { ButtonMenu, Dot } from './verticalDot.style';

interface ActionButtonType {
  actions?: any[];
  id?: string | number | undefined;
  rowData?: Record<string, string>;
  counter?: number;
  as?: string;
}

export const ActionButton = ({
  actions,
  id,
  rowData,
  counter,
  as,
}: ActionButtonType): JSX.Element => {
  const buttonRef = useRef<any>([]);
  const [show, setShow] = useState(false);
  const handleShow = (value?: boolean): void => {
    value !== null && value !== undefined ? setShow(value) : setShow(!show);
  };

  return (
    <>
      {as === 'Button' ? (
        <>
          <ButtonMenu ref={buttonRef}>
            <Button
              variant={'secondary-white'}
              title={'Plan Options'}
              icon={'edit'}
              onClick={() => {
                handleShow();
              }}
            />
          </ButtonMenu>
          <PopperContainer
            actions={actions}
            rowData={rowData}
            referenceElement={buttonRef}
            id={id}
            counter={counter}
            show={show}
            handleShow={handleShow}
          />
        </>
      ) : (
        <>
          <Dot key={counter} className='cursor-pointer'>
            <span
              className={`material-symbols-outlined ${show ? 'active' : ''}`}
              ref={buttonRef}
              onClick={() => {
                handleShow();
              }}
            >
              more_vert
            </span>
          </Dot>
          <PopperContainer
            actions={actions}
            rowData={rowData}
            referenceElement={buttonRef}
            id={id}
            counter={counter}
            show={show}
            handleShow={handleShow}
          />
        </>
      )}
    </>
  );
};
