import { type UseQueryResult, useQuery } from 'react-query';
import { PlanService } from '../../services/wealthlane-award-services';
import { type OptionType } from '../../types/common';

const useGetTaxAllPlan = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  participantId?: string,
  applicableData?: string | null
): UseQueryResult<
  // eslint-disable-next-line camelcase
  OptionType[] | undefined,
  unknown
> => {
  const taxAllPlanOptions = useQuery(
    ['useGetTaxAllPlanOptions', participantId, applicableData],
    async () => {
      if (
        applicableData?.includes('Transaction') === true ||
        applicableData?.includes('Grant') === true ||
        applicableData?.includes('Plan') === true
      ) {
        if (participantId !== '') {
          return await handleRequest(
            PlanService.getApiAwardApiAppPlanPlansForTax('', participantId)
          );
        } else {
          return await handleRequest(PlanService.getApiAwardApiAppPlanPlansForTax());
        }
      }
    },
    {
      select: (data) => {
        return data?.map((item) => {
          return {
            label: item.planName ?? '',
            value: item.planId ?? '',
          };
        });
      },
    }
  );

  return taxAllPlanOptions;
};

export default useGetTaxAllPlan;
