import _ from 'lodash';
import { type OptionType } from '../types/common';

const permissionIdentifier = 'CustomerService.Vendor.';
const permissionIdentifierField = 'CustomerService.Vendor.FieldLevel.';
const permissionApprovalIdentifierField = 'VendorService.ApprovalProcess.';

export const getOptionLabel = (
  options: OptionType[],
  value: string | undefined
): string | number | boolean | undefined => {
  return options.find((option) => option.value === value)?.label;
};

export const getActionList = (data: Array<any>) => {
  const actionList = data?.map((item: any) => {
    return { ...item, action: item?.action, type: item?.action?.props?.children || item?.action };
  });

  return actionList;
};

// Format data to object
export const restructureNonFieldPermissions = (
  data: string[],
  permissionIdentifier: string,
  removeText?: string
): Array<{
  groupKey: string;
  key: string;
  text: string;
  checked: boolean;
}> => {
  return data?.map((item: string) => {
    const permissionParts = item.replace(permissionIdentifier, '').split('.');
    let text: string;

    if (permissionParts.length > 1 && permissionParts[1] !== '') {
      text = permissionParts[1];
    } else if (removeText !== undefined) {
      text = removeText;
    } else {
      text = 'View';
    }
    return {
      groupKey: permissionParts[0],
      key: item,
      text,
      checked: false,
    };
  });
};

// Filter data which has permissionIdentifier
const getCustomerPolicies = (policies: any): string[] =>
  Object.keys(policies)
    .map((item) => {
      return item;
    })
    .filter((item) => {
      return item.startsWith(permissionIdentifier);
    });

// Filter data which has permissionIdentifier
const getVendorApprovalPolicies = (policies: any): string[] =>
  Object.keys(policies)
    .map((item) => {
      return item;
    })
    .filter((item) => {
      return item.startsWith(permissionApprovalIdentifierField);
    });

// Filter data which don't have FieldLevel
export const getNonFieldLevelPermissions = (permissions: any): any[] => {
  return permissions.filter((item: any) => {
    return item.includes('.FieldLevel.') === false;
  });
};

// Filter data which have FieldLevel
export const getFieldLevelPermissions = (permissions: any): any[] => {
  return permissions.filter((item: any) => {
    return item.includes('.FieldLevel.');
  });
};

// Filter data which have Approval Process
export const getApprovalLevelPermissions = (permissions: any): any[] => {
  return permissions.filter((item: any) => {
    return item.includes('.ApprovalProcess.');
  });
};

// Converts Strings Data with value to groups objects
export const getFilteredPermissionData = (
  policies: any,
  isArray?: boolean
): {
  nonFieldLevelPermissionsGroups: _.Dictionary<
    Array<{
      groupKey: string;
      key: string;
      text: string;
      checked: boolean;
    }>
  >;
  fieldLevelPermissionsGroups: any;
  nonFieldLevelPermissionsWithApprovalGroups: any;
} => {
  const vendorPolicies =
    isArray !== undefined && isArray ? policies : getCustomerPolicies(policies);
  const vendorApprovalPolicies =
    isArray !== undefined && isArray ? policies : getVendorApprovalPolicies(policies);

  const nonFieldLevelPermissions = getNonFieldLevelPermissions(vendorPolicies);
  const fieldLevelPermissions = getFieldLevelPermissions(vendorPolicies);
  const approvalProcessPermissions = getApprovalLevelPermissions(vendorApprovalPolicies);

  const nonFieldLevelPermissionsData = restructureNonFieldPermissions(
    nonFieldLevelPermissions,
    permissionIdentifier
  );
  const fieldLevelPermissionsData = restructureNonFieldPermissions(
    fieldLevelPermissions,
    permissionIdentifierField
  );

  const approvalPermissionsData = restructureNonFieldPermissions(
    approvalProcessPermissions,
    permissionApprovalIdentifierField,
    'Approval'
  );

  const nonFieldLevelPermissionsGroupsWithApproval = {
    ...nonFieldLevelPermissionsData,
    ...approvalPermissionsData,
  };

  const nonFieldLevelPermissionsGroups = _.groupBy(
    nonFieldLevelPermissionsData,
    (item) => item.groupKey
  );

  const fieldLevelPermissionsGroups = _.groupBy(fieldLevelPermissionsData, (item) => item.groupKey);

  const nonFieldLevelPermissionsWithApprovalGroups = _.groupBy(
    nonFieldLevelPermissionsGroupsWithApproval,
    (item) => item.groupKey
  );

  return {
    nonFieldLevelPermissionsGroups,
    fieldLevelPermissionsGroups,
    nonFieldLevelPermissionsWithApprovalGroups,
  };
};

export const FontSizeHtml: number = 10;

export const rem = (size: number): string => {
  const remSize = size / FontSizeHtml;
  // Default font size on html element is 100%, equivalent to 16px;
  return `${remSize}rem`;
};
