import { COLORS } from '../../constants/style/color';
import { Spinner } from './spinner.styled';

interface SpinnersType {
  borderColor?: string;
  className?: string;
  containerClassName?: string;
  size?: 'sm' | 'md' | 'lg';
}

const Spinners = ({
  borderColor,
  className,
  containerClassName,
  size,
  ...rest
}: SpinnersType): JSX.Element => {
  return (
    <div
      className={`flex items-center justify-center ${
        containerClassName !== undefined ? containerClassName : ''
      }`}
    >
      <Spinner
        borderColor={borderColor ?? COLORS.Purple}
        size={size ?? 'lg'}
        className={className}
        {...rest}
      />
    </div>
  );
};

export default Spinners;
