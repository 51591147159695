import { commonValidation } from './validationRule';
import * as Yup from 'yup';
import { GlobalValidation } from './commonValidation';
import CheckFieldLevelPermissions from '../Permissions/checkFieldLevelPermission';
import { isEmpty } from 'lodash';
import { Wealthlane_TaxService_Dtos_TaxItemDto_JsonDto } from '../../services/wealthlane-tax-services';

const URLRegExp =
  // eslint-disable-next-line no-useless-escape
  /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/gm;

const Domain = /^((?!www\.)[a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,}$/i;
const passwordRegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

export const COMPANY_STEP_ONE_FORM_VALIDATION = Yup.object().shape({
  name: GlobalValidation.Name,
  ticker: commonValidation({ required: true, noBlank: true })
    .matches(/^[a-zA-Z ]*$/, 'Invalid Ticker')
    .max(4, 'Must be 4 or less characters'),

  primaryStockExchangeId: commonValidation({ required: true }),
  taxId: commonValidation({
    required: true,
    numericPhone: true,
  })
    .min(10, 'Must be 9 digits')
    .max(10, 'Must be 9 digits')
    .nullable(),
  businessDescription: GlobalValidation.Description.nullable(),
  websiteUrl: commonValidation({ required: true, max: 5000 }).matches(
    URLRegExp,
    'Invalid Website URL'
  ),
  domainNames: Yup.array().of(
    Yup.string().matches(Domain, 'Invalid Domain Name').required('Required field')
  ),
  cusip: commonValidation({ required: false, noBlank: true }),
  sicCode: commonValidation({ required: false, noBlank: true }),
  headQuarterCityText: commonValidation({ required: true, noBlank: true }),
  headQuarterStateText: Yup.string()
    .required('Required field')
    .matches(/^\s*\S[\s\S]*$/, 'Must not contain blank spaces')
    .when('headQuarterIsOtherCountry', {
      is: true,
      otherwise: (d: any) => Yup.string().nullable(),
    }),
  headQuarterCountryId: commonValidation({ required: true }),
  headQuarterAddressLine1: GlobalValidation.AddressLine1,
  headQuarterAddressLine2: GlobalValidation.AddressLine2,
  headQuarterStateId: Yup.string()
    .required('Required field')
    .when('headQuarterIsOtherCountry', {
      is: false,
      otherwise: (d: any) => Yup.string().nullable(),
    }),
  headQuarterZipCode: commonValidation({
    required: true,
    digits: true,
    noBlank: true,
    max: 5,
    min: 5,
    minValue: 5,
  }).when('headQuarterIsOtherCountry', {
    is: false,
    otherwise: (d: any) =>
      commonValidation({
        required: true,
        digits: true,
        noBlank: true,
      }),
  }),
  companyAddressList: Yup.array()
    .of(
      Yup.object().shape({
        countryId: commonValidation({ required: true }),
        addressLine1: GlobalValidation.AddressLine1.nullable(),
        addressLine2: GlobalValidation.AddressLine2.nullable(),
        taxId: commonValidation({
          required: true,
          numericPhone: true,
        })
          .min(10, 'Must be 9 digits')
          .max(10, 'Must be 9 digits')
          .nullable(),
        stateText: Yup.string()
          .required('Required field')
          .matches(/^\s*\S[\s\S]*$/, 'Must not contain blank spaces')
          .when('isOtherCountry', {
            is: true,
            otherwise: (d: any) => Yup.string().nullable(),
          }),
        stateId: Yup.string()
          .required('Required field')
          .when('isOtherCountry', {
            is: false,
            otherwise: (d: any) => Yup.string().nullable(),
          }),
        cityText: commonValidation({ required: true, noBlank: true }),
        zipCode: commonValidation({
          required: true,
          digits: true,
          noBlank: true,
          max: 5,
          min: 5,
          minValue: 5,
        }).when('isOtherCountry', {
          is: false,
          otherwise: (d: any) =>
            commonValidation({
              required: true,
              digits: true,
              noBlank: true,
            }),
        }),
        stateOfIncorporationId: commonValidation({ required: true })
          .nullable()
          .when('isOtherCountryOfIncorporation', {
            is: false,
            otherwise: (d: any) => commonValidation({ required: false }),
          }),
      })
    )
    .when('additionalAddress', {
      is: true,
      otherwise: (d: any) => Yup.array(),
    })
    .nullable(),
});

export const COMPANY_STEP_TWO_FORM_VALIDATION = Yup.object().shape({
  ADMIN: Yup.object({
    email: GlobalValidation.Email,
    phone: GlobalValidation.Phone.min(12, 'Must be 10 digits').when('phoneIsOtherCountry', {
      is: false,
      otherwise: (d: any) => GlobalValidation.Phone,
    }),
    firstName: GlobalValidation.Name.matches(/^[a-zA-Z-'' ]*$/, 'Invalid First Name'),
    middleName: commonValidation({
      required: false,
      textLimit: 100,
      noBlank: true,
      doubleSpace: true,
    }).matches(/^[a-zA-Z-'' . ]*$/, 'Invalid Middle Name'),
    lastName: GlobalValidation.Name.matches(/^[a-zA-Z-'' ]*$/, 'Invalid Last Name'),
  }),

  // APPROVAL: Yup.object({
  //   email: GlobalValidation.Email,
  //   phone: GlobalValidation.Phone.min(12, 'Must be 10 digits').when('phoneIsOtherCountry', {
  //     is: false,
  //     otherwise: (d: any) => GlobalValidation.Phone,
  //   }),
  //   firstName: GlobalValidation.Name.matches(/^[a-zA-Z-'' ]*$/, 'Invalid First Name'),
  //   middleName: commonValidation({
  //     required: false,
  //     textLimit: 100,
  //     noBlank: true,
  //     doubleSpace: true,
  //   }).matches(/^[a-zA-Z-'' . ]*$/, 'Invalid Middle Name'),
  //   lastName: GlobalValidation.Name.matches(/^[a-zA-Z-'' ]*$/, 'Invalid Last Name'),
  // }),

  vendorDesignationUsers: Yup.array().of(
    Yup.object().shape({
      roleId: Yup.string().required('Required field'),
      vendorUserIds: Yup.array().min(1, 'Required field'),
    })
  ),
  // .when('vendorDesignationUsersArray', {
  //   is: true,
  //   otherwise: (d: any) =>
  //     Yup.array().of(
  //       Yup.object().shape({
  //         roleId: Yup.string().nullable(),
  //         vendorUserIds: Yup.array().nullable(),
  //       })
  //     ),
  // }),
});

export const ROLES_VALIDATION = {
  roleName: GlobalValidation.Name,
  description: commonValidation({ required: false, textLimit: 100 }),
};

export const SYSTEM_USERS_VALIDATION: any = (isEdit: boolean) => ({
  workEmail:
    isEdit && CheckFieldLevelPermissions('EmployeeEmail', isEdit) ? null : GlobalValidation.Email,
  firstName:
    isEdit && CheckFieldLevelPermissions('FirstMiddleLastName', isEdit)
      ? null
      : GlobalValidation.Name.matches(/^[a-zA-Z-'' ]*$/, 'Invalid First Name'),
  middleName:
    isEdit && CheckFieldLevelPermissions('FirstMiddleLastName', isEdit)
      ? null
      : commonValidation({
          required: false,
          textLimit: 100,
          noBlank: true,
          doubleSpace: true,
        }).matches(/^[a-zA-Z-'' . ]*$/, 'Invalid Middle Name'),
  lastName:
    isEdit && CheckFieldLevelPermissions('FirstMiddleLastName', isEdit)
      ? null
      : GlobalValidation.Name.matches(/^[a-zA-Z-'' ]*$/, 'Invalid Last Name'),
  contactNumber:
    isEdit && CheckFieldLevelPermissions('PhoneNumber', isEdit)
      ? null
      : GlobalValidation.Phone.min(12, 'Must be 10 digits').when('phoneIsOtherCountry', {
          is: false,
          otherwise: (d: any) => GlobalValidation.Phone,
        }),
  employeeId:
    isEdit && CheckFieldLevelPermissions('EmployeeId', isEdit)
      ? null
      : GlobalValidation.RequiredNoBlankAlphanumeric,
  countryCallingCodeId:
    isEdit && CheckFieldLevelPermissions('PhoneNumber', isEdit)
      ? null
      : commonValidation({ required: true }),
  vendorTitle:
    isEdit && CheckFieldLevelPermissions('JobTitle', isEdit)
      ? null
      : commonValidation({ required: true, doubleSpace: true, noBlank: true }),
  vendorDepartment:
    isEdit && CheckFieldLevelPermissions('Department', isEdit)
      ? null
      : commonValidation({ required: true, doubleSpace: true, noBlank: true }),
  vendorRoleId: commonValidation({ required: true }),
});

export const CHANGE_PASSWORD_VALIDATION = Yup.object().shape({
  currentPassword: Yup.string().required('Required field'),
  newPassword: Yup.string().required('Required field').matches(passwordRegExp, 'Invalid Password'),
  repeatPassword: Yup.string()
    .required('Required field')
    .test('match', 'Password do not match', function (repeatPassword) {
      return repeatPassword === this.parent.newPassword;
    }),
});

export const FEDERAL_VALIDATION = Yup.object().shape({
  year: commonValidation({ required: true }),
  addTaxSetup: Yup.array().of(
    Yup.object().shape({
      rate: commonValidation({ required: true, digits: true }),
      lowerRange: commonValidation({ required: true, digits: true }),
      upperRange: GlobalValidation.DigitWithComma.when('lowerRange', (value: any, schema: any) => {
        return schema.test({
          test: (approvedAmount: any) => {
            // return parseInt(parseInt(approvedAmount) <= parseInt(value));
            // parseInt(approvedAmount?.toString()?.replaceAll(',', '') || '') <=
            // parseInt(value?.toString()?.replaceAll(',', '') || '')
          },
          message: 'Must not be greater than No. of Shares Authorized',
        });
      }).typeError('Must be only digits'),
    })
  ),
});
export const STATE_VALIDATION = Yup.object().shape({
  year: commonValidation({ required: true }),
  addStateTaxSetup: Yup.array().of(
    Yup.object().shape({
      rate: commonValidation({ required: true, digits: true }),
      description: commonValidation({ required: true }).max(150, 'Message exceeds 150 characters'),
    })
  ),
});

export const TAX_PERIOD_VALIDATION = Yup.object().shape({
  name: GlobalValidation.Name,
  effectiveFrom: GlobalValidation.Date,
  effectiveTill: Yup.date()
    .required('Required field')
    .typeError('Required field')
    .test('is-greater', 'End date must be after start date', function (value) {
      const { effectiveFrom } = this.parent;
      return !effectiveFrom || !value || new Date(value) >= new Date(effectiveFrom);
    }),
});

export const TAX_SETUP_VALIDATION = (
  validation?: Wealthlane_TaxService_Dtos_TaxItemDto_JsonDto | any,
  applicableName?: string
): any => {
  return {
    name: GlobalValidation.Name,
    jurisdictionLevelId: commonValidation({ required: true }),
    taxPeriodId: commonValidation({ required: true }),
    equityCompensationLevelId: commonValidation({ required: true }),
    countryId: (validation?.requiresCountry as boolean)
      ? validation?.establishedCountry === 'US'
        ? null
        : commonValidation({ required: true })
      : null,
    stateId: Boolean(validation?.requiresState) && commonValidation({ required: true }),
    cityId: Boolean(validation?.requiresCity) && commonValidation({ required: true }),
    participantId:
      !isEmpty(applicableName) &&
      (applicableName === 'Vesting Tranche' || applicableName === 'Participant') &&
      commonValidation({ required: true }),
    planId:
      !isEmpty(applicableName) &&
      (applicableName === 'Vesting Tranche' ||
        applicableName === 'Plan' ||
        applicableName === 'Grant') &&
      commonValidation({ required: true }),
    grantId:
      !isEmpty(applicableName) &&
      (applicableName === 'Vesting Tranche' || applicableName === 'Grant') &&
      commonValidation({ required: true }),
    awardId:
      !isEmpty(applicableName) &&
      applicableName === 'Vesting Tranche' &&
      commonValidation({ required: true }),

    transactionId:
      !isEmpty(applicableName) &&
      applicableName === 'Vesting Tranche' &&
      commonValidation({ required: true }),
    userDefinedCriteria:
      Boolean(validation?.allowUserDefinedCriteria) &&
      Yup.object().shape({
        // logicalOperatorType: commonValidation({ required: true }),
        Conditions: Yup.array().of(
          Yup.object().shape({
            SubCriteria: Yup.object().shape({
              // logicalOperatorType: commonValidation({ required: true }),
              Conditions: Yup.array().of(
                Yup.object().shape({
                  // Field: commonValidation({ required: true }),
                  EqualityOperatorType: commonValidation({ required: true }).when('Field', {
                    is: (value: any) => !isEmpty(value),
                    otherwise: (d: any) =>
                      commonValidation({
                        required: false,
                      }),
                  }),
                  Value: commonValidation({ required: true }).when('Field', {
                    is: (value: any) => !isEmpty(value),
                    otherwise: (d: any) =>
                      commonValidation({
                        required: false,
                      }),
                  }),
                })
              ),
            }),
          })
        ),
      }),
    taxBrackets: Yup.array().of(
      Yup.object().shape({
        taxRate: Yup.string()
          .matches(/^[0-9.]*$/, 'Must be only digits')
          .matches(/^\d*(\.\d{0,4})?$/, 'Must be 4 decimal place only')
          .required('Required Field'),
        lowerLimit: commonValidation({
          required: false,
          digitsWithComma: true,
          noBlank: true,
        }),
        upperLimit: commonValidation({
          required: false,
          digitsWithComma: true,
          noBlank: true,
        }),
        // .when('lowerLimit', (value: any, schema: any) => {
        //   return (
        //     value &&
        //     schema.test({
        //       test: (approvedAmount: any) => {
        //         return (
        //           parseInt(approvedAmount?.toString()?.replaceAll(',', '') ?? '') >
        //           parseInt(value?.toString()?.replaceAll(',', '') ?? '')
        //         );
        //       },
        //       message: 'Must be greater than Lower limits',
        //     })
        //   );
        // })
        // .typeError('Must be only digits'),
      })
    ),
  };
};
