import { type UseQueryResult, useQuery } from 'react-query';
import {
  TaxItemApprovalService,
  TaxPeriodService,
  Wealthlane_TaxService_Dtos_TaxItemDto_ApprovalStatusDto,
} from '../../services/wealthlane-tax-services';
import moment from 'moment';
import { DATE_FORMAT } from '../../constants/common';

const useGetTaxApprovalStatusList = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>
): UseQueryResult<any> => {
  const taxApprovalStatusListOptions = useQuery(
    ['getTaxApprovalStatusList'],
    async () => {
      return await handleRequest(
        TaxItemApprovalService.getApiTaxApiAppTaxItemApprovalApprovalList()
      );
    },
    {
      select: (data) =>
        data?.map((item: Wealthlane_TaxService_Dtos_TaxItemDto_ApprovalStatusDto) => {
          return {
            label: item?.displayName,
            value: item.id ?? '',
            systemName: item.systemName ?? '',
          };
        }),
    }
  );

  return taxApprovalStatusListOptions;
};

export default useGetTaxApprovalStatusList;
