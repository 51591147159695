import { type JSXElementConstructor, type ReactElement } from 'react';
import { useLayer } from 'react-laag';
import { PopoverModelContainer, PopoverModelWrapper } from './modal.styled';

interface PopoverModalComponentType {
  children: ReactElement<any, string | JSXElementConstructor<any>>;
  trigger: any;
  popoverClassName?: string;
  hasMousePosition: boolean;
  resetMousePosition: () => void;
}

const PopoverModalComponent = ({
  children,
  trigger,
  hasMousePosition,
  popoverClassName,
  resetMousePosition,
}: PopoverModalComponentType): JSX.Element => {
  const { renderLayer, layerProps } = useLayer({
    isOpen: hasMousePosition,
    // onOutsideClick: resetMousePosition, // close the menu when the user clicks outside
    onDisappear: resetMousePosition, // close the menu when the menu gets scrolled out of sight
    overflowContainer: true, // keep the menu positioned inside the container
    auto: true, // automatically find the best placement
    placement: 'bottom-start', // we prefer to place the menu "top-end"
    triggerOffset: 12, // keep some distance to the trigger
    trigger,
    snap: true,
    possiblePlacements: [
      'top-start',
      'left-end',
      'right-start',
      'bottom-start',
      'bottom-center',
      'top-center',
    ],
    containerOffset: 16, // give the menu some room to breath relative to the container
  });

  return (
    <PopoverModelWrapper className={popoverClassName}>
      {hasMousePosition &&
        renderLayer(
          <PopoverModelContainer {...layerProps} className={popoverClassName}>
            {children}
          </PopoverModelContainer>
        )}
    </PopoverModelWrapper>
  );
};

export default PopoverModalComponent;
