import { UseQueryResult, useQuery } from 'react-query';
import { StateService } from '../../services/wealthlane-tax-services';
import { OptionType } from '../../types/common';

const useGetTaxStateOptions = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  id?: string | null
): UseQueryResult<
  // eslint-disable-next-line camelcase
  OptionType[] | undefined,
  unknown
> => {
  const taxStateOptions = useQuery(
    ['getTaxStates', id],
    async () => {
      if (id) {
        return await handleRequest(StateService.getApiTaxApiAppStateStateByCountry(id));
      }
    },
    {
      select: (data) => {
        return data?.map((item) => {
          return {
            label: item.name || '',
            value: item.id || '',
            abbreviation: item.abbreviation || '',
          };
        });
      },
      // cacheTime: 1000 * 60 * 5,
    }
  );

  return taxStateOptions;
};

export default useGetTaxStateOptions;
