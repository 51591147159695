import FormSelect from '../../../../../components/dropdown/formSelect';
import {
  useGetTaxAllGrant,
  useGetTaxAllParticipant,
  useGetTaxAllPlan,
  useGetTaxAllTransaction,
  useGetTaxApplicableOptions,
  useWealthlaneCustomerApi,
} from '../../../../../hooks';
import CustomDropdown from '../../../../../components/custom-dropdown';
import { type OptionType } from '../../../../../types/common';
import { useEffect, useState } from 'react';
import { Wealthlane_AwardService_Dtos_RSUAward_AwardTransactionDetails } from '../../../../../services/wealthlane-award-services';
import useWealthlaneAwardApi from '../../../../../hooks/useWealthlaneAwardApi';

interface ApplicableType {
  setApplicableName: (value: string) => void;
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>;
  handleChange: any;
  errors: any;
  touched: any;
  values: any;
  setFieldValue: any;
  modalStatus: string;
}

const ApplicableComponent = ({
  setApplicableName,
  handleRequest,
  handleChange,
  errors,
  touched,
  values,
  setFieldValue,
  modalStatus,
}: ApplicableType): JSX.Element => {
  const { handleRequest: customerHandle } = useWealthlaneCustomerApi();
  const { handleRequest: awardHandle } = useWealthlaneAwardApi();
  const [input, setInput] = useState('');
  const { data: applicableOptions } = useGetTaxApplicableOptions(handleRequest);
  const applicableData = applicableOptions?.filter(
    (item: OptionType) => item.value === values?.equityCompensationLevelId
  )[0];

  const { data: allParticipant, isLoading } = useGetTaxAllParticipant(
    customerHandle,
    input,
    values?.participantId,
    applicableData?.systemName
  );
  const { data: planOptions } = useGetTaxAllPlan(
    awardHandle,
    values?.participantId,
    applicableData?.systemName
  );
  const { data: grantOptions } = useGetTaxAllGrant(
    awardHandle,
    values?.planId,
    applicableData?.systemName
  );

  const { data: transactionOptions } = useGetTaxAllTransaction(
    awardHandle,
    values?.grantId,
    applicableData?.systemName
  );

  const sortOrder = ['Company', 'Plan', 'Grant', 'Participant', 'Transaction'];

  return (
    <>
      <div className='grid md:grid-cols-2 sm:grid-cols-2 mt-4'>
        <FormSelect
          type='text'
          label={'Applicable To'}
          name='equityCompensationLevelId'
          onChange={(event: any) => {
            const applicableName = applicableOptions?.filter(
              (item: OptionType) => item.value === event
            )[0].label;
            setApplicableName(applicableName as string);
            setFieldValue('equityCompensationLevelName', applicableName);
            setFieldValue('equityCompensationLevelId', event);
            setFieldValue('participantId', null);
            setFieldValue('planId', null);
            setFieldValue('grantPlanId', null);
            setFieldValue('grantId', null);
            setFieldValue('awardId', null);
            setFieldValue('transactionId', null);
          }}
          errors={errors}
          touched={touched}
          options={
            modalStatus === 'Override'
              ? applicableOptions
                  ?.filter((item: Record<string, string>) => item?.systemName !== 'Company')
                  ?.sort((a: any, b: any) => {
                    const A = a.systemName;
                    const B = b.systemName;
                    return sortOrder.indexOf(A) > sortOrder.indexOf(B) ? 1 : -1;
                  })
                  .map((itemData: any, index: number) => {
                    return itemData;
                  })
              : applicableOptions
                  ?.sort((a: any, b: any) => {
                    const A = a.systemName;
                    const B = b.systemName;
                    return sortOrder.indexOf(A) > sortOrder.indexOf(B) ? 1 : -1;
                  })
                  .map((itemData: any, index: number) => {
                    return itemData;
                  })
          }
          values={values}
          disabled={modalStatus === 'View'}
          required
        />
      </div>
      <div className='grid md:grid-cols-2 sm:grid-cols-1 gap-5'>
        {(applicableData?.systemName?.includes('Transaction') === true ||
          applicableData?.systemName?.includes('Participant') === true) && (
          <CustomDropdown
            title={'Participant Name'}
            errorMessage={errors?.participantId}
            touchedValue={touched?.participantId}
            loading={isLoading}
            placeholder={
              allParticipant?.filter(
                (item: Record<string, string>) => item.value === values?.participantId
              )[0]?.label
            }
            data={allParticipant}
            disabled={modalStatus === 'View'}
            searchInput={setInput}
            toggleItems={{
              displaySelected: true,
              close: true,
              showCheckbox: false,
              showTotal: false,
              searchable: true,
            }}
            cssStyles={{
              contentHeight: '200px',
              width: '434px',
              height: '275px',
              column: 2,
              disabledContainer: modalStatus === 'View',
            }}
            handleUser={(e) => {
              allParticipant
                ?.filter((item: Record<string, string>) => item.value === e?.value)
                .map((emp: Record<string, string>) => {
                  setFieldValue(`participantId`, emp.value);
                  return null;
                });
              setFieldValue('planId', null);
              setFieldValue('grantPlanId', null);
              setFieldValue('grantId', null);
              setFieldValue('awardId', null);
              setFieldValue('transactionId', null);
              handleChange(e);
            }}
          />
        )}

        {(applicableData?.systemName?.includes('Transaction') === true ||
          applicableData?.systemName?.includes('Grant') === true ||
          applicableData?.systemName?.includes('Plan') === true) && (
          <>
            <FormSelect
              type='text'
              label={'Plan Name'}
              name='planId'
              onChange={(e: any) => {
                handleChange(e);
                setFieldValue('grantPlanId', null);
                setFieldValue('grantId', null);
                setFieldValue('awardId', null);
                setFieldValue('transactionId', null);
              }}
              errors={errors}
              touched={touched}
              options={planOptions}
              values={values}
              setFieldValue={setFieldValue}
              inputType={modalStatus}
              disabled={modalStatus === 'View'}
              required
            />
            {(applicableData?.systemName?.includes('Transaction') === true ||
              applicableData?.systemName?.includes('Grant') === true) && (
              <FormSelect
                type='text'
                label={'Grant Name'}
                name='grantId'
                onChange={(e: any) => {
                  handleChange(e);
                  setFieldValue('awardId', null);
                  setFieldValue('transactionId', null);
                }}
                errors={errors}
                touched={touched}
                options={grantOptions}
                values={values}
                setFieldValue={setFieldValue}
                inputType={modalStatus}
                disabled={modalStatus === 'View'}
                required
              />
            )}
          </>
        )}
        {applicableData?.systemName?.includes('Transaction') === true && (
          <>
            <FormSelect
              type='text'
              label={'Award Number'}
              name='awardId'
              onChange={(e: any) => {
                handleChange(e);
                setFieldValue('transactionId', null);
              }}
              errors={errors}
              touched={touched}
              options={transactionOptions
                ?.filter(
                  (a: Record<string, string>, i: number) =>
                    transactionOptions?.findIndex(
                      (s: Record<string, string>) => a.awardId === s.awardId
                    ) === i
                )
                ?.map((item: Record<string, string>) => ({
                  label: item?.label,
                  value: item?.awardId,
                }))}
              values={values}
              setFieldValue={setFieldValue}
              inputType={modalStatus}
              disabled={modalStatus === 'View'}
              required
            />

            <FormSelect
              type='text'
              label={'Vesting Tranche'}
              name='transactionId'
              onChange={handleChange}
              errors={errors}
              touched={touched}
              options={transactionOptions
                ?.filter((item: Record<string, string>) => item?.awardId === values?.awardId)
                .map((item: Record<string, string>) => ({
                  label: item?.vestingDate,
                  value: item?.value,
                }))}
              values={values}
              setFieldValue={setFieldValue}
              inputType={modalStatus}
              disabled={modalStatus === 'View'}
              required
            />
          </>
        )}
      </div>
    </>
  );
};

export default ApplicableComponent;
