import { useSelector } from 'react-redux';
import FlashMessage from '../../../components/flash-message';
import Tag from '../../../components/lozenge/tags';
import { PopoverContentWrapper } from '../../../components/modal/modal.styled';
import Spinners from '../../../components/spinner';
import Heading from '../../../components/typography/index';
import {
  ROLES_PERMISSION_LIST,
  SORTED_FIELD_PERMISSION,
  SORTED_MODULE_PERMISSION,
} from '../../../constants/common';
import { getFilteredPermissionData } from '../../../utils';
import {
  getPopoverGridSystemColumn,
  getPopoverGridSystemRow,
  handleFilterPermissionList,
  handleSortPermissionNames,
} from '../../../utils/global';
import { selectAuthRoutesList } from '../../configuration/routeSlice';

interface RolesPopoverContentType {
  permissionData: Record<string, boolean>;
  loading: boolean;
}

const RolesPopoverContent = ({ permissionData, loading }: RolesPopoverContentType): JSX.Element => {
  const userAuthList = useSelector(selectAuthRoutesList);
  const allFieldValues: any = userAuthList.localization?.values?.Wealthlane;
  const permissionsData = getFilteredPermissionData(permissionData);

  const { nonFieldLevelPermissionsGroups, fieldLevelPermissionsGroups } = permissionsData;
  const filterNonFieldLevelPermission = handleSortPermissionNames(
    nonFieldLevelPermissionsGroups as any,
    SORTED_MODULE_PERMISSION
  );
  const filterFieldLevelPermission = handleSortPermissionNames(
    fieldLevelPermissionsGroups,
    SORTED_FIELD_PERMISSION
  );

  const nonFieldGridRow = getPopoverGridSystemRow(Object.keys(nonFieldLevelPermissionsGroups)) ?? 1;
  const nonFieldGridColumn =
    getPopoverGridSystemColumn(Object.keys(nonFieldLevelPermissionsGroups)) ?? 1;

  const fieldGridRow = getPopoverGridSystemRow(Object.keys(fieldLevelPermissionsGroups)) || 1;
  const fieldGridColumn = getPopoverGridSystemColumn(Object.keys(fieldLevelPermissionsGroups)) || 1;
  return (
    <PopoverContentWrapper className='roles-popovers'>
      {loading && <Spinners size='md' />}
      {!loading && Object.keys(permissionsData).length > 0 && (
        <>
          {Object.keys(nonFieldLevelPermissionsGroups).length > 0 ? (
            <div className='roles-popovers'>
              <Tag
                variant='info'
                title='Module Level Permission'
                className='tag'
                popoverClassName='roles-popovers'
              />
              <div
                className={`roles-popovers content grid gap-y-6 grid-cols-${nonFieldGridColumn} grid-rows-${nonFieldGridRow}`}
              >
                {permissionsData !== undefined &&
                  Object.keys(filterNonFieldLevelPermission).map((data: string) => {
                    const rolesInfo = filterNonFieldLevelPermission[data].find(
                      (item: any) => item.text === 'View'
                    )?.key;

                    return (
                      <div className='content-info roles-popovers'>
                        <Heading
                          title={
                            rolesInfo !== undefined && allFieldValues != null
                              ? allFieldValues[rolesInfo]
                              : '-'
                          }
                          variant='body-xs'
                          className='title roles-popovers'
                        />
                        <div className='flex roles-popovers'>
                          {filterNonFieldLevelPermission[`${data}`]
                            .sort((a: any, b: any) => {
                              const A = a.text;
                              const B = b.text;
                              return ROLES_PERMISSION_LIST.indexOf(A) >
                                ROLES_PERMISSION_LIST.indexOf(B)
                                ? 1
                                : -1;
                            })
                            .map((itemData: any, index: number) => {
                              return (
                                <>
                                  <Heading
                                    title={itemData.text}
                                    variant='body-s'
                                    className='roles-popovers'
                                  />
                                  {
                                    <Heading
                                      title={
                                        filterNonFieldLevelPermission[`${data}`].length !==
                                        index + 1 ? (
                                          <>
                                            {filterNonFieldLevelPermission[`${data}`].length > 1 &&
                                              filterNonFieldLevelPermission[`${data}`].length -
                                                2 !==
                                                index && (
                                                <span className='comma-text roles-popovers'>,</span>
                                              )}
                                          </>
                                        ) : (
                                          <>
                                            {filterNonFieldLevelPermission[`${data}`].length ===
                                              1 && (
                                              <span className='only-text roles-popovers'>Only</span>
                                            )}
                                          </>
                                        )
                                      }
                                      variant='body-s'
                                      className='roles-popovers'
                                    />
                                  }
                                  {filterNonFieldLevelPermission[`${data}`].length > 1 &&
                                    filterNonFieldLevelPermission[`${data}`].length - 2 ===
                                      index && (
                                      <Heading
                                        title={
                                          <span className='comma-text only-text roles-popovers'>
                                            &
                                          </span>
                                        }
                                        variant='body-s'
                                        className='roles-popovers'
                                      />
                                    )}
                                </>
                              );
                            })}
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          ) : null}
          {Object.keys(filterFieldLevelPermission).length > 0 ? (
            <div className='roles-popovers'>
              <Tag
                variant='info'
                title='Field Level Permission'
                className='tag'
                popoverClassName='roles-popovers'
              />
              <div
                className={`roles-popovers content grid gap-y-6 grid-cols-${fieldGridColumn} grid-rows-${fieldGridRow}`}
              >
                {Boolean(permissionsData) &&
                  Object.keys(filterFieldLevelPermission).map((data: string) => {
                    const rolesInfo = filterFieldLevelPermission[data].find(
                      (item: any) => item.text === 'View'
                    )?.key;

                    return (
                      <div className='content-info roles-popovers'>
                        <Heading
                          title={
                            Boolean(rolesInfo) && Boolean(allFieldValues)
                              ? allFieldValues[rolesInfo]
                              : '-'
                          }
                          variant='body-xs'
                          className='title roles-popovers'
                        />
                        <div className='flex roles-popovers'>
                          {filterFieldLevelPermission[`${data}`]
                            .sort((a: any, b: any) => {
                              const A = a.text;
                              const B = b.text;
                              return ROLES_PERMISSION_LIST.indexOf(A) >
                                ROLES_PERMISSION_LIST.indexOf(B)
                                ? 1
                                : -1;
                            })
                            .map((itemData: any, index: number) => {
                              return (
                                <>
                                  <Heading
                                    title={itemData.text}
                                    variant='body-s'
                                    className='roles-popovers'
                                  />
                                  {
                                    <Heading
                                      title={
                                        filterFieldLevelPermission[`${data}`].length !==
                                        index + 1 ? (
                                          <>
                                            {filterFieldLevelPermission[`${data}`].length > 1 &&
                                              filterFieldLevelPermission[`${data}`].length - 2 !==
                                                index && (
                                                <span className='comma-text roles-popovers'>,</span>
                                              )}
                                          </>
                                        ) : (
                                          <>
                                            {filterFieldLevelPermission[`${data}`].length === 1 && (
                                              <span className='only-text roles-popovers'>Only</span>
                                            )}
                                          </>
                                        )
                                      }
                                      variant='body-s'
                                      className='roles-popovers'
                                    />
                                  }
                                  {filterFieldLevelPermission[`${data}`].length > 1 &&
                                    filterFieldLevelPermission[`${data}`].length - 2 === index && (
                                      <Heading
                                        title={
                                          <span className='comma-text only-text roles-popovers'>
                                            &
                                          </span>
                                        }
                                        variant='body-s'
                                        className='roles-popovers'
                                      />
                                    )}
                                </>
                              );
                            })}
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          ) : null}
        </>
      )}
      {!loading &&
        permissionsData !== undefined &&
        Object.keys(nonFieldLevelPermissionsGroups).length === 0 &&
        Object.keys(fieldLevelPermissionsGroups).length === 0 && (
          <FlashMessage
            size='sm'
            message='No Data Found'
            type='info'
            popoverClassName='roles-popovers'
          />
        )}
    </PopoverContentWrapper>
  );
};

export default RolesPopoverContent;
