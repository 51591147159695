import { useEffect, useState } from 'react';
import { PopoverModelWrapper } from '../../components/modal/modal.styled';
import Heading from '../../components/typography/index';
import { ActionButton } from '../../components/vertical-dot/popper';
import { TableBodyCellColumn } from '../style/table.styled';
import { type TableHeaderType } from '../../types/common';

export const RolesHeader = (
  action: (value: number) => any,
  handleShow: (id: string, event: any) => void,
  hasMousePosition: boolean,
  resetMousePosition: () => void
): TableHeaderType[] => {
  const [title, setTitle] = useState('');
  useEffect(() => {
    function handleClickOutside(event: MouseEvent): void {
      const targetElement = event.target as Element;
      if (!targetElement.className.includes('roles-popovers')) {
        resetMousePosition();
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [resetMousePosition]);
  return [
    {
      Header: 'Role Names',
      accessor: 'roleName',
      width: 200,
      Cell: ({ row }: any) => (
        <PopoverModelWrapper className='roles-popovers'>
          <Heading
            variant='body-s'
            title={row.original.roleName ?? '-'}
            onClick={(e: any) => {
              setTitle(
                `${row.original.roleName !== null ? String(row.original.roleName) : '-'}-${
                  row.id !== null ? String(row.id) : '-'
                }`
              );

              handleShow(row.original.id, e);
            }}
            className={`roles-popovers title ${
              hasMousePosition &&
              title ===
                `${row.original.roleName !== null ? String(row.original.roleName) : '-'}-${
                  row.id !== null ? String(row.id) : '-'
                }`
                ? 'active'
                : ''
            }`}
          />
        </PopoverModelWrapper>
      ),
    },
    {
      Header: 'Description',
      accessor: 'description',
      width: 500,
      sortable: false,
      Cell: ({ row }: any) => {
        return (
          <TableBodyCellColumn>
            {row.original.description ? row.original.description : '-'}
          </TableBodyCellColumn>
        );
      },
    },
    {
      Header: '',
      accessor: 'actions',
      width: 20,
      sortable: false,
      Cell: ({ row }: any) => (
        <ActionButton
          actions={action(row.original)}
          id={row.original.id}
          rowData={row.original}
          counter={parseInt(row?.id)}
        />
      ),
    },
  ];
};
