import { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import { ModalStatusType, OptionType } from '../../../../types/common';
import InitialValuesForm from '../../../../constants/initialValues';
import useWealthlaneTaxApi from '../../../../hooks/useWealthlaneTaxApi';
import {
  useGetTaxCityOptions,
  useGetTaxCountryOptions,
  useGetTaxJuridictionLevel,
  useGetTaxPeriodAll,
  useGetTaxStateOptions,
} from '../../../../hooks';
import {
  JurisdictionLevelComponent,
  TaxBracketComponent,
  UserDefinedCriteriaComponent,
} from './tax-block';
import ApplicableComponent from './tax-block/applicableComponent';
import {
  VendorTaxItemService,
  Wealthlane_TaxService_Dtos_Response_ResponseDto,
  Wealthlane_TaxService_Dtos_TaxItemDto_CreateTaxItemOverrideDto,
  Wealthlane_TaxService_Dtos_TaxItemDto_JsonDto,
  Wealthlane_TaxService_Dtos_TaxItemDto_TaxItemDto,
} from '../../../../services/wealthlane-tax-services';
import { toast } from 'react-toastify';
import useGetTaxNameOptions from '../../../../hooks/get/useGetTaxNameOptions';

import _ from 'lodash';
import Spinners from '../../../../components/spinner';
import ToastComponent from '../../../../components/toast';
import ModalComponent from '../../../../components/modal/modal';
import ModalBodyComponent from '../../../../components/modal/modalBodyComponent';
import InputText from '../../../../components/input-text/inputText';
import FormSelect from '../../../../components/dropdown/formSelect';
import ModalFooterComponent from '../../../../components/modal/modalFooterComponent';

interface TaxOverrideType {
  isModalOpen: boolean;
  closeModal: () => void;
  modalStatus: ModalStatusType;
  setModalStatus: (value: ModalStatusType) => void;
  initialValue?: Wealthlane_TaxService_Dtos_TaxItemDto_TaxItemDto | null;
}

const TaxOverrideModal = ({
  isModalOpen,
  closeModal,
  modalStatus,
  setModalStatus,
  initialValue,
}: TaxOverrideType) => {
  let optionState = {
    countryId: '',
    stateId: '',
  };
  const [optionSelectedState, setOptionSelectedState] =
    useState<Record<string, string>>(optionState);

  const { handleRequest } = useWealthlaneTaxApi();
  const { data: taxPeriodOption } = useGetTaxPeriodAll(handleRequest);
  const { data: juridictionLevelOptions } = useGetTaxJuridictionLevel(handleRequest);
  const [loading, setLoading] = useState(false);

  const { data: parentTax } = useGetTaxNameOptions(handleRequest);
  const { data: countryOptions } = useGetTaxCountryOptions(handleRequest);

  const [applicableName, setApplicableName] = useState<string>('');

  const { data: cityOptions } = useGetTaxCityOptions(handleRequest, optionSelectedState?.stateId);

  const handleSubmit = (values: Wealthlane_TaxService_Dtos_TaxItemDto_CreateTaxItemOverrideDto) => {
    setLoading(true);
    handleRequest(VendorTaxItemService.postApiTaxApiAppVendorTaxItemTaxItemOverride(values))
      .then((response: Wealthlane_TaxService_Dtos_Response_ResponseDto | undefined) => {
        if (response) {
          closeModal();
          toast.success(<ToastComponent label='Success' message={`Tax override.`} />);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // const [metaValidation, setMetaValidation] = useState<
  //   Wealthlane_TaxService_Dtos_TaxItemDto_JsonDto | undefined
  // >();

  return (
    <Formik
      enableReinitialize
      initialValues={
        initialValue
          ? {
              ...initialValue,
              // taxBrackets: [
              //   {
              //     taxRate: '',
              //     lowerLimit: '',
              //     upperLimit: '',
              //   },
              // ],
            }
          : InitialValuesForm.taxSetup
      }
      // validationSchema={Yup.object().shape(TAX_SETUP_VALIDATION(metaValidation))}
      onSubmit={(values) => {
        const payload = {
          ...values,
          taxBrackets: values?.taxBrackets?.map((item: any) => ({
            lowerLimit: Number(String(item?.lowerLimit)?.replaceAll(',', '') || ''),
            taxRate: Number(String(item?.taxRate)?.replaceAll(',', '') || ''),
            upperLimit: Number(String(item?.upperLimit)?.replaceAll(',', '') || ''),
          })),
          parentTaxItemId: initialValue?.id,
        };
        handleSubmit(payload);
      }}
    >
      {({ touched, handleChange, errors, setFieldValue, setFieldTouched, values }: any) => {
        console.log('error', errors);
        const metaData: Wealthlane_TaxService_Dtos_TaxItemDto_JsonDto | undefined =
          juridictionLevelOptions?.filter(
            (item: OptionType) => item?.value === values?.jurisdictionLevelId
          )[0]?.metadata;

        // eslint-disable-next-line react-hooks/rules-of-hooks
        // useEffect(() => {
        //   setMetaValidation(metaData);
        // }, [metaData]);

        return (
          <ModalComponent
            show={isModalOpen}
            closeModal={closeModal}
            // loading={loading}
            valueEdited={_.isEqual(values, InitialValuesForm.taxSetup)}
            size='lg'
            title={`Override Tax`}
            // title={`${modalStatus === 'Add' ? 'Create' : modalStatus} Tax Period`}
            children={
              <Form>
                <ModalBodyComponent>
                  <>
                    {!initialValue ? (
                      <Spinners className='mt-[35%]' />
                    ) : (
                      <div className=''>
                        <div className='grid grid-cols-12 gap-x-8'>
                          <div className='col-span-5'>
                            <InputText
                              type='text'
                              label='Tax Year Name'
                              name='name'
                              onChange={handleChange}
                              errors={errors}
                              touched={touched}
                              inputType={modalStatus}
                              required
                            />
                          </div>
                          <div className='col-span-3'>
                            <FormSelect
                              type='text'
                              label={'Tax period'}
                              name='taxPeriodId'
                              containerClassName='formGroup'
                              onChange={handleChange}
                              errors={errors}
                              touched={touched}
                              options={taxPeriodOption}
                              values={values}
                              setFieldValue={setFieldValue}
                              inputType={modalStatus}
                              disabled
                              required
                            />
                          </div>
                          <div className='col-span-4'>
                            <FormSelect
                              type='text'
                              label={'Jurisdiction'}
                              name='jurisdictionLevelId'
                              containerClassName='formGroup'
                              onChange={handleChange}
                              errors={errors}
                              touched={touched}
                              options={juridictionLevelOptions}
                              values={values}
                              setFieldValue={setFieldValue}
                              inputType={modalStatus}
                              disabled
                              required
                            />
                          </div>
                          <div className='col-span-4'>
                            <FormSelect
                              type='text'
                              label={'Parent Tax'}
                              name='id'
                              containerClassName='formGroup'
                              onChange={handleChange}
                              errors={errors}
                              touched={touched}
                              options={parentTax}
                              values={values}
                              setFieldValue={setFieldValue}
                              inputType={modalStatus}
                              disabled
                              required
                            />
                          </div>
                        </div>

                        {/* Tax Bracket */}
                        <TaxBracketComponent
                          metaData={metaData}
                          handleChange={handleChange}
                          errors={errors}
                          touched={touched}
                          values={values}
                          setFieldValue={setFieldValue}
                          modalStatus={modalStatus}
                        />

                        {/* Applicable TO */}
                        <ApplicableComponent
                          setApplicableName={setApplicableName}
                          handleRequest={handleRequest}
                          handleChange={handleChange}
                          errors={errors}
                          touched={touched}
                          values={values}
                          setFieldValue={setFieldValue}
                          modalStatus={'Add'}
                        />
                      </div>
                    )}
                  </>
                </ModalBodyComponent>
                <ModalFooterComponent
                  modalStatus={modalStatus}
                  loading={loading}
                  titleCollection={{ reviewTitle: 'Review & Confirm', cancelTitle: 'Go Back' }}
                  onCloseModal={() => {
                    closeModal();
                  }}
                  // onEditModal={() => setModalStatus('Edit')}
                />
              </Form>
            }
          />
        );
      }}
    </Formik>
  );
};

export default TaxOverrideModal;
