import { type UseQueryResult, useQuery } from 'react-query';
import {
  VendorRoleService,
  // eslint-disable-next-line camelcase
  type Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_AdministrationService_Dtos_VendorRole_VendorRoleDto_Wealthlane_AdministrationService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null,
} from '../../services/wealthlane-administration-services';
import { type TableParamsType } from '../../types/common';

const useGetRoleList = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  tableInfo: TableParamsType
): UseQueryResult<
  // eslint-disable-next-line camelcase
  | Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_AdministrationService_Dtos_VendorRole_VendorRoleDto_Wealthlane_AdministrationService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null
  | undefined,
  unknown
> => {
  const { filter, sorting, skipCount, maxResultCount } = tableInfo;
  const rolesData = useQuery(
    ['getAdminRoles', filter, sorting, skipCount, maxResultCount],
    async () => {
      return await handleRequest(
        VendorRoleService.getApiAdministrationApiAppVendorRoleVendorRole(
          filter,
          sorting,
          skipCount,
          maxResultCount
        )
      );
    }
    // {
    //   keepPreviousData: true,
    // }
  );

  return rolesData;
};

export default useGetRoleList;
