import { type UseQueryResult, useQuery } from 'react-query';
import {
  RsuAwardService,
  // eslint-disable-next-line camelcase
  type Wealthlane_AwardService_Dtos_RSUAward_AwardTransactionDetailsByTransactionIdDTO,
} from '../../services/wealthlane-award-services';

const useGetTransactionById = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  id?: string
): UseQueryResult<
  // eslint-disable-next-line camelcase
  Wealthlane_AwardService_Dtos_RSUAward_AwardTransactionDetailsByTransactionIdDTO | undefined,
  unknown
> => {
  const TransactionById = useQuery(['getTransactionById', id], async () => {
    if (id) {
      return await handleRequest(
        RsuAwardService.getApiAwardApiAppRSUAwardTransactionDetailByTransactionId(id)
      );
    }
  });

  return TransactionById;
};

export default useGetTransactionById;
