import { useRef, useEffect, useState, type PropsWithChildren } from 'react';
import { FilterContainer, FilterDropdown } from './filter.styled';
import { FormWrapper } from '../input-text/input.styled';
import { COLORS } from '../../constants/style/color';
import { usePopper } from 'react-popper';
import { Portal } from '../vertical-dot/popperContainer';
import { ErrorMessage } from 'formik';
import Heading from '../typography';

interface SearchWithFilterType {
  size?: 'lg' | 'md' | 'sm';
  open?: boolean;
  error?: any;
  touched?: any;
  setOpen?: any;
  className?: string;
  wrapperRef?: any;
  handleChange?: any;
  height?: string;
  name?: string;
  value?: string;
  modalStatus?: string;
  openFilter: boolean;
  setOpenFilter: any;
  renderFunction?: any;
}

const SearchWithFilter = ({
  children,
  className,
  error,
  touched,
  open,
  size,
  name,
  value,
  handleChange,
  height,
  modalStatus,
  openFilter,
  setOpenFilter,
  renderFunction,
}: PropsWithChildren<SearchWithFilterType>): JSX.Element => {
  const wrapperRef = useRef<any>(null);
  const [openContainer, setOpenContainer] = useState<boolean>(false);

  useEffect(() => {
    setOpenContainer(openFilter);
  }, [openFilter]);

  return (
    <FilterContainer className={className} id={name}>
      <div ref={wrapperRef}>
        <FormWrapper className=' m-0 w-full'>
          <input
            type='text'
            className={`w-full formGroup m-0 ${touched && error ? 'is-invalid' : ''}`}
            placeholder={'Search'}
            name={name}
            style={{
              color: COLORS.Black,
              width: '176px',
            }}
            onClick={() => {
              setOpenFilter(!openContainer);
              setOpenContainer(!openContainer);
              renderFunction && renderFunction();
            }}
            value={value}
            disabled={modalStatus === 'View'}
          />
          {touched && error && (
            <Heading
              variant='body-xs'
              title={'Required Field'}
              className={'mt-2'}
              color={COLORS.Gray}
            />
          )}
        </FormWrapper>
      </div>
      <PortalContainer
        size={size}
        children={children}
        open={openContainer}
        setOpen={setOpenContainer}
        wrapperRef={wrapperRef}
        height={height}
        openFilter={openFilter}
        setOpenFilter={setOpenFilter}
      />
    </FilterContainer>
  );
};

export default SearchWithFilter;

const PortalContainer = ({
  children,
  size,
  open,
  setOpen,
  wrapperRef,
  height,
  setOpenFilter,
}: PropsWithChildren<SearchWithFilterType>): JSX.Element => {
  const [popperElement, setPopperElement] = useState<any>();
  const [visible, setVisibility] = useState(open);
  useEffect(() => {
    setVisibility(open);
  }, [open]);

  useEffect(() => {
    function handleClickOutside(event: Event): void {
      if (popperElement != null && !(popperElement as HTMLElement).contains(event.target as Node)) {
        setVisibility(false);
        setOpen(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [popperElement]);

  const { styles, attributes } = usePopper(wrapperRef.current, popperElement, {
    placement: 'bottom-start',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 10],
        },
      },
    ],
  });
  return (
    <Portal>
      {visible === true && (
        <FilterDropdown
          size={size ?? 'md'}
          ref={setPopperElement}
          style={styles.popper}
          {...attributes.popper}>
          {children}
        </FilterDropdown>
      )}
    </Portal>
  );
};
