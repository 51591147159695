import { type UseQueryResult, useQuery } from 'react-query';
import {
  // eslint-disable-next-line camelcase
  type Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_AdministrationService_Dtos_VendorUser_VendorUserViewDto_Wealthlane_AdministrationService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null,
} from '../../services/wealthlane-administration-services';
import { type TableParamsType } from '../../types/common';
import {  VendorTaxItemService } from '../../services/wealthlane-tax-services';

const useGetCompanyTaxSetupList = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  tableInfo: TableParamsType,
  thisYearTax?: boolean,
  nextYearTax?: boolean
): UseQueryResult<
  // eslint-disable-next-line camelcase
  | Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_AdministrationService_Dtos_VendorUser_VendorUserViewDto_Wealthlane_AdministrationService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null
  | undefined,
  unknown
> => {
  const { filter, sorting, skipCount, maxResultCount } = tableInfo;

  const companyTaxSetupList = useQuery(
    [
      'getCompanyTaxSetupList',
      thisYearTax,
      nextYearTax,
      filter,
      sorting,
      skipCount,
      maxResultCount,
    ],
    async () => {
      return await handleRequest(
        VendorTaxItemService.getApiTaxApiAppVendorTaxItemCompanyTaxSetupList(
          thisYearTax,
          nextYearTax,
          filter,
          sorting,
          skipCount,
          maxResultCount
        )
      );
    }
  );

  return companyTaxSetupList;
};

export default useGetCompanyTaxSetupList;
