import { type UseQueryResult, useQuery } from 'react-query';
import { ParticipantService } from '../../services/wealthlane-customer-services';

const useGetTaxAllParticipant = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  input?: string,
  participantId?: string,
  applicableName?: string | null | undefined
): any => {
  const taxAllParticipantOptions = useQuery(
    ['useGetTaxAllParticipantOptions', input, participantId, applicableName],
    async () => {
      if (
        applicableName?.includes('Transaction') === true ||
        applicableName?.includes('Participant') === true
      ) {
        if (input ?? participantId) {
          return await handleRequest(
            ParticipantService.getApiCustomerApiAppParticipantParticipantsForTax(
              input,
              participantId as string
            )
          );
        } else {
          return await handleRequest(
            ParticipantService.getApiCustomerApiAppParticipantParticipantsForTax()
          );
        }
      }
    },
    {
      select: (data) => {
        return data?.map((item) => {
          return {
            label: item.participantName ?? '',
            value: item.participantId ?? '',
            department: item.department ?? '',
            title: item.jobTitle ?? '',
            employeeId: item?.employeeId ?? '',
          };
        });
      },
    }
  );

  return taxAllParticipantOptions;
};

export default useGetTaxAllParticipant;
