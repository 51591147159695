import { type UseQueryResult, useQuery } from 'react-query';
import {
  PlanService,
  // eslint-disable-next-line camelcase
  type Wealthlane_AwardService_Dtos_Plan_PlanDto,
} from '../../services/wealthlane-award-services';

const useGetPlanById = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  id?: string
): UseQueryResult<
  // eslint-disable-next-line camelcase
  Wealthlane_AwardService_Dtos_Plan_PlanDto | undefined,
  unknown
> => {
  const PlanById = useQuery(['getPlanById', id], async () => {
    if (id) {
      return await handleRequest(PlanService.getApiAwardApiAppPlanPlanById(id));
    }
  });

  return PlanById;
};

export default useGetPlanById;
