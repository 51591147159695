import moment from 'moment';

import { DATE_FORMAT } from '../common';
import { PopoverModelWrapper } from '../../components/modal/modal.styled';
import { Heading } from '../../components';
import { TableBodyCellColumn } from '../style/table.styled';
import Tag from '../../components/lozenge/tags';
import { ActionButton } from '../../components/vertical-dot/popper';

export const taxPeriodHeader = (action?: any) => {
  return [
    {
      Header: 'Tax Period Name',
      accessor: 'name',
      width: 200,
      Cell: ({ row }: any) => (
        <PopoverModelWrapper className='roles-popovers'>
          <Heading variant='body-s' title={row.original.name || '-'} />
        </PopoverModelWrapper>
      ),
    },
    {
      Header: 'Date Range',
      accessor: 'effectiveFrom',
      //   width: 500,
      sortable: false,
      Cell: ({ row }: any) => {
        return (
          <TableBodyCellColumn>
            {`${moment(row.original.effectiveFrom).format(DATE_FORMAT)} - ${moment(
              row.original.effectiveTill
            ).format(DATE_FORMAT)}` || '-'}
          </TableBodyCellColumn>
        );
      },
    },

    {
      Header: '',
      accessor: 'actions',
      width: 80,
      sortable: false,
      Cell: ({ row }: any) => {
        return (
          <ActionButton
            actions={action}
            id={row.original.id}
            rowData={row.original}
            counter={parseInt(row?.id)}
          />
        );
      },
    },
  ];
};
