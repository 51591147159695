import Heading from '../typography';
import { TagWrapper } from './lozenge.styled';

interface TagType {
  variant:
    | 'default'
    | 'default-outline'
    | 'default-light'
    | 'default-outline-light'
    | 'primary'
    | 'primary-outline'
    | 'success'
    | 'success-outline'
    | 'error'
    | 'error-dark'
    | 'error-outline'
    | 'info'
    | 'info-outline'
    | 'rounded-primary'
    | 'rounded-default';
  title: string | number | null | undefined;
  className?: string;
  hasDot?: boolean;
  icon?: string;
  popoverClassName?: string; // special class for popovers
  color?: string;
  handleClick?: () => void;
}

const Tag = ({
  variant,
  title,
  className,
  hasDot,
  icon,
  popoverClassName,
  color,
  handleClick,
}: TagType): JSX.Element => {
  return (
    <TagWrapper
      variant={variant}
      className={`${className ?? ''} ${popoverClassName ?? ''}`}
      onClick={handleClick}
    >
      {(hasDot ?? false) && <div className={`dot ${popoverClassName ?? ''}`} />}
      {icon != null && (
        <span className={`material-symbols-outlined ${popoverClassName ?? ''}`}>{icon}</span>
      )}
      <Heading variant='body-xs' title={title} className={popoverClassName} color={color} />
    </TagWrapper>
  );
};

export default Tag;
