import styled from 'styled-components';
import { rem } from '../../../../utils/style';
import { COLORS } from '../../../../constants/style/color';
export const TaxBracketAccordingContainer = styled.div`
  background: ${COLORS.Grey400Transparent};
  /* opacity: 0.5; */
  border-bottom: 1px solid ${COLORS.LightGray};
  border-radius: 6px 6px 0px 0px;
  padding: ${rem(13)} ${rem(15)};
  font-weight: 500;
`;
export const ModalDropWrapper = styled.div`
  position: relative;
  margin-left: 8px;
`;

export const ModalDrop = styled.div`
  display: flex;
  height: 100%;
  justify-content: flex-start;
  align-items: center;
  /* cursor: pointer; */

  .downIcon {
    color: ${COLORS.SpanishGray};
    margin-left: 20px;
    font-size: 28px;
    height: 19px;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    &.active {
      color: ${COLORS.Purple};
    }

    &:hover {
      color: ${COLORS.Purple};
    }
  }
`;

export const ModalDropdownMenu = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 145px;
  left: 0;
  top: 105%;
  z-index: 10;
  background: ${COLORS.White};
  overflow: auto;
  max-height: 50vh;
  box-shadow: 0px 2px 20px rgba(109, 109, 109, 0.15);
  border-radius: 10px;
  padding: 10px 12px;

  &.nav-subMenu {
    left: 0;
    top: 73px;
    right: reset;
    justify-content: flex-start;

    a {
      width: 100%;
      justify-content: flex-start;
      color: #969696;
      font-size: ${rem(14)};
    }
  }

  ::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 7px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${COLORS.SpanishGray};
    border-radius: 7px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

export const TaxBracketAccordingValue = styled.div`
  height: auto;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 7px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${COLORS.SpanishGray};
    border-radius: 7px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  background: ${COLORS.Grey100Transparent};
  border-radius: 0px 0px 6px 6px;
  padding: ${rem(21)} ${rem(32)};
`;
export const TaxBracketHeaders = styled.div`
  padding-bottom: ${rem(15)};
  border-bottom: 1px solid ${COLORS.LightGray};
`;
export const TaxBracketValue = styled.div`
  padding: ${rem(21)} ${rem(0)} ${rem(0)} ${rem(0)};
  border-bottom: 1px solid ${COLORS.LightGray};
  &:last-child {
    border-bottom: 0;
  }
`;
export const UserDefinedCriteria = styled.div`
  padding: ${rem(10)} ${rem(12)};
  background-color: ${COLORS.TagShadow};
  border-radius: 6px;
  width: 100%;
`;

export const ListWrapper = styled.div`
  width: 100%;
  border-radius: 10px;
  ul {
    /* max-width: 400px; */
    margin: 0 auto;
    list-style-type: none;
    margin: 0;
    font-family: sans-serif;
    padding: 30px 0px;
  }
  li {
    display: flex;
    align-items: center;
  }
  ul li {
    padding: 0 0 20px 30px;
    position: relative;
    margin: 0;
  }
  ul li:after {
    position: absolute;
    top: 15px;
    left: 0;
    content: '';
    border-radius: 50%;
    display: inline-block;
    height: 10px;
    width: 10px;
    text-align: center;
    line-height: 24px;
    background: #5bbcc9;
  }
  ul li:before {
    position: absolute;
    left: 4px;
    top: 15px;
    content: '';
    height: 100%;
    width: 0;
    border-left: 2px solid #5bbcc9;
  }
  ul li:last-of-type:before {
    border: none;
  }
  ul li:last-of-type div {
    border: none;
  }
`;

export const ListContent = styled.div`
  border-bottom: 1px solid rgba(233, 233, 233, 0.5);
  width: 100%;

  h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 19px;
    margin: 0;
    color: #000000;
  }
  p {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    margin: 0;
    color: #7a7a7a;
    width: 162px;
  }
  span {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    text-align: right;

    color: #333333;
  }
  div {
    margin-top: 5px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-end;
    padding-bottom: 20px;
  }
`;
export const ListLink = styled.div`
  display: flex;
  justify-content: end;

  a {
    text-decoration: none;
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 19px;
    text-align: right;

    color: #071360;
  }
`;
export const ViewData = styled.div`
  padding-bottom: 24px;
  margin-bottom: 24px;
  border-bottom: 1px solid ${COLORS.CulturedWhite};
`;

export const BtnGroup = styled.div`
  display: flex;
  align-items: center;
  .btnTag {
    /* border: none; */
    transition: all 300ms ease;
    span {
      transition: inherit;
      line-height: 8px;
    }
  }
  > button {
    position: relative;
    z-index: initial;
    transition: all 300ms ease;
    &:first-child {
      &:hover {
        color: ${COLORS.Iris};
        border-color: ${COLORS.Iris};
        .btnTag {
          border-color: ${COLORS.Iris};
          background: ${COLORS.Iris};
          span {
            color: ${COLORS.White};
          }
        }
        &:after {
          content: '';
          background: #969696;
          z-index: 10;
          background: ${COLORS.Iris};
        }
      }
      &:after {
        content: '';
        height: 107%;
        width: 1px;
        background: #d7d7d7;
        top: -1px;
        position: absolute;
        right: -1px;
        z-index: 0;
        transition: all 300ms ease;
      }
    }
    &:last-child {
      &:hover {
        color: ${COLORS.Purple};
        border-color: ${COLORS.Purple};
        &:after {
          content: '';
          background: #969696;
          z-index: 9;
          background: ${COLORS.Purple};
        }
      }
      &:after {
        content: '';
        height: 107%;
        width: 1px;
        background: #d7d7d7;
        top: -1px;
        position: absolute;
        left: -1px;
        /* z-index: 9999; */
        transition: all 300ms ease;
      }
    }

    &:not(:first-child) {
      margin-left: -1px;
    }
    &:not(:last-child) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    &:first-child {
      border-right-color: inherit;
    }
    &:last-child {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-left-color: inherit;
    }
  }
`;
