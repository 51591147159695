export const NotificationRouteList = [
  {
    title: 'Vendor User',
    url: '/settings/users',
  },
  {
    title: 'Vendor User Approver',
    url: '/task/user-approval',
  },
  {
    title: 'Vendor Tax',
    url: '/settings/tax-setup',
  },
  {
    title: 'Vendor Tax Approval',
    url: '/task/tax-setup-approval',
  },
];
