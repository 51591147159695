import React, { useState } from 'react';

import useWealthlaneTaxApi from '../../../../hooks/useWealthlaneTaxApi';
import { ModalStatusType, TableParamsType } from '../../../../types/common';
import InitialValuesForm from '../../../../constants/initialValues';
import { useBoolean, useGetTaxPeriodList } from '../../../../hooks';
import { PageHeader } from '../../../../constants/style/common.styled';
import { COLORS } from '../../../../constants/style/color';
import Button from '../../../../components/button';
import Table from '../../../../components/table';
import { taxPeriodHeader } from '../../../../constants/table-headers/taxPeriodHeader';
import AnimationWrapper from '../../../../components/animation/animationWrapper';
import TaxYearModal from './taxYearModal';
import Heading from '../../../../components/typography';

const TaxYear = () => {
  const { handleRequest } = useWealthlaneTaxApi();
  const [tableInfo, setTableInfo] = useState<TableParamsType>(InitialValuesForm.tableParams);
  const [modalStatus, setModalStatus] = useState<ModalStatusType>('Edit');
  const { value: isModalOpen, setFalse: closeModal, setTrue: openModal } = useBoolean(false);
  const { data, status } = useGetTaxPeriodList(handleRequest, tableInfo);
  const [initialValue, setInitialValue] = useState<any>(InitialValuesForm.taxPeriod);

  const action = [
    {
      action: 'Edit',
      onClick: (id: string, rowData: any) => {
        setInitialValue(rowData);
        openModal();
      },
    },
  ];
  return (
    <div>
      <div className='flex justify-between items-center'>
        <PageHeader>
          <Heading variant='h3' title={`Tax Period`} />
          <Heading
            variant='body-s'
            title={`View all the tax period present in your company.`}
            color={`${COLORS.Gray}`}
          />
        </PageHeader>
        <Button
          variant={'primary'}
          title={'Create Tax Period'}
          icon='add_circle_outline'
          onClick={() => {
            openModal();
            setModalStatus('Add');
            setInitialValue(InitialValuesForm.taxPeriod);
          }}
        />
      </div>
      <Table
        columns={taxPeriodHeader(action)}
        data={data?.items ?? []}
        loading={status}
        totalCounts={data?.totalCount ?? 10}
        showPagination={true}
        tableInfo={tableInfo}
        setTableInfo={setTableInfo}
      />

      <AnimationWrapper>
        {isModalOpen && (
          <TaxYearModal
            isModalOpen={isModalOpen}
            closeModal={closeModal}
            modalStatus={modalStatus}
            setModalStatus={setModalStatus}
            loading={false}
            initialValue={initialValue}
            setInitialValue={setInitialValue}
          />
        )}
      </AnimationWrapper>
    </div>
  );
};

export default TaxYear;
