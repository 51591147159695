import { type UseQueryResult, useQuery } from 'react-query';
import { NotificationService } from '../../services/wealthlane-notification-services';

const useGetUnseenNotificationCount = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  token: string | null
): UseQueryResult<number | undefined, unknown> => {
  const unseenNotificationCountList = useQuery(
    ['getGetUnseenNotificationCountList', token],
    async () => {
      if (token !== null && token !== undefined) {
        return await handleRequest(
          NotificationService.getApiNotificationApiAppNotificationUnseenNotificationsCount()
        );
      }
    },
    {
      enabled: token !== null && token !== undefined && token !== '',
    }
  );
  return unseenNotificationCountList;
};

export default useGetUnseenNotificationCount;
