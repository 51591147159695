import { TableContainer, TableSorting, TableStyle, TableWrapper } from './table.style';
import { useTable, usePagination, useSortBy } from 'react-table';

import { type LoadingType, type TableParamsType } from '../../types/common';
import { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import Heading from '../typography/index';
import FlashMessage from '../flash-message';
import PaginationComponent from './pagination';
import { RadioButtonWrapper } from '../radio-button/radioButton.styled';
import { SkeletonLine } from '../skeleton';
import moment from 'moment';

export interface TableType {
  columns: any;
  data: any;
  loading?: LoadingType;
  totalCounts: number;
  showPagination?: boolean;
  showPaginationList?: boolean;
  showRow?: boolean;
  pageOfIndex?: number;
  tableInfo: TableParamsType;
  setTableInfo?: any;
  containerClassName?: string;
  checkedValues?: string[];
  showCheckBox?: boolean;
  onChecked?: any;
  border?: string;
  padding?: number;
  checkboxFor?: string;
  coloredTable?: boolean;
  isParticipantTable?: boolean;
  setIsHovering?: any;
}

const Table = ({
  columns,
  showPagination = false,
  data,
  loading,
  totalCounts,
  tableInfo,
  setTableInfo,
  containerClassName,
  showPaginationList = true,
  showRow = true,
  border,
  padding,
  checkedValues,
  showCheckBox = false,
  checkboxFor,
  onChecked,
  coloredTable = false,
  isParticipantTable = false,
  setIsHovering,
}: TableType): JSX.Element => {
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSizeTotal, setPageSizeTotal] = useState(10);
  const [sortField, setSortField] = useState('');
  const [order, setOrder] = useState('');
  const handleMouseOver = (id: string) => {
    setIsHovering(id);
  };

  useEffect(() => {
    if (tableInfo?.sorting === '') {
      setOrder('');
    }
  }, [tableInfo?.sorting]);

  const handleMouseOut = () => {
    setIsHovering('');
  };
  const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow, setPageSize, state } =
    useTable(
      {
        columns,
        data,
        initialState: { pageSize: totalCounts },
      },
      useSortBy,
      usePagination
    );

  useEffect(() => {
    if (tableInfo.skipCount === 0) {
      setPageIndex(1);
    }
    if (typeof tableInfo.maxResultCount === 'number' && tableInfo.maxResultCount !== 0) {
      setPageSizeTotal(tableInfo.maxResultCount);
    }
  }, [tableInfo]);

  // useEffect(() => {
  //   if (!showPagination) {
  //     setPageSize(totalCounts);
  //   }
  //   if (!isParticipantTable && !showPaginationList) {
  //     setPageSize(totalCounts);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [showPagination, totalCounts, showPaginationList, isParticipantTable]);

  const capitalizeFirst = (str: string): string => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const handleSortingChange = (accessor: any, sortable: boolean = true): void => {
    const sortingTitle = capitalizeFirst(accessor);
    const sortOrder =
      accessor === sortField && order === 'Asc'
        ? 'Desc'
        : accessor === sortField && order === 'Desc'
        ? ''
        : 'Asc';
    setSortField(accessor);

    sortable &&
      setTableInfo({
        ...tableInfo,
        sorting: isEmpty(sortOrder) ? '' : `${sortingTitle} ${sortOrder}`,
      });
    setOrder(sortOrder);
  };

  // Checkbox function
  const [checkedKeys, setCheckedKeys] = useState<Array<string | number>>([]);
  let checked = false;

  useEffect(() => {
    if (!isEmpty(checkedValues) && isEmpty(checkedKeys)) {
      setCheckedKeys(checkedValues ?? []);
    }
    if (checkedValues !== checkedKeys) {
      setCheckedKeys(checkedValues ?? []);
    }
    if (isEmpty(checkedValues)) {
      setCheckedKeys([]);
    }
  }, [checkedValues]);

  useEffect(() => {
    if (loading === 'success' && data.length === 0 && tableInfo.skipCount > 0) {
      setPageIndex(tableInfo.skipCount / 10);
      setTableInfo({ ...tableInfo, skipCount: tableInfo.skipCount - 10 });
    }
  }, [data, loading]);

  const PendingData = data?.filter((item: any) => item.status === 'Pending');
  const ApprovedData = data?.filter((item: any) => item.status === 'Approved');

  if (checkedKeys.length === data?.length && checkedKeys.length !== 0) {
    checked = true;
  }
  if (
    checkboxFor === 'Pending' &&
    checkedKeys.length === PendingData?.length &&
    checkedKeys.length !== 0
  ) {
    checked = true;
  }
  if (
    checkboxFor === 'Approved' &&
    checkedKeys.length === ApprovedData?.length &&
    checkedKeys.length !== 0
  ) {
    checked = true;
  } else if (checkedKeys.length === 0) {
    checked = false;
  }

  const handleCheckAll = (checked: boolean): void => {
    const keys = checked
      ? !isEmpty(checkedKeys)
        ? []
        : checkboxFor === 'Pending'
        ? PendingData.map((items: any) => items.id)
        : checkboxFor === 'Approved'
        ? ApprovedData.map((items: any) => items.id)
        : data.map((item: any) => item.id)
      : [];
    setCheckedKeys(keys);
    showCheckBox !== undefined && onChecked !== undefined && onChecked(keys);
  };

  const handleCheck = (value: string, checked: boolean): void => {
    const keys: any = checked
      ? [...checkedKeys, value]
      : checkedKeys.filter((item) => item !== value);
    setCheckedKeys(keys);
    showCheckBox !== undefined && onChecked !== undefined && onChecked(keys);
  };

  const checkTableBodyHeight =
    loading === 'loading'
      ? 'auto'
      : data.length === 0
      ? '213px'
      : data !== undefined && data.length < 6
      ? `${data.length * 87 + 81}px`
      : '500px';

  return (
    <TableWrapper border={border}>
      <TableContainer padding={padding} height={checkTableBodyHeight}>
        <TableStyle {...getTableProps} className={containerClassName}>
          <thead>
            {
              // Loop over the header rows
              headerGroups.map((headerGroup: any) => {
                return (
                  // Apply the header row props
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {
                      // Loop over the headers in each row
                      <>
                        {showCheckBox && (
                          <th style={{ minWidth: '10px', width: '10px' }}>
                            <RadioButtonWrapper
                              label='label'
                              className={`${
                                (checkboxFor === 'Approved' &&
                                  checkedKeys.length < ApprovedData?.length &&
                                  checkedKeys.length > 0) ||
                                (checkboxFor === 'Pending' &&
                                  checkedKeys.length < PendingData?.length &&
                                  checkedKeys.length > 0)
                                  ? 'checkboxWrapper block partialSelect'
                                  : 'checkboxWrapper block'
                              }  `}
                            >
                              <input
                                type='checkbox'
                                name={'row.index'}
                                checked={checked}
                                onChange={(event: any) => {
                                  handleCheckAll(event.target.checked);
                                }}
                              />
                            </RadioButtonWrapper>
                          </th>
                        )}
                        {headerGroup.headers.map((column: any) => {
                          return (
                            <th
                              key={column.id}
                              onClick={() => {
                                handleSortingChange(column.id, column?.sortable);
                              }}
                              {...column.getHeaderProps(
                                column?.manualSorting !== undefined &&
                                  column.getSortByToggleProps(),
                                {
                                  style: {
                                    width: column.width,
                                  },
                                }
                              )}
                            >
                              <TableSorting
                                className={`flex items-start justify-${
                                  column.center !== undefined ? 'center' : 'start'
                                }`}
                              >
                                <Heading variant='body-s' title={column.Header} />
                                {column.sortable !== false && (
                                  <div className='flex flex-col sorting-icon'>
                                    <span
                                      className={`material-symbols-rounded 
                                      ${
                                        (column.id === sortField && order === 'Asc') ||
                                        (column.isSorted === true && column.isSortedDesc === false)
                                          ? 'active'
                                          : ''
                                      }`}
                                    >
                                      arrow_drop_up
                                    </span>
                                    <span
                                      className={`material-symbols-rounded 
                                      ${
                                        (column.id === sortField && order === 'Desc') ||
                                        column.isSortedDesc !== undefined
                                          ? 'active'
                                          : ''
                                      }`}
                                    >
                                      arrow_drop_down
                                    </span>
                                  </div>
                                )}
                              </TableSorting>
                            </th>
                            // </>
                          );
                        })}
                      </>
                    }
                  </tr>
                );
              })
            }
          </thead>
          <tbody {...getTableBodyProps()}>
            {loading === 'loading' && (
              <>
                {Array.from(Array(6).keys()).map((item) => (
                  <>
                    {headerGroups.map((headerGroup: any) => {
                      return (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                          {
                            <>
                              {headerGroup.headers.map((column: any) => {
                                return (
                                  <td>
                                    <SkeletonLine size='table' />
                                  </td>
                                );
                              })}
                            </>
                          }
                        </tr>
                      );
                    })}
                  </>
                ))}
              </>
            )}
            {loading === 'success' &&
              page.map((row: any, i: number) => {
                prepareRow(row);
                return (
                  <>
                    <tr
                      style={{
                        background:
                          coloredTable &&
                          (new Date() >= moment(row?.original?.vestingDate).toDate()
                            ? `#a1d7f73b`
                            : `#f0e9ffb0`),
                      }}
                      {...row.getRowProps()}
                      onMouseOver={() => setIsHovering && handleMouseOver(row?.id)}
                      onMouseOut={() => setIsHovering && handleMouseOut()}
                    >
                      {showCheckBox && (
                        <>
                          <td style={{ minWidth: '10px', width: '10px', paddingLeft: '3px' }}>
                            {checkboxFor === 'Pending' ? (
                              row.original.status === 'Pending' ? (
                                <RadioButtonWrapper label='label' className='checkboxWrapper block'>
                                  <input
                                    type='checkbox'
                                    value={row.original.id}
                                    checked={checkedKeys.some(
                                      (item: any) => item === row.original.id
                                    )}
                                    onChange={(event: any) => {
                                      handleCheck(row.original.id, event.target.checked);
                                    }}
                                  />
                                </RadioButtonWrapper>
                              ) : null
                            ) : checkboxFor === 'Approved' ? (
                              row.original.status === 'Approved' ? (
                                <RadioButtonWrapper label='label' className='checkboxWrapper block'>
                                  <input
                                    type='checkbox'
                                    value={row.original.id}
                                    checked={checkedKeys.some(
                                      (item: any) => item === row.original.id
                                    )}
                                    onChange={(event: any) => {
                                      handleCheck(row.original.id, event.target.checked);
                                    }}
                                  />
                                </RadioButtonWrapper>
                              ) : null
                            ) : (
                              <RadioButtonWrapper label='label' className='checkboxWrapper block'>
                                <input
                                  type='checkbox'
                                  value={row.original.id}
                                  checked={checkedKeys.some(
                                    (item: any) => item === row.original.id
                                  )}
                                  onChange={(event: any) => {
                                    handleCheck(row.original.id, event.target.checked);
                                  }}
                                />
                              </RadioButtonWrapper>
                            )}
                          </td>
                        </>
                      )}
                      {row.cells.map((cell: any) => {
                        return (
                          <td
                            {...cell.getCellProps({
                              style: {
                                minWidth: cell.column.minWidth,
                                width: cell.column.width,
                              },
                            })}
                          >
                            {cell.render('Cell')}
                          </td>
                        );
                      })}
                    </tr>
                  </>
                );
              })}
            {loading !== undefined &&
              loading === 'success' &&
              page !== undefined &&
              page.length === 0 && (
                <tr className='border-0'>
                  <td colSpan={100} className='border-b-0'>
                    <div className='flex justify-center'>
                      <FlashMessage size='md' message='No Data Found' type='info' />
                    </div>
                  </td>
                </tr>
              )}
          </tbody>
        </TableStyle>
      </TableContainer>
      {showPagination && data.length > 0 && (
        <>
          <PaginationComponent
            data={data}
            totalCount={totalCounts}
            pageIndex={pageIndex}
            showRow={showRow}
            pageSize={state.pageSize}
            showPaginationList={showPaginationList}
            maxResultCount={tableInfo.maxResultCount}
            pageSizeTotal={pageSizeTotal}
            handlePaginationInfo={(value: number) => {
              setTableInfo({
                ...tableInfo,
                skipCount: (value - 1) * tableInfo.maxResultCount,
              });
              setPageIndex(value);
            }}
            handleSelectInfo={(value: string) => {
              setTableInfo({
                ...tableInfo,
                skipCount: 0,
                maxResultCount: Number(value),
              });
              setPageSizeTotal(Number(value));
              setPageSize(Number(value));
            }}
          />
        </>
      )}
    </TableWrapper>
  );
};

export default Table;
