/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Wealthlane_TaxService_Dtos_Country_CountryDto } from '../models/Wealthlane_TaxService_Dtos_Country_CountryDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CountryService {

    /**
     * @returns Wealthlane_TaxService_Dtos_Country_CountryDto Success
     * @throws ApiError
     */
    public static getApiTaxApiAppCountryCountries(): CancelablePromise<Array<Wealthlane_TaxService_Dtos_Country_CountryDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/tax/api/app/country/countries',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param jurisdictionId 
     * @returns Wealthlane_TaxService_Dtos_Country_CountryDto Success
     * @throws ApiError
     */
    public static getApiTaxApiAppCountryCountriesByJurisdiction(
jurisdictionId: string,
): CancelablePromise<Array<Wealthlane_TaxService_Dtos_Country_CountryDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/tax/api/app/country/countries-by-jurisdiction/{JurisdictionId}',
            path: {
                'JurisdictionId': jurisdictionId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

}
