import { useQuery } from 'react-query';
import {  VendorTaxApprovalService } from '../../services/wealthlane-tax-services';

const useGetTaxApproverHistory = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  id?: string
) => {
  const taxApproverHistory = useQuery(['getTaxApproverHistory', id], async () => {
    if (id) {
      return await handleRequest(
        VendorTaxApprovalService.getApiTaxApiAppVendorTaxApprovalTaxItemHistoryById(id)
      );
    }
  });

  return taxApproverHistory;
};

export default useGetTaxApproverHistory;
