import { useState } from 'react';
import { useMousePositionAsTrigger } from 'react-laag';
import { useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import AnimationWrapper from '../../../components/animation/animationWrapper';
import Button from '../../../components/button';
import PopoverModalComponent from '../../../components/modal/popoverModal';
import Search from '../../../components/search/search';
import Table from '../../../components/table';
import ToastComponent from '../../../components/toast';
import Heading from '../../../components/typography/index';
import InitialValuesForm from '../../../constants/initialValues';
import { PageHeader } from '../../../constants/style/common.styled';
import { RolesHeader } from '../../../constants/table-headers/rolesHeader';
import { useBoolean, useGetRoleList } from '../../../hooks';
import useWealthlaneAdministrationApi from '../../../hooks/useWealthlaneAdministrationApi';
import {
  VendorRoleService,
  // eslint-disable-next-line camelcase
  type Wealthlane_AdministrationService_Dtos_VendorRole_VendorRoleDto,
} from '../../../services/wealthlane-administration-services';
import { type ActionType, type ModalStatusType, type TableParamsType } from '../../../types/common';
import { getFieldLevelPermissions, getNonFieldLevelPermissions } from '../../../utils';
import { selectAuthRoutesList } from '../../configuration/routeSlice';
import RoleModal from './roleModal';
import RolesConfirmModal from './rolesConfirmModal';
import RolesPopoverContent from './rolesPopoverContent';
import { SettingRoleButton } from './systemRole.styled';

export interface rolesInitialValuesType {
  [x: string]: any;
  data: typeof InitialValuesForm.addSystemRoleForm;
  id: string;
  searchQuery: string;
  allPermission: Record<string, boolean>;
  isConfirm: boolean;
  isAssigned: boolean;
  loading: boolean;
}

export interface rolePermissionType {
  groupKey: string;
  key: string;
  text: string;
  checked?: boolean;
  isDisabled?: boolean;
}

export const rolesInitialValues: rolesInitialValuesType = {
  data: InitialValuesForm.addSystemRoleForm,
  id: '',
  searchQuery: '',
  allPermission: {},
  isConfirm: false,
  isAssigned: false,
  loading: false,
};

const SystemRoles = (): JSX.Element => {
  const queryClient = useQueryClient();
  const { handleRequest } = useWealthlaneAdministrationApi();
  const [modalStatus, setModalStatus] = useState<ModalStatusType>('Add');
  const [rolesInfo, setRolesInfo] = useState({ ...rolesInitialValues });
  const [tableInfo, setTableInfo] = useState<TableParamsType>(InitialValuesForm.tableParams);
  const userAuthList = useSelector(selectAuthRoutesList);

  const { value: isModalOpen, setFalse: closeModal, setTrue: openModal } = useBoolean(false);
  const {
    value: isConfirmModalOpen,
    setFalse: closeConfirmModal,
    setTrue: openConfirmModal,
  } = useBoolean(false);

  // GET ROLES
  const { data, status } = useGetRoleList(handleRequest, tableInfo);

  const {
    hasMousePosition, // did we get a mouse-position from the event-handler
    resetMousePosition, // reset the mouse-position to `null`, essentially closing the menu
    handleMouseEvent, // event-handler we will use below
    trigger, // information regarding positioning we can provide to `useLayer`
  } = useMousePositionAsTrigger();

  const actions = (): ActionType[] => [
    {
      action: 'View',
      onClick: (id: string) => {
        setModalStatus('View');
        getRolesData(id, true);
      },
    },
    {
      action: 'Edit',
      as: 'Edit',
      onClick: (id: string) => {
        setModalStatus('Edit');
        getRolesData(id, true);
      },
    },
    {
      action: <div className='delete-label'>Delete</div>,
      as: 'Delete',
      onClick: (id: string) => {
        handleDeleteRoles(id);
      },
    },
  ];

  const getRolesData = (id: string, isModalOpen?: boolean): void => {
    setRolesInfo({
      ...rolesInfo,
      loading: true,
    });
    handleRequest(VendorRoleService.getApiAdministrationApiAppVendorRoleVendorRoleById(id))
      // eslint-disable-next-line camelcase
      .then((data: Wealthlane_AdministrationService_Dtos_VendorRole_VendorRoleDto | undefined) => {
        if (data != null) {
          const filteredData = {
            ...data,
            permissions:
              data?.permissions !== undefined
                ? getNonFieldLevelPermissions(data?.permissions).reduce(
                    (a: any, v: any) => ({ ...a, [v.replaceAll('.', '-')]: true }),
                    {}
                  )
                : {},
            fieldLevelPermissions:
              data?.permissions != null
                ? getFieldLevelPermissions(data?.permissions).reduce(
                    (a: any, v: any) => ({ ...a, [v.replaceAll('.', '-')]: true }),
                    {}
                  )
                : {},
          };

          const filterData = data?.permissions?.reduce(
            (a: any, v: any) => ({ ...a, [v]: true }),
            {}
          );

          // setRolesInitialData({ id, data: filteredData });
          // setAllPermission(filterData);
          setRolesInfo({
            ...rolesInfo,
            id,
            data: filteredData as any,
            allPermission: filterData,
            loading: false,
          });
          Boolean(isModalOpen) && openModal();
        }
      })
      .catch((error: any) => {
        console.log('error', error);
        setRolesInfo({
          ...rolesInfo,
          loading: false,
        });
      });
  };

  const handleDeleteRoles = (id: string): void => {
    setRolesInfo({
      ...rolesInfo,
      loading: true,
    });
    handleRequest(
      VendorRoleService.deleteApiAdministrationApiAppVendorRoleVendorRole(id, rolesInfo.isConfirm)
    )
      .then(async (data: any) => {
        if (data !== undefined) {
          if (rolesInfo.isConfirm) {
            setRolesInfo({
              ...rolesInfo,
              loading: false,
              isConfirm: false,
            });
            closeConfirmModal();
            toast.success(<ToastComponent label='Success' message='Role Deleted.' />);
            await queryClient.invalidateQueries('getAdminRoles');
          } else {
            setRolesInfo({
              ...rolesInfo,
              id,
              loading: false,
              isConfirm: true,
              isAssigned: data.isAssigned,
            });
            openConfirmModal();
          }
        }
      })
      .catch((error: any) => {
        console.log('error', error);
      });
  };

  const handleShow = (id: string, e: any): void => {
    getRolesData(id);
    handleMouseEvent(e);
  };

  return (
    <>
      <PageHeader>
        <Heading variant='h3' title='Role Management' />
        <Heading
          variant='body-s'
          title={`Current list of Wealthlane system access roles for ${
            userAuthList?.currentTenant?.name ?? ''
          }.`}
        />
      </PageHeader>
      <div className='grid sm:grid-cols-12 justify-between gap-4 sm:gap-0'>
        <div className='lg:col-span-3 col-span-6'>
          <Search
            placeholder='Search'
            searchValue={rolesInfo.searchQuery}
            onSearch={(value: string) => {
              setRolesInfo({ ...rolesInfo, searchQuery: value });
              setTableInfo({
                ...tableInfo,
                skipCount: 0,
                filter: value,
              });
            }}
          />
        </div>
        <SettingRoleButton className='lg:col-start-10 lg:col-span-3 col-span-6'>
          <Button
            as='Create'
            variant={'primary'}
            title={'Add Role'}
            icon={'add_circle_outline'}
            onClick={() => {
              setRolesInfo({
                ...rolesInfo,
                data: InitialValuesForm.addSystemRoleForm,
                id: '',
              });
              setModalStatus('Add');
              openModal();
            }}
          />
        </SettingRoleButton>
        <AnimationWrapper>
          {isModalOpen && (
            <RoleModal
              isModalOpen={isModalOpen}
              closeModal={closeModal}
              modalStatus={modalStatus}
              rolesInitialData={rolesInfo}
              setModalStatus={setModalStatus}
              loading={rolesInfo.loading}
            />
          )}

          {isConfirmModalOpen && (
            <RolesConfirmModal
              isShow={isConfirmModalOpen}
              handleClose={() => {
                closeConfirmModal();
                setRolesInfo({
                  ...rolesInfo,
                  isConfirm: false,
                });
              }}
              modalStatus={rolesInfo.isAssigned}
              handleSubmit={() => {
                handleDeleteRoles(rolesInfo.id);
              }}
              loading={rolesInfo.loading}
            />
          )}
        </AnimationWrapper>
      </div>

      <PopoverModalComponent
        children={
          <RolesPopoverContent
            permissionData={rolesInfo.allPermission}
            loading={rolesInfo.loading}
          />
        }
        hasMousePosition={hasMousePosition}
        trigger={trigger}
        popoverClassName='roles-popovers'
        resetMousePosition={resetMousePosition}
      />
      <Table
        columns={RolesHeader(actions, handleShow, hasMousePosition, resetMousePosition)}
        data={data?.items ?? []}
        loading={status}
        showPagination
        totalCounts={data?.totalCount ?? 10}
        tableInfo={tableInfo}
        setTableInfo={setTableInfo}
      />
    </>
  );
};

export default SystemRoles;
