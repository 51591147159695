import { rem } from '../../utils/style';
import styled from 'styled-components';
import { COLORS } from '../../constants/style/color';

export const SearchContainer = styled.div`
  position: relative;

  input {
    border: ${rem(1)} solid ${COLORS.CulturedWhite};
    border-radius: ${rem(6)};
    padding: ${rem(11)} ${rem(40)} ${rem(11)} ${rem(13)};
    width: 100%;
    font-weight: 400;
    font-size: ${rem(15)};
    line-height: ${rem(16)};
    color: ${COLORS.Black};

    box-shadow: ${rem(0)} ${rem(2)} ${rem(4)} ${COLORS.DefaultShadow};
    transition: all 0.2s linear;
    &:hover,
    &:focus {
      border-color: ${COLORS.Purple};
      outline: none;
      filter: drop-shadow(${rem(0)} ${rem(2)} ${rem(3)} ${COLORS.DefaultShadow});
    }

    &::placeholder {
      font-weight: 400;
      font-size: 15px;
      line-height: 16px;
      color: ${COLORS.LightGray};
    }
  }

  span {
    font-weight: 400;
    font-size: ${rem(20)};
    transform: all 300ms ease-in;

    position: absolute;
    right: ${rem(14)};
    top: ${rem(10)};
    cursor: pointer;

    &.close {
      color: ${COLORS.Silver};
    }

    &.search {
      color: ${COLORS.Gray};
    }
  }
`;
