import { useState, useRef, useEffect } from 'react';
import Search from '../search/search';
import { DropdownContainer } from './customDropdown.style';
import { TableBodyCellColumn } from '../../constants/style/table.styled';
import Spinners from '../spinner';
import { type UserType } from '../../types/common';
import FlashMessage from '../flash-message';
import { RadioButtonWrapper } from '../radio-button/radioButton.styled';
import Heading from '../typography';
import CheckFieldLevelPermissions from '../../utils/Permissions/checkFieldLevelPermission';
import { isEmpty } from 'lodash';

type CustomDropdownType = {
  errorMessage?: string;
  title: string;
  placeholder?: string;
  searchPlaceholder?: string;
  data?: UserType[];
  checkedList?: string[];
  loading?: boolean;
  handleUser: (userInfo: UserType) => void;
  handleSubmit?: (userInfo: string) => void;
  cssStyles?: Record<string, string | number | boolean>;
  toggleItems?: Record<string, string | number | boolean>;
  // close?: boolean;
  // showCheckbox?: boolean;
  // toggleSelected?: boolean;
  disabled?: boolean;
  touchedValue?: boolean;
  fieldLabelName?: string;
  searchInput?: (value: string) => void;
  isEdit?: boolean;
};

const CustomDropdown = ({
  errorMessage,
  title,
  placeholder,
  searchPlaceholder,
  data,
  loading,
  checkedList = [],
  handleUser,
  handleSubmit,
  cssStyles,
  toggleItems,
  touchedValue,
  searchInput,

  disabled,
  fieldLabelName,
  isEdit,
}: CustomDropdownType): JSX.Element => {
  const [search, setSearch] = useState<string>('');
  const [approvalUserList, setApprovalUserList] = useState<UserType[] | undefined>([]);
  const [show, setShow] = useState(false);
  const [toggleData, setToggleData] = useState<Record<string, string | number | boolean>>({
    showSearch: true,
    showTotal: true,
    showCheckbox: true,
    showErrorMessage: true,
    close: false,
    searchable: false,
  });
  const [styles, setStyles] = useState<Record<string, string | number | boolean>>({
    column: 2,
    verticalGap: 4,
  });

  useEffect(() => {
    if (data) {
      setApprovalUserList(data);
    }
  }, [data]);

  useEffect(() => {
    setToggleData({ ...toggleData, ...toggleItems });
    setStyles({ ...styles, ...cssStyles });
  }, [toggleItems, cssStyles]);

  const handleShow = () => {
    setShow(!show);
  };

  const handleSearch = (value: string) => {
    const filteredData = data?.filter((item) =>
      item.label.toLowerCase().includes(value.toLowerCase())
    );
    toggleData?.searchable === false && setApprovalUserList(filteredData);
  };

  const wrapperRef = useRef<any>(null);
  useEffect(() => {
    function handleClickOutside(event: Event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target) && show) {
        const targetElement: any = event.target;
        const labelElement = targetElement.querySelector('label');
        const planApprovalText = labelElement.textContent;
        handleSubmit?.(planApprovalText);
        setShow(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef, show]);

  return (
    <div className='formField'>
      <DropdownContainer
        className={`${show ? 'active' : ''}`}
        padding={styles?.padding}
        outerPadding={styles?.outerPadding}
        height={styles?.height}
        width={styles?.width}
        contentHeight={styles?.contentHeight}
      >
        <div
          className={`dropdownContainer ${disabled && styles.disabledContainer ? 'disabled' : ''} ${
            show ? 'active' : ''
          }`}
        >
          <label htmlFor='dropdownId'>{title}</label>
          <button
            type='button'
            className={`dropdownButton ${
              fieldLabelName && CheckFieldLevelPermissions(fieldLabelName, isEdit, disabled)
                ? 'disabled-button'
                : disabled && styles.disabledContainer
                ? 'disabled-button'
                : ''
            }  ${errorMessage && touchedValue ? 'is-invalid' : ''}`}
            onClick={() => handleShow()}
            id='dropdownId'
          >
            <span
              className={`${
                toggleData?.displaySelected && (checkedList?.length > 0 || !isEmpty(placeholder))
                  ? 'placeholderTitle'
                  : 'dropdownTitle'
              } text-truncate `}
            >
              {fieldLabelName && CheckFieldLevelPermissions(fieldLabelName, isEdit, disabled)
                ? 'xxxxx'
                : toggleData?.displaySelected
                ? checkedList?.length > 0
                  ? `${checkedList?.length} selected`
                  : placeholder || 'Select'
                : ''}
            </span>
            <span className='dropdown-arrow'>
              <span className='material-symbols-rounded'>arrow_drop_down</span>
            </span>
          </button>
          {show && (
            <div className='dropdownListContainer' ref={wrapperRef}>
              <div className='dropdownListWrapper'>
                {toggleData?.showSearch != null && toggleData?.showSearch !== false && (
                  <Search
                    searchValue={search}
                    placeholder={searchPlaceholder ?? 'Search'}
                    onSearch={(value: string) => {
                      handleSearch(value);
                      setSearch(value);
                      toggleData?.searchable === true && searchInput && searchInput(value);
                    }}
                  />
                )}
                {toggleData?.showTotal && data && (
                  <Heading
                    variant='body-xs'
                    title={
                      checkedList?.length > 0
                        ? `${checkedList?.length} participant${
                            checkedList.length > 1 ? 's' : ''
                          } selected out of ${data?.length}`
                        : `Total ${data?.length} participants`
                    }
                    className='selectedDropdownListInfo'
                  />
                )}
                <div
                  className={`dropdownSearchContainer ${
                    toggleData?.showTotal && checkedList.length === 0 ? 'containerSpace' : ''
                  }`}
                >
                  <div
                    className={`grid sm:grid-cols-${styles?.column} md:grid-cols-${styles?.column} gap-y-${styles?.verticalGap} gap-x-5`}
                  >
                    {!loading &&
                      approvalUserList?.map((item) => (
                        <div className='dropdownSearchItem'>
                          {toggleData?.showCheckbox && (
                            <RadioButtonWrapper
                              label={item.label}
                              className='checkboxWrapper'
                              id={item.value}
                              onClick={() => !disabled && handleUser(item)}
                            >
                              <input
                                type='checkbox'
                                id={item.value}
                                disabled={disabled}
                                checked={checkedList.includes(item.value)}
                              />
                            </RadioButtonWrapper>
                          )}
                          <label
                            htmlFor={item.value}
                            className={`dropdownSearchItem-label ${
                              !(item.department && item.title) && 'mb-0'
                            }`}
                            onClick={() => {
                              toggleData?.close && setShow(false);
                              !disabled && handleUser(item);
                            }}
                          >
                            <TableBodyCellColumn wrap={true}>
                              <div className={`cellTitle`}>{item.label}</div>
                              {item.department && item.title && (
                                <>
                                  <div className='cellSubtitle'>
                                    {item.department ? `${item.department} | ${item.title}` : ''}
                                  </div>
                                  {/* <div className='cellSubtitle'>
                                    {item.employeeId ? `#${item.employeeId}` : ''}
                                  </div> */}
                                </>
                              )}
                            </TableBodyCellColumn>
                          </label>
                        </div>
                      ))}
                  </div>
                  {!loading && approvalUserList && approvalUserList.length === 0 && (
                    <FlashMessage size='md' message='No Data Found' type='info' />
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
        {toggleData?.showErrorMessage && errorMessage && touchedValue && (
          <div className='error-message'>{errorMessage}</div>
        )}
      </DropdownContainer>
    </div>
  );
};

export default CustomDropdown;
