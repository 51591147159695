import { ErrorMessage } from 'formik';
import React, { useEffect, useState } from 'react';
import { FormWrapper, Label } from '../input-text/input.styled';
import { DateWrapper, DatePickerInput } from './datePicker.styled';
import { DATE_FORMAT } from '../../constants/common';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { isEmpty } from 'lodash';

type TouchedType<Values> = {
  [field in keyof Values]?: boolean;
};

type ErrorValueType<Values> = {
  [field in keyof Values]?: any;
};

type TouchedValueType<Values> = {
  [field in keyof Values]?: any;
};

interface DatePickerTypeProps {
  label?: string;
  name: string;
  placeholder?: string;
  touched?: Record<string, any>;
  labelClassName?: string;
  onChange?: (date: Date) => void | Promise<void> | any;
  errors?: Record<any, any>;
  errorValue?: ErrorValueType<Record<string, any>> | any;
  touchedValue?: TouchedValueType<Record<string, any>> | any;
  required?: boolean;
  disabled?: boolean;
  setFieldValue?: (value: string, nextValue: string) => void;
  values?: Record<any, any>;
  arrValue?: string;
  as?: string;
  min?: string | Date;
  onKeyUp?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  defaultValue?: string | Date | number;
  max?: string | Date;
  inputType?: 'View' | string;
  width?: string;
  setFieldTouched?: (field: string, touched?: boolean, shouldValidate?: boolean) => void;
  fieldLabelName?: string;
  isEdit?: boolean;
  isFormik?: boolean;
  dateFormat?: string;
  fromFilter?: boolean;
}

const CustomDatePicker = React.memo((props: DatePickerTypeProps) => {
  const [title, setTitle] = useState<string>('');
  const [datePickerActive, setDatePickerActive] = useState<boolean>(false);
  const {
    label,
    name,
    onChange,
    touched,
    placeholder,
    labelClassName,
    errors,
    errorValue,
    touchedValue,
    disabled,
    required,
    onKeyUp,
    values,
    arrValue,
    min = null,
    defaultValue,
    setFieldValue,
    inputType,
    max = null,
    width,
    setFieldTouched,
    isFormik = false,
    dateFormat,
    fromFilter = false,
  } = props;

  useEffect(() => {
    if (
      (Boolean(arrValue) && arrValue !== undefined) ||
      (Boolean(values) && Boolean(values?.[name]) && values?.[name] !== undefined)
    ) {
      if (setFieldTouched != null) {
        setFieldTouched(name, true);
      }
    }
  }, [arrValue]);

  return (
    <FormWrapper>
      <div className='flex flex-col'>
        {label != null && (
          <Label
            htmlFor={name}
            className={`${labelClassName ?? ''} ${inputType === 'View' ? 'label-view' : ''}`}
          >
            {label} {!(required ?? false) && <span>(Optional)</span>}
          </Label>
        )}
        <DateWrapper
          className={`${name === title ? 'date-active' : ''}`}
          onClick={() => {
            setDatePickerActive(true);
          }}
        >
          <DatePickerInput
            className={`${inputType === 'View' ? 'input-view' : ''} customDatepicker ${
              datePickerActive ? 'customDatepicker-active' : ''
            }`}
            onClick={() => {
              setTitle(name);
            }}
          >
            <DatePicker
              name={name}
              onClickOutside={() => {
                setDatePickerActive(false);
              }}
              label={label}
              autoComplete='off'
              className={
                (errors != null &&
                  Boolean(errors[name]) &&
                  Boolean(touched) &&
                  Boolean(touched?.[name])) ??
                (Boolean(errorValue) && touchedValue)
                  ? 'is-invalid'
                  : ''
              }
              format={DATE_FORMAT}
              dateFormat={dateFormat ?? 'MM/dd/yyyy'}
              showMonthYearPicker={!(dateFormat == null)}
              // onBlur={onBlur}
              minDate={min != null ? moment(min).toDate() : moment().subtract(100, 'year').toDate()}
              maxDate={moment(max).toDate() ?? null}
              onKeyUp={onKeyUp}
              // showMonthDropdown
              placeholderText={placeholder ?? `${DATE_FORMAT}`}
              onChange={
                fromFilter
                  ? async (date: Date) => {
                      setFieldValue?.(name, moment(date).format('YYYY-MM-DD'));
                      void (Boolean(onChange) && onChange?.(date));
                    }
                  : (date: Date) => {
                      setFieldValue?.(name, moment(date).format('YYYY-MM-DD'));
                    }
              }
              defaultValue={defaultValue}
              selected={
                !isEmpty(arrValue) && arrValue != null && arrValue !== 'Invalid date'
                  ? moment(arrValue).toDate()
                  : !isEmpty(values)
                  ? Boolean(values[name]) && values[name] !== 'Invalid date'
                    ? moment(values[name]).toDate()
                    : null
                  : null
              }
              disabled={disabled}
              style={{ width: width ?? '100%' }}
            />
            {inputType !== 'View' && (
              <span className='material-symbols-outlined'>calendar_month</span>
            )}
          </DatePickerInput>
        </DateWrapper>

        {isFormik && <ErrorMessage name={name} component='div' className='error-message' />}
      </div>
    </FormWrapper>
  );
});

export default CustomDatePicker;
