/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_AdministrationService_Dtos_VendorUser_VendorUserViewDto_Wealthlane_AdministrationService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null } from '../models/Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_AdministrationService_Dtos_VendorUser_VendorUserViewDto_Wealthlane_AdministrationService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null';
import type { Wealthlane_AdministrationService_Dtos_VendorUser_GetStatusDto } from '../models/Wealthlane_AdministrationService_Dtos_VendorUser_GetStatusDto';
import type { Wealthlane_AdministrationService_Dtos_VendorUser_ResponseDto } from '../models/Wealthlane_AdministrationService_Dtos_VendorUser_ResponseDto';
import type { Wealthlane_AdministrationService_Dtos_VendorUser_VendorUserDeactivateRequestDto } from '../models/Wealthlane_AdministrationService_Dtos_VendorUser_VendorUserDeactivateRequestDto';
import type { Wealthlane_AdministrationService_Dtos_VendorUser_VendorUserDto } from '../models/Wealthlane_AdministrationService_Dtos_VendorUser_VendorUserDto';
import type { Wealthlane_AdministrationService_Dtos_VendorUser_VendorUserRoleDto } from '../models/Wealthlane_AdministrationService_Dtos_VendorUser_VendorUserRoleDto';
import type { Wealthlane_AdministrationService_Dtos_VendorUser_VendorUserViewDto } from '../models/Wealthlane_AdministrationService_Dtos_VendorUser_VendorUserViewDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class VendorUserService {

    /**
     * @returns boolean Success
     * @throws ApiError
     */
    public static getApiAdministrationApiAppVendorUserCheckVendorSignUp(): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/administration/api/app/vendor-user/check-vendor-sign-up',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_AdministrationService_Dtos_VendorUser_VendorUserRoleDto Success
     * @throws ApiError
     */
    public static getApiAdministrationApiAppVendorUserVendorRoles(): CancelablePromise<Array<Wealthlane_AdministrationService_Dtos_VendorUser_VendorUserRoleDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/administration/api/app/vendor-user/vendor-roles',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_AdministrationService_Dtos_VendorUser_VendorUserRoleDto Success
     * @throws ApiError
     */
    public static getApiAdministrationApiAppVendorUserVendorTitles(): CancelablePromise<Array<Wealthlane_AdministrationService_Dtos_VendorUser_VendorUserRoleDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/administration/api/app/vendor-user/vendor-titles',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_AdministrationService_Dtos_VendorUser_VendorUserRoleDto Success
     * @throws ApiError
     */
    public static getApiAdministrationApiAppVendorUserVendorDepartments(): CancelablePromise<Array<Wealthlane_AdministrationService_Dtos_VendorUser_VendorUserRoleDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/administration/api/app/vendor-user/vendor-departments',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param id 
     * @returns Wealthlane_AdministrationService_Dtos_VendorUser_VendorUserViewDto Success
     * @throws ApiError
     */
    public static getApiAdministrationApiAppVendorUserVendorUserById(
id: string,
): CancelablePromise<Wealthlane_AdministrationService_Dtos_VendorUser_VendorUserViewDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/administration/api/app/vendor-user/vendor-user-by-id/{Id}',
            path: {
                'Id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param id 
     * @returns Wealthlane_AdministrationService_Dtos_VendorUser_VendorUserViewDto Success
     * @throws ApiError
     */
    public static getApiAdministrationApiAppVendorUserVendorUserForUpdateById(
id: string,
): CancelablePromise<Wealthlane_AdministrationService_Dtos_VendorUser_VendorUserViewDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/administration/api/app/vendor-user/vendor-user-for-update-by-id/{Id}',
            path: {
                'Id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_AdministrationService_Dtos_VendorUser_GetStatusDto Success
     * @throws ApiError
     */
    public static getApiAdministrationApiAppVendorUserVendorUserStatuses(): CancelablePromise<Array<Wealthlane_AdministrationService_Dtos_VendorUser_GetStatusDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/administration/api/app/vendor-user/vendor-user-statuses',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param vendorUserId 
     * @returns Wealthlane_AdministrationService_Dtos_VendorUser_ResponseDto Success
     * @throws ApiError
     */
    public static postApiAdministrationApiAppVendorUserResendEmailToVendorUser(
vendorUserId: string,
): CancelablePromise<Wealthlane_AdministrationService_Dtos_VendorUser_ResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/administration/api/app/vendor-user/resend-email-to-vendor-user/{vendorUserId}',
            path: {
                'vendorUserId': vendorUserId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param statusId 
     * @param isEnabled 
     * @param filter 
     * @param sorting 
     * @param skipCount 
     * @param maxResultCount 
     * @returns Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_AdministrationService_Dtos_VendorUser_VendorUserViewDto_Wealthlane_AdministrationService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null Success
     * @throws ApiError
     */
    public static getApiAdministrationApiAppVendorUserVendorUserList(
statusId?: Array<number>,
isEnabled?: boolean,
filter?: string,
sorting?: string,
skipCount?: number,
maxResultCount?: number,
): CancelablePromise<Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_AdministrationService_Dtos_VendorUser_VendorUserViewDto_Wealthlane_AdministrationService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/administration/api/app/vendor-user/vendor-user-list',
            query: {
                'StatusId': statusId,
                'IsEnabled': isEnabled,
                'Filter': filter,
                'Sorting': sorting,
                'SkipCount': skipCount,
                'MaxResultCount': maxResultCount,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_AdministrationService_Dtos_VendorUser_VendorUserViewDto Success
     * @throws ApiError
     */
    public static getApiAdministrationApiAppVendorUserVendorUsers(): CancelablePromise<Array<Wealthlane_AdministrationService_Dtos_VendorUser_VendorUserViewDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/administration/api/app/vendor-user/vendor-users',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Wealthlane_AdministrationService_Dtos_VendorUser_VendorUserDto Success
     * @throws ApiError
     */
    public static postApiAdministrationApiAppVendorUserVendorUser(
requestBody?: Wealthlane_AdministrationService_Dtos_VendorUser_VendorUserDto,
): CancelablePromise<Wealthlane_AdministrationService_Dtos_VendorUser_VendorUserDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/administration/api/app/vendor-user/vendor-user',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Wealthlane_AdministrationService_Dtos_VendorUser_VendorUserDto Success
     * @throws ApiError
     */
    public static putApiAdministrationApiAppVendorUserVendorUser(
requestBody?: Wealthlane_AdministrationService_Dtos_VendorUser_VendorUserDto,
): CancelablePromise<Wealthlane_AdministrationService_Dtos_VendorUser_VendorUserDto> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/administration/api/app/vendor-user/vendor-user',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Wealthlane_AdministrationService_Dtos_VendorUser_ResponseDto Success
     * @throws ApiError
     */
    public static postApiAdministrationApiAppVendorUserDeactivateVendorUser(
requestBody?: Wealthlane_AdministrationService_Dtos_VendorUser_VendorUserDeactivateRequestDto,
): CancelablePromise<Wealthlane_AdministrationService_Dtos_VendorUser_ResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/administration/api/app/vendor-user/deactivate-vendor-user',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

}
