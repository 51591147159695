import { isEmpty } from 'lodash';
import { useState } from 'react';
import { useMousePositionAsTrigger } from 'react-laag';
import Button from '../../../components/button';
import AnimationWrapper from '../../../components/animation/animationWrapper';
import Search from '../../../components/search/search';
import Table from '../../../components/table';
import Heading from '../../../components/typography/index';
import InitialValuesForm from '../../../constants/initialValues';
import { COLORS } from '../../../constants/style/color';
import { SystemUserHeader } from '../../../constants/table-headers/systemUserHeader';
import { type ModalStatusType, type TableParamsType } from '../../../types/common';
import RolesPopoverContent from '../system-roles/rolesPopoverContent';
import {
  SettingSystemUserButton,
  SettingSystemUserHeader,
  SystemUserContainer,
} from './systemUser.styled';
import UserModel from './userModel';
import { useSelector } from 'react-redux';
import { selectAuthRoutesList } from '../../configuration/routeSlice';
import { useBoolean, useGetAllAdminUserList, useGetUserStatusList } from '../../../hooks';
import { UserStatusOptions } from '../../../constants/common';
import {
  VendorRoleService,
  VendorUserApprovalService,
  VendorUserService,
  // eslint-disable-next-line camelcase
  type Wealthlane_AdministrationService_Dtos_VendorUser_VendorUserViewDto,
} from '../../../services/wealthlane-administration-services';
import useWealthlaneAdministrationApi from '../../../hooks/useWealthlaneAdministrationApi';
import ToastComponent from '../../../components/toast';
import { toast } from 'react-toastify';
import { useQueryClient } from 'react-query';
import DeactivatePopup from './deactivateModal';
import { AlertPopup, FormSelect } from '../../../components';
import PopoverModalComponent from '../../../components/modal/popoverModal';
import { ResetFilterWrapper } from '../../../constants/style/common.styled';
import Filter from '../../../components/filter';

const usersInitialValues = {
  data: InitialValuesForm.addSystemUserForm,
  id: '',
  remarks: '',
  loading: false,
  searchQuery: '',
  status: [] as number[],
  userStatus: undefined,
  deactivatedByUserFullName: '',
  deactivatedDate: '',
  dataRow: '',
};

const SystemUsers = (): JSX.Element => {
  const queryClient = useQueryClient();
  const userAuthList = useSelector(selectAuthRoutesList);

  const [showModal, setShowModal] = useState('');
  const [open, setOpen] = useState(false);
  const [allPermission, setAllPermission] = useState({});
  const [usersInitialData, setUsersInitialData] = useState(usersInitialValues);
  const [tableInfo, setTableInfo] = useState<TableParamsType>(InitialValuesForm.tableParams);
  const [modalStatus, setModalStatus] = useState<ModalStatusType>('Add');

  const { value: isModalOpen, setFalse: closeModal, setTrue: openModal } = useBoolean(false);
  const {
    value: isConfirmOpen,
    setFalse: closeConfirmModal,
    setTrue: openConfirmModal,
  } = useBoolean(false);

  const { isLoading, handleRequest } = useWealthlaneAdministrationApi();
  const { data: statusList } = useGetUserStatusList(handleRequest);
  const { data: getCustomerUserCustomerUserList, status } = useGetAllAdminUserList(
    handleRequest,
    tableInfo,
    usersInitialData.status,
    usersInitialData.userStatus
  );

  const [isEditForm, setIsEditForm] = useState<boolean>(false);

  // To get System Roles Data
  const { hasMousePosition, resetMousePosition, handleMouseEvent, trigger } =
    useMousePositionAsTrigger();

  const actions = [
    {
      action: 'View',
      as: 'View',
      onClick: (id: string, rowData: any) => {
        setUsersInitialData({
          ...usersInitialData,
          dataRow: rowData.status,
        });
        setModalStatus('View');
        setIsEditForm(true);
        getUserData(id);
        openModal();
      },
    },
    {
      action: 'Edit',
      as: 'Edit',
      onClick: async (id: string, rowData: any) => {
        setUsersInitialData({
          ...usersInitialData,
          dataRow: rowData.status,
        });
        setIsEditForm(true);
        setModalStatus('Edit');
        void getUserDataForUpdate(id);
        openModal();
      },
    },
    {
      action: 'Deactivate',
      as: 'Delete',
      onClick: (id: string) => {
        setModalStatus('Deactivate');
        setUsersInitialData({
          ...usersInitialData,
          id,
          remarks: '',
          deactivatedByUserFullName: '',
          deactivatedDate: '',
        });
        openConfirmModal();
      },
    },
    {
      action: 'View Comments',
      onClick: (id: string) => {
        const filterViewComments = getCustomerUserCustomerUserList?.items?.find(
          (item) => item.id === id
        );
        setModalStatus('View Remarks');

        if (filterViewComments?.remarks) {
          setShowModal('Reject');
          setUsersInitialData({
            ...usersInitialData,
            id,
            remarks: filterViewComments?.remarks ?? '',
          });
        } else {
          setShowModal('Deactivate');
          setUsersInitialData({
            ...usersInitialData,
            id,
            remarks: filterViewComments?.reasonForDeactivatation ?? '',
            deactivatedByUserFullName: filterViewComments?.deactivatedByUserFullName ?? '',
            deactivatedDate: filterViewComments?.deactivatedDate ?? '',
          });
        }
        openConfirmModal();
      },
    },
    {
      action: 'Resend Mail',
      onClick: (id: string) => {
        handleResendMail(id);
      },
    },
  ];

  // View and Edit Get User Data
  const getUserData = async (id: string): Promise<void> => {
    await handleRequest(
      VendorUserService.getApiAdministrationApiAppVendorUserVendorUserById(id)
    ).then((data: any) => {
      if (data !== undefined) {
        setUsersInitialData({
          ...usersInitialData,
          id,
          data: {
            ...data,
          },
        });
      }
    });
  };

  const handleResendMail = (id: string): void => {
    handleRequest(
      VendorUserService.postApiAdministrationApiAppVendorUserResendEmailToVendorUser(id)
    ).then((data: any) => {
      if (data) {
        toast.success(<ToastComponent label={'Success'} message={'Email resend'} />);
      }
    });
  };

  const getUserDataForUpdate = async (id: string): Promise<void> => {
    await handleRequest(
      VendorUserService.getApiAdministrationApiAppVendorUserVendorUserForUpdateById(id)
      // eslint-disable-next-line camelcase
    ).then((data?: Wealthlane_AdministrationService_Dtos_VendorUser_VendorUserViewDto) => {
      if (data !== undefined) {
        setUsersInitialData({
          ...usersInitialData,
          id,
          data: data as any,
        });
      }
    });
  };

  // Approve, Reject and Deactivate User
  const postUserData = (): void => {
    setUsersInitialData({
      ...usersInitialData,
      loading: true,
    });
    const payload = {
      vendorUserId: usersInitialData.id,
      remarks: usersInitialData.remarks,
    };

    const vendorUserAPI =
      modalStatus === 'Deactivate'
        ? VendorUserService.postApiAdministrationApiAppVendorUserDeactivateVendorUser({
            vendorUserId: usersInitialData.id,
            reasonForDeactivation: usersInitialData.remarks,
          })
        : modalStatus === 'Approval'
        ? VendorUserApprovalService.postApiAdministrationApiAppVendorUserApprovalApproveVendorUser(
            payload
          )
        : VendorUserApprovalService.postApiAdministrationApiAppVendorUserApprovalRejectVendorUser(
            payload
          );

    const vendorUserMessage =
      modalStatus === 'Deactivate'
        ? 'Deactivated'
        : modalStatus === 'Approval'
        ? 'Approved'
        : modalStatus === 'Reject'
        ? 'Rejected'
        : '';

    if (vendorUserMessage !== '') {
      handleRequest(vendorUserAPI)
        .then(async (response: any) => {
          if (response !== undefined) {
            toast.success(
              <ToastComponent label={'Success'} message={`User ${vendorUserMessage}`} />
            );
            await queryClient.invalidateQueries('getAllAdminUserList');
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setUsersInitialData({
            ...usersInitialData,
            loading: false,
          });
          closeConfirmModal();
        });
    }
  };

  // Get Roles Info
  const getRolesData = (id: string): void => {
    setUsersInitialData({
      ...usersInitialData,
      loading: true,
    });
    handleRequest(VendorRoleService.getApiAdministrationApiAppVendorRoleVendorRoleById(id))
      .then((data: any) => {
        if (data !== undefined) {
          const filterData = data.permissions.reduce((a: any, v: any) => ({ ...a, [v]: true }), {});
          setAllPermission(filterData);
        }
      })
      .catch((error: any) => {
        console.log('error', error);
      })
      .finally(() => {
        setUsersInitialData({
          ...usersInitialData,
          loading: false,
        });
      });
  };

  const handleShow = (id: string, e: any): void => {
    if (id) {
      getRolesData(id);
    } else {
      setAllPermission([]);
    }
    handleMouseEvent(e);
  };

  return (
    <SystemUserContainer>
      <SettingSystemUserHeader>
        <Heading variant='h3' title='User Management' />
        <Heading
          variant='body-s'
          title={`${
            userAuthList?.currentTenant?.name
              ? `${userAuthList?.currentTenant?.name} users`
              : 'Users'
          } with access to the Wealthlane system.`}
          color={`${COLORS.Gray}`}
        />
      </SettingSystemUserHeader>
      <div className='grid xl:grid-cols-12 items-start gap-y-4'>
        <div className='xl:col-span-10 flex lg:grid grid-cols-10 gap-2'>
          <div className='col-span-3'>
            <Search
              placeholder='Search'
              searchValue={usersInitialData.searchQuery}
              onSearch={(value: string) => {
                setUsersInitialData({
                  ...usersInitialData,
                  searchQuery: value,
                });
                setTableInfo({
                  ...tableInfo,
                  skipCount: 0,
                  filter: value,
                });
              }}
            />
          </div>
          <div className=''>
            <Filter setOpen={setOpen} open={open}>
              <div className='flex gap-x-2'>
                <FormSelect
                  type='text'
                  name=''
                  placeholder='Approval Status'
                  onChange={(value: number[]) => {
                    if (value[0] === 0) value.shift();
                    if (value.includes(0)) value = [0];
                    if (isEmpty(value)) value = [];
                    setUsersInitialData({
                      ...usersInitialData,
                      status: value,
                    });
                  }}
                  multiple
                  options={statusList}
                  values={usersInitialData.status}
                  containerClassName='mb-0 w-full'
                  isFormik={false}
                />
                <FormSelect
                  type='text'
                  name=''
                  placeholder='User Status'
                  onChange={(value: any) => {
                    setUsersInitialData({
                      ...usersInitialData,
                      userStatus: value,
                    });
                  }}
                  options={UserStatusOptions}
                  arrValue={usersInitialData.userStatus}
                  containerClassName='mb-0 w-full'
                  isFormik={false}
                />
              </div>
            </Filter>
          </div>
          <ResetFilterWrapper className='col-span-2'>
            <span className='material-symbols-outlined'>settings_backup_restore</span>
            <Heading
              variant='body-s'
              title='Reset Filter'
              onClick={() => {
                setUsersInitialData(usersInitialValues);
              }}
            />
          </ResetFilterWrapper>
        </div>
        <SettingSystemUserButton className='xl:col-span-2 flex justify-start lg:justify-end'>
          <Button
            as='Create'
            variant={'primary'}
            title={'Add User'}
            icon={'add_circle_outline'}
            onClick={() => {
              setUsersInitialData(usersInitialValues);
              setModalStatus('Add');
              setIsEditForm(false);
              openModal();
            }}
          />
        </SettingSystemUserButton>

        {isConfirmOpen && (
          <>
            {showModal === 'Reject' ? (
              <AlertPopup
                isShow={isConfirmOpen}
                closeModal={closeConfirmModal}
                modalStatus={modalStatus}
                title='user'
                handleSubmit={postUserData}
                setRemark={(value: string) => {
                  setUsersInitialData({ ...usersInitialData, remarks: value });
                }}
                remark={usersInitialData.remarks}
                loading={usersInitialData.loading}
              />
            ) : showModal === 'Deactivate' || modalStatus === 'Deactivate' ? (
              <DeactivatePopup
                isShow={isConfirmOpen}
                closeModal={closeConfirmModal}
                modalStatus={modalStatus}
                title='user'
                handleSubmit={postUserData}
                setRemark={(value: string) => {
                  setUsersInitialData({ ...usersInitialData, remarks: value });
                }}
                data={usersInitialData}
                loading={usersInitialData.loading}
              />
            ) : (
              ''
            )}
          </>
        )}
      </div>
      <AnimationWrapper>
        {isModalOpen && (
          <UserModel
            isModalOpen={isModalOpen}
            isEditForm={isEditForm}
            closeModal={closeModal}
            modalStatus={modalStatus}
            setModalStatus={(value: ModalStatusType) => {
              setModalStatus(value);
            }}
            usersInitialData={usersInitialData}
            isLoading={isLoading}
            tableInfo={tableInfo}
            setTableInfo={setTableInfo}
            dataRow={usersInitialData.dataRow}
          />
        )}
      </AnimationWrapper>
      <PopoverModalComponent
        children={
          <RolesPopoverContent permissionData={allPermission} loading={usersInitialData.loading} />
        }
        hasMousePosition={hasMousePosition}
        trigger={trigger}
        resetMousePosition={resetMousePosition}
        popoverClassName='roles-popovers'
      />

      <Table
        columns={SystemUserHeader(actions, handleShow, hasMousePosition, resetMousePosition)}
        data={getCustomerUserCustomerUserList?.items ?? []}
        loading={status}
        showPagination
        totalCounts={getCustomerUserCustomerUserList?.totalCount ?? 10}
        tableInfo={tableInfo}
        setTableInfo={setTableInfo}
      />
    </SystemUserContainer>
  );
};

export default SystemUsers;
