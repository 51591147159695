import { lazy } from 'react';

export const RouteList = [
  {
    path: '/customer-list',
    component: lazy(async () => await import('../pages/customer')),
    exact: true,
  },
  {
    path: '/customer-list/create',
    component: lazy(async () => await import('../pages/customer/steps/index')),
    exact: true,
  },
  {
    path: '/customer-list/create/:id',
    component: lazy(async () => await import('../pages/customer/steps/index')),
    exact: true,
  },
  {
    path: '/transactions',
    component: lazy(async () => await import('../pages/transactions')),
    exact: true,
  },
  {
    path: '/reports',
    component: lazy(async () => await import('../pages/reports')),
    exact: true,
  },
  {
    path: '/security',
    component: lazy(async () => await import('../pages/settings/login-and-security')),
    exact: true,
  },
  {
    path: '/settings',
    component: lazy(async () => await import('../pages/settings')),
    exact: true,
  },
  {
    path: '/settings/:slug',
    component: lazy(async () => await import('../pages/settings')),
    exact: true,
  },

  {
    path: '/task/user-approval',
    component: lazy(async () => await import('../pages/settings/system-users/approval')),
    exact: true,
  },

  {
    path: '/task/tax-setup-approval',
    component: lazy(async () => await import('../pages/settings/tax/tax-setup/taxSetupApprover')),
    exact: true,
  },

  {
    path: '/task/tax-setup-approval/approver-history/:id',
    component: lazy(async () => await import('../pages/settings/tax/tax-setup/taxApproverHistory')),
    exact: true,
  },
  {
    path: '/check-user',
    component: lazy(async () => await import('../auth/checkUser')),
    exact: true,
  },

  // {
  //   path: '/task/federal-tax-approval',
  //   component: lazy(async () => await import('../pages/settings/federal/federal-approver')),
  //   exact: true,
  // },
  // {
  //   path: '/task/state-tax-approval',
  //   component: lazy(async () => await import('../pages/settings/state-tax/state-tax-approver')),
  //   exact: true,
  // },

  // Page Not Found

  {
    path: '/page-not-found',
    component: lazy(async () => await import('../pages/PageNotFound')),
    exact: true,
  },
];
