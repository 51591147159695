import styled from 'styled-components';
import { COLORS } from '../../constants/style/color';

export const DropdownContainer = styled.div`
  &.table-field {
    border: none;
  }

  .material-symbols-outlined,
  .material-symbols-rounded {
    color: ${COLORS.Gray};
  }
  @media (max-width: 512px) {
    width: 100% !important;
  }
`;
