import { type UseQueryResult, useQuery } from 'react-query';
import { RsuAwardService } from '../../services/wealthlane-award-services';
import moment from 'moment';
import { DATE_FORMAT } from '../../constants/common';

const useGetTaxAllTransaction = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  grantId?: string,
  applicableData?: string | null
): UseQueryResult<any> => {
  const taxAllTransactionOptions = useQuery(
    ['useGetTaxAllTransactionOptions', grantId, applicableData],
    async () => {
      if (applicableData?.includes('Transaction') === true) {
        if (grantId != null && grantId !== '') {
          return await handleRequest(
            RsuAwardService.getApiAwardApiAppRSUAwardTransactionForTax(grantId)
          );
        }
      }
    },
    {
      select: (data) => {
        return data?.map((item) => {
          return {
            label: item.awardNumber,
            value: item.id ?? '',
            vestingDate: moment(item?.vestingDate).format(DATE_FORMAT) ?? '',
            awardId: item?.awardId ?? '',
          };
        });
      },
    }
  );

  return taxAllTransactionOptions;
};

export default useGetTaxAllTransaction;
