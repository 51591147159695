import ApprovalConfig from '../pages/settings/approval-config';
import SystemRoles from '../pages/settings/system-roles';
import SystemUsers from '../pages/settings/system-users';
import CompanyTaxSetupList from '../pages/settings/tax/company-tax-setup-list';
import TaxYear from '../pages/settings/tax/tax-period';
import TaxSetup from '../pages/settings/tax/tax-setup';

export const OrganizationSettingsList = [
  {
    showOnList: true,
    url: 'users',
    slug: 'users',
    title: 'User Management',
    name: 'VendorUsers',
    category: 'Vendor Users & Roles',
    component: <SystemUsers />,
  },
  {
    showOnList: true,
    url: 'roles',
    slug: 'roles',
    title: 'Role Management',
    name: 'VendorRoles',
    category: 'Vendor Users & Roles',
    component: <SystemRoles />,
  },
  {
    showOnList: true,
    url: 'system-approvers',
    slug: 'system-approvers',
    title: 'System Approvers',
    name: 'VendorApprovers',
    category: 'Vendor Users & Roles',
    component: <ApprovalConfig />,
  },
  {
    showOnList: true,
    url: 'tax-period',
    slug: 'tax-period',
    title: 'Tax Period',
    name: 'VendorTax',
    category: 'Configuration',
    component: <TaxYear />,
  },
  {
    showOnList: true,
    url: 'tax-setup',
    slug: 'tax-setup',
    title: 'Tax Setup',
    name: 'VendorTax',
    category: 'Configuration',
    component: <TaxSetup />,
  },
  {
    showOnList: false,
    url: 'company-tax-setup-list',
    slug: 'company-tax-setup-list',
    title: 'Company Tax Setup List',
    name: 'VendorTax',
    category: 'Configuration',
    component: <CompanyTaxSetupList />,
  },
  // {
  //   showOnList: false,
  //   url: 'state-tax',
  //   slug: 'state-tax',
  //   title: 'State Tax',
  //   name: 'VendorRoles',
  //   category: 'Income Tax Configuration',
  //   component: <StateTaxComponent />,
  // },
];

export const ComponentsNames = [
  {
    url: 'login-security',
    name: 'Companies',
  },
  {
    url: 'users',
    name: 'VendorUsers',
  },
  {
    url: 'roles',
    name: 'VendorRoles',
  },
  {
    url: 'tax-setup',
    name: 'VendorTax',
  },
  {
    url: 'customer-list',
    name: 'Companies',
  },
];

export const MyTaskRouteList = [
  {
    url: '/task/user-approval',
    permissionName: 'VendorUserApproval',
    name: 'System User Approval',
  },
  {
    url: '/task/participants-approval',
    permissionName: 'ParticipantApproval',
    name: 'Participant Approval',
  },
  {
    url: '/task/rsu-awards-approval',
    permissionName: 'RSUAwardApproval',
    name: 'RSU Awards Approval',
  },
  {
    url: '/task/plan-approval',
    permissionName: 'PlanApproval',
    name: 'Plan Approval',
  },
  {
    url: '/task/settlement-config-approval',
    permissionName: 'SettlementConfigs',
    name: 'Settlement Config Approval',
  },
  {
    url: '/task/tax-setup-approval',
    permissionName: 'VendorTaxApproval',
    name: 'Tax Approval',
  },
  // {
  //   url: '/task/federal-tax-approval',
  //   permissionName: 'VendorTax',
  //   name: 'Federal Tax',
  // },
  // {
  //   url: '/task/state-tax-approval',
  //   permissionName: 'VendorTax',
  //   name: 'State Tax',
  // },
];

export const EquityAwardsList = [
  {
    url: '/awards/rsu-awards',
    permissionName: 'RSUAwards',
    name: 'RSU Awards',
  },
];
