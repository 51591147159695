import { type UseQueryResult, useQuery } from 'react-query';
import {
  ParticipantService,
  // eslint-disable-next-line camelcase
  type Wealthlane_CustomerService_Dtos_Participant_ViewParticipantDto,
} from '../../services/wealthlane-customer-services';

const useGetParticipantViewDetails = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  id?: string
): UseQueryResult<
  // eslint-disable-next-line camelcase
  Wealthlane_CustomerService_Dtos_Participant_ViewParticipantDto | undefined,
  unknown
> => {
  const ParticipantViewDetails = useQuery(['participantDetails', id], async () => {
    if (id) {
      return await handleRequest(
        ParticipantService.getApiCustomerApiAppParticipantParticipantById(id)
      );
    }
  });

  return ParticipantViewDetails;
};

export default useGetParticipantViewDetails;
