/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_CustomerService_Dtos_VendorCompany_CompanyListDto_Wealthlane_CustomerService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null } from '../models/Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_CustomerService_Dtos_VendorCompany_CompanyListDto_Wealthlane_CustomerService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null';
import type { Wealthlane_CustomerService_Dtos_Response_ResponseDto } from '../models/Wealthlane_CustomerService_Dtos_Response_ResponseDto';
import type { Wealthlane_CustomerService_Dtos_VendorCompany_AdminCompanyContactDto } from '../models/Wealthlane_CustomerService_Dtos_VendorCompany_AdminCompanyContactDto';
import type { Wealthlane_CustomerService_Dtos_VendorCompany_ApproverCompanyContactDto } from '../models/Wealthlane_CustomerService_Dtos_VendorCompany_ApproverCompanyContactDto';
import type { Wealthlane_CustomerService_Dtos_VendorCompany_CompanyContactDto } from '../models/Wealthlane_CustomerService_Dtos_VendorCompany_CompanyContactDto';
import type { Wealthlane_CustomerService_Dtos_VendorCompany_CompanyDto } from '../models/Wealthlane_CustomerService_Dtos_VendorCompany_CompanyDto';
import type { Wealthlane_CustomerService_Dtos_VendorCompany_CompanyStatusDto } from '../models/Wealthlane_CustomerService_Dtos_VendorCompany_CompanyStatusDto';
import type { Wealthlane_CustomerService_Dtos_VendorCompany_CreateAdminCompanyContactDto } from '../models/Wealthlane_CustomerService_Dtos_VendorCompany_CreateAdminCompanyContactDto';
import type { Wealthlane_CustomerService_Dtos_VendorCompany_CreateApproverCompanyContactDto } from '../models/Wealthlane_CustomerService_Dtos_VendorCompany_CreateApproverCompanyContactDto';
import type { Wealthlane_CustomerService_Dtos_VendorCompany_CreateCompanyContactsDto } from '../models/Wealthlane_CustomerService_Dtos_VendorCompany_CreateCompanyContactsDto';
import type { Wealthlane_CustomerService_Dtos_VendorCompany_CreateCompanyDto } from '../models/Wealthlane_CustomerService_Dtos_VendorCompany_CreateCompanyDto';
import type { Wealthlane_CustomerService_Dtos_VendorCompany_CreateUpdateCustomerVendorUserDto } from '../models/Wealthlane_CustomerService_Dtos_VendorCompany_CreateUpdateCustomerVendorUserDto';
import type { Wealthlane_CustomerService_Dtos_VendorCompany_CustomerCompayDetailViewForVendorDto } from '../models/Wealthlane_CustomerService_Dtos_VendorCompany_CustomerCompayDetailViewForVendorDto';
import type { Wealthlane_CustomerService_Dtos_VendorCompany_CustomerVendorUserDto } from '../models/Wealthlane_CustomerService_Dtos_VendorCompany_CustomerVendorUserDto';
import type { Wealthlane_CustomerService_Dtos_VendorCompany_UpdateAdminCompanyContactDto } from '../models/Wealthlane_CustomerService_Dtos_VendorCompany_UpdateAdminCompanyContactDto';
import type { Wealthlane_CustomerService_Dtos_VendorCompany_UpdateApproverCompanyContactDto } from '../models/Wealthlane_CustomerService_Dtos_VendorCompany_UpdateApproverCompanyContactDto';
import type { Wealthlane_CustomerService_Dtos_VendorCompany_UpdateCompanyContactsDto } from '../models/Wealthlane_CustomerService_Dtos_VendorCompany_UpdateCompanyContactsDto';
import type { Wealthlane_CustomerService_Dtos_VendorCompany_UpdateCompanyDto } from '../models/Wealthlane_CustomerService_Dtos_VendorCompany_UpdateCompanyDto';
import type { Wealthlane_CustomerService_Dtos_VendorCompany_UpdateCompanyStatusDto } from '../models/Wealthlane_CustomerService_Dtos_VendorCompany_UpdateCompanyStatusDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class VendorCompanyService {

    /**
     * @param fromOnboardedDate 
     * @param toOnboardedDate 
     * @param statusIds 
     * @param filter 
     * @param sorting 
     * @param skipCount 
     * @param maxResultCount 
     * @returns Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_CustomerService_Dtos_VendorCompany_CompanyListDto_Wealthlane_CustomerService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null Success
     * @throws ApiError
     */
    public static getApiCustomerApiAppVendorCompany(
fromOnboardedDate?: string,
toOnboardedDate?: string,
statusIds?: Array<number>,
filter?: string,
sorting?: string,
skipCount?: number,
maxResultCount?: number,
): CancelablePromise<Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_CustomerService_Dtos_VendorCompany_CompanyListDto_Wealthlane_CustomerService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/customer/api/app/vendor-company',
            query: {
                'FromOnboardedDate': fromOnboardedDate,
                'ToOnboardedDate': toOnboardedDate,
                'StatusIds': statusIds,
                'Filter': filter,
                'Sorting': sorting,
                'SkipCount': skipCount,
                'MaxResultCount': maxResultCount,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Wealthlane_CustomerService_Dtos_VendorCompany_CompanyDto Success
     * @throws ApiError
     */
    public static postApiCustomerApiAppVendorCompanyCompanyProfile(
requestBody?: Wealthlane_CustomerService_Dtos_VendorCompany_CreateCompanyDto,
): CancelablePromise<Wealthlane_CustomerService_Dtos_VendorCompany_CompanyDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/customer/api/app/vendor-company/company-profile',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Wealthlane_CustomerService_Dtos_VendorCompany_CompanyDto Success
     * @throws ApiError
     */
    public static putApiCustomerApiAppVendorCompanyCompanyProfile(
requestBody?: Wealthlane_CustomerService_Dtos_VendorCompany_UpdateCompanyDto,
): CancelablePromise<Wealthlane_CustomerService_Dtos_VendorCompany_CompanyDto> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/customer/api/app/vendor-company/company-profile',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Wealthlane_CustomerService_Dtos_VendorCompany_AdminCompanyContactDto Success
     * @throws ApiError
     */
    public static postApiCustomerApiAppVendorCompanyAdminCompanyContact(
requestBody?: Wealthlane_CustomerService_Dtos_VendorCompany_CreateAdminCompanyContactDto,
): CancelablePromise<Wealthlane_CustomerService_Dtos_VendorCompany_AdminCompanyContactDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/customer/api/app/vendor-company/admin-company-contact',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Wealthlane_CustomerService_Dtos_VendorCompany_AdminCompanyContactDto Success
     * @throws ApiError
     */
    public static putApiCustomerApiAppVendorCompanyAdminCompanyContact(
requestBody?: Wealthlane_CustomerService_Dtos_VendorCompany_UpdateAdminCompanyContactDto,
): CancelablePromise<Wealthlane_CustomerService_Dtos_VendorCompany_AdminCompanyContactDto> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/customer/api/app/vendor-company/admin-company-contact',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Wealthlane_CustomerService_Dtos_VendorCompany_ApproverCompanyContactDto Success
     * @throws ApiError
     */
    public static postApiCustomerApiAppVendorCompanyApproverCompanyContact(
requestBody?: Wealthlane_CustomerService_Dtos_VendorCompany_CreateApproverCompanyContactDto,
): CancelablePromise<Wealthlane_CustomerService_Dtos_VendorCompany_ApproverCompanyContactDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/customer/api/app/vendor-company/approver-company-contact',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Wealthlane_CustomerService_Dtos_VendorCompany_ApproverCompanyContactDto Success
     * @throws ApiError
     */
    public static putApiCustomerApiAppVendorCompanyApproverCompanyContact(
requestBody?: Wealthlane_CustomerService_Dtos_VendorCompany_UpdateApproverCompanyContactDto,
): CancelablePromise<Wealthlane_CustomerService_Dtos_VendorCompany_ApproverCompanyContactDto> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/customer/api/app/vendor-company/approver-company-contact',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Wealthlane_CustomerService_Dtos_VendorCompany_CustomerVendorUserDto Success
     * @throws ApiError
     */
    public static postApiCustomerApiAppVendorCompanyCustomerVendorUser(
requestBody?: Wealthlane_CustomerService_Dtos_VendorCompany_CreateUpdateCustomerVendorUserDto,
): CancelablePromise<Wealthlane_CustomerService_Dtos_VendorCompany_CustomerVendorUserDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/customer/api/app/vendor-company/customer-vendor-user',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Wealthlane_CustomerService_Dtos_VendorCompany_CustomerVendorUserDto Success
     * @throws ApiError
     */
    public static putApiCustomerApiAppVendorCompanyCustomerVendorUser(
requestBody?: Wealthlane_CustomerService_Dtos_VendorCompany_CreateUpdateCustomerVendorUserDto,
): CancelablePromise<Wealthlane_CustomerService_Dtos_VendorCompany_CustomerVendorUserDto> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/customer/api/app/vendor-company/customer-vendor-user',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param companyId 
     * @returns Wealthlane_CustomerService_Dtos_VendorCompany_CompanyDto Success
     * @throws ApiError
     */
    public static getApiCustomerApiAppVendorCompanyCompanyProfileById(
companyId: string,
): CancelablePromise<Wealthlane_CustomerService_Dtos_VendorCompany_CompanyDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/customer/api/app/vendor-company/company-profile-by-id',
            query: {
                'CompanyId': companyId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param companyId 
     * @returns Wealthlane_CustomerService_Dtos_VendorCompany_CustomerCompayDetailViewForVendorDto Success
     * @throws ApiError
     */
    public static getApiCustomerApiAppVendorCompanyCompanyDetailForView(
companyId: string,
): CancelablePromise<Wealthlane_CustomerService_Dtos_VendorCompany_CustomerCompayDetailViewForVendorDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/customer/api/app/vendor-company/company-detail-for-view',
            query: {
                'CompanyId': companyId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Wealthlane_CustomerService_Dtos_Response_ResponseDto Success
     * @throws ApiError
     */
    public static putApiCustomerApiAppVendorCompanyCompanyStatus(
requestBody?: Wealthlane_CustomerService_Dtos_VendorCompany_UpdateCompanyStatusDto,
): CancelablePromise<Wealthlane_CustomerService_Dtos_Response_ResponseDto> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/customer/api/app/vendor-company/company-status',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_CustomerService_Dtos_VendorCompany_CompanyStatusDto Success
     * @throws ApiError
     */
    public static getApiCustomerApiAppVendorCompanyCompanyStatuses(): CancelablePromise<Array<Wealthlane_CustomerService_Dtos_VendorCompany_CompanyStatusDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/customer/api/app/vendor-company/company-statuses',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Wealthlane_CustomerService_Dtos_VendorCompany_CompanyContactDto Success
     * @throws ApiError
     */
    public static postApiCustomerApiAppVendorCompanyCompanyUserContact(
requestBody?: Wealthlane_CustomerService_Dtos_VendorCompany_CreateCompanyContactsDto,
): CancelablePromise<Wealthlane_CustomerService_Dtos_VendorCompany_CompanyContactDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/customer/api/app/vendor-company/company-user-contact',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Wealthlane_CustomerService_Dtos_VendorCompany_CompanyContactDto Success
     * @throws ApiError
     */
    public static putApiCustomerApiAppVendorCompanyCompanyUserContact(
requestBody?: Wealthlane_CustomerService_Dtos_VendorCompany_UpdateCompanyContactsDto,
): CancelablePromise<Wealthlane_CustomerService_Dtos_VendorCompany_CompanyContactDto> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/customer/api/app/vendor-company/company-user-contact',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

}
