import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Navbar from './components/navbar';
import Spinners from './components/spinner';
import { InnerContainer } from './constants/style/common.styled';
import { selectToken, setCredentials } from './auth/authSlice';
import { selectAuthRoutesList, setRouteList } from './pages/configuration/routeSlice';
import AuthService from './services/authService';
import { AbpApplicationConfigurationService } from './services/wealthlane-identity-services';
import { getFilteredPermissionData } from './utils';
import pingBg from '../src/assets/images/pink_background.png';
import RoutesContainer from './routes/RoutesContainer';
import { useBoolean, useWealthlaneIdentityApi } from './hooks';
import { useLocation, useNavigate } from 'react-router-dom';
import { AlertPopup } from './components';
import { REDIRECT_PERMISSION_MESSAGE, RELOAD_PERMISSION_MESSAGE } from './constants/common';

function App(): JSX.Element {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authService = new AuthService();
  const token = useSelector(selectToken);
  const userAuthList = useSelector(selectAuthRoutesList);
  const { isLoading, handleRequest } = useWealthlaneIdentityApi();
  const [permissionStatus, setPermissionStatus] = useState<string>('');
  const { value: isModalOpen, setFalse: closeModal, setTrue: openModal } = useBoolean(false);

  useEffect(() => {
    authService
      .getUser()
      .then((user) => {
        if (user != null && user !== undefined) {
          dispatch(
            setCredentials({
              isAuthenticated: authService.isAuthenticated(),
              token: authService.getToken(),
              user,
            })
          );
        }
      })
      .catch((err) => {
        if (!(err as boolean)) void authService.signOutRedirectCallback();
      });
  }, []);

  useEffect(() => {
    if (token?.length > 0) {
      handleRequest(
        AbpApplicationConfigurationService.getApiIdentityApiAbpApplicationConfiguration()
      )
        .then((response) => {
          const { nonFieldLevelPermissionsGroups } = getFilteredPermissionData(
            response?.auth?.grantedPolicies
          );

          const RouteList = Object.keys(nonFieldLevelPermissionsGroups).map((item) => item);
          dispatch(
            setRouteList({
              currentUserAuthList: response ?? {},
              currentUserRoutesList: RouteList ?? [],
            })
          );
        })
        .catch((err) => {
          console.log('err', err);
        });
    }
  }, [token]);

  useEffect(() => {
    if (token?.length > 0 && userAuthList.currentUser?.isAuthenticated === false) {
      void authService.logout();
    }
  }, [userAuthList]);

  useEffect(() => {
    document.getElementsByTagName('html')[0].style.overflow = 'unset';
  }, [location]);

  window.addEventListener('PERMISSION_CHANGED', (evt: any) => {
    setPermissionStatus(evt.detail);
    openModal();
  });

  const handleCloseModal = () => {
    closeModal();
    if (permissionStatus === 'SIGN_IN') {
      authService.signInSilent();
    } else if (permissionStatus === 'REFRESH') {
      navigate('/');
      navigate(0);
    } else {
      navigate(0);
    }
  };

  return (
    <div>
      {isLoading ? (
        <Spinners containerClassName='h-screen' />
      ) : (
        <>
          <Navbar isAuth={authService.isAuthenticated()} />
          <InnerContainer img={pingBg}>
            <RoutesContainer />
          </InnerContainer>
          {isModalOpen && (
            <AlertPopup
              isShow={isModalOpen}
              ConfirmTitle='Ok'
              customTitle={
                permissionStatus === 'RELOAD'
                  ? RELOAD_PERMISSION_MESSAGE
                  : REDIRECT_PERMISSION_MESSAGE
              }
              handleSubmit={() => handleCloseModal()}
            />
          )}
        </>
      )}
    </div>
  );
}

export default App;
