import { useQuery } from 'react-query';
import { ResidenceStatusService } from '../../services/wealthlane-customer-services';

const useResidenceStatusOptions = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>
) => {
  const residenceStatusOptions = useQuery(
    ['residenceStatusOptions'],
    async () => {
      return await handleRequest(
        ResidenceStatusService.getApiCustomerApiAppResidenceStatusResidenceStatusList()
      );
    },
    {
      select: (data) => {
        return data?.map((item) => {
          return {
            label: item.name || '',
            value: item.id || '',
          };
        });
      },
    }
  );

  return residenceStatusOptions;
};

export default useResidenceStatusOptions;
