import Button from '../button';
import { useRef, useEffect, useState, type ReactNode } from 'react';
import { FilterContainer, FilterDropdown } from './filter.styled';
import { usePopper } from 'react-popper';
import { Portal } from '../vertical-dot/popperContainer';

interface filterType {
  children: ReactNode;
  open: boolean;
  setOpen: (value: boolean) => void;
  className?: string
}

const Filter = ({ children, open, setOpen, className }: filterType): JSX.Element => {
  const wrapperRef = useRef<any>(null);

  return (
    <FilterContainer className={className}>
      <div ref={wrapperRef}>
        <Button
          title=''
          variant='primary-dark'
          onClick={() => {
            setOpen(!open);
          }}
          icon={'tune'}
        />
      </div>
      <PortalContainer children={children} open={open} setOpen={setOpen} wrapperRef={wrapperRef} />
    </FilterContainer>
  );
};

export default Filter;

const PortalContainer = ({ children, open, setOpen, wrapperRef }: any): JSX.Element => {
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  const [visible, setVisibility] = useState(open);

  useEffect(() => {
    setVisibility(open);
  }, [open]);

  useEffect(() => {
    function handleClickOutside(event: Event): void {
      if (popperElement != null && !popperElement.contains(event.target as Node)) {
        setVisibility(false);
        setOpen(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [popperElement]);

  const { styles, attributes } = usePopper(wrapperRef.current, popperElement, {
    placement: 'bottom-start',
    modifiers: [
      {
        name: 'offset',

        options: {
          offset: [0, 10],
        },
      },
    ],
  });
  return (
    <Portal>
      {visible === true && (
        <FilterDropdown
          size={'md'}
          ref={setPopperElement}
          style={styles.popper}
          {...attributes.popper}
        >
          {children}
        </FilterDropdown>
      )}
    </Portal>
  );
};
