import { Form, Formik } from 'formik';
import useWealthlaneCustomerApi from '../../../hooks/useWealthlaneCustomerApi';
import { getFilteredPermissionData } from '../../../utils';
import { type ModalStatusType } from '../../../types/common';
import { useSelector } from 'react-redux';
import { selectAuthRoutesList } from '../../configuration/routeSlice';
import {
  ROLES_PERMISSION_LIST,
  SORTED_FIELD_PERMISSION,
  SORTED_MODULE_PERMISSION,
} from '../../../constants/common';
import { handleFilterPermissionList, handleSortPermissionNames } from '../../../utils/global';
import { usePostSystemRoles } from '../../../hooks';
import type { rolePermissionType, rolesInitialValuesType } from '.';
import {
  CustomTextArea,
  Heading,
  InputText,
  ModalComponent,
  ModalFooterComponent,
} from '../../../components';
import ModalBody from '../../../components/modal/modalBodyComponent';
import CustomCheckBox from '../../../components/radio-button/customCheckBox';
import { ROLES_VALIDATION } from '../../../utils/validations/validations';
import * as Yup from 'yup';
import {
  CheckBoxFlex,
  FieldLevelPermissionsGroups,
  ListWrapper,
  RoleModalWrapper,
} from './systemRole.styled';
import _ from 'lodash';
import Tag from '../../../components/lozenge/tags';

interface RoleModalType {
  isModalOpen: boolean;
  closeModal: () => void;
  modalStatus: ModalStatusType;
  setModalStatus: (value: ModalStatusType) => void;
  rolesInitialData: { data: rolesInitialValuesType; id: string } | any;
  loading: boolean;
}

const RoleModal = ({
  isModalOpen,
  closeModal,
  modalStatus,
  rolesInitialData,
  setModalStatus,
  loading,
}: RoleModalType): JSX.Element => {
  const userAuthList = useSelector(selectAuthRoutesList);
  const { nonFieldLevelPermissionsGroups, fieldLevelPermissionsGroups } = getFilteredPermissionData(
    userAuthList.auth?.policies
  );
  const { handleRequest } = useWealthlaneCustomerApi();
  const allFieldValues = userAuthList.localization?.values?.Wealthlane;
  const filterNonFieldLevelPermission = handleSortPermissionNames(
    handleFilterPermissionList(nonFieldLevelPermissionsGroups),
    SORTED_MODULE_PERMISSION
  );
  const filterFieldLevelPermission = handleSortPermissionNames(
    handleFilterPermissionList(fieldLevelPermissionsGroups),
    SORTED_FIELD_PERMISSION
  );

  const { mutate, isLoading } = usePostSystemRoles(handleRequest, closeModal, rolesInitialData.id);
  const handleSubmit = (values: any): void => {
    delete values.permissions['CustomerService-Vendor'];
    delete values.fieldLevelPermissions['CustomerService-Vendor-FieldLevel'];
    const payload = {
      ...values,
      permissions: Object.keys(values?.permissions)
        .filter((x) => values.permissions[x] !== false)
        .map((item) => item.replaceAll('-', '.')),
      fieldLevelPermissions: Object.keys(values?.fieldLevelPermissions)
        .filter((x) => values.fieldLevelPermissions[x] !== false)
        .map((item) => item.replaceAll('-', '.')),
    };
    mutate(payload);
  };

  const handleCheckedAll = (
    key: string,
    setFieldValue: any,
    dataList: Record<string, any>,
    wrapperName: string,
    isCheckedAll: boolean
  ): void => {
    const allFields = handleFilterPermissionList(dataList)[key];
    const filterAllDisabledData = allFields.filter((item: rolePermissionType) => !item?.isDisabled);

    if (isCheckedAll) {
      allFields.forEach((item: rolePermissionType) =>
        setFieldValue(`${wrapperName}[${item.key.replaceAll('.', '-')}]`, false)
      );
    } else {
      filterAllDisabledData.forEach((item: rolePermissionType) =>
        setFieldValue(`${wrapperName}[${item.key.replaceAll('.', '-')}]`, true)
      );
    }
  };

  return (
    <RoleModalWrapper>
      <Formik
        enableReinitialize
        initialValues={rolesInitialData.data}
        validationSchema={Yup.object().shape(ROLES_VALIDATION)}
        onSubmit={(values) => handleSubmit(values)}
      >
        {({ touched, handleChange, setFieldValue, errors, values }) => {
          return (
            <ModalComponent
              show={isModalOpen}
              closeModal={closeModal}
              loading={loading}
              size='lg'
              valueEdited={_.isEqual(values, rolesInitialData.data)}
              title={`${
                modalStatus === 'View' ? rolesInitialData.data.roleName : `${modalStatus} Role`
              }`}
              children={
                <Form>
                  <ModalBody>
                    <div className='grid sm:grid-cols-4 form-gap'>
                      <div className='sm:col-span-3'>
                        <InputText
                          type='text'
                          label='Role Name'
                          name='roleName'
                          onChange={handleChange}
                          errors={errors}
                          touched={touched}
                          inputType={modalStatus}
                          disabled={modalStatus === 'View'}
                          required
                        />
                      </div>
                      <div className='sm:col-span-3'>
                        <CustomTextArea
                          label='Description'
                          name='description'
                          onChange={handleChange}
                          errors={errors}
                          inputType={modalStatus}
                          disabled={modalStatus === 'View'}
                          touched={touched}
                          rows='5'
                        />
                      </div>
                    </div>
                    <div className='table-wrapper'>
                      <div className='grid grid-cols-12 gap-x-5'>
                        <div className='col-span-5'>
                          <Tag variant='info' title='Module Level Permission List' />
                        </div>
                        <div className='col-span-7 grid grid-cols-12'>
                          <Heading
                            variant='body-xs'
                            title='All'
                            className='col-span-2 roleSubTitle'
                          />
                          {ROLES_PERMISSION_LIST.map((col, i) => (
                            <Heading
                              key={`header-table-${i}`}
                              variant='body-xs'
                              title={col}
                              className='col-span-2 roleSubTitle'
                            />
                          ))}
                        </div>
                      </div>

                      {Object.keys(filterNonFieldLevelPermission).map((data: string) => {
                        const allInfo = filterNonFieldLevelPermission[data].filter(
                          (item: rolePermissionType) => !item?.isDisabled
                        );

                        const checkRoles = allInfo.map(
                          (item: rolePermissionType) =>
                            values.permissions[item.key.replaceAll('.', '-')] ?? false
                        );

                        const labelKey = allInfo?.find((item: any) => item.text === 'View')?.key;
                        return (
                          <ListWrapper className='grid grid-cols-12 gap-x-5 items-center'>
                            <Heading
                              title={allFieldValues != null ? allFieldValues[labelKey] : '-'}
                              variant='body-xs'
                              className='roleTitle-main col-span-5'
                            />
                            <CheckBoxFlex className='col-span-7 grid grid-cols-12'>
                              <div className='col-span-2 justify-center CheckboxCenterInRoles'>
                                <CustomCheckBox
                                  name={`nonFieldPermissions${data}All`}
                                  onChange={() => {
                                    handleCheckedAll(
                                      data,
                                      setFieldValue,
                                      filterNonFieldLevelPermission,
                                      'permissions',
                                      checkRoles.every((v: boolean) => v)
                                    );
                                  }}
                                  errors={errors}
                                  touched={touched}
                                  disabled={modalStatus === 'View'}
                                  checked={checkRoles.every((v: boolean) => v)}
                                />
                              </div>
                              {ROLES_PERMISSION_LIST.map((item, index) => {
                                const itemData: rolePermissionType =
                                  filterNonFieldLevelPermission[`${data}`][index];
                                return (
                                  <div className='col-span-2 justify-center CheckboxCenterInRoles'>
                                    <CustomCheckBox
                                      name={`permissions[${itemData?.key?.replaceAll('.', '-')}]`}
                                      onChange={(e: any) => {
                                        if (
                                          !itemData.key.includes('View') &&
                                          Boolean(e.target.checked)
                                        ) {
                                          const newValue = itemData.key
                                            .replaceAll('.', '-')
                                            .split('-');

                                          const filterValue = newValue
                                            .filter(
                                              (item: string, index: number) =>
                                                index !== newValue.length - 1 && item
                                            )
                                            .join('-');
                                          setFieldValue(`permissions[${filterValue}]`, true);
                                        }
                                        setFieldValue(
                                          `permissions[${itemData.key.replaceAll('.', '-')}]`,
                                          e.target.checked
                                        );
                                      }}
                                      errors={errors}
                                      touched={touched}
                                      id={itemData.key}
                                      arrValue={
                                        item === itemData.text &&
                                        values.permissions[itemData.key.replaceAll('.', '-')]
                                      }
                                      disabled={
                                        modalStatus === 'View' ||
                                        itemData.key.length === 0 ||
                                        itemData.isDisabled
                                      }
                                    />
                                  </div>
                                );
                              })}
                            </CheckBoxFlex>
                          </ListWrapper>
                        );
                      })}
                    </div>
                    <FieldLevelPermissionsGroups>
                      <div className='grid grid-cols-12 gap-x-5 roleTitle'>
                        <div className='col-span-5'>
                          <Tag variant='info' title='Field Level Permission' />
                        </div>
                      </div>
                      {Object.keys(filterFieldLevelPermission).map((data: string) => {
                        let allInfo = filterFieldLevelPermission[data].filter(
                          (item: any) => !item.isDisabled
                        );

                        const checkRoles = allInfo.map((item: any) =>
                          values.fieldLevelPermissions[item.key.replaceAll('.', '-')] ? true : false
                        );
                        const labelKey = allInfo?.find((item: any) => item.text === 'View').key;
                        return (
                          <ListWrapper className='grid grid-cols-12 gap-x-5 items-center'>
                            <Heading
                              title={allFieldValues ? allFieldValues[labelKey] : '-'}
                              variant='body-xs'
                              className='roleTitle-main col-span-5'
                            />
                            <CheckBoxFlex className='col-span-7 grid grid-cols-12'>
                              <div className='col-span-2 justify-center CheckboxCenterInRoles'>
                                <CustomCheckBox
                                  name={`fieldLevelPermissions${data}All`}
                                  onChange={() =>
                                    handleCheckedAll(
                                      data,
                                      setFieldValue,
                                      filterFieldLevelPermission,
                                      'fieldLevelPermissions',
                                      checkRoles.every((v: boolean) => v === true)
                                    )
                                  }
                                  errors={errors}
                                  touched={touched}
                                  disabled={modalStatus === 'View'}
                                  checked={checkRoles.every((v: boolean) => v === true)}
                                />
                              </div>
                              {ROLES_PERMISSION_LIST.map((item, index) => {
                                const itemData = filterFieldLevelPermission[`${data}`][index];
                                return (
                                  <div className='col-span-2 justify-center CheckboxCenterInRoles'>
                                    <CustomCheckBox
                                      name={`fieldLevelPermissions[${itemData.key.replaceAll(
                                        '.',
                                        '-'
                                      )}]`}
                                      onChange={(e: any) => {
                                        if (
                                          !itemData.key.includes('View') &&
                                          Boolean(e.target.checked)
                                        ) {
                                          const newValue = itemData.key
                                            .replaceAll('.', '-')
                                            .split('-');

                                          const filterValue = newValue
                                            .filter(
                                              (item: string, index: number) =>
                                                index !== newValue.length - 1 && item
                                            )
                                            .join('-');
                                          setFieldValue(
                                            `fieldLevelPermissions[${filterValue}]`,
                                            true
                                          );
                                        }
                                        setFieldValue(
                                          `fieldLevelPermissions[${itemData.key.replaceAll(
                                            '.',
                                            '-'
                                          )}]`,
                                          e.target.checked
                                        );
                                      }}
                                      errors={errors}
                                      touched={touched}
                                      id={itemData.key}
                                      arrValue={
                                        item === itemData.text &&
                                        values.fieldLevelPermissions[
                                          itemData.key.replaceAll('.', '-')
                                        ]
                                      }
                                      disabled={
                                        modalStatus === 'View' ||
                                        !itemData.key.length ||
                                        itemData.isDisabled
                                      }
                                    />
                                  </div>
                                );
                              })}
                            </CheckBoxFlex>
                          </ListWrapper>
                        );
                      })}
                    </FieldLevelPermissionsGroups>
                  </ModalBody>
                  <ModalFooterComponent
                    modalStatus={modalStatus}
                    onCloseModal={() => {
                      closeModal();
                    }}
                    onEditModal={() => {
                      setModalStatus('Edit');
                    }}
                    valueEdited={_.isEqual(values, rolesInitialData?.data)}
                    loading={isLoading}
                    isEditShow
                  />
                </Form>
              }
            />
          );
        }}
      </Formik>
    </RoleModalWrapper>
  );
};

export default RoleModal;
