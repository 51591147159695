import { type UseQueryResult, useQuery } from 'react-query';
import { CountryService } from '../../services/wealthlane-customer-services';

const useGetCountryCode = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>
): UseQueryResult<
  Array<{
    label: string;
    value: string;
    code: string;
    isOtherCountry: boolean;
  }>,
  unknown
> => {
  const countryCodeOptions = useQuery(
    ['getCountriesCode'],
    async () => {
      return await handleRequest(CountryService.getApiCustomerApiAppCountryCountries());
    },
    {
      select: (data) => {
        if (data != null) {
          data.unshift(
            data.splice(
              data.findIndex((item) => item.isOtherCountry === false),
              1
            )[0]
          );
          return data
            ?.filter(
              (notEmpty: any) =>
                notEmpty?.abbreviation !== null || notEmpty?.countryCallingCode !== null
            )
            .map((item) => {
              return {
                label: `(${item.countryCallingCode ?? ''}) ${item.name ?? ''}`,
                value: item.id ?? '',
                code: item.countryCallingCode ?? '',
                isOtherCountry: item.isOtherCountry ?? false,
              };
            });
        } else {
          return [];
        }
      },
      cacheTime: 1000 * 60 * 5,
    }
  );

  return countryCodeOptions;
};

export default useGetCountryCode;
