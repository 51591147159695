import { type ReactNode, useState } from 'react';

import { Transition } from 'react-transition-group';
import { isEmpty } from 'lodash';
import { AlertPopupContainer } from '../../../../components/alert-popup/alertPopup.styled';
import Heading from '../../../../components/typography';

import Button from '../../../../components/button';
import { FormWrapper } from '../../../../components/input-text/input.styled';

interface RequestModalType {
  isShow: boolean;
  closeModal: () => void;
  handleSubmit?: any;
  title?: string | ReactNode;
  modalStatus?: string | ReactNode;
  setRemark?: any;
  remark?: any;
  loading?: boolean;
  customTitle?: string;
  dataRow?: any;
}

const RequestModal = ({
  isShow,
  title,
  modalStatus,
  closeModal,
  handleSubmit,
  remark,
  setRemark,
  loading,
  customTitle,
  dataRow,
}: RequestModalType): JSX.Element => {
  const [errorMessage, setErrorMessage] = useState('');

  return (
    <Transition in={isShow} timeout={250}>
      {(state) => (
        <AlertPopupContainer>
          <div className={`modalBackdrop modalBackdrop-${state}`}>
            <div className={`sideBar sideBar-${state} `}>
              <div className='popupDialog'>
                <div className='popupContent'>
                  <Heading
                    variant='h5'
                    className='mb-5'
                    title={
                      customTitle ??
                      (modalStatus === 'View Remarks'
                        ? 'Reason'
                        : `Are you sure you want to ${
                            title === ''
                              ? dataRow?.hasApprover === true
                                ? 'request for approval'
                                : 'activate'
                              : String(title)
                          }?`)
                    }
                  />

                  {(modalStatus === 'Deactivate' || modalStatus === 'View Remarks') && (
                    <FormWrapper>
                      <textarea
                        name='remarks'
                        placeholder='Enter Reason'
                        className={!isEmpty(errorMessage) ? 'input-view-error' : 'reject-view'}
                        style={{ minHeight: '100px', minWidth: '430px', resize: 'none' }}
                        defaultValue={remark}
                        disabled={modalStatus === 'View Remarks'}
                        onChange={(e) => {
                          setErrorMessage('');
                          setRemark(e?.target?.value);
                        }}></textarea>
                      {errorMessage !== '' && (
                        <Heading variant='body-xs' title={'Required Field'} className={'mt-2'} />
                      )}
                    </FormWrapper>
                  )}
                  <div className='popupButtons'>
                    <Button
                      variant='primary-dark'
                      title={modalStatus === 'View Remarks' ? 'Close' : 'No'}
                      onClick={() => {
                        closeModal();
                        setRemark && setRemark('');
                      }}
                    />
                    ;
                    {modalStatus !== 'View Remarks' && (
                      <Button
                        variant='primary'
                        title='Confirm'
                        onClick={() => {
                          modalStatus === 'Deactivate'
                            ? !isEmpty(remark)
                              ? handleSubmit()
                              : setErrorMessage && setErrorMessage('Required Field')
                            : handleSubmit();
                        }}
                        icon={<span className='material-symbols-outlined'>check_circle</span>}
                        loading={loading}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </AlertPopupContainer>
      )}
    </Transition>
  );
};

export default RequestModal;
