/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_TaxService_Dtos_TaxItemDto_GetTaxItemDto_Wealthlane_TaxService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null } from '../models/Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_TaxService_Dtos_TaxItemDto_GetTaxItemDto_Wealthlane_TaxService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null';
import type { Wealthlane_TaxService_Dtos_Response_ResponseDto } from '../models/Wealthlane_TaxService_Dtos_Response_ResponseDto';
import type { Wealthlane_TaxService_Dtos_TaxItemApprovalHistory_TaxItemApprovalHistoryDto } from '../models/Wealthlane_TaxService_Dtos_TaxItemApprovalHistory_TaxItemApprovalHistoryDto';
import type { Wealthlane_TaxService_Dtos_TaxItemDto_ApprovalStatusDto } from '../models/Wealthlane_TaxService_Dtos_TaxItemDto_ApprovalStatusDto';
import type { Wealthlane_TaxService_Dtos_TaxItemDto_TaxItemDto } from '../models/Wealthlane_TaxService_Dtos_TaxItemDto_TaxItemDto';
import type { Wealthlane_TaxService_Dtos_TaxItemDto_TaxItemRejectDto } from '../models/Wealthlane_TaxService_Dtos_TaxItemDto_TaxItemRejectDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class TaxItemApprovalService {

    /**
     * @returns boolean Success
     * @throws ApiError
     */
    public static getApiTaxApiAppTaxItemApprovalHasApprover(): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/tax/api/app/tax-item-approval/has-approver',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Wealthlane_TaxService_Dtos_Response_ResponseDto Success
     * @throws ApiError
     */
    public static postApiTaxApiAppTaxItemApprovalApproveTaxItem(
requestBody?: Array<string>,
): CancelablePromise<Wealthlane_TaxService_Dtos_Response_ResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/tax/api/app/tax-item-approval/approve-tax-item',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Wealthlane_TaxService_Dtos_Response_ResponseDto Success
     * @throws ApiError
     */
    public static postApiTaxApiAppTaxItemApprovalRejectTaxItem(
requestBody?: Array<Wealthlane_TaxService_Dtos_TaxItemDto_TaxItemRejectDto>,
): CancelablePromise<Wealthlane_TaxService_Dtos_Response_ResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/tax/api/app/tax-item-approval/reject-tax-item',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_TaxService_Dtos_TaxItemApprovalHistory_TaxItemApprovalHistoryDto Success
     * @throws ApiError
     */
    public static getApiTaxApiAppTaxItemApprovalTaxItemHistory(): CancelablePromise<Array<Wealthlane_TaxService_Dtos_TaxItemApprovalHistory_TaxItemApprovalHistoryDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/tax/api/app/tax-item-approval/tax-item-history',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param taxItemId 
     * @returns Wealthlane_TaxService_Dtos_TaxItemApprovalHistory_TaxItemApprovalHistoryDto Success
     * @throws ApiError
     */
    public static getApiTaxApiAppTaxItemApprovalTaxItemHistoryById(
taxItemId: string,
): CancelablePromise<Array<Wealthlane_TaxService_Dtos_TaxItemApprovalHistory_TaxItemApprovalHistoryDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/tax/api/app/tax-item-approval/tax-item-history-by-id/{taxItemId}',
            path: {
                'taxItemId': taxItemId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param taxPeriodId 
     * @param taxApprovalStatusId 
     * @param filter 
     * @param sorting 
     * @param skipCount 
     * @param maxResultCount 
     * @returns Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_TaxService_Dtos_TaxItemDto_GetTaxItemDto_Wealthlane_TaxService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null Success
     * @throws ApiError
     */
    public static getApiTaxApiAppTaxItemApprovalTaxItemList(
taxPeriodId?: string,
taxApprovalStatusId?: number,
filter?: string,
sorting?: string,
skipCount?: number,
maxResultCount?: number,
): CancelablePromise<Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_TaxService_Dtos_TaxItemDto_GetTaxItemDto_Wealthlane_TaxService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/tax/api/app/tax-item-approval/tax-item-list',
            query: {
                'TaxPeriodId': taxPeriodId,
                'TaxApprovalStatusId': taxApprovalStatusId,
                'Filter': filter,
                'Sorting': sorting,
                'SkipCount': skipCount,
                'MaxResultCount': maxResultCount,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param taxItemId 
     * @returns Wealthlane_TaxService_Dtos_TaxItemDto_TaxItemDto Success
     * @throws ApiError
     */
    public static getApiTaxApiAppTaxItemApprovalTaxItemById(
taxItemId: string,
): CancelablePromise<Wealthlane_TaxService_Dtos_TaxItemDto_TaxItemDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/tax/api/app/tax-item-approval/tax-item-by-id/{TaxItemId}',
            path: {
                'TaxItemId': taxItemId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param taxItemId 
     * @returns string Success
     * @throws ApiError
     */
    public static getApiTaxApiAppTaxItemApprovalRemarksByTaxItemId(
taxItemId: string,
): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/tax/api/app/tax-item-approval/remarks-by-tax-item-id/{TaxItemId}',
            path: {
                'TaxItemId': taxItemId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_TaxService_Dtos_TaxItemDto_ApprovalStatusDto Success
     * @throws ApiError
     */
    public static getApiTaxApiAppTaxItemApprovalApprovalList(): CancelablePromise<Array<Wealthlane_TaxService_Dtos_TaxItemDto_ApprovalStatusDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/tax/api/app/tax-item-approval/approval-list',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

}
