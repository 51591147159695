import { rem } from '../../utils/style';
import styled from 'styled-components';
import { COLORS } from '../../constants/style/color';

interface RadioButtonWrapperType {
  label?: string;
}

export const RadioButtonWrapper = styled.div<RadioButtonWrapperType>`
  position: relative;
  display: flex;
  gap: 17px;
  min-width: ${rem(20)};
  align-items: center;
  &.partialSelect {
    input[type='checkbox']::after {
      position: absolute;
      content: '\\e15b';
      color: ${COLORS.White};
      font-size: ${rem(16)};
      background-color: ${COLORS.SpanishGray};
      width: ${rem(18)};
      height: ${rem(18)};
      border-radius: ${rem(3)};
      font-family: 'Material Symbols Rounded';
      line-height: ${rem(19)};
      cursor: pointer;
      display: flex;
      justify-content: center;
      left: 0px;
      top: 0px;
    }
  }
  input {
    width: 0px;
    height: 0px;
    -moz-appearance: initial;
  }

  /* input[type='radio'] {
    -webkit-appearance: caret;
  }

  input[type='checkbox'] {
    -webkit-appearance: caret;
  } */

  input[type='radio']::before {
    position: absolute;
    content: '';
    width: ${rem(18)};
    height: ${rem(18)};
    border: ${rem(1)} solid ${COLORS.LightGray};
    border-radius: 50%;
    background-color: ${COLORS.White};
    top: 11px;
    left: 9px;
    transform: translate(-50%, -50%);
    cursor: pointer;
    transition: 0.3s all ease;
  }

  input[type='radio']:disabled::before {
    background-color: ${COLORS.OffWhite};
  }

  input[type='radio']:checked::before {
    border: ${rem(1)} solid ${COLORS.Green};
  }

  input[type='radio']:checked::after {
    position: absolute;
    content: '';
    width: ${rem(10)};
    height: ${rem(10)};
    border-radius: 50%;
    background-color: ${COLORS.Green};

    left: 9px;
    transform: translate(-50%, -50%);
  }

  input[type='checkbox']::before {
    position: absolute;
    content: '';
    width: ${rem(18)};
    height: ${rem(18)};
    border: ${rem(1)} solid ${COLORS.LightGray};
    border-radius: ${rem(4)};
    background-color: ${COLORS.White};
    top: 9px;
    left: 9px;
    transform: translate(-50%, -50%);
    cursor: pointer;
    transition: 0.3s all linear;
  }

  input[type='checkbox']:checked::before {
    border: ${rem(1)} solid ${COLORS.Green};
    transition: 0.3s all linear;
  }

  input[type='checkbox']:checked::after {
    position: absolute;
    content: '\f88b';
    color: ${COLORS.White};
    font-size: ${rem(18)};
    background-color: ${COLORS.Green};
    width: ${rem(18)};
    height: ${rem(18)};
    border-radius: ${rem(4)};
    font-family: 'Material Symbols Outlined';
    font-weight: 900;
    line-height: ${rem(19)};
    transition: 0.3s all linear;
    cursor: pointer;
    display: flex;
    justify-content: center;

    left: 0px;
    top: 0px;
  }

  input[type='checkbox']:disabled::before {
    background-color: ${COLORS.OffWhite};
  }

  input[type='checkbox']:disabled::after {
    background-color: ${COLORS.SpanishGray};
  }

  label {
    margin-left: ${rem(6)};
    margin-bottom: 0;
    font-weight: 400;
    transition: 0.3s all linear;
  }

  input[type='checkbox'].is-invalid:not(:disabled)::before,
  input[type='radio'].is-invalid:not(:disabled)::before {
    border: ${rem(1)} solid ${COLORS.Red};
  }
`;
