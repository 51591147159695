import { getFilteredPermissionData } from './index';
import { ROLES_PERMISSION_LIST } from './../constants/common';
import _ from 'lodash';
import * as Yup from 'yup';
import { SUPPORTED_IMAGE_FORMATS } from '../constants/common';
import { PermissionType, type NavRoutePermissionListType } from '../types/common';
import { useSelector } from 'react-redux';
import { selectAuthRoutesList } from '../pages/configuration/routeSlice';
import type { AnyObject } from 'yup/lib/object';

export const getFormData = (data: any): FormData => {
  const formData = new FormData(); // Must be FormData so that the ajax request will be Form post
  Object.keys(data).forEach((k) => {
    formData.append(k, data[k]);
  });
  return formData;
};

export const taxFormatWithDash = (value: string): string => {
  const taxNumber = value?.replace(/[^\d]/g, '');
  const formattedTax = `${taxNumber.slice(0, 2)}-${taxNumber.slice(2, 9)}`;
  return formattedTax;
};

export const CommaFormatted = (
  amount: any,
  name?: string,
  setFieldValue?: any,
  ...rest: any
): string => {
  const numberValue = Number(String(amount)?.replaceAll(',', '') ?? '');
  const value = numberValue.toLocaleString();
  if (name != null && Boolean(amount)) setFieldValue(name, !isNaN(numberValue) ? value : '');
  return value;
};

export const CommaFormattedFloatPost = (amount: any, name?: string, setFieldValue?: any): any => {
  const removeComma = String(amount)?.replaceAll(',', '') ?? '';
  if (!isNaN(Number(removeComma))) {
    const numberValue = amount;
    const splitValue = amount?.split('.');
    const decimalValueWithComma = parseInt(
      String(splitValue[0])?.replaceAll(',', '') ?? ''
    ).toLocaleString();

    const value =
      String(amount).includes('.') && name == null
        ? numberValue.toFixed(2).toLocaleString(undefined, { maximumFractionDigits: 2 })
        : numberValue.toLocaleString(undefined, { maximumFractionDigits: 2 });

    if (name != null && Boolean(amount))
      setFieldValue(
        name,
        amount.includes('.') === true
          ? `${decimalValueWithComma}.${typeof splitValue[1] === 'string' ? splitValue[1] : ''}`
          : decimalValueWithComma
      );
    return value;
  } else {
    setFieldValue(name, '');
  }
};

export const getNonUsCountryValidation = (
  isOtherCountry: boolean | undefined,
  fieldName: string
): Yup.StringSchema<string | null | undefined, AnyObject> => {
  if (isOtherCountry !== undefined) {
    return Yup.string()
      .required('Required field')
      .matches(/^[a-zA-Z ]*$/, `Invalid ${fieldName} Name`)
      .matches(/^\s*\S[\s\S]*$/, 'This field cannot contain only blank spaces')
      .nullable();
  } else {
    return Yup.string()
      .matches(/^[a-zA-Z ]*$/, `Invalid ${fieldName} Name`)
      .matches(/^\s*\S[\s\S]*$/, 'This field cannot contain only blank spaces')
      .nullable();
  }
};

export const getUsCountryValidation = (
  isCountryUS: boolean | undefined
): Yup.StringSchema<string | null | undefined, AnyObject> => {
  if (isCountryUS !== null && isCountryUS !== undefined) {
    return Yup.string().required('Required field').nullable();
  } else {
    return Yup.string().nullable();
  }
};

export const setDashAsEmptyValue = (values: any): any =>
  values !== null && values !== undefined && values !== '' ? values : '-';

export const phoneFormat = (name: string, setFieldValue: any, value: string): void => {
  if (value !== '') setFieldValue(name, value);
  const phoneNumber = value.replace(/[^\d]/g, '');
  const phoneNumberLength = phoneNumber.length;
  if (phoneNumberLength < 4) {
    setFieldValue(name, phoneNumber);
  } else if (phoneNumberLength < 7) {
    setFieldValue(name, `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3)}`);
  } else {
    setFieldValue(
      name,
      `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`
    );
  }
};

export const TaxIdFormat = (name: string, value: string, setFieldValue?: any): string => {
  if (value !== '' && setFieldValue) setFieldValue(name, value);
  const taxId = value.replace(/[^\d]/g, '');
  const taxIdLength = taxId.length;
  let finalValue = '';
  if (taxIdLength < 3) {
    setFieldValue(name, taxId);
    finalValue = taxId;
  } else {
    finalValue = `${taxId.slice(0, 2)}-${taxId.slice(2, taxIdLength)}`;
    if (setFieldValue) setFieldValue(name, finalValue);
  }
  return finalValue;
};

export const setObjectNullValue = (
  data: Record<string, string | boolean | number | null>
): Record<string, string | boolean | number | null> => {
  const nullableData = Object.fromEntries(
    Object.entries(data).map(([k, v]) => [k, v === false ? false : v !== null ? v : null])
  );
  return nullableData;
};

export const setArrayOfObjectNullValue = (arrData: any[]): Array<Record<string, any>> => {
  const nullableArrayData = arrData.map((item: any) =>
    Object.fromEntries(
      Object.entries(item).map(([k, v]) => [k, v === false ? false : v !== null ? v : null])
    )
  );
  return nullableArrayData;
};

export const getFileExtension = (name: string): string => {
  const fileArray = name.split('.');
  const fileExtension = fileArray[fileArray.length - 1];

  return fileExtension;
};

export const handleOpenFile = (fileName: string, fileContent: string): void => {
  const fileExtension = getFileExtension(fileName);

  if (SUPPORTED_IMAGE_FORMATS.includes(fileExtension)) {
    const image = new Image();
    image.src = `data:image/${fileExtension};base64,` + fileContent;
    const w: any = window.open('');
    w.document.write(image.outerHTML);
    w.document.close();
  } else if (fileExtension === 'pdf') {
    const pdfWindow: any = window.open('');
    pdfWindow.document.write(
      `<iframe width='100%' height='100%' src='data:application/pdf;base64, ` +
        encodeURI(fileContent) +
        "'></iframe>"
    );
  } else {
    const aElement = document.createElement('a');
    const downloadLink = `data:application/${fileExtension};base64,` + fileContent;
    aElement.setAttribute('download', fileName);
    aElement.setAttribute('href', downloadLink);
    aElement.click();
  }
};

export const handleDownloadFile = (fileName: string, fileContent: string): void => {
  const fileExtension = getFileExtension(fileName);

  const aElement = document.createElement('a');
  const downloadLink = `data:application/${fileExtension};base64,` + fileContent;
  aElement.setAttribute('download', fileName);
  aElement.setAttribute('href', downloadLink);
  aElement.click();
};

export const getPopoverGridSystemRow = (data: any[]): number => {
  const dataLength = _.range(1, 11);
  let row: number = 1;
  dataLength.forEach((item) => {
    if (data.length >= item * 5) {
      row = row + 1;
    }
  });
  return row;
};

export const getPopoverGridSystemColumn = (data: any[]): 1 | 2 | 3 | 4 | undefined => {
  if (data.length <= 2) {
    return 1;
  } else if (data.length <= 4) {
    return 2;
  } else if (data.length <= 6) {
    return 3;
  } else if (data.length > 6) {
    return 4;
  }
};

export const handleFilterPermissionList = (
  data: Record<
    string,
    Array<{
      groupKey: string;
      key: string;
      text: string;
    }>
  >
): Record<string, any> => {
  const rolesObj: any = {};
  const permissionInfo = 'CustomerService.Vendor.';
  Object.entries(data).forEach(([key]) => {
    const rolesAllData = data[`${key}`].map((itemData) => itemData.text);

    const newData: any = data[`${key}`];
    ROLES_PERMISSION_LIST.forEach((item) => {
      if (!rolesAllData.includes(item)) {
        newData.push({
          groupKey: data[`${key}`][0].groupKey,
          key: `${permissionInfo}${data[`${key}`][0].groupKey}.${item}`,
          text: item,
          isDisabled: true,
        });
      }
    });

    const filteredRoles = newData.sort((a: any, b: any) => {
      const A = a.text;
      const B = b.text;
      return ROLES_PERMISSION_LIST.indexOf(A) > ROLES_PERMISSION_LIST.indexOf(B) ? 1 : -1;
    });

    rolesObj[key] = filteredRoles;
  });

  return rolesObj;
};

export const MaterialIconFilled = {
  fontVariationSettings: `"FILL" 1, "wght" 400, "GRAD" 0, "opsz" 48`,
};

export const NavPermissionCheck = (
  routeList: NavRoutePermissionListType[]
): NavRoutePermissionListType[] => {
  const userAuthList = useSelector(selectAuthRoutesList);
  const { nonFieldLevelPermissionsWithApprovalGroups } = getFilteredPermissionData(
    (userAuthList?.auth?.grantedPolicies != null && userAuthList?.auth?.grantedPolicies) || {}
  );

  const hasPermission = Object.keys(nonFieldLevelPermissionsWithApprovalGroups)?.map(
    (item: string) => item
  );

  const filterPermissionList = routeList.filter((item: any) =>
    hasPermission.includes(item.permissionName)
  );

  return filterPermissionList;
};

export const capitalizeFirstLetter = (str: string): string => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const handleSortPermissionNames = (data: PermissionType, filterList: string[]): any => {
  const sortedData = Object.keys(data)
    .sort((a: string, b: string) => {
      return filterList.indexOf(a) > filterList.indexOf(b) ? 1 : -1;
    })
    .reduce((result: PermissionType, key: string) => {
      result[key] = data[key];
      return result;
    }, {});

  return sortedData;
};

export const areArraysEqual = (arr1: string[], arr2: string[]) => {
  if (arr1.length !== arr2.length) {
    return false;
  }

  const sortedArr1 = arr1.slice().sort();
  const sortedArr2 = arr2.slice().sort();

  return sortedArr1.every((value, index) => value === sortedArr2[index]);
};