import { Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import CustomCheckBox from '../../../components/radio-button/customCheckBox';
// eslint-disable-next-line camelcase
import { type Wealthlane_CustomerService_Dtos_CustomerRole_CustomerRoleDto } from '../../../services/wealthlane-customer-services';
import { type ModalStatusType, type TableParamsType } from '../../../types/common';
import { SYSTEM_USERS_VALIDATION } from '../../../utils/validations/validations';
import { useSelector } from 'react-redux';
import { selectAuthRoutesList } from '../../configuration/routeSlice';
import { getFilteredPermissionData } from '../../../utils';
import Heading from '../../../components/typography/index';
import {
  ROLES_PERMISSION_LIST,
  SORTED_FIELD_PERMISSION,
  SORTED_MODULE_PERMISSION,
} from '../../../constants/common';
import * as Yup from 'yup';
import {
  capitalizeFirstLetter,
  handleFilterPermissionList,
  handleSortPermissionNames,
  phoneFormat,
} from '../../../utils/global';
import ModalBodyComponent from '../../../components/modal/modalBodyComponent';
import useWealthlaneAdministrationApi from '../../../hooks/useWealthlaneAdministrationApi';
import { useGetCountryCode, usePostSystemUsers, useWealthlaneCustomerApi } from '../../../hooks';
import {
  CheckBoxFlex,
  FieldLevelPermissionsGroups,
  ListWrapper,
  RoleModalWrapper,
} from '../system-roles/systemRole.styled';
import {
  VendorRoleService,
  // eslint-disable-next-line camelcase
  type Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_AdministrationService_Dtos_VendorRole_VendorRoleDto_Wealthlane_AdministrationService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null,
} from '../../../services/wealthlane-administration-services';
import _, { isNull } from 'lodash';
import { ModalComponent, InputText, FormSelect, ModalFooterComponent } from '../../../components';
import { Label } from '../../../components/input-text/input.styled';
import Tag from '../../../components/lozenge/tags';
import { SettingDivider } from '../settings.styled';
import { rolePermissionType } from '../system-roles';

interface RoleItem {
  label: string;
  value: string;
  permissions: string[];
}
interface AddUserType {
  isModalOpen: boolean;
  isEditForm?: boolean;
  closeModal: () => void;
  handleApprove?: () => void;
  handleReject?: () => void;
  modalStatus: ModalStatusType;
  setModalStatus?: any;
  status?: number;
  usersInitialData: { data: any; id: string };
  isLoading: boolean;
  tableInfo: TableParamsType;
  setTableInfo: any;
  dataRow?: string;
  approver?: boolean;
}

const UserModel = ({
  isModalOpen,
  isEditForm,
  closeModal,
  modalStatus,
  setModalStatus,
  usersInitialData,
  isLoading,
  dataRow,
  handleReject,
  handleApprove,
  approver = false,
}: AddUserType): JSX.Element => {
  const { handleRequest } = useWealthlaneAdministrationApi();
  const { handleRequest: customerHandleRequest } = useWealthlaneCustomerApi();
  const userAuthList = useSelector(selectAuthRoutesList);
  const { nonFieldLevelPermissionsGroups, fieldLevelPermissionsGroups } = getFilteredPermissionData(
    userAuthList.auth?.policies
  );
  const filterNonFieldLevelPermission = handleSortPermissionNames(
    handleFilterPermissionList(nonFieldLevelPermissionsGroups),
    SORTED_MODULE_PERMISSION
  );
  const filterFieldLevelPermission = handleSortPermissionNames(
    handleFilterPermissionList(fieldLevelPermissionsGroups),
    SORTED_FIELD_PERMISSION
  );

  const [role, setRole] = useState<
    // eslint-disable-next-line camelcase
    Wealthlane_CustomerService_Dtos_CustomerRole_CustomerRoleDto[] | null | undefined
  >();
  const [roleList, setRoleList] = useState<RoleItem[]>([]);
  useEffect(() => {}, [role]);

  const { data: countryCodeOptions } = useGetCountryCode(customerHandleRequest);

  const maxResultCount = 1000;
  const sorting = 'Name ASC';
  const filter = '';
  const skipCount = 0;
  const allFieldValues = userAuthList.localization?.values?.Wealthlane;

  const getData = async (): Promise<void> => {
    await customerHandleRequest(
      VendorRoleService.getApiAdministrationApiAppVendorRoleVendorRole(
        filter,
        sorting,
        skipCount,
        maxResultCount
      )
    ).then(
      (
        // eslint-disable-next-line camelcase
        data?: Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_AdministrationService_Dtos_VendorRole_VendorRoleDto_Wealthlane_AdministrationService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null
      ) => {
        setRole(data?.items);
        if (data !== undefined) {
          const list = data?.items?.map((data) => {
            return {
              label: data?.roleName ?? '',
              value: data?.id ?? '',
              permissions: data?.permissions ?? [],
            };
          });
          setRoleList(list !== undefined ? list : []);
        }
      }
    );
  };

  useEffect(() => {
    getData().catch((error) => {
      console.log(error);
    });
  }, []);

  const { mutate, isLoading: postLoading } = usePostSystemUsers(
    handleRequest,
    closeModal,
    usersInitialData.id
  );

  const handleSubmit = (values: any): void => {
    const payload = {
      firstName: values.firstName != null ? capitalizeFirstLetter(values.firstName) : '',
      middleName: values.middleName != null ? capitalizeFirstLetter(values.middleName) : '',
      lastName: values.lastName != null ? capitalizeFirstLetter(values.lastName) : '',
      employeeId: values.employeeId,
      countryCallingCode: countryCodeOptions?.find(
        (item) => item.value === values.countryCallingCodeId
      )?.code,
      countryCallingCodeId: values.countryCallingCodeId,
      contactNumber: values.contactNumber,
      workEmail: values.workEmail,
      vendorRoleId: values.vendorRoleId,
      vendorDepartment: values.vendorDepartment,
      vendorTitle: values.vendorTitle,
      id: values.id,
    };
    mutate(payload);
  };

  const getPermissionList = (value: string): Record<string, unknown> => {
    const permissionList = roleList.find((item) => item.value === value);
    const filterData =
      permissionList?.permissions !== null
        ? permissionList?.permissions
            .map((item: string) => item.replaceAll('.', '-'))
            .reduce((a, v) => ({ ...a, [v]: true }), {})
        : {};
    return filterData ?? {};
  };

  const defaultCountry = countryCodeOptions?.find((item: any) => item.isOtherCountry === false);
  const initialValues = {
    ...usersInitialData.data,
    permissions: getPermissionList(usersInitialData.data.vendorRoleId) || [],
    countryCallingCodeId: usersInitialData?.data?.countryCallingCodeId
      ? usersInitialData?.data?.countryCallingCodeId
      : (modalStatus === 'Edit' || modalStatus === 'View') &&
        isNull(usersInitialData?.data?.countryCallingCodeId)
      ? null
      : defaultCountry?.value,
  };

  return (
    <RoleModalWrapper>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={Yup.object().shape(SYSTEM_USERS_VALIDATION(isEditForm))}
        onSubmit={handleSubmit}
      >
        {({ touched, handleChange, errors, setFieldValue, setFieldTouched, values }) => {
          return (
            <ModalComponent
              show={isModalOpen}
              closeModal={closeModal}
              title={`${modalStatus} User`}
              loading={isLoading}
              valueEdited={_.isEqual(values, initialValues)}
              size='lg'
              children={
                <>
                  <div className='flex flex-col justify-between'>
                    <div>
                      <Form>
                        <ModalBodyComponent>
                          <fieldset>
                            <div className='grid gap-x-[14px] sm:grid-cols-2 lg:grid lg:grid-cols-3'>
                              <InputText
                                type='text'
                                label='First Name'
                                name='firstName'
                                onChange={handleChange}
                                errors={errors}
                                touched={touched}
                                inputType={modalStatus}
                                disabled={modalStatus === 'View'}
                                fieldLabelName='FirstMiddleLastName'
                                isEdit={modalStatus === 'Edit' ? true : false}
                                required
                              />
                              <InputText
                                type='text'
                                label='Middle Name'
                                name='middleName'
                                onChange={handleChange}
                                errors={errors}
                                touched={touched}
                                inputType={modalStatus}
                                disabled={modalStatus === 'View'}
                                fieldLabelName='FirstMiddleLastName'
                                isEdit={modalStatus === 'Edit' ? true : false}
                              />
                              <InputText
                                type='text'
                                label='Last Name'
                                name='lastName'
                                onChange={handleChange}
                                errors={errors}
                                touched={touched}
                                inputType={modalStatus}
                                disabled={modalStatus === 'View'}
                                fieldLabelName='FirstMiddleLastName'
                                isEdit={modalStatus === 'Edit' ? true : false}
                                required
                              />
                            </div>
                            <div className='grid sm:grid-cols-2 gap-x-[14px]'>
                              <InputText
                                type='text'
                                label='Title/Position'
                                name='vendorTitle'
                                onChange={handleChange}
                                errors={errors}
                                touched={touched}
                                inputType={modalStatus}
                                disabled={modalStatus === 'View'}
                                fieldLabelName='JobTitle'
                                isEdit={modalStatus === 'Edit' ? true : false}
                                required
                              />
                              <InputText
                                type='text'
                                label='Department'
                                name='vendorDepartment'
                                onChange={handleChange}
                                errors={errors}
                                touched={touched}
                                inputType={modalStatus}
                                disabled={modalStatus === 'View'}
                                fieldLabelName='Department'
                                isEdit={modalStatus === 'Edit' ? true : false}
                                required
                              />
                            </div>
                            <div className='grid sm:grid-cols-12 gap-x-[20px]'>
                              <div className='sm:col-span-3'>
                                <InputText
                                  type='text'
                                  label='Employee ID'
                                  name='employeeId'
                                  onChange={handleChange}
                                  errors={errors}
                                  touched={touched}
                                  inputType={modalStatus}
                                  disabled={modalStatus === 'View'}
                                  fieldLabelName='EmployeeId'
                                  isEdit={modalStatus === 'Edit' ? true : false}
                                  required
                                />
                              </div>
                              <div className='flex gap-x-[20px]'>
                                <div className='flex-column'>
                                  <Label htmlFor={'contactNumber'}>Contact Number (Work)</Label>
                                  <div className='flex flex-col sm:flex-row gap-x-[12px]'>
                                    <FormSelect
                                      name={`countryCallingCodeId`}
                                      viewName='countryCallingCode'
                                      onChange={(e: any) => {
                                        const isOther = countryCodeOptions?.find(
                                          (obj) => obj?.value === e
                                        )?.isOtherCountry;
                                        handleChange(e);
                                        setFieldValue('contactNumber', '');
                                        setFieldValue('phoneIsOtherCountry', isOther);
                                        setFieldTouched('contactNumber', false);
                                      }}
                                      setFieldValue={setFieldValue}
                                      values={values}
                                      arrValue={values?.countryCallingCodeId}
                                      placeholder={'Select'}
                                      errorValue={errors?.countryCallingCodeId}
                                      touchedValue={touched?.countryCallingCodeId}
                                      options={countryCodeOptions}
                                      touched={touched}
                                      errors={modalStatus === 'View' ? {} : errors}
                                      disabled={modalStatus === 'View'}
                                      fieldLabelName='PhoneNumber'
                                      isEdit={modalStatus === 'Edit' ? true : false}
                                      width={'220px'}
                                      required
                                    />
                                    <InputText
                                      type='text'
                                      name={`contactNumber`}
                                      onChange={(e: any) => {
                                        (countryCodeOptions?.find(
                                          (obj) => obj?.value === values?.countryCallingCodeId
                                        )?.isOtherCountry as boolean)
                                          ? handleChange(e)
                                          : phoneFormat(
                                              `contactNumber`,
                                              setFieldValue,
                                              e.target.value
                                            );
                                      }}
                                      errors={errors}
                                      touched={touched}
                                      inputType={'contact'}
                                      width={'200px'}
                                      disabled={modalStatus === 'View'}
                                      fieldLabelName='PhoneNumber'
                                      isEdit={modalStatus === 'Edit' ? true : false}
                                      required
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </fieldset>
                          <SettingDivider></SettingDivider>

                          <div className='grid sm:grid-cols-2 mt-[24px] gap-x-[14px]'>
                            <FormSelect
                              type='text'
                              label={'System Role'}
                              name='vendorRoleId'
                              viewName='vendorRole'
                              onChange={(value: string) => {
                                const filterData = getPermissionList(value);
                                setFieldValue('vendorRoleId', value);
                                setFieldValue('permissions', filterData);
                              }}
                              errors={errors}
                              touched={touched}
                              options={roleList}
                              values={values}
                              setFieldValue={setFieldValue}
                              inputType={modalStatus}
                              disabled={modalStatus === 'View'}
                              required
                            />
                            <InputText
                              type='email'
                              className='mb-0'
                              label='Work Email (Username)'
                              name='workEmail'
                              onChange={handleChange}
                              errors={errors}
                              inputType={modalStatus}
                              touched={touched}
                              disabled={
                                usersInitialData.data.vendorStatus === 'Approved' ||
                                modalStatus === 'View'
                              }
                              fieldLabelName='EmployeeEmail'
                              isEdit={modalStatus === 'Edit' ? true : false}
                              required
                            />
                          </div>

                          {values?.vendorRoleId !== '' && (
                            <div className='borderTop'>
                              <div className='mb-4'>
                                <Label>View Only Permission</Label>
                              </div>
                              {role != null && role.length > 0 && (
                                <>
                                  <div className='table-wrapper'>
                                    <div className='grid sm:grid-cols-12 gap-x-5'>
                                      <div className='col-span-5'>
                                        <Tag variant='info' title='Module Level Permission' />
                                      </div>
                                      <div className='col-span-7 grid grid-cols-12'>
                                        <Heading
                                          variant='body-xs'
                                          title='All'
                                          className='col-span-2 roleSubTitle'
                                        />
                                        {ROLES_PERMISSION_LIST.map((col, i) => (
                                          <Heading
                                            key={`header-table-${i}`}
                                            variant='body-xs'
                                            title={col}
                                            className='col-span-2 roleSubTitle'
                                          />
                                        ))}
                                      </div>
                                    </div>
                                    {Object.keys(filterNonFieldLevelPermission).map(
                                      (data: string) => {
                                        const allInfo = filterNonFieldLevelPermission[data].filter(
                                          (item: rolePermissionType) => !item?.isDisabled
                                        );

                                        const checkRoles = allInfo.map(
                                          (item: rolePermissionType) =>
                                            values.permissions[item.key.replaceAll('.', '-')] ??
                                            false
                                        );

                                        const labelKey = allInfo?.find(
                                          (item: any) => item.text === 'View'
                                        )?.key;
                                        return (
                                          <ListWrapper className='grid grid-cols-12 gap-x-5 items-center'>
                                            <Heading
                                              title={
                                                allFieldValues != null
                                                  ? allFieldValues[labelKey]
                                                  : '-'
                                              }
                                              variant='body-xs'
                                              className='roleTitle-main col-span-5'
                                            />
                                            <CheckBoxFlex className='col-span-7 grid grid-cols-12'>
                                              <div className='col-span-2 justify-center CheckboxCenterInRoles'>
                                                <CustomCheckBox
                                                  name={`nonFieldPermissions${data}All`}
                                                  onChange={handleChange}
                                                  errors={errors}
                                                  touched={touched}
                                                  disabled
                                                  checked={checkRoles.every((v: boolean) => v)}
                                                />
                                              </div>
                                              {ROLES_PERMISSION_LIST.map((item, index) => {
                                                const itemData =
                                                  filterNonFieldLevelPermission[`${data}`][index];

                                                return (
                                                  <div className='col-span-2 justify-center CheckboxCenterInRoles'>
                                                    <CustomCheckBox
                                                      name={`permissions[${
                                                        itemData.key.replaceAll('.', '-') as string
                                                      } ]`}
                                                      onChange={handleChange}
                                                      errors={errors}
                                                      touched={touched}
                                                      id={itemData.key}
                                                      arrValue={
                                                        item === itemData.text &&
                                                        values.permissions[
                                                          itemData.key.replaceAll('.', '-')
                                                        ]
                                                      }
                                                      disabled
                                                    />
                                                  </div>
                                                );
                                              })}
                                            </CheckBoxFlex>
                                          </ListWrapper>
                                        );
                                      }
                                    )}
                                  </div>
                                  {Object.keys(fieldLevelPermissionsGroups).length > 0 && (
                                    <FieldLevelPermissionsGroups>
                                      <div className='grid grid-cols-12 gap-x-5 roleTitle'>
                                        <div className='col-span-5'>
                                          <Tag variant='info' title='Field Level Permission' />
                                        </div>
                                      </div>
                                      {Object.keys(filterFieldLevelPermission).map(
                                        (data: string) => {
                                          const allInfo = filterFieldLevelPermission[data].filter(
                                            (item: rolePermissionType) => !item?.isDisabled
                                          );

                                          const checkRoles = allInfo.map(
                                            (item: rolePermissionType) =>
                                              values.permissions[item.key.replaceAll('.', '-')] ??
                                              false
                                          );

                                          const labelKey = allInfo?.find(
                                            (item: any) => item.text === 'View'
                                          )?.key;
                                          return (
                                            <ListWrapper className='grid grid-cols-12 gap-x-5 items-center'>
                                              <Heading
                                                title={
                                                  allFieldValues ? allFieldValues[labelKey] : '-'
                                                }
                                                variant='body-xs'
                                                className='roleTitle-main col-span-5'
                                              />
                                              <CheckBoxFlex className='col-span-7 grid grid-cols-12'>
                                                <div className='col-span-2 justify-center CheckboxCenterInRoles'>
                                                  <CustomCheckBox
                                                    name={`permissions${data}All`}
                                                    onChange={handleChange}
                                                    errors={errors}
                                                    touched={touched}
                                                    disabled
                                                    checked={checkRoles.every((v: boolean) => v)}
                                                  />
                                                </div>
                                                {ROLES_PERMISSION_LIST.map((item, index) => {
                                                  const itemData =
                                                    filterFieldLevelPermission[`${data}`][index];

                                                  return (
                                                    <div className='col-span-2 justify-center CheckboxCenterInRoles'>
                                                      <CustomCheckBox
                                                        name={`permissions[${
                                                          itemData?.key?.replaceAll(
                                                            '.',
                                                            '-'
                                                          ) as string
                                                        }]`}
                                                        onChange={handleChange}
                                                        errors={errors}
                                                        touched={touched}
                                                        id={itemData.key}
                                                        arrValue={
                                                          item === itemData.text &&
                                                          values.permissions[
                                                            itemData.key.replaceAll('.', '-')
                                                          ]
                                                        }
                                                        disabled
                                                      />
                                                    </div>
                                                  );
                                                })}
                                              </CheckBoxFlex>
                                            </ListWrapper>
                                          );
                                        }
                                      )}
                                    </FieldLevelPermissionsGroups>
                                  )}
                                </>
                              )}
                            </div>
                          )}
                        </ModalBodyComponent>
                        {dataRow === 'Rejected' ? (
                          <ModalFooterComponent
                            onCloseModal={() => {
                              closeModal();
                            }}
                          />
                        ) : approver === true &&
                          usersInitialData.data.vendorStatus !== 'Rejected' &&
                          usersInitialData.data.vendorStatus !== 'Approved' ? (
                          <ModalFooterComponent
                            approverView={true}
                            onCloseModal={() => closeModal()}
                            // loading={loading}
                            addTitle={''}
                            onApprove={handleApprove}
                            onReject={handleReject}
                          />
                        ) : (
                          <ModalFooterComponent
                            modalStatus={modalStatus}
                            isEditShow={
                              usersInitialData.data?.isEnabled === false
                                ? usersInitialData.data?.isEnabled
                                : usersInitialData.data?.vendorStatus !== 'Rejected'
                            }
                            onCloseModal={() => {
                              closeModal();
                            }}
                            loading={postLoading}
                            onEditModal={() => setModalStatus('Edit')}
                            onApprove={() => {
                              handleApprove?.();
                            }}
                            onReject={() => {
                              handleReject?.();
                            }}
                            valueEdited={_.isEqual(values, initialValues)}
                          />
                        )}
                      </Form>
                    </div>
                  </div>
                </>
              }
            />
          );
        }}
      </Formik>
    </RoleModalWrapper>
  );
};

export default UserModel;
