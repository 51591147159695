import moment from 'moment';
import { useState, useEffect } from 'react';
// eslint-disable-next-line camelcase
import {
  NotificationService,
  type Wealthlane_NotificationService_DTOs_BellNotificationDto,
} from '../../../services/wealthlane-notification-services';
import Button from '../../button';
import ModalComponent from '../../modal/modal';
import Spinners from '../../spinner';
import Heading from '../../typography';
import { NotificationContainer } from './notification.styled';
import { usePostSeenNotification, useGetUnseenNotification, useBoolean } from '../../../hooks';
import FlashMessage from '../../flash-message';
import ModalBodyComponent from '../../modal/modalBodyComponent';
import { toast } from 'react-toastify';
import ToastComponent from '../../toast';
import { useQueryClient } from 'react-query';
import { NotificationRouteList } from '../../../constants/notificationRouteList';
import { useNavigate } from 'react-router-dom';
import SlideLeftAnimation from '../../animation/slideLeft';
import AlertPopup from '../../alert-popup';

interface NotificationModalType {
  // eslint-disable-next-line camelcase
  newMessage: Wealthlane_NotificationService_DTOs_BellNotificationDto;
  isModalOpen: boolean;
  token: string | null;
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>;
  closeModal: () => void;
}
interface NotificationCardType {
  // eslint-disable-next-line camelcase
  notificationData: Wealthlane_NotificationService_DTOs_BellNotificationDto;
}

const NotificationModal = ({
  newMessage,
  isModalOpen,
  handleRequest,
  closeModal,
}: NotificationModalType): JSX.Element => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(5);
  const [allNotification, setAllNotification] = useState<
    // eslint-disable-next-line camelcase
    Wealthlane_NotificationService_DTOs_BellNotificationDto[]
  >([]);
  const { data, isLoading } = useGetUnseenNotification(handleRequest);
  const {
    value: isConfirmOpen,
    setFalse: closeConfirmModal,
    setTrue: openConfirmModal,
  } = useBoolean(false);
  const [show, setShow] = useState('');
  const [notificationId, setNotificationId] = useState('');

  useEffect(() => {
    if (data != null) {
      setAllNotification([...data]);
    }
  }, [data]);

  useEffect(() => {
    if (newMessage != null) {
      setAllNotification([newMessage, ...allNotification]);
    }
  }, [newMessage]);

  const { mutate } = usePostSeenNotification(handleRequest);
  const handleNotificationSeen = (id?: string): void => {
    mutate(id);
  };
  const handleDeleteAllNotification = (): void => {
    closeConfirmModal();
    handleRequest(NotificationService.deleteApiNotificationApiAppNotificationNotifications())
      .then((data?: boolean) => {
        if (data ?? false) {
          closeConfirmModal();
          setShow('All');
          setTimeout(() => {
            setAllNotification([]);
            setShow('');
            toast.success(<ToastComponent label='Success' message='All Notification Deleted.' />);
            void queryClient.invalidateQueries('getGetUnseenNotificationList');
            void queryClient.invalidateQueries('getGetUnseenNotificationCountList');
          }, 1000);
        }
      })
      .catch((error: any) => {
        console.log('error', error);
      });
  };

  const handleDeleteNotification = (id: string): void => {
    setNotificationId(id);
    handleRequest(NotificationService.deleteApiNotificationApiAppNotificationNotification(id))
      .then((notifyData?: boolean) => {
        if (notifyData ?? false) {
          const filterData = data?.filter((item) => item.notificationId !== id);
          setNotificationId('');
          setShow(id);

          setTimeout(() => {
            if (filterData != null) {
              setAllNotification([...filterData]);
            }
            setShow('');
            void queryClient.invalidateQueries('getGetUnseenNotificationCountList');
            void queryClient.invalidateQueries('getGetUnseenNotificationList');
          }, 1000);
        }
      })
      .catch((error: any) => {
        console.log('error', error);
        setNotificationId('');
      });
  };
  const handleViewMore = (): void => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      if (allNotification.length > count) {
        setCount(count + 5);
      }
    }, 1000);
  };
  const redirectNotification = (notifyTitle: string | null | undefined): void => {
    const notificationUrl = NotificationRouteList?.find(
      (item: { title: string; url: string }) => item?.title === notifyTitle
    )?.url;
    notificationUrl != null && navigate(notificationUrl);
    closeModal();
  };

  // const NotificationCard = ({ notificationData }: NotificationCardType): JSX.Element => {
  //   return (
  //     <SlideLeftAnimation isOpen={notificationData.notificationId === show ? true : show === 'All'}>
  //       <div
  //         className={`notification ${
  //           notificationData.isSeen ?? false ? 'notification-seen' : ''
  //         } flex justify-between items-start`}
  //       >
  //         <div>
  //           <div
  //             onClick={() => {
  //               redirectNotification(notificationData?.notificationRedirectionPage);
  //               handleNotificationSeen(notificationData.notificationId);
  //             }}
  //           >
  //             <Heading
  //               title={<div dangerouslySetInnerHTML={{ __html: notificationData.message ?? '' }} />}
  //               variant='body'
  //               className='notification-title'
  //             />
  //           </div>
  //           <Heading
  //             title={`${moment(notificationData.notificationDateTime).format(
  //               'DD MMM. YYYY h:mm A'
  //             )}`}
  //             variant='body-xs'
  //             className='notification-date'
  //           />
  //         </div>
  //         <div>
  //           {notificationId === notificationData.notificationId ? (
  //             <div className='notification-loader'>
  //               <Spinners size='sm' />
  //             </div>
  //           ) : (
  //             <span
  //               className='notification-delete material-symbols-sharp'
  //               onClick={() => {
  //                 handleDeleteNotification(notificationData.notificationId ?? '');
  //               }}
  //             >
  //               Delete
  //             </span>
  //           )}
  //         </div>
  //       </div>
  //     </SlideLeftAnimation>
  //   );
  // };

  return (
    <ModalComponent
      show={isModalOpen}
      size='sm'
      closeModal={closeModal}
      title='Notifications'
      loading={isLoading}
      outsideClickable
      clearAll={allNotification.length > 0}
      isNotification
      clearAllNotification={openConfirmModal}
      notificationModalClassName={'notification-modal'}
      children={
        <>
          <ModalBodyComponent showFooter={false} className='p-0' closeModal={closeModal}>
            {allNotification != null && allNotification.length > 0 ? (
              <NotificationContainer>
                {allNotification.map((notificationData) => (
                  <SlideLeftAnimation
                    isOpen={notificationData.notificationId === show ? true : show === 'All'}
                  >
                    <div
                      className={`notification ${
                        notificationData.isSeen ?? false ? 'notification-seen' : ''
                      } flex justify-between items-start`}
                    >
                      <div>
                        <div
                          onClick={() => {
                            redirectNotification(notificationData?.notificationRedirectionPage);
                            handleNotificationSeen(notificationData.notificationId);
                          }}
                        >
                          <Heading
                            title={
                              <div
                                dangerouslySetInnerHTML={{ __html: notificationData.message ?? '' }}
                              />
                            }
                            variant='body'
                            className='notification-title'
                          />
                        </div>
                        <Heading
                          title={`${moment(notificationData.notificationDateTime).format(
                            'DD MMM. YYYY h:mm A'
                          )}`}
                          variant='body-xs'
                          className='notification-date'
                        />
                      </div>
                      <div>
                        {notificationId === notificationData.notificationId ? (
                          <div className='notification-loader'>
                            <Spinners size='sm' />
                          </div>
                        ) : (
                          <span
                            className='notification-delete material-symbols-sharp '
                            onClick={() => {
                              handleDeleteNotification(notificationData.notificationId ?? '');
                            }}
                          >
                            Delete
                          </span>
                        )}
                      </div>
                    </div>
                  </SlideLeftAnimation>
                ))}
                {/* Don't remove the below code, else you will face ghost error (issue). Don't even
                dare. */}
                {false && (
                  <Button title='' variant='secondary' onClick={() => {}} disabled={true} />
                )}
                {/* Don't remove the above code, else you will face ghost error (issue). Don't even
                dare. */}
              </NotificationContainer>
            ) : (
              <FlashMessage size='md' message='Your currently have no notifications' type='info' />
            )}
            {isConfirmOpen && (
              <AlertPopup
                isShow={isConfirmOpen}
                closeModal={closeConfirmModal}
                customTitle={'Are you sure you want to clear all notifications ?'}
                handleSubmit={handleDeleteAllNotification}
                // loading={loading}
              />
            )}
          </ModalBodyComponent>
        </>
      }
    />
  );
};

export default NotificationModal;
