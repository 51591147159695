import { FieldArray } from 'formik';
import { useEffect, useState } from 'react';
import Heading from '../../../../../components/typography';
import FormSelect from '../../../../../components/dropdown/formSelect';
import Button from '../../../../../components/button';
import InitialValuesForm from '../../../../../constants/initialValues';
import { ListLineItem, ListLineItemWrapper } from '../../../../../components/tree-view';
import { BtnGroup, UserDefinedCriteria } from '../taxSetupStyled';
import { COLORS } from '../../../../../constants/style/color';
import { type OptionType } from '../../../../../types/common';
import { EqualityOperatorOptions, TAX_TOGGLE_OPTIONS } from '../../../../../constants/common';
import {
  useGetCountryOptions,
  useGetTaxCityOptions,
  useGetTaxCountryOptions,
  useGetTaxCountryOptionsWithJuridiction,
  useGetTaxStateOptions,
  useGetTaxStateOptionsByJurisdiction,
  useGetTaxUserDefinedOptions,
  useResidenceStatusOptions,
  useWealthlaneCustomerApi,
} from '../../../../../hooks';
import CustomToggle from '../../../../../components/toggle';
import {
  DescriptionType,
  type UserDefindeConditionTypes,
  type UserDefinedCriteriaTypes,
} from '../taxConstantTypes';
import _, { isEmpty } from 'lodash';
import CityComponent from './cityComponent';
import StateComponent from './stateComponent';

interface UserDefinedCriteriaType {
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>;
  metaData?: any;
  handleChange: any;
  errors: any;
  touched: any;
  values: any;
  setFieldValue: any;
  modalStatus: string;
  jurisdictionOptions: any;
  setDescriptionData?: any;
  descriptionData?: any;
}

const UserDefinedCriteriaComponent = ({
  handleRequest,
  metaData,
  handleChange,
  errors,
  touched,
  values,
  setFieldValue,
  modalStatus,
  jurisdictionOptions,
  setDescriptionData,
  descriptionData,
}: UserDefinedCriteriaType): JSX.Element => {
  const [udcDescription, setUdcDescription] = useState<DescriptionType>();
  const { data: userDefinedOptions } = useGetTaxUserDefinedOptions(handleRequest);
  const { data: allCountryOptions } = useGetTaxCountryOptions(handleRequest);
  const { data: allStateOptions } = useGetTaxStateOptions(handleRequest);
  const { data: allCityOptions } = useGetTaxCityOptions(handleRequest);
  const { handleRequest: customerRequest } = useWealthlaneCustomerApi();
  const { data: residenceOptions } = useResidenceStatusOptions(customerRequest);
  const [udcData, setUdcData] = useState<any>({ udcIndex: '' });
  const [jurisdictionId, setJurisdictionId] = useState<any>(null);
  const [countryArrayOptions, setCountryArrayOptions] = useState<any>(null);
  const [stateArrayOptions, setStateArrayOptions] = useState<any>(null);
  const [cityArrayOptions, setCityArrayOptions] = useState<any>(null);

  const { data: countryOptions } = useGetTaxCountryOptionsWithJuridiction(
    handleRequest,
    udcData?.udcIndex !== ''
      ? jurisdictionId[udcData?.udcIndex]
      : jurisdictionOptions?.find((item: OptionType) => item?.label == 'Country').value
  );

  useEffect(() => {
    udcData?.stateInitial !== undefined &&
      setCountryArrayOptions({
        ...countryArrayOptions,
        [`${udcData?.countryInitial}`]: countryOptions,
      });
  }, [udcData?.udcIndex, countryOptions]);

  const { data: stateOptions } = useGetTaxStateOptionsByJurisdiction(
    handleRequest,
    udcData?.udcIndex !== '' ? jurisdictionId[udcData?.udcIndex] : '',
    udcData?.countryId !== '' ? udcData?.countryId : ''
  );

  useEffect(() => {
    udcData?.stateArrayIndex !== undefined &&
      setStateArrayOptions({
        ...stateArrayOptions,
        [`${udcData?.stateArrayIndex}`]: stateOptions,
      });
  }, [udcData?.stateArrayIndex, stateOptions]);

  const { data: cityOptions } = useGetTaxCityOptions(
    handleRequest,
    udcData?.stateId !== '' ? udcData?.stateId : ''
  );

  useEffect(() => {
    udcData?.cityArrayIndex !== undefined &&
      setCityArrayOptions({
        ...cityArrayOptions,
        [`${udcData?.cityArrayIndex}`]: cityOptions,
      });
  }, [udcData?.cityArrayIndex, cityOptions]);
  // useEffect(() => {
  //   if (metaData?.establishedCountry === 'US') {
  //     setOptionSelectedState({ ...optionSelectedState, countryId: countryOptions?.[0]?.value });
  //   }
  // }, [metaData]);

  useEffect(() => {
    const index = descriptionData?.findIndex((e: any) => e?.id === udcDescription?.id);
    if (descriptionData[0] === undefined) {
      descriptionData?.pop();
    }
    if (index === -1) {
      setDescriptionData([...descriptionData, udcDescription]);
    } else {
      descriptionData[index] = udcDescription;
    }
  }, [udcDescription]);

  const findData = (data: DescriptionType[], index: number, ruleIndex: number) => {
    const value = data?.find((item: DescriptionType) => item?.id === `${index}${ruleIndex}`);
    return value;
  };

  const filterValues = () => {
    const descriptionArray = values?.userDefinedCriteria?.Conditions?.flatMap(
      (firstBlock: any, firstIndex: number) => {
        return firstBlock?.SubCriteria?.Conditions?.map((secondBlock: any, secondIndex: number) => {
          const fieldValue = userDefinedOptions?.find((item) => item.value === secondBlock?.Field);

          const equalityValue = EqualityOperatorOptions?.find(
            (item) => item.value === secondBlock?.EqualityOperatorType
          )?.label;

          const countryValue = allCountryOptions?.find(
            (item: any) => item?.abbreviation === secondBlock?.Value
          )?.label;

          const statusValue = residenceOptions?.find(
            (item: any) => item.label === secondBlock?.Value
          )?.label;

          return {
            id: `${firstIndex}${secondIndex}`,
            groupLogicalOperator: firstBlock?.SubCriteria?.LogicalOperatorType,
            field: fieldValue?.label,
            equalityOperator: equalityValue,
            name:
              fieldValue?.value === 'WorkCountry' || fieldValue?.value === 'ResidentCountry'
                ? countryValue
                : fieldValue?.value === 'WorkState' || fieldValue?.value === 'ResidentState'
                  ? secondBlock?.Name
                  : fieldValue?.value === 'WorkCity' || fieldValue?.value === 'ResidentCity'
                    ? secondBlock?.Name
                    : fieldValue?.value === 'USResidentStatus'
                      ? statusValue
                      : `${secondBlock?.Value}`,
          };
        });
      }
    );
    setDescriptionData(descriptionArray);

    return descriptionArray;
  };

  useEffect(() => {
    filterValues();
  }, [values, allCountryOptions]);
  return (
    <div>
      <Heading title='User-Defined Criteria' variant='h5' color={COLORS.Gray} className='mb-4' />
      <FieldArray
        name='userDefinedCriteria.Conditions'
        render={(arrayHelpers1) => {
          return (
            <>
              {Boolean(values?.userDefinedCriteria?.Conditions) &&
                values?.userDefinedCriteria?.Conditions.length > 0 &&
                values?.userDefinedCriteria?.Conditions?.map(
                  (userDefinedCriteria: UserDefinedCriteriaTypes, index: number) => (
                    <FieldArray
                      name={`userDefinedCriteria.Conditions[${index}].SubCriteria.Conditions`}
                      key={index}
                      render={(arrayHelpers2) => {
                        return (
                          <>
                            <div className='flex justify-between items-center'>
                              <FormSelect
                                type='text'
                                name={`userDefinedCriteria.Conditions[${index}].SubCriteria.LogicalOperatorType`}
                                errorValue={
                                  errors?.userDefinedCriteria?.Conditions?.[index]?.SubCriteria
                                    ?.LogicalOperatorType
                                }
                                touchedValue={
                                  touched?.userDefinedCriteria?.Conditions?.[index]?.SubCriteria
                                    ?.LogicalOperatorType
                                }
                                onChange={(event: any) => {
                                  handleChange(event);
                                }}
                                errors={errors}
                                touched={touched}
                                options={TAX_TOGGLE_OPTIONS}
                                values={values}
                                arrValue={
                                  values?.userDefinedCriteria?.Conditions?.[index]?.SubCriteria
                                    .LogicalOperatorType
                                }
                                setFieldValue={setFieldValue}
                                inputType={modalStatus}
                                disabled={modalStatus === 'View'}
                                required
                              />
                              {modalStatus !== 'View' && (
                                <div className=''>
                                  <BtnGroup>
                                    <Button
                                      variant={'secondary-white'}
                                      icon={'add_circle_outline'}
                                      type='button'
                                      title={'Add Group'}
                                      onClick={() => {
                                        arrayHelpers1.push(
                                          InitialValuesForm.taxSetup.userDefinedCriteria
                                            .Conditions[0]
                                        );
                                      }}
                                    />

                                    {values?.userDefinedCriteria?.Conditions.length > 1 && (
                                      <Button
                                        variant={'secondary-red'}
                                        title={'Delete Group'}
                                        type='button'
                                        icon={'remove_circle_outline'}
                                        onClick={() => {
                                          arrayHelpers1.remove(index);
                                        }}
                                      />
                                    )}
                                    <Button
                                      variant={'secondary-white'}
                                      title={'Add Rule'}
                                      type='button'
                                      icon={'add_circle_outline'}
                                      onClick={() => {
                                        arrayHelpers2.push(
                                          InitialValuesForm.taxSetup.userDefinedCriteria
                                            ?.Conditions[0]?.SubCriteria?.Conditions[0]
                                        );
                                      }}
                                    />
                                  </BtnGroup>
                                </div>
                              )}
                            </div>
                            <div>
                              <ListLineItemWrapper>
                                {Boolean(
                                  values?.userDefinedCriteria.Conditions[index]?.SubCriteria
                                    ?.Conditions
                                ) &&
                                  values?.userDefinedCriteria.Conditions[index]?.SubCriteria
                                    ?.Conditions.length > 0 &&
                                  values?.userDefinedCriteria.Conditions[
                                    index
                                  ]?.SubCriteria?.Conditions?.map(
                                    (conditions: UserDefindeConditionTypes, ruleIndex: number) => (
                                      <ListLineItem key={ruleIndex}>
                                        <UserDefinedCriteria>
                                          <div className='flex justify-between'>
                                            <div className='w-full grid lg:grid-cols-3 sm:grid-cols-1 gap-x-5 pe-0'>
                                              <FormSelect
                                                type='text'
                                                name={`userDefinedCriteria.Conditions[${index}].SubCriteria.Conditions[${ruleIndex}].Field`}
                                                errorValue={
                                                  errors?.userDefinedCriteria?.Conditions?.[index]
                                                    ?.SubCriteria?.Conditions?.[ruleIndex]?.Field
                                                }
                                                touchedValue={
                                                  touched?.userDefinedCriteria?.Conditions?.[index]
                                                    ?.SubCriteria?.Conditions?.[ruleIndex]?.Field
                                                }
                                                containerClassName='mb-0'
                                                onChange={(event: any) => {
                                                  setUdcData({
                                                    ...udcData,
                                                    udcIndex: `userDefinedCriteria.Conditions[${index}].SubCriteria.Conditions[${ruleIndex}].Field`,
                                                    countryInitial: event?.includes('Country')
                                                      ? 'countryOptions'
                                                      : event?.includes('State')
                                                        ? `userDefinedCriteria.Conditions[${index}].SubCriteria.Conditions[${ruleIndex}]?.countryOptions`
                                                        : '',
                                                    stateInitial: event?.includes('State')
                                                      ? `userDefinedCriteria.Conditions[${index}].SubCriteria.Conditions[${ruleIndex}]?.stateOptions`
                                                      : '',
                                                  });
                                                  setJurisdictionId({
                                                    [`userDefinedCriteria.Conditions[${index}].SubCriteria.Conditions[${ruleIndex}].Field`]:
                                                      event?.includes('Country')
                                                        ? jurisdictionOptions?.find(
                                                          (item: OptionType) =>
                                                            item?.label == 'Country'
                                                        ).value
                                                        : event?.includes('State')
                                                          ? jurisdictionOptions?.find(
                                                            (item: OptionType) =>
                                                              item?.label == 'State'
                                                          ).value
                                                          : event?.includes('City')
                                                            ? jurisdictionOptions?.find(
                                                              (item: OptionType) =>
                                                                item?.label == 'Local'
                                                            ).value
                                                            : '',
                                                  });
                                                  const fieldValue = userDefinedOptions?.find(
                                                    (item) => item.value === event
                                                  );
                                                  setUdcDescription({
                                                    field: fieldValue?.label as string,
                                                    groupLogicalOperator:
                                                      values?.userDefinedCriteria?.Conditions?.[
                                                        index
                                                      ]?.SubCriteria?.LogicalOperatorType,
                                                    id: `${index}${ruleIndex}`,
                                                    name: findData(
                                                      descriptionData,
                                                      index,
                                                      ruleIndex
                                                    )
                                                      ? findData(descriptionData, index, ruleIndex)
                                                        ?.name
                                                      : '',
                                                    equalityOperator: findData(
                                                      descriptionData,
                                                      index,
                                                      ruleIndex
                                                    )
                                                      ? findData(descriptionData, index, ruleIndex)
                                                        ?.equalityOperator
                                                      : '',
                                                  });
                                                  setFieldValue(
                                                    `userDefinedCriteria.Conditions[${index}].SubCriteria.Conditions[${ruleIndex}].Value`,
                                                    ''
                                                  );

                                                  setFieldValue(
                                                    `userDefinedCriteria.Conditions[${index}].SubCriteria.Conditions[${ruleIndex}].Name`,
                                                    ''
                                                  );

                                                  setFieldValue(
                                                    `userDefinedCriteria.Conditions[${index}].SubCriteria.Conditions[${ruleIndex}].EqualityOperatorType`,
                                                    null
                                                  );

                                                  setFieldValue(
                                                    `udc.userDefinedCriteria[${index}].conditions[${ruleIndex}].countryId`,
                                                    null
                                                  );
                                                  setFieldValue(
                                                    `udc.userDefinedCriteria[${index}].conditions[${ruleIndex}].stateId`,
                                                    null
                                                  );
                                                  setFieldValue(
                                                    `udc.userDefinedCriteria[${index}].conditions[${ruleIndex}].cityId`,
                                                    null
                                                  );
                                                  handleChange(event);
                                                }}
                                                errors={errors}
                                                touched={touched}
                                                allOptions={userDefinedOptions}
                                                options={userDefinedOptions}
                                                values={values}
                                                arrValue={
                                                  values?.userDefinedCriteria?.Conditions?.[index]
                                                    ?.SubCriteria?.Conditions?.[ruleIndex]?.Field
                                                }
                                                setFieldValue={setFieldValue}
                                                inputType={modalStatus}
                                                disabled={modalStatus === 'View'}
                                                required
                                              />

                                              {!isEmpty(
                                                values?.userDefinedCriteria?.Conditions?.[index]
                                                  ?.SubCriteria?.Conditions?.[ruleIndex]?.Field
                                              ) && (
                                                <FormSelect
                                                  type='text'
                                                  name={`userDefinedCriteria.Conditions[${index}].SubCriteria.Conditions[${ruleIndex}].EqualityOperatorType`}
                                                  errorValue={
                                                    errors?.userDefinedCriteria?.Conditions?.[index]
                                                      ?.SubCriteria?.Conditions?.[ruleIndex]
                                                      ?.EqualityOperatorType
                                                  }
                                                  touchedValue={
                                                    touched?.userDefinedCriteria?.Conditions?.[
                                                      index
                                                    ]?.SubCriteria?.Conditions?.[ruleIndex]
                                                      ?.EqualityOperatorType
                                                  }
                                                  containerClassName='mb-0'
                                                  onChange={(e: any) => {
                                                    setUdcDescription({
                                                      groupLogicalOperator:
                                                        values?.userDefinedCriteria?.Conditions?.[
                                                          index
                                                        ]?.SubCriteria?.LogicalOperatorType,
                                                      equalityOperator: e,
                                                      id: `${index}${ruleIndex}`,
                                                      name: findData(
                                                        descriptionData,
                                                        index,
                                                        ruleIndex
                                                      )
                                                        ? findData(
                                                          descriptionData,
                                                          index,
                                                          ruleIndex
                                                        )?.name
                                                        : '',
                                                      field: findData(
                                                        descriptionData,
                                                        index,
                                                        ruleIndex
                                                      )
                                                        ? findData(
                                                          descriptionData,
                                                          index,
                                                          ruleIndex
                                                        )?.field
                                                        : '',
                                                    });
                                                    handleChange(e);
                                                  }}
                                                  errors={errors}
                                                  touched={touched}
                                                  options={EqualityOperatorOptions}
                                                  values={values}
                                                  arrValue={
                                                    values?.userDefinedCriteria?.Conditions?.[index]
                                                      ?.SubCriteria?.Conditions?.[ruleIndex]
                                                      ?.EqualityOperatorType
                                                  }
                                                  setFieldValue={setFieldValue}
                                                  inputType={modalStatus}
                                                  disabled={modalStatus === 'View'}
                                                  required
                                                />
                                              )}
                                              {values?.userDefinedCriteria?.Conditions?.[
                                                index
                                              ]?.SubCriteria?.Conditions?.[
                                                ruleIndex
                                              ]?.Field?.includes('Country') === true && (
                                                <FormSelect
                                                  type='text'
                                                  name={`userDefinedCriteria.Conditions[${index}].SubCriteria.Conditions[${ruleIndex}].Value`}
                                                  errorValue={
                                                    errors?.userDefinedCriteria?.Conditions?.[index]
                                                      ?.SubCriteria?.Conditions?.[ruleIndex]?.Value
                                                  }
                                                  touchedValue={
                                                    touched?.userDefinedCriteria?.Conditions?.[
                                                      index
                                                    ]?.SubCriteria?.Conditions?.[ruleIndex]?.Value
                                                  }
                                                  containerClassName='mb-0'
                                                  onChange={(event: any) => {
                                                    const countryValue =
                                                      countryArrayOptions !== null
                                                        ? countryArrayOptions?.countryOptions?.filter(
                                                          (item: any) => item.value === event
                                                        )
                                                        : countryOptions?.filter(
                                                          (item: any) => item.value === event
                                                        );
                                                    // const countryValue = countryOptions?.filter(
                                                    //   (item) => item.value === event
                                                    // );
                                                    setUdcDescription({
                                                      groupLogicalOperator:
                                                        values?.userDefinedCriteria?.Conditions?.[
                                                          index
                                                        ]?.SubCriteria?.LogicalOperatorType,
                                                      name: countryValue?.[0]?.label as string,
                                                      id: `${index}${ruleIndex}`,
                                                      field: findData(
                                                        descriptionData,
                                                        index,
                                                        ruleIndex
                                                      )
                                                        ? findData(
                                                          descriptionData,
                                                          index,
                                                          ruleIndex
                                                        )?.field
                                                        : '',
                                                      equalityOperator: findData(
                                                        descriptionData,
                                                        index,
                                                        ruleIndex
                                                      )
                                                        ? findData(
                                                          descriptionData,
                                                          index,
                                                          ruleIndex
                                                        )?.equalityOperator
                                                        : '',
                                                    });
                                                    setFieldValue(
                                                      `userDefinedCriteria.Conditions[${index}].SubCriteria.Conditions[${ruleIndex}].Value`,
                                                      countryValue?.[0].abbreviation
                                                    );
                                                  }}
                                                  errors={errors}
                                                  touched={touched}
                                                  options={
                                                    countryArrayOptions !== undefined &&
                                                      countryArrayOptions !== null
                                                      ? countryArrayOptions?.countryOptions
                                                      : countryOptions
                                                  }
                                                  values={values}
                                                  arrValue={
                                                    countryArrayOptions !== undefined &&
                                                      countryArrayOptions !== null &&
                                                      countryArrayOptions?.countryOptions?.filter(
                                                        (item: any) =>
                                                        item.abbreviation ===
                                                        values?.userDefinedCriteria?.Conditions?.[
                                                          index
                                                        ]?.SubCriteria?.Conditions?.[ruleIndex]
                                                          ?.Value
                                                    )
                                                      ? countryArrayOptions?.countryOptions?.filter(
                                                        (item: any) =>
                                                          item.abbreviation ===
                                                          values?.userDefinedCriteria
                                                            ?.Conditions?.[index]?.SubCriteria
                                                            ?.Conditions?.[ruleIndex]?.Value
                                                      )[0]?.value
                                                      : countryOptions?.filter(
                                                        (item: any) =>
                                                            item.abbreviation ===
                                                            values?.userDefinedCriteria
                                                              ?.Conditions?.[index]?.SubCriteria
                                                              ?.Conditions?.[ruleIndex]?.Value
                                                      )[0]?.value
                                                  }
                                                  setFieldValue={setFieldValue}
                                                  inputType={modalStatus}
                                                  disabled={modalStatus === 'View'}
                                                  required
                                                />
                                              )}
                                              {values?.userDefinedCriteria?.Conditions?.[
                                                index
                                              ]?.SubCriteria?.Conditions?.[
                                                ruleIndex
                                              ]?.Field?.includes('State') === true && (
                                                <StateComponent
                                                  errors={errors}
                                                  setOptionSelectedState={setUdcData}
                                                  optionSelectedState={udcData}
                                                  touched={touched}
                                                  countryOptions={
                                                    countryArrayOptions !== undefined &&
                                                      countryArrayOptions !== null
                                                      ? countryArrayOptions[udcData?.countryInitial]
                                                      : []
                                                  }
                                                  values={values}
                                                  setFieldValue={setFieldValue}
                                                  index={index}
                                                  ruleIndex={ruleIndex}
                                                  handleChange={handleChange}
                                                  stateArrayOptions={stateArrayOptions}
                                                  modalStatus={modalStatus}
                                                  setJurisdictionId={setJurisdictionId}
                                                  jurisdictionOptions={jurisdictionOptions}
                                                  setUdcData={setUdcData}
                                                  udcData={udcData}
                                                  setUdcDescription={setUdcDescription}
                                                  udcDescription={udcDescription}
                                                  findData={findData}
                                                  descriptionData={descriptionData}
                                                />
                                              )}
                                              {values?.userDefinedCriteria?.Conditions?.[
                                                index
                                              ]?.SubCriteria?.Conditions?.[
                                                ruleIndex
                                              ]?.Field?.includes('City') === true && (
                                                <CityComponent
                                                  errors={errors}
                                                  setOptionSelectedState={setUdcData}
                                                  optionSelectedState={udcData}
                                                  touched={touched}
                                                  countryOptions={
                                                    countryArrayOptions !== undefined &&
                                                      countryArrayOptions !== null
                                                      ? countryArrayOptions[udcData?.countryInitial]
                                                      : []
                                                  }
                                                  values={values}
                                                  setFieldValue={setFieldValue}
                                                  index={index}
                                                  ruleIndex={ruleIndex}
                                                  handleChange={handleChange}
                                                  stateArrayOptions={stateArrayOptions}
                                                  cityArrayOptions={cityArrayOptions}
                                                  stateOptions={stateOptions}
                                                  cityOptions={cityOptions}
                                                  modalStatus={modalStatus}
                                                  setJurisdictionId={setJurisdictionId}
                                                  jurisdictionOptions={jurisdictionOptions}
                                                  setUdcData={setUdcData}
                                                  udcData={udcData}
                                                  setUdcDescription={setUdcDescription}
                                                  udcDescription={udcDescription}
                                                  findData={findData}
                                                  descriptionData={descriptionData}
                                                />
                                              )}
                                              {values?.userDefinedCriteria?.Conditions?.[
                                                index
                                              ]?.SubCriteria?.Conditions?.[
                                                ruleIndex
                                              ]?.Field?.includes('Status') === true && (
                                                <FormSelect
                                                  type='text'
                                                  name={`userDefinedCriteria.Conditions[${index}].SubCriteria.Conditions[${ruleIndex}].Value`}
                                                  viewName='workCountry'
                                                  containerClassName='mb-0'
                                                  onChange={(event: any) => {
                                                    const statusValue = residenceOptions?.filter(
                                                      (item) => item.value === event
                                                    )[0].label;
                                                    setFieldValue(
                                                      `userDefinedCriteria.Conditions[${index}].SubCriteria.Conditions[${ruleIndex}].Value`,
                                                      statusValue
                                                    );
                                                    setUdcDescription({
                                                      groupLogicalOperator:
                                                        values?.userDefinedCriteria?.Conditions?.[
                                                          index
                                                        ]?.SubCriteria?.LogicalOperatorType,
                                                      name: statusValue as string,
                                                      id: `${index}${ruleIndex}`,
                                                      equalityOperator: findData(
                                                        descriptionData,
                                                        index,
                                                        ruleIndex
                                                      )
                                                        ? findData(
                                                          descriptionData,
                                                          index,
                                                          ruleIndex
                                                        )?.equalityOperator
                                                        : '',
                                                      field: findData(
                                                        descriptionData,
                                                        index,
                                                        ruleIndex
                                                      )
                                                        ? findData(
                                                          descriptionData,
                                                          index,
                                                          ruleIndex
                                                        )?.field
                                                        : '',
                                                    });
                                                    handleChange(event);
                                                  }}
                                                  errors={errors}
                                                  touched={touched}
                                                  options={residenceOptions}
                                                  values={values}
                                                  arrValue={
                                                    residenceOptions?.filter(
                                                      (item) =>
                                                        item.label ===
                                                        values?.userDefinedCriteria?.Conditions?.[
                                                          index
                                                        ]?.SubCriteria?.Conditions?.[ruleIndex]
                                                          ?.Value
                                                    )[0]?.value

                                                    // values?.userDefinedCriteria?.Conditions?.[index]
                                                    //   ?.SubCriteria?.Conditions?.[ruleIndex]?.Value
                                                  }
                                                  errorValue={
                                                    errors?.userDefinedCriteria?.Conditions?.[index]
                                                      ?.SubCriteria?.Conditions?.[ruleIndex]?.Value
                                                  }
                                                  touchedValue={
                                                    touched?.userDefinedCriteria?.Conditions?.[
                                                      index
                                                    ]?.SubCriteria?.Conditions?.[ruleIndex]?.Value
                                                  }
                                                  setFieldValue={setFieldValue}
                                                  inputType={modalStatus}
                                                  disabled={modalStatus === 'View'}
                                                  required
                                                />
                                              )}
                                              {values?.userDefinedCriteria?.Conditions?.[
                                                index
                                              ]?.SubCriteria?.Conditions?.[
                                                ruleIndex
                                              ]?.Field?.includes('FICA') === true && (
                                                <div className='sm:col-span-1  mt-3'>
                                                  <CustomToggle
                                                    name={`userDefinedCriteria.Conditions[${index}].SubCriteria.Conditions[${ruleIndex}].Value`}
                                                    onChange={(event) => {
                                                      setFieldValue(
                                                        `userDefinedCriteria.Conditions[${index}].SubCriteria.Conditions[${ruleIndex}].Value`,
                                                        event?.target?.checked
                                                      );
                                                    }}
                                                    id={`userDefinedCriteria.Conditions[${index}].SubCriteria.Conditions[${ruleIndex}].Value`}
                                                    value={values}
                                                    errors={errors}
                                                    touched={touched}
                                                    checked={
                                                      values?.userDefinedCriteria?.Conditions?.[
                                                        index
                                                      ]?.SubCriteria?.Conditions?.[ruleIndex]?.Value
                                                    }
                                                  />
                                                </div>
                                              )}
                                            </div>
                                            {modalStatus !== 'View' &&
                                              values?.userDefinedCriteria?.Conditions?.[index]
                                                ?.SubCriteria?.Conditions?.length > 1 && (
                                                <div>
                                                  <span
                                                    className='material-symbols-outlined text-red-600 mt-3 ms-5 cursor-pointer'
                                                  onClick={() => {
                                                    descriptionData?.filter(
                                                      (item: DescriptionType) =>
                                                        item?.id !== `${index}${ruleIndex}`
                                                    );
                                                    arrayHelpers2.remove(ruleIndex);
                                                  }}>
                                                    delete
                                                  </span>
                                                </div>
                                              )}
                                          </div>
                                        </UserDefinedCriteria>
                                      </ListLineItem>
                                    )
                                  )}
                              </ListLineItemWrapper>
                            </div>
                          </>
                        );
                      }}
                    />
                  )
                )}
            </>
          );
        }}
      />
    </div>
  );
};

export default UserDefinedCriteriaComponent;
