import styled from 'styled-components';
import { getViewHeight, rem } from '../../utils/style';
import { COLORS } from './color';
import { SecondaryButton } from '../../components/button/button.styled';

interface Types {
  alignItems?: string;
  justifyContent?: string;
  leading?: string;
  bgColor?: string;
  img?: string;
  paddingInfo?: string;
}

export const getRgbaValue = (color: string, opacity?: number): string => {
  // const rgbaColor =
  //   parseInt(color.substring(1, 3), 16) +
  //   ',' +
  //   parseInt(color.substring(3, 5), 16) +
  //   ',' +
  //   parseInt(color.substring(5, 7), 16) +
  //   ',' +
  //   opacity;

  const rgbaColor = `${parseInt(color.substring(1, 3), 16)},${parseInt(
    color.substring(3, 5),
    16
  )},${parseInt(color.substring(5, 7), 16)},${opacity ?? ''}`;

  return `rgba(${rgbaColor})`;
};
export const Display = styled.div<Types>`
  display: flex;
  align-items: ${(props) => props.alignItems ?? 'center'};
  justify-content: ${(props) => (props.justifyContent != null ? props.justifyContent : 'center')};
`;

export const ImportBlock = styled.div`
  margin: ${rem(25)} 0 ${rem(18)} 0;
`;

export const InvitationBlock = styled.div`
  display: flex;
  gap: ${rem(14)};
  margin: ${rem(18)} 0 ${rem(10)} 0;
`;
export const HeadingMain = styled.h5<Types>`
  font-weight: 500;
  font-size: 12px;
  line-height: ${(props) => (props.leading ? props.leading : '15px')};
  color: ${(props) => (props.color ? props.color : `${COLORS.Black}`)};
  margin-bottom: 10px;

  @media (min-width: 768px) {
    font-size: 15px;
    line-height: 19px;
  }
`;

export const PageHeader = styled.div`
  margin-bottom: ${rem(25)};

  h3 {
    margin-bottom: ${rem(5)};
  }

  span {
    color: ${COLORS.Gray};
  }
`;

export const PageSearchHeader = styled.div`
  margin-bottom: ${rem(24)};
  display: flex;
  justify-content: space-between;
  align-items: center;

  .search-wrap {
    width: 100%;

    @media (min-width: 715px) {
      width: ${rem(350)};
    }
  }
`;

export const WrapperContainer = styled.div<Types>`
  border-radius: 10px;
  padding: 20px;
  width: 100%;
  position: relative;
  &:before {
    content: '';
    position: fixed;
    top: 67px;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    background: ${(props) => `url(${props.img != null ? props.img : ''})`};
    height: ${getViewHeight()};
  }

  @media (min-width: 768px) {
    padding: 37px;
  }

  @media (min-width: 1200px) {
    padding: ${(props) => (props.paddingInfo != null ? props.paddingInfo : '43px 120px')};
  }
`;

export const InputField = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rem(9)};

  @media (min-width: 512px) {
    flex-direction: row;
  }

  .input-file-container {
    position: relative;
    font-weight: 500;
    font-size: ${rem(14)};
    line-height: ${rem(18)};
    border: ${rem(1)} solid ${getRgbaValue(`${COLORS.Purple}`, 1)};

    position: relative;
    border-radius: ${rem(6)};
    padding: ${rem(6)} ${rem(8)};
    transition: all 300ms ease;

    color: ${COLORS.Purple};

    &:not(:disabled):hover {
      box-shadow: ${rem(0)} ${rem(4)} ${rem(6)} ${getRgbaValue(`${COLORS.Iris}`, 0.24)};
    }

    &:not(:disabled).active {
      box-shadow: ${rem(0)} ${rem(1)} ${rem(4)} ${getRgbaValue(`${COLORS.Iris}`, 0.44)};
    }

    &:disabled {
      border: 1px solid ${getRgbaValue(`${COLORS.Purple}`, 0.2)};
      color: ${getRgbaValue(`${COLORS.Purple}`, 0.5)};
    }
  }

  .input-file-trigger {
    display: block;
    font-weight: 500;
  }

  .input-file {
    position: absolute;
    top: 0;
    left: 0;
    color: #fff;
    width: 100%;
    height: 100%;
    opacity: 0;
    visibility: hidden;
  }
`;

export const Container = styled.div`
  width: calc(100% - 20px);
  margin: 0 auto;

  @media (min-width: 768px) {
    width: 720px;
  }

  @media (min-width: 992px) {
    width: 960px;
  }

  @media (min-width: 1200px) {
    width: calc(100% - 220px);
  }
`;

export const InnerContainer = styled.div<Types>`
  min-height: ${getViewHeight('67px')};
  position: relative;
  top: 67px;

  &:before {
    content: '';
    position: fixed;
    top: 67px;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    background: ${(props) => `url(${props.img != null ? props.img : ''})`};
    height: ${getViewHeight()};
    background-size: cover;
  }
`;

export const ErrorMessageWrapper = styled.div`
  font-weight: 400;
  font-size: ${rem(12)};
  line-height: ${rem(14)};
  color: ${COLORS.Gray};
  margin-top: ${rem(6)};
  transition: all 0.2s linear;
`;

export const TooltipWrapper = styled.div`
  .tooltip {
    position: relative;
    display: inline-block;
  }
  .tooltip .tooltiptext {
    visibility: hidden;
    position: absolute;
    z-index: 1;
  }

  .tooltip:hover .tooltiptext {
    visibility: visible;
  }
`;
export const GoBackBlock = styled.div`
  display: inline-flex;
  margin-bottom: 12px;
  cursor: pointer;
  .goBack {
    transition: all 0.2s linear;
  }
  .material-symbols-outlined {
    transition: all 0.2s linear;
    font-size: 20px;
    padding-right: 5px;
  }
  &:hover {
    color: ${COLORS.Purple};
    .material-symbols-outlined {
      transform: translateX(-2px);
    }
    .goBack {
      text-decoration: underline;
      color: ${COLORS.Purple};
    }
  }
`;
export const CardBlock = styled.div`
  background: ${COLORS.White};
  border: ${rem(1)} solid ${COLORS.CulturedWhite};
  border-radius: ${rem(6)};
  margin-top: ${rem(32)};
  margin-bottom: ${rem(12)};
`;

export const ParticipantCard = styled.div`
  padding: ${rem(9)} ${rem(12)} ${rem(42)} ${rem(8)};
  margin-top: ${rem(32)} 0 ${rem(12)} 0;
  .cardTagCount {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .card-form {
    padding: ${rem(26)} ${rem(12)} ${rem(0)} ${rem(24)};

    .employeeAddress {
      padding-bottom: ${rem(12)};
    }
  }
`;

export const ResetFilterWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: ${rem(6)};
  /* margin-top: ${rem(8)}; */
  cursor: pointer;
  gap: ${rem(2)};
  transition: all 0.2s linear;
  &,
  & span {
    color: ${COLORS.Gray};
  }

  &:hover,
  &:hover span {
    color: ${COLORS.Purple};
  }
`;

export const PasswordDetailsContainer = styled.div`
  padding: ${rem(16)};
  background-color: ${COLORS.Magnolia};
  border: ${rem(1)} solid rgba(123, 83, 209, 0.25);
  border-radius: ${rem(6)};
`;

export const PageNotFoundWrapper = styled.div`
  /* background-color: red; */
  height: ${getViewHeight('101px')};

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  span {
    font-size: ${rem(127)};
    font-weight: 400;
    color: ${COLORS.LightGray};
    margin-bottom: ${rem(35)};
  }

  .page-btn {
    @include ${SecondaryButton(COLORS.Iris)};
  }

  h3 {
    color: ${COLORS.Gray};
    /* font-weight: 600; */
    margin-bottom: ${rem(12)};
  }

  h5 {
    color: ${COLORS.Gray};
    margin-bottom: ${rem(24)};
    font-weight: 400;
  }
`;
