import { FieldArray } from 'formik';
import * as Yup from 'yup';

import { useEffect, useRef, useState } from 'react';
import Heading from '../../../../../components/typography';
import Button from '../../../../../components/button';
import {
  TaxBracketAccordingContainer,
  TaxBracketAccordingValue,
  TaxBracketHeaders,
  TaxBracketValue,
} from '../taxSetupStyled';
import { COLORS } from '../../../../../constants/style/color';
import InputText from '../../../../../components/input-text/inputText';
import InitialValuesForm from '../../../../../constants/initialValues';
import { CommaFormatted, CommaFormattedFloatPost } from '../../../../../utils/global';
import { Wealthlane_TaxService_Dtos_TaxItemDto_CreateTaxBracketDto } from '../../../../../services/wealthlane-tax-services';
import { isEmpty } from 'lodash';

interface TaxBracketType {
  metaData?: any;
  handleChange: any;
  errors: any;
  touched: any;
  values: any;
  setFieldValue: any;
  modalStatus: string;
  setValues?: any;
  setTouched?: any;
}

const TaxBracketComponent = ({
  metaData,
  handleChange,
  errors,
  touched,
  values,
  setFieldValue,
  modalStatus,
  setValues,
  setTouched,
}: TaxBracketType): JSX.Element => {
  const [hide, setHide] = useState<boolean>(false);
  const [deleted, setDeleted] = useState<boolean>(false);

  const sortFunction = (formIndex?: number) => {
    const sortedData = values?.taxBrackets?.sort(
      (a: any, b: any) =>
        Number(a.lowerLimit.replaceAll(',', '')) - Number(b.lowerLimit.replaceAll(',', ''))
    );

    const lowerLimit = values?.taxBrackets.map((item: any) => item?.lowerLimit.replaceAll(',', ''));
    const checkDuplicate = lowerLimit?.some((element: any, index: number) => {
      return lowerLimit.indexOf(element.replaceAll(',', '')) !== index;
    });

    setValues({
      ...values,
      taxBrackets: sortedData.map((a: any, i: any) => ({
        ...a,
        lowerLimit: CommaFormatted(sortedData[i]?.lowerLimit),
        upperLimit:
          sortedData[i + 1]?.lowerLimit !== undefined
            ? CommaFormatted(sortedData[i + 1]?.lowerLimit)
            : '',
      })),
    });
    setDeleted(false);
  };

  useEffect(() => {
    if (deleted) {
      sortFunction();
    }
  }, [deleted]);

  return (
    <>
      <TaxBracketAccordingContainer className='flex justify-between mt-5 mb-5'>
        <Heading variant='body' title={'Tax Bracket'} />
        <div
          className='flex'
          onClick={() => {
            setHide(!hide);
          }}
        >
          {!hide ? (
            <>
              <span className='material-symbols-outlined image-icon cursor-pointer'>
                expand_less
              </span>
            </>
          ) : (
            <>
              <span className='material-symbols-outlined image-icon cursor-pointer'>
                expand_more
              </span>
            </>
          )}
        </div>
      </TaxBracketAccordingContainer>
      <FieldArray
        name='taxBrackets'
        render={(arrayHelpers) => {
          return (
            <>
              {!hide && (
                // <motion.div
                //   initial={{ y: -2, opacity: 0 }}
                //   animate={{ y: 0, opacity: 1 }}
                //   transition={{
                //     delay: 0.5,
                //     duration: 0.3,
                //   }}>
                <>
                  <TaxBracketAccordingValue>
                    {/* <motion.div initial={{ y: 10 }} animate={{ y: 0 }}> */}

                    <TaxBracketHeaders className='grid grid-cols-12 gap-x-5'>
                      <div className='col-span-3'>
                        <Heading variant='body-xs' title={'Tax Rate (%)'} color={COLORS.Gray} />
                      </div>
                      <div className='col-span-4'>
                        <Heading
                          variant='body-xs'
                          title={'Lower Bracket Limit ($)'}
                          color={COLORS.Gray}
                        />
                      </div>
                      <div className='col-span-4'>
                        <Heading
                          variant='body-xs'
                          title={'Upper Bracket Limit ($)'}
                          color={COLORS.Gray}
                        />
                      </div>
                    </TaxBracketHeaders>

                    <div>
                      {Boolean(values.taxBrackets) &&
                        values.taxBrackets.length > 0 &&
                        values.taxBrackets.map(
                          (taxBracket: Record<string, number | string>, index: number) => {
                            return (
                              <TaxBracketValue
                                className='grid lg:grid-cols-12 sm:grid-cols-1 items-start gap-x-5'
                                key={index}
                              >
                                <div className='col-span-3'>
                                  <InputText
                                    type='text'
                                    name={`taxBrackets[${index}].taxRate`}
                                    errorValue={errors?.taxBrackets?.[index]?.taxRate}
                                    touchedValue={touched?.taxBrackets?.[index]?.taxRate}
                                    onChange={(e: any) => {
                                      handleChange(e);
                                      CommaFormattedFloatPost(
                                        e.target.value,
                                        `taxBrackets[${index}].taxRate`,
                                        setFieldValue
                                      );
                                    }}
                                    onFocus={(e: any) => {
                                      setFieldValue(
                                        `taxBrackets[${index}].taxRate`,
                                        e.target.value.replaceAll(',', '')
                                      );
                                    }}
                                    onBlur={(e: any) => {
                                      CommaFormattedFloatPost(
                                        e.target.value,
                                        `taxBrackets[${index}].taxRate`,
                                        setFieldValue
                                      );
                                    }}
                                    errors={errors}
                                    touched={touched}
                                    disabled={modalStatus === 'View'}
                                    required
                                  />
                                </div>
                                <div className='col-span-4'>
                                  <InputText
                                    type='text'
                                    name={`taxBrackets[${index}].lowerLimit`}
                                    errorValue={errors?.taxBrackets?.[index]?.lowerLimit}
                                    touchedValue={touched?.taxBrackets?.[index]?.lowerLimit}
                                    onChange={(e: any) => {
                                      handleChange(e);
                                      CommaFormatted(
                                        e.target.value,
                                        `taxBrackets[${index}].lowerLimit`,
                                        setFieldValue
                                      );
                                    }}
                                    onFocus={(e: any) => {
                                      setFieldValue(
                                        `taxBrackets[${index}].lowerLimit`,
                                        e.target.value.replaceAll(',', '')
                                      );
                                    }}
                                    onBlur={(e: any) => {
                                      sortFunction(index);
                                    }}
                                    errors={errors}
                                    touched={touched}
                                    disabled={modalStatus === 'View'}
                                    required
                                  />
                                </div>
                                <div className='col-span-4'>
                                  <InputText
                                    type='text'
                                    name={`taxBrackets[${index}].upperLimit`}
                                    errorValue={errors?.taxBrackets?.[index]?.upperLimit}
                                    touchedValue={touched?.taxBrackets?.[index]?.upperLimit}
                                    onChange={(e: any) => {
                                      handleChange(e);
                                      CommaFormatted(
                                        e.target.value,
                                        `taxBrackets[${index}].upperLimit`,
                                        setFieldValue
                                      );
                                    }}
                                    errors={errors}
                                    touched={touched}
                                    disabled={true}
                                    required
                                  />
                                </div>
                                {modalStatus !== 'View' && values.taxBrackets.length > 1 && (
                                  <div>
                                    <span
                                      className='material-symbols-outlined text-red-600 mt-3 cursor-pointer'
                                      onClick={() => {
                                        arrayHelpers.remove(index);
                                        setDeleted(true);
                                      }}
                                    >
                                      delete
                                    </span>
                                  </div>
                                )}
                              </TaxBracketValue>
                            );
                          }
                        )}

                      {modalStatus !== 'View' && (
                        <Button
                          title='Add Tax Bracket'
                          type='button'
                          variant='primary-dark'
                          icon='group_add'
                          btnClassName='w-full'
                          onClick={() => {
                            arrayHelpers.push(InitialValuesForm.taxSetup.taxBrackets[0]);
                            setTouched({
                              ...touched,
                              taxBrackets: [
                                ...touched?.taxBrackets,
                                {
                                  taxRate: true,
                                  lowerLimit: true,
                                  upperLimit: true,
                                },
                              ],
                            });
                          }}
                        />
                      )}
                    </div>
                    {/* </motion.div> */}
                  </TaxBracketAccordingValue>
                  {/* </motion.div> */}
                </>
              )}
            </>
          );
        }}
      />
    </>
  );
};

export default TaxBracketComponent;
