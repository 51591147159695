import styled from 'styled-components';
import { COLORS } from '../../constants/style/color';
import { Display } from '../../constants/style/common.styled';
import { rem } from '../../utils/style';

interface FilterDropdownType {
  size: 'xl' | 'lg' | 'md' | 'sm';
}

interface ReportFilterModalWrapperType {
  hasNotify?: boolean;
}
export const TableFilterContainer = styled(Display)`
  justify-content: space-between;

  .filter-left,
  .filter-right {
    gap: '14px';
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

export const FilterContainer = styled(Display)`
  /* gap: '14px'; */
`;

export const FilterDropdown = styled.div<FilterDropdownType>`
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 100%;
  left: 594px;
  top: 167px;
  margin-bottom: ${rem(20)};
  z-index: 999;
  background: ${COLORS.White};

  box-shadow: 0px 2px 20px ${COLORS.SoftShadow};
  border-radius: 6px;
  padding: ${rem(21)};

  @media (min-width: 992px) {
    width: ${(props) =>
      props.size === 'xl'
        ? '900px'
        : props.size === 'lg'
        ? '768px'
        : props.size === 'md'
        ? '600px'
        : '400px'};
  }

  &.nav-subMenu {
    left: 0;
    top: 73px;
    right: reset;
    justify-content: flex-start;

    a {
      width: 100%;
      justify-content: flex-start;
      color: ${COLORS.SpanishGray};
      font-size: ${rem(14)};
    }
  }

  .filter-label {
    font-weight: 500;
    line-height: ${rem(18)};
    letter-spacing: ${rem(4)};
    margin-bottom: ${rem(14)};
    text-transform: uppercase;
    color: ${COLORS.Black};
  }

  &.report-filter {
    position: initial;
    box-shadow: 0px 4px 15px rgba(29, 29, 29, 0.22);
    padding: ${rem(0)};
    margin: 50px auto;

    @media (min-width: 992px) {
      width: 60%;
    }

    .filter-label {
      color: ${COLORS.Silver};
      padding: ${rem(12)} ${rem(15)};
      border-bottom: 1px solid ${COLORS.CulturedWhite};
      margin-bottom: 0;
    }

    .report-button {
      margin: ${rem(18)} ${rem(15)} ${rem(15)} 0;
      gap: ${rem(9)};
    }
  }
`;
