import { Field } from 'formik';
import React from 'react';
import { Label } from '../input-text/input.styled';
import { type RadioButtonProps } from './customRadio';
import { RadioButtonWrapper } from './radioButton.styled';

const CustomCheckBox = React.memo((props: RadioButtonProps): JSX.Element => {
  const {
    label,
    name,
    className,
    errors,
    touched,
    labelClassName,
    required,
    value,
    disabled,
    id,
    arrValue,
    checked = false,
    ...rest
  } = props;
  return (
    <RadioButtonWrapper label={label} className={className}>
      <Field
        name={name}
        id={id}
        label={label}
        type='checkbox'
        disabled={disabled}
        checked={arrValue != null ? arrValue : checked}
        {...rest}
      />
      {label !== undefined && (
        <Label htmlFor={id} className={labelClassName}>
          {label} {required !== undefined && <span className='required'>*</span>}
        </Label>
      )}
    </RadioButtonWrapper>
  );
});

export default CustomCheckBox;
