import { useState } from 'react';
import { type ModalStatusType } from '../../types/common';
import Button from '../button';
import { ModelFooter } from './modal.styled';
import AlertPopup from '../alert-popup';

interface ModalFooterComponentType {
  onCloseModal: () => void;
  handleSubmit?: () => void;
  onEditModal?: () => void;
  loading?: boolean;
  editTitle?: string;
  addTitle?: string;
  modalStatus?: ModalStatusType;
  isEditShow?: boolean;
  onApprove?: () => void;
  onReject?: () => void;
  onReview?: () => void;
  isExport?: boolean;
  icon?: string;
  isLoading?: boolean;
  confirmValues?: boolean;
  urlName?: string;
  titleCollection?: Record<string, string>;
  valueEdited?: boolean;
  approverView?: boolean;
}

const ModalFooterComponent = ({
  modalStatus,
  loading,
  editTitle,
  addTitle,
  onCloseModal,
  handleSubmit,
  onEditModal,
  isEditShow = true,
  onApprove,
  onReject,
  onReview,
  isExport,
  isLoading,
  confirmValues = false,
  icon,
  urlName,
  titleCollection,
  valueEdited = true,
  approverView = false,
}: ModalFooterComponentType): JSX.Element => {
  const [openAlert, setOpenAlert] = useState<boolean>(false);
  return (
    <ModelFooter>
      <div className='footer-button'>
        <Button
          title={
            titleCollection?.cancelTitle != null && (modalStatus?.includes('Review') ?? false)
              ? titleCollection?.cancelTitle
              : 'Cancel'
          }
          type='button'
          variant='primary-dark'
          onClick={() => {
            modalStatus !== 'View'
              ? valueEdited
                ? onCloseModal()
                : setOpenAlert(true)
              : onCloseModal();
          }}
        />
        {isEditShow && modalStatus === 'View' && (
          <Button
            title={`Edit ${editTitle ?? ''}`}
            loading={loading}
            as='Edit'
            hasId={urlName}
            type='submit'
            variant='primary-outline'
            icon='edit'
            onClick={onEditModal}
          />
        )}
        {(isExport ?? false) && (
          <Button
            title={`Export ${editTitle ?? ''}`}
            type='button'
            variant={isExport ?? false ? 'primary' : 'primary-outline'}
            icon={icon ?? 'edit'}
            loading={isLoading}
            onClick={handleSubmit}
          />
        )}
        {(modalStatus === 'Edit' || modalStatus === 'Add') && !confirmValues && (
          <Button
            title={
              titleCollection?.reviewTitle != null
                ? titleCollection?.reviewTitle
                : `Save ${addTitle ?? ''}`
            }
            loading={loading}
            type='submit'
            variant='primary'
            disabled={modalStatus === 'Edit' && valueEdited}
            icon='check_circle'
          />
        )}
        {approverView && (
          <div className='flex gap-x-5'>
            <Button
              onClick={onApprove}
              title={`Approve`}
              loading={loading}
              type='button'
              variant={'approve'}
              icon=''
            />
            <Button
              onClick={onReject}
              title={`Reject`}
              loading={loading}
              type='button'
              variant={'primary-danger'}
              icon=''
            />
          </div>
        )}
        {modalStatus === 'New Approval' && (
          <div className='flex gap-x-5'>
            <Button
              onClick={onApprove}
              title={`Approve`}
              loading={loading}
              type='button'
              variant='primary'
              icon=''
            />
            <Button
              onClick={onReject}
              title={`Reject`}
              loading={loading}
              type='button'
              variant='primary-outline'
              icon=''
            />
          </div>
        )}
        {confirmValues && (
          <Button
            title={`Save`}
            loading={loading}
            type='submit'
            variant='primary'
            icon='check_circle'
          />
        )}
        {/* {modalStatus === 'Review' && (
          <>
            {!confirmValues && (
              <Button
                title={`Review & Confirm`}
                loading={loading}
                type='submit'
                variant='primary'
                icon='check_circle'
              />
            )}
            {confirmValues && (
              <Button
                onClick={onReview}
                title={`Save`}
                loading={loading}
                type='button'
                variant='primary'
                icon='check_circle'
              />
            )}
          </>
        )} */}
      </div>
      {openAlert && (
        <AlertPopup
          isShow={openAlert}
          closeModal={() => {
            setOpenAlert(false);
          }}
          customTitle={'All data will be lost if you close. Are you sure you want to close?'}
          handleSubmit={async () => {
            setOpenAlert(false);
            onCloseModal();
          }}
          // loading={loading}
        />
      )}
    </ModelFooter>
  );
};

export default ModalFooterComponent;
