import { UseQueryResult, useQuery } from 'react-query';
import { JurisdictionLevelService } from '../../services/wealthlane-tax-services';
import { OptionType } from '../../types/common';

const useGetTaxJuridictionLevel = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>
): UseQueryResult<any> => {
  const juridictionLevelOptions = useQuery(
    ['getJuridictionLevel'],
    async () => {
      return await handleRequest(
        JurisdictionLevelService.getApiTaxApiAppJurisdictionLevelJuridctionLevelListAync()
      );
    },
    {
      select: (data) => {
        const juridctionLevel = data?.map((item) => {
          return {
            label: item.name || '',
            value: item.id || '',
            filterCriteria: item.filterCriteria || '',
            preFilterCriteria: item.preFilterCriteria || '',
            metadata: item.metadata,
            isEnabled: item.isEnabled || '',
          };
        });

        return juridctionLevel;
      },
    }
  );

  return juridictionLevelOptions;
};

export default useGetTaxJuridictionLevel;
