/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_AwardService_Dtos_RSUAward_AdminDashboardGrantList_Wealthlane_AwardService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null } from '../models/Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_AwardService_Dtos_RSUAward_AdminDashboardGrantList_Wealthlane_AwardService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null';
import type { Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_AwardService_Dtos_RSUAward_RsuAwardListDto_Wealthlane_AwardService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null } from '../models/Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_AwardService_Dtos_RSUAward_RsuAwardListDto_Wealthlane_AwardService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null';
import type { Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_AwardService_Dtos_RSUAward_UpcomingVestingGrantsDto_Wealthlane_AwardService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null } from '../models/Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_AwardService_Dtos_RSUAward_UpcomingVestingGrantsDto_Wealthlane_AwardService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null';
import type { Wealthlane_AwardService_Dtos_Response_ResponseDto } from '../models/Wealthlane_AwardService_Dtos_Response_ResponseDto';
import type { Wealthlane_AwardService_Dtos_RSUAward_AdminDashboardGrants } from '../models/Wealthlane_AwardService_Dtos_RSUAward_AdminDashboardGrants';
import type { Wealthlane_AwardService_Dtos_RSUAward_AdminDashboardGrantView } from '../models/Wealthlane_AwardService_Dtos_RSUAward_AdminDashboardGrantView';
import type { Wealthlane_AwardService_Dtos_RSUAward_AdminDashboardGrantViewRequestDto } from '../models/Wealthlane_AwardService_Dtos_RSUAward_AdminDashboardGrantViewRequestDto';
import type { Wealthlane_AwardService_Dtos_RSUAward_AwardedParticipants } from '../models/Wealthlane_AwardService_Dtos_RSUAward_AwardedParticipants';
import type { Wealthlane_AwardService_Dtos_RSUAward_AwardTransactionDetails } from '../models/Wealthlane_AwardService_Dtos_RSUAward_AwardTransactionDetails';
import type { Wealthlane_AwardService_Dtos_RSUAward_AwardTransactionDetailsByTransactionIdDTO } from '../models/Wealthlane_AwardService_Dtos_RSUAward_AwardTransactionDetailsByTransactionIdDTO';
import type { Wealthlane_AwardService_Dtos_RSUAward_GetGrantDetailsDto } from '../models/Wealthlane_AwardService_Dtos_RSUAward_GetGrantDetailsDto';
import type { Wealthlane_AwardService_Dtos_RSUAward_GetParticipantDto } from '../models/Wealthlane_AwardService_Dtos_RSUAward_GetParticipantDto';
import type { Wealthlane_AwardService_Dtos_RSUAward_GetStatusDTO } from '../models/Wealthlane_AwardService_Dtos_RSUAward_GetStatusDTO';
import type { Wealthlane_AwardService_Dtos_RSUAward_GetValuesDTO } from '../models/Wealthlane_AwardService_Dtos_RSUAward_GetValuesDTO';
import type { Wealthlane_AwardService_Dtos_RSUAward_RSUAwardBlobDto } from '../models/Wealthlane_AwardService_Dtos_RSUAward_RSUAwardBlobDto';
import type { Wealthlane_AwardService_Dtos_RSUAward_RSUAwardBlobRequestDto } from '../models/Wealthlane_AwardService_Dtos_RSUAward_RSUAwardBlobRequestDto';
import type { Wealthlane_AwardService_Dtos_RSUAward_RSUAwardImportDataDto } from '../models/Wealthlane_AwardService_Dtos_RSUAward_RSUAwardImportDataDto';
import type { Wealthlane_AwardService_Dtos_RSUAward_RSUAwardImportResponseDTO } from '../models/Wealthlane_AwardService_Dtos_RSUAward_RSUAwardImportResponseDTO';
import type { Wealthlane_AwardService_Dtos_RSUAward_RsuAwardNumberDto } from '../models/Wealthlane_AwardService_Dtos_RSUAward_RsuAwardNumberDto';
import type { Wealthlane_AwardService_Dtos_RSUAward_RSUAwardOverviewDto } from '../models/Wealthlane_AwardService_Dtos_RSUAward_RSUAwardOverviewDto';
import type { Wealthlane_AwardService_Dtos_RSUAward_RSUAwardSampleFileBlobDto } from '../models/Wealthlane_AwardService_Dtos_RSUAward_RSUAwardSampleFileBlobDto';
import type { Wealthlane_AwardService_Dtos_RSUAward_RsuAwardSettlementHistory } from '../models/Wealthlane_AwardService_Dtos_RSUAward_RsuAwardSettlementHistory';
import type { Wealthlane_AwardService_Dtos_RSUAward_RSUAwardUpdateImportDataDto } from '../models/Wealthlane_AwardService_Dtos_RSUAward_RSUAwardUpdateImportDataDto';
import type { Wealthlane_AwardService_Dtos_RSUAward_RSUAwardUpdateImportResponseDTO } from '../models/Wealthlane_AwardService_Dtos_RSUAward_RSUAwardUpdateImportResponseDTO';
import type { Wealthlane_AwardService_Dtos_RSUAward_RSUAwardVestingScheduleDto } from '../models/Wealthlane_AwardService_Dtos_RSUAward_RSUAwardVestingScheduleDto';
import type { Wealthlane_AwardService_Dtos_RSUAward_RSUDocumentDto } from '../models/Wealthlane_AwardService_Dtos_RSUAward_RSUDocumentDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class RsuAwardService {

    /**
     * @param formData 
     * @returns Wealthlane_AwardService_Dtos_RSUAward_RSUAwardImportResponseDTO Success
     * @throws ApiError
     */
    public static postApiAwardApiAppRSUAwardImportRsuAwards(
formData?: {
File?: Blob;
},
): CancelablePromise<Wealthlane_AwardService_Dtos_RSUAward_RSUAwardImportResponseDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/award/api/app/r-sUAward/import-rsu-awards',
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param formData 
     * @returns Wealthlane_AwardService_Dtos_RSUAward_RSUAwardUpdateImportResponseDTO Success
     * @throws ApiError
     */
    public static postApiAwardApiAppRSUAwardImportRsuAwardsForUpdate(
formData?: {
File?: Blob;
},
): CancelablePromise<Wealthlane_AwardService_Dtos_RSUAward_RSUAwardUpdateImportResponseDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/award/api/app/r-sUAward/import-rsu-awards-for-update',
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_AwardService_Dtos_RSUAward_GetValuesDTO Success
     * @throws ApiError
     */
    public static getApiAwardApiAppRSUAwardGrants(): CancelablePromise<Array<Wealthlane_AwardService_Dtos_RSUAward_GetValuesDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/r-sUAward/grants',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_AwardService_Dtos_RSUAward_GetValuesDTO Success
     * @throws ApiError
     */
    public static getApiAwardApiAppRSUAwardPlanTypes(): CancelablePromise<Array<Wealthlane_AwardService_Dtos_RSUAward_GetValuesDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/r-sUAward/plan-types',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_AwardService_Dtos_RSUAward_GetValuesDTO Success
     * @throws ApiError
     */
    public static getApiAwardApiAppRSUAwardVestingSchedule(): CancelablePromise<Array<Wealthlane_AwardService_Dtos_RSUAward_GetValuesDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/r-sUAward/vesting-schedule',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Wealthlane_AwardService_Dtos_RSUAward_RSUAwardImportDataDto Success
     * @throws ApiError
     */
    public static postApiAwardApiAppRSUAwardRSUAwards(
requestBody?: Array<Wealthlane_AwardService_Dtos_RSUAward_RSUAwardImportDataDto>,
): CancelablePromise<Array<Wealthlane_AwardService_Dtos_RSUAward_RSUAwardImportDataDto>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/award/api/app/r-sUAward/r-sUAwards',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Wealthlane_AwardService_Dtos_RSUAward_RSUAwardUpdateImportDataDto Success
     * @throws ApiError
     */
    public static putApiAwardApiAppRSUAwardRSUAwardsInBulk(
requestBody?: Array<Wealthlane_AwardService_Dtos_RSUAward_RSUAwardUpdateImportDataDto>,
): CancelablePromise<Array<Wealthlane_AwardService_Dtos_RSUAward_RSUAwardUpdateImportDataDto>> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/award/api/app/r-sUAward/r-sUAwards-in-bulk',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Wealthlane_AwardService_Dtos_RSUAward_RSUAwardImportDataDto Success
     * @throws ApiError
     */
    public static putApiAwardApiAppRSUAwardRSUAward(
requestBody?: Wealthlane_AwardService_Dtos_RSUAward_RSUAwardImportDataDto,
): CancelablePromise<Wealthlane_AwardService_Dtos_RSUAward_RSUAwardImportDataDto> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/award/api/app/r-sUAward/r-sUAward',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param awardId 
     * @returns Wealthlane_AwardService_Dtos_RSUAward_RSUAwardImportDataDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppRSUAwardRSUAwardForUpdateById(
awardId: string,
): CancelablePromise<Wealthlane_AwardService_Dtos_RSUAward_RSUAwardImportDataDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/r-sUAward/r-sUAward-for-update-by-id/{awardId}',
            path: {
                'awardId': awardId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param approvalStatusIds 
     * @param fromDate 
     * @param toDate 
     * @param employeeStatuses 
     * @param rsuStatus 
     * @param rsuAcceptance 
     * @param fileName 
     * @param createdFromDate 
     * @param createdToDate 
     * @param filter 
     * @param sorting 
     * @param skipCount 
     * @param maxResultCount 
     * @returns Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_AwardService_Dtos_RSUAward_RsuAwardListDto_Wealthlane_AwardService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null Success
     * @throws ApiError
     */
    public static getApiAwardApiAppRSUAwardRSUAwardList(
approvalStatusIds?: Array<number>,
fromDate?: string,
toDate?: string,
employeeStatuses?: Array<number>,
rsuStatus?: number,
rsuAcceptance?: number,
fileName?: string,
createdFromDate?: string,
createdToDate?: string,
filter?: string,
sorting?: string,
skipCount?: number,
maxResultCount?: number,
): CancelablePromise<Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_AwardService_Dtos_RSUAward_RsuAwardListDto_Wealthlane_AwardService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/r-sUAward/r-sUAward-list',
            query: {
                'ApprovalStatusIds': approvalStatusIds,
                'FromDate': fromDate,
                'ToDate': toDate,
                'EmployeeStatuses': employeeStatuses,
                'RSUStatus': rsuStatus,
                'RSUAcceptance': rsuAcceptance,
                'FileName': fileName,
                'CreatedFromDate': createdFromDate,
                'CreatedToDate': createdToDate,
                'Filter': filter,
                'Sorting': sorting,
                'SkipCount': skipCount,
                'MaxResultCount': maxResultCount,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param fileName 
     * @param awardIds 
     * @param createdFromDate 
     * @param createdToDate 
     * @param filter 
     * @param sorting 
     * @param skipCount 
     * @param maxResultCount 
     * @returns Wealthlane_AwardService_Dtos_RSUAward_RSUAwardBlobDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppRSUAwardExportRSuAward(
fileName: string,
awardIds?: Array<string>,
createdFromDate?: string,
createdToDate?: string,
filter?: string,
sorting?: string,
skipCount?: number,
maxResultCount?: number,
): CancelablePromise<Wealthlane_AwardService_Dtos_RSUAward_RSUAwardBlobDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/r-sUAward/export-rSUAward',
            query: {
                'FileName': fileName,
                'AwardIds': awardIds,
                'CreatedFromDate': createdFromDate,
                'CreatedToDate': createdToDate,
                'Filter': filter,
                'Sorting': sorting,
                'SkipCount': skipCount,
                'MaxResultCount': maxResultCount,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param fromDate 
     * @param toDate 
     * @returns string Success
     * @throws ApiError
     */
    public static getApiAwardApiAppRSUAwardRSUFileNameList(
fromDate?: string,
toDate?: string,
): CancelablePromise<Array<string>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/r-sUAward/r-sUFile-name-list',
            query: {
                'fromDate': fromDate,
                'toDate': toDate,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param formData 
     * @returns boolean Success
     * @throws ApiError
     */
    public static postApiAwardApiAppRSUAwardUploadRSuAwardSample(
formData?: {
document?: Blob;
},
): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/award/api/app/r-sUAward/upload-rSUAward-sample',
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_AwardService_Dtos_RSUAward_RSUAwardSampleFileBlobDto Success
     * @throws ApiError
     */
    public static postApiAwardApiAppRSUAwardDownloadRSuAwardSample(): CancelablePromise<Wealthlane_AwardService_Dtos_RSUAward_RSUAwardSampleFileBlobDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/award/api/app/r-sUAward/download-rSUAward-sample',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_AwardService_Dtos_RSUAward_GetStatusDTO Success
     * @throws ApiError
     */
    public static getApiAwardApiAppRSUAwardRSUAwardStatuses(): CancelablePromise<Array<Wealthlane_AwardService_Dtos_RSUAward_GetStatusDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/r-sUAward/r-sUAward-statuses',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_AwardService_Dtos_RSUAward_GetStatusDTO Success
     * @throws ApiError
     */
    public static getApiAwardApiAppRSUAwardEmployeeStatuses(): CancelablePromise<Array<Wealthlane_AwardService_Dtos_RSUAward_GetStatusDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/r-sUAward/employee-statuses',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_AwardService_Dtos_RSUAward_GetStatusDTO Success
     * @throws ApiError
     */
    public static getApiAwardApiAppRSUAwardRSUStatuses(): CancelablePromise<Array<Wealthlane_AwardService_Dtos_RSUAward_GetStatusDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/r-sUAward/r-sUStatuses',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_AwardService_Dtos_RSUAward_GetStatusDTO Success
     * @throws ApiError
     */
    public static getApiAwardApiAppRSUAwardRSUAcceptances(): CancelablePromise<Array<Wealthlane_AwardService_Dtos_RSUAward_GetStatusDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/r-sUAward/r-sUAcceptances',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_AwardService_Dtos_RSUAward_GetParticipantDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppRSUAwardEmployeeIds(): CancelablePromise<Array<Wealthlane_AwardService_Dtos_RSUAward_GetParticipantDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/r-sUAward/employee-ids',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_AwardService_Dtos_RSUAward_GetGrantDetailsDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppRSUAwardGrantDetails(): CancelablePromise<Array<Wealthlane_AwardService_Dtos_RSUAward_GetGrantDetailsDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/r-sUAward/grant-details',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param rsuAwardId 
     * @returns Wealthlane_AwardService_Dtos_RSUAward_RSUAwardOverviewDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppRSUAwardRSUAwardOverview(
rsuAwardId: string,
): CancelablePromise<Wealthlane_AwardService_Dtos_RSUAward_RSUAwardOverviewDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/r-sUAward/r-sUAward-overview/{RSUAwardId}',
            path: {
                'RSUAwardId': rsuAwardId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param rsuAwardId 
     * @returns Wealthlane_AwardService_Dtos_RSUAward_RSUAwardVestingScheduleDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppRSUAwardRSUAwardVestingSchedule(
rsuAwardId: string,
): CancelablePromise<Wealthlane_AwardService_Dtos_RSUAward_RSUAwardVestingScheduleDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/r-sUAward/r-sUAward-vesting-schedule/{RSUAwardId}',
            path: {
                'RSUAwardId': rsuAwardId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param rsuAwardId 
     * @returns Wealthlane_AwardService_Dtos_RSUAward_RSUDocumentDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppRSUAwardRSUAwardDocuments(
rsuAwardId: string,
): CancelablePromise<Array<Wealthlane_AwardService_Dtos_RSUAward_RSUDocumentDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/r-sUAward/r-sUAward-documents/{RSUAwardId}',
            path: {
                'RSUAwardId': rsuAwardId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Wealthlane_AwardService_Dtos_RSUAward_RSUAwardBlobDto Success
     * @throws ApiError
     */
    public static postApiAwardApiAppRSUAwardDownloadDocument(
requestBody?: Wealthlane_AwardService_Dtos_RSUAward_RSUAwardBlobRequestDto,
): CancelablePromise<Wealthlane_AwardService_Dtos_RSUAward_RSUAwardBlobDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/award/api/app/r-sUAward/download-document',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param rsuAwardId 
     * @returns string Success
     * @throws ApiError
     */
    public static getApiAwardApiAppRSUAwardRSUAwardStatusById(
rsuAwardId: string,
): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/r-sUAward/r-sUAward-status-by-id/{RSUAwardId}',
            path: {
                'RSUAwardId': rsuAwardId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param rsuAwardId 
     * @returns string Success
     * @throws ApiError
     */
    public static postApiAwardApiAppRSUAwardExportRSuAwardVestingSchedule(
rsuAwardId: string,
): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/award/api/app/r-sUAward/export-rSUAward-vesting-schedule/{RSUAwardId}',
            path: {
                'RSUAwardId': rsuAwardId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Wealthlane_AwardService_Dtos_Response_ResponseDto Success
     * @throws ApiError
     */
    public static postApiAwardApiAppRSUAwardSendParticipantsSignUpLinks(
requestBody?: Array<string>,
): CancelablePromise<Wealthlane_AwardService_Dtos_Response_ResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/award/api/app/r-sUAward/send-participants-sign-up-links',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param rsuAwardId 
     * @returns Wealthlane_AwardService_Dtos_RSUAward_RsuAwardSettlementHistory Success
     * @throws ApiError
     */
    public static getApiAwardApiAppRSUAwardRSUAwardSettlementHistory(
rsuAwardId: string,
): CancelablePromise<Wealthlane_AwardService_Dtos_RSUAward_RsuAwardSettlementHistory> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/r-sUAward/r-sUAward-settlement-history/{RSUAwardId}',
            path: {
                'RSUAwardId': rsuAwardId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param rsuAwardId 
     * @returns Wealthlane_AwardService_Dtos_RSUAward_RsuAwardNumberDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppRSUAwardRSUAwardNumbers(
rsuAwardId: string,
): CancelablePromise<Array<Wealthlane_AwardService_Dtos_RSUAward_RsuAwardNumberDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/r-sUAward/r-sUAward-numbers/{RSUAwardId}',
            path: {
                'RSUAwardId': rsuAwardId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param filter 
     * @param sorting 
     * @param skipCount 
     * @param maxResultCount 
     * @returns Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_AwardService_Dtos_RSUAward_UpcomingVestingGrantsDto_Wealthlane_AwardService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null Success
     * @throws ApiError
     */
    public static getApiAwardApiAppRSUAwardUpcomingVestingGrants(
filter?: string,
sorting?: string,
skipCount?: number,
maxResultCount?: number,
): CancelablePromise<Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_AwardService_Dtos_RSUAward_UpcomingVestingGrantsDto_Wealthlane_AwardService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/r-sUAward/upcoming-vesting-grants',
            query: {
                'Filter': filter,
                'Sorting': sorting,
                'SkipCount': skipCount,
                'MaxResultCount': maxResultCount,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param vestingDate 
     * @param grantId 
     * @returns Wealthlane_AwardService_Dtos_RSUAward_AdminDashboardGrantView Success
     * @throws ApiError
     */
    public static getApiAwardApiAppRSUAwardAdminDashboardGrantView(
vestingDate?: string,
grantId?: string,
): CancelablePromise<Wealthlane_AwardService_Dtos_RSUAward_AdminDashboardGrantView> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/r-sUAward/admin-dashboard-grant-view',
            query: {
                'VestingDate': vestingDate,
                'GrantId': grantId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param grantId 
     * @returns Wealthlane_AwardService_Dtos_RSUAward_AdminDashboardGrantView Success
     * @throws ApiError
     */
    public static getApiAwardApiAppRSUAwardAdminDashboardGrantViewOld(
grantId: string,
): CancelablePromise<Wealthlane_AwardService_Dtos_RSUAward_AdminDashboardGrantView> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/r-sUAward/admin-dashboard-grant-view-old/{grantId}',
            path: {
                'grantId': grantId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_AwardService_Dtos_RSUAward_AwardedParticipants Success
     * @throws ApiError
     */
    public static getApiAwardApiAppRSUAwardAwardedParticipants(): CancelablePromise<Array<Wealthlane_AwardService_Dtos_RSUAward_AwardedParticipants>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/r-sUAward/awarded-participants',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_AwardService_Dtos_RSUAward_AdminDashboardGrants Success
     * @throws ApiError
     */
    public static getApiAwardApiAppRSUAwardUpcomingGrantList(): CancelablePromise<Array<Wealthlane_AwardService_Dtos_RSUAward_AdminDashboardGrants>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/r-sUAward/upcoming-grant-list',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param vestingDate 
     * @param grantId 
     * @param filter 
     * @param sorting 
     * @param skipCount 
     * @param maxResultCount 
     * @returns Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_AwardService_Dtos_RSUAward_AdminDashboardGrantList_Wealthlane_AwardService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null Success
     * @throws ApiError
     */
    public static getApiAwardApiAppRSUAwardAdminDashboardGrantList(
vestingDate?: string,
grantId?: string,
filter?: string,
sorting?: string,
skipCount?: number,
maxResultCount?: number,
): CancelablePromise<Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_AwardService_Dtos_RSUAward_AdminDashboardGrantList_Wealthlane_AwardService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/r-sUAward/admin-dashboard-grant-list',
            query: {
                'VestingDate': vestingDate,
                'GrantId': grantId,
                'Filter': filter,
                'Sorting': sorting,
                'SkipCount': skipCount,
                'MaxResultCount': maxResultCount,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param id 
     * @returns Wealthlane_AwardService_Dtos_Response_ResponseDto Success
     * @throws ApiError
     */
    public static deleteApiAwardApiAppRSUAwardRsuAwardById(
id: string,
): CancelablePromise<Wealthlane_AwardService_Dtos_Response_ResponseDto> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/award/api/app/r-sUAward/rsu-award-by-id/{Id}',
            path: {
                'Id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param id 
     * @returns Wealthlane_AwardService_Dtos_Response_ResponseDto Success
     * @throws ApiError
     */
    public static postApiAwardApiAppRSUAwardCancelRsuAwardById(
id: string,
): CancelablePromise<Wealthlane_AwardService_Dtos_Response_ResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/award/api/app/r-sUAward/cancel-rsu-award-by-id/{Id}',
            path: {
                'Id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns string Success
     * @throws ApiError
     */
    public static postApiAwardApiAppRSUAwardExportAdminDashboardGrantList(
requestBody?: Wealthlane_AwardService_Dtos_RSUAward_AdminDashboardGrantViewRequestDto,
): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/award/api/app/r-sUAward/export-admin-dashboard-grant-list',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param grantId 
     * @param input 
     * @returns Wealthlane_AwardService_Dtos_RSUAward_AwardTransactionDetails Success
     * @throws ApiError
     */
    public static getApiAwardApiAppRSUAwardTransactionForTax(
grantId: string,
input?: string,
): CancelablePromise<Array<Wealthlane_AwardService_Dtos_RSUAward_AwardTransactionDetails>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/r-sUAward/transaction-for-tax/{GrantId}',
            path: {
                'GrantId': grantId,
            },
            query: {
                'input': input,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param transactionId 
     * @returns Wealthlane_AwardService_Dtos_RSUAward_AwardTransactionDetailsByTransactionIdDTO Success
     * @throws ApiError
     */
    public static getApiAwardApiAppRSUAwardTransactionDetailByTransactionId(
transactionId: string,
): CancelablePromise<Wealthlane_AwardService_Dtos_RSUAward_AwardTransactionDetailsByTransactionIdDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/r-sUAward/transaction-detail-by-transaction-id/{TransactionId}',
            path: {
                'TransactionId': transactionId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

}
