import { rem } from '../../../utils/style';
import styled from 'styled-components';
import { COLORS } from '../../../constants/style/color';

export const ConfigContainer = styled.div`
  background: ${COLORS.White};
  padding: ${rem(32)} 0;
  border: ${rem(1)} solid ${COLORS.CulturedWhite};
  border-radius: ${rem(6)};

  .left-space {
    padding-left: ${rem(32)};

    @media (max-width: 1024px) {
      padding-right: ${rem(32)};
    }
  }

  .right-space {
    padding-right: ${rem(32)};

    @media (max-width: 1024px) {
      padding-left: ${rem(32)};
    }
  }
`;

export const ApproverSelectContainer = styled.div`
  label {
    font-weight: 500;
  }
`;
export const ApproverSelectGroup = styled.div`
  background: ${COLORS.White};
  border: 1px solid ${COLORS.LightGray};
  border-radius: 6px;
  padding: ${rem(4)};
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  min-height: ${rem(40)};
`;

export const ApprovalConfigLoader = styled.div`
  height: 120vh;
  width: 120%;

  display: flex;
  justify-content: center;
  align-items: center;

  position: fixed;
  top: -40px;
  left: -55px;
  z-index: 10;
  background: rgb(178 170 170 / 10%);
`;
