import { type UseQueryResult, useQuery } from 'react-query';
import { VendorUserService } from '../../services/wealthlane-administration-services';

const useGetUserStatusList = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>
): UseQueryResult<
  | Array<{
      label: string;
      value: string | number;
    }>
  | undefined,
  unknown
> => {
  const getCustomerUserStatusList = useQuery(
    ['getUserStatusList'],
    async () => {
      return await handleRequest(
        VendorUserService.getApiAdministrationApiAppVendorUserVendorUserStatuses()
      );
    },
    {
      select: (data) => {
        const options = data?.map((item) => {
          return {
            label: item.name ?? '',
            value: item.id ?? '',
          };
        });

        const sortOptions = options?.concat({ label: 'All', value: 0 }).sort(function (a, b) {
          return a.label.localeCompare(b.label);
        });
        return sortOptions;
      },
    }
  );

  return getCustomerUserStatusList;
};

export default useGetUserStatusList;
