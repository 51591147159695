import { UseQueryResult, useQuery } from 'react-query';
import { CountryService } from '../../services/wealthlane-tax-services';
import { OptionType } from '../../types/common';

const useGetTaxCountryOptions = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>
): UseQueryResult<any> => {
  const taxCountriesOptions = useQuery(
    ['getTaxCountries'],
    async () => {
      return await handleRequest(CountryService.getApiTaxApiAppCountryCountries());
    },
    {
      select: (data) => {
        if (data) {
          data.unshift(
            data.splice(
              data.findIndex((item) => item.name === 'United States Of America'),
              1
            )[0]
          );
          return data?.map((item) => {
            return {
              label: item.name || '',
              value: item.id || '',
              countryCodeValue: item.countryCallingCode || '',
              isOtherCountry: item.isOtherCountry || false,
              abbreviation: item.abbreviation,
            };
          });
        }
      },
    }
  );

  return taxCountriesOptions;
};

export default useGetTaxCountryOptions;
