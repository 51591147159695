import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getFilteredPermissionData } from '.';
import { ComponentsNames } from '../constants/innerRoutesList';
import { selectAuthRoutesList } from '../pages/configuration/routeSlice';
import { type ModalStatusType } from '../types/common';
import { isEmpty } from 'lodash';

interface Props {
  type?: ModalStatusType;
  urlName?: string;
  children: React.ReactNode;
}

// Checks Permission
const CheckPermissions = ({ type, urlName, children }: Props): JSX.Element => {
  const location = useLocation();
  const url = location.pathname.split('/');

  const userAuthList = useSelector(selectAuthRoutesList);
  const { nonFieldLevelPermissionsGroups } = getFilteredPermissionData(
    userAuthList?.auth?.grantedPolicies ?? {}
  );
  const getUrlName =
    urlName !== undefined
      ? ComponentsNames?.find((item) => item.url === urlName)?.name ?? ''
      : ComponentsNames?.find((item) => item.url === url[url.length - 1])?.name ?? '';

  const hasPermission =
    getUrlName &&
    nonFieldLevelPermissionsGroups[getUrlName]?.some(
      (item: { text: string }) => item.text === type
    );

  return <>{!isEmpty(type) ? (hasPermission ? children : null) : children}</>;
};

export default CheckPermissions;
