import { useEffect, useState } from 'react';
import CustomDropdown from '../../../components/custom-dropdown';
import Spinners from '../../../components/spinner';
import Heading from '../../../components/typography/index';
import {
  useGetApprovalProcessList,
  useGetAllVendorApprovalProcess,
  usePostVendorApprovalConfig,
} from '../../../hooks';
import { type UserType } from '../../../types/common';

import Lozenge from '../../../components/lozenge/index';
import { Label } from '../../../components/input-text/input.styled';
import { useSelector } from 'react-redux';
import { selectAuthRoutesList } from '../../configuration/routeSlice';
import { SettingSystemUserHeader } from '../system-users/systemUser.styled';
import { FormDivider } from '../system-roles/systemRole.styled';
import useGetVendorUserList from '../../../hooks/get/useGetVendorUserList';
import useWealthlaneAdministrationApi from '../../../hooks/useWealthlaneAdministrationApi';
import {
  ConfigContainer,
  ApproverSelectContainer,
  ApproverSelectGroup,
  ApprovalConfigLoader,
} from './approvalConfig.styled';
import { areArraysEqual } from '../../../utils/global';

interface ApprovalConfigType {
  approvalProcessId: string;
  name: string;
  permission: string;
  vendorUserDtos: Array<string | undefined>;
}

const ApprovalConfig = (): JSX.Element => {
  const [initialValues, setInitialValues] = useState<ApprovalConfigType[] | undefined>();
  const [dataChanged, setDataChanged] = useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState<string>('');
  const [isCurrentUser, setCurrentUser] = useState<boolean>(false);

  const { handleRequest } = useWealthlaneAdministrationApi();
  const { data: vendorUserOptions, isLoading: userListLoader } =
    useGetVendorUserList(handleRequest);
  const { data: vendorApprovalProcessList } = useGetAllVendorApprovalProcess(handleRequest);
  const { data, isLoading } = useGetApprovalProcessList(handleRequest);
  const userAuthList: any = useSelector(selectAuthRoutesList);

  useEffect(() => {
    if (data !== undefined && vendorApprovalProcessList !== undefined) {
      const newData = data.map((item) => {
        const processList =
          vendorApprovalProcessList?.find((processItem) => processItem.name === item.name)
            ?.vendorUserDtos ?? [];

        const processListOptions = processList?.map((item) => item.vendorUserId) ?? [''];

        return {
          name: item.name ?? '',
          permission: item.permission ?? '',
          approvalProcessId: item.id ?? '',
          vendorUserDtos: processListOptions,
        };
      });
      setInitialValues(newData);
    }
  }, [data, vendorApprovalProcessList]);

  useEffect(() => {
    if (dataChanged) {
      handleSubmit(selectedRow);
    }
  }, [initialValues]);

  const { mutate, isLoading: isSubmitting } = usePostVendorApprovalConfig(
    handleRequest,
    isCurrentUser
  );
  const handleSubmit = (value: string): void => {
    const payload = initialValues?.map((item) => {
      return {
        ...item,
        vendorUserDtos: item.vendorUserDtos.map((userIds) => {
          return {
            vendorUserId: userIds,
            fullName: vendorUserOptions?.find((option) => option.value === userIds)?.label,
          };
        }),
      };
    });
    // const filterPayload = payload?.filter((item) => item.name === value)[0];

    const currentApprovalPayload = payload?.filter((item) => item.name === value)[0];
    const filterPayload = payload?.map((item: any) => {
      return {
        ...item,
        vendorUserDtos: item.vendorUserDtos.map((customDtos: any) => customDtos.vendorUserId),
      };
    });

    const oldApprovalValue = vendorApprovalProcessList?.filter((item) => item.name === value)[0]
      ?.vendorUserDtos;
    const currentApprovalValue = filterPayload?.filter((item) => item.name === value)[0]
      ?.vendorUserDtos;

    const filterOldApprovalValue = oldApprovalValue?.map((item) => item.vendorUserId || '') ?? [];
    const isApprovalValueEqual = !areArraysEqual(filterOldApprovalValue, currentApprovalValue);
    setDataChanged(false);

    isApprovalValueEqual && mutate(currentApprovalPayload);
  };

  const handleUser = (userData: UserType, userId: string | undefined): void => {
    const getUser = initialValues?.map((item) => {
      if (item.approvalProcessId === userId) {
        const userInfo = item.vendorUserDtos;
        !userInfo.includes(userData.value)
          ? userInfo.push(userData.value)
          : userInfo.splice(userInfo.indexOf(userData.value), 1);
        return {
          ...item,
          vendorUserDtos: userInfo,
        };
      } else {
        return item;
      }
    });

    setInitialValues(getUser);
  };

  return (
    <>
      {isSubmitting && (
        <ApprovalConfigLoader>
          <Spinners size='lg' />
        </ApprovalConfigLoader>
      )}
      <SettingSystemUserHeader>
        <Heading variant='h3' title='System Approvers' />
        <Heading
          variant='body-s'
          title={`List of ${
            userAuthList?.currentTenant?.name ?? ''
          } employees authorized to approve certain activities on and user access of the Wealthlane system. `}
        />
      </SettingSystemUserHeader>
      <ConfigContainer>
        {isLoading && <Spinners />}
        {!isLoading && (
          <div className='grid lg:grid-cols-12 gap-5'>
            {data?.map((item, index) => (
              <>
                <div className='lg:col-span-4 left-space'>
                  <CustomDropdown
                    title={item.name !== null && item.name !== undefined ? item.name : ''}
                    placeholder='Selected Users'
                    data={vendorUserOptions}
                    loading={userListLoader}
                    handleUser={(userInfo) => {
                      const isUser = userInfo.userId === userAuthList.currentUser.id;
                      console.log('isCurrentUser', userInfo, userAuthList.currentUser.id);
                      if (isUser) {
                        setCurrentUser(isUser);
                      }
                      handleUser(userInfo, item.id);
                    }}
                    checkedList={(initialValues?.[index]?.vendorUserDtos as string[]) ?? []}
                    handleSubmit={handleSubmit}
                  />
                </div>
                <div className='lg:col-span-8 right-space'>
                  <ApproverSelectContainer>
                    <Label className='bold'>User</Label>
                    <ApproverSelectGroup>
                      {vendorUserOptions
                        ?.filter((option) =>
                          initialValues?.[index].vendorUserDtos.includes(option.value)
                        )
                        ?.map((mapped) => (
                          <div>
                            <Lozenge
                              variant='default'
                              title={mapped.label}
                              handleIconClick={() => {
                                const filterData = initialValues?.map((data) => {
                                  if (data.approvalProcessId === item.id) {
                                    return {
                                      ...data,
                                      vendorUserDtos: data.vendorUserDtos.filter(
                                        (user: string | undefined) => user !== mapped.value
                                      ),
                                    };
                                  } else {
                                    return data;
                                  }
                                });

                                const isUser = mapped.userId === userAuthList.currentUser.id;
                                setCurrentUser(isUser);
                                setSelectedRow(item.name || '');
                                setInitialValues(filterData as any);
                                setDataChanged(true);
                              }}
                            />
                          </div>
                        ))}
                    </ApproverSelectGroup>
                  </ApproverSelectContainer>
                </div>
                {!(data.length - 1 === index) && (
                  <div className='lg:col-span-12'>
                    <FormDivider />
                  </div>
                )}
              </>
            ))}
          </div>
        )}
      </ConfigContainer>
    </>
  );
};

export default ApprovalConfig;
