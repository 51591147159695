import { type UseQueryResult, useQuery } from 'react-query';
import { VendorUserService } from '../../services/wealthlane-administration-services';

const useGetVendorUserList = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>
): UseQueryResult<
  | Array<{
      label: string;
      value: string;
      department: string;
      title: string;
      userId: string;
    }>
  | undefined,
  unknown
> => {
  const vendorUserList = useQuery(
    ['getVendorUserList'],
    async () => {
      return await handleRequest(
        VendorUserService.getApiAdministrationApiAppVendorUserVendorUsers()
      );
    },
    {
      keepPreviousData: true,
      select: (data) => {
        if (data != null) {
          return data?.map((item) => {
            return {
              label: item.fullName ?? '',
              value: item.id ?? '',
              department: item.vendorDepartment ?? '',
              title: item.vendorTitle ?? '',
              userId: item.abpUserId ?? '',
            };
          });
        }
      },
    }
  );
  return vendorUserList;
};

export default useGetVendorUserList;
