/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Wealthlane_AwardService_Dtos_Grant_PlanNameTaxDto } from '../models/Wealthlane_AwardService_Dtos_Grant_PlanNameTaxDto';
import type { Wealthlane_AwardService_Dtos_Plan_GetPlanBlobRequestDto } from '../models/Wealthlane_AwardService_Dtos_Plan_GetPlanBlobRequestDto';
import type { Wealthlane_AwardService_Dtos_Plan_PlanBlobDto } from '../models/Wealthlane_AwardService_Dtos_Plan_PlanBlobDto';
import type { Wealthlane_AwardService_Dtos_Plan_PlanDto } from '../models/Wealthlane_AwardService_Dtos_Plan_PlanDto';
import type { Wealthlane_AwardService_Dtos_Plan_PlanListDto } from '../models/Wealthlane_AwardService_Dtos_Plan_PlanListDto';
import type { Wealthlane_AwardService_Dtos_Plan_PlanStatusDto } from '../models/Wealthlane_AwardService_Dtos_Plan_PlanStatusDto';
import type { Wealthlane_AwardService_Dtos_Plan_UpdateDocumentDto } from '../models/Wealthlane_AwardService_Dtos_Plan_UpdateDocumentDto';
import type { Wealthlane_AwardService_Dtos_Response_ResponseDto } from '../models/Wealthlane_AwardService_Dtos_Response_ResponseDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class PlanService {

    /**
     * @param formData 
     * @returns Wealthlane_AwardService_Dtos_Plan_PlanDto Success
     * @throws ApiError
     */
    public static postApiAwardApiAppPlanPlan(
formData?: {
Name: string;
NumberOfSharesAuthorized: number;
NumberOfSharesAvailable: number;
EligibleShareClass?: string;
BoardApprovalDate: string;
ShareHolderApprovalDate: string;
PlanTerm: number;
EvergreenPlan: boolean;
EvergreenCriteria?: string;
DeathExpirationOption?: number;
DisablityExpirationOption?: number;
RetirementExpirationOption?: number;
VolutaryTerminationOption?: number;
InvolutaryTerminationOption?: number;
Description?: string;
PlanTypeCategoryIds: Array<string>;
PlanDocument: Array<Blob>;
PlanEffectiveDate: string;
DividendEligibility: boolean;
OptionExpirationDate?: number;
NumberOfSharesUnAvailable: number;
WithACause?: number;
},
): CancelablePromise<Wealthlane_AwardService_Dtos_Plan_PlanDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/award/api/app/plan/plan',
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param formData 
     * @returns Wealthlane_AwardService_Dtos_Plan_PlanDto Success
     * @throws ApiError
     */
    public static putApiAwardApiAppPlanPlan(
formData?: {
Id: string;
Name: string;
NumberOfSharesAuthorized: number;
NumberOfSharesAvailable: number;
EligibleShareClass?: string;
BoardApprovalDate: string;
ShareHolderApprovalDate: string;
PlanTerm: number;
EvergreenPlan: boolean;
EvergreenCriteria?: string;
DeathExpirationOption?: number;
DisablityExpirationOption?: number;
RetirementExpirationOption?: number;
VolutaryTerminationOption?: number;
InvolutaryTerminationOption?: number;
Description?: string;
PlanTypeCategoryIds: Array<string>;
PlanDocument?: Array<Blob>;
PlanDocumentDetails?: Array<Wealthlane_AwardService_Dtos_Plan_UpdateDocumentDto>;
PlanEffectiveDate: string;
DividendEligibility: boolean;
OptionExpirationDate?: number;
NumberOfSharesUnAvailable: number;
WithACause?: number;
},
): CancelablePromise<Wealthlane_AwardService_Dtos_Plan_PlanDto> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/award/api/app/plan/plan',
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param id 
     * @returns Wealthlane_AwardService_Dtos_Plan_PlanDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppPlanPlanById(
id: string,
): CancelablePromise<Wealthlane_AwardService_Dtos_Plan_PlanDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/plan/{id}/plan-by-id',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param id 
     * @returns Wealthlane_AwardService_Dtos_Plan_PlanDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppPlanPlanByIdForUpdate(
id: string,
): CancelablePromise<Wealthlane_AwardService_Dtos_Plan_PlanDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/plan/{id}/plan-by-id-for-update',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_AwardService_Dtos_Plan_PlanListDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppPlanPlanList(): CancelablePromise<Array<Wealthlane_AwardService_Dtos_Plan_PlanListDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/plan/plan-list',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Wealthlane_AwardService_Dtos_Plan_PlanBlobDto Success
     * @throws ApiError
     */
    public static postApiAwardApiAppPlanDownloadPlanDocument(
requestBody?: Wealthlane_AwardService_Dtos_Plan_GetPlanBlobRequestDto,
): CancelablePromise<Wealthlane_AwardService_Dtos_Plan_PlanBlobDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/award/api/app/plan/download-plan-document',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_AwardService_Dtos_Plan_PlanStatusDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppPlanPlanStatus(): CancelablePromise<Array<Wealthlane_AwardService_Dtos_Plan_PlanStatusDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/plan/plan-status',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param id 
     * @returns Wealthlane_AwardService_Dtos_Response_ResponseDto Success
     * @throws ApiError
     */
    public static deleteApiAwardApiAppPlanPlan(
id: string,
): CancelablePromise<Wealthlane_AwardService_Dtos_Response_ResponseDto> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/award/api/app/plan/plan/{Id}',
            path: {
                'Id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param input 
     * @param participantId 
     * @returns Wealthlane_AwardService_Dtos_Grant_PlanNameTaxDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppPlanPlansForTax(
input?: string,
participantId?: string,
): CancelablePromise<Array<Wealthlane_AwardService_Dtos_Grant_PlanNameTaxDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/plan/plans-for-tax',
            query: {
                'Input': input,
                'ParticipantId': participantId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param planId 
     * @returns Wealthlane_AwardService_Dtos_Plan_PlanDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppPlanPlanByIdForTax(
planId: string,
): CancelablePromise<Wealthlane_AwardService_Dtos_Plan_PlanDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/plan/plan-by-id-for-tax/{PlanId}',
            path: {
                'PlanId': planId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

}
