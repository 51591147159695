/* eslint-disable no-useless-escape */
import styled, { css } from 'styled-components';
import { COLORS } from '../../constants/style/color';
import { rem } from '../../utils/style';

interface TableStyles {
  border?: string;
  padding?: number;
}

interface TableContainerType extends TableStyles {
  height: number | string;
}

export const TableWrapper = styled.div<TableStyles>`
  background: ${COLORS.White};
  border-radius: ${rem(6)};

  ${(props) => {
    return css`
      border: ${props?.border !== undefined
        ? props.border
        : `${rem(1)} solid ${COLORS.CulturedWhite}`};
    `;
  }}
`;

export const TableContainer = styled.div<TableContainerType>`
  background-color: ${COLORS.White};
  overflow-x: auto;
  border-radius: 0.6rem;

  ${(props) => {
    return css`
      height: ${props?.height !== undefined ? `${props.height}` : `${rem(500)}`};
      padding: ${props?.padding !== undefined ? props.padding : `${rem(0)} ${rem(32)}`};
    `;
  }}

  ::-webkit-scrollbar {
    height: 5px;
    width: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 7px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${COLORS.SpanishGray};
    border-radius: 7px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

export const TableStyle = styled.table`
  border: none;
  border-collapse: separate;
  width: 100%;
  border-spacing: 0;
  border-radius: 6px;

  thead {
    top: 0;
    position: sticky;
    z-index: 2;
    box-shadow: 0 2px 2px -2px rgb(0 0 0 / 10%);

    tr th {
      padding: 35px 14px 27px 3px;
      font-weight: 900;
      font-size: 14px;
      line-height: 18px;
      text-align: left;
      color: ${COLORS.Gray60};
      white-space: nowrap;
      border-bottom: ${rem(1)} solid ${COLORS.CulturedWhite};

      &:last-child {
        text-align: center;
      }

      & > div {
        font-weight: 900;
      }
    }
  }

  td,
  th {
    border: none;
  }
  th {
    background: ${COLORS.White};
    cursor: pointer;
  }

  td {
    padding: 14px 0px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #161616;
    position: relative;
    padding-right: 14px;
    padding-left: 5px;
    .hasError {
      &::before {
        content: '';
        top: 0;
        left: 0;
        position: absolute;
        height: 100%;
        width: 100%;
        background-color: ${COLORS.RedLightTransparent};
      }
    }
  }

  tbody tr {
    /* border-bottom: ${rem(1)} solid ${COLORS.CulturedWhite}; */
    /* transition: all 0.2s ease-in-out; */
    td {
      transition: all 0.2s ease-in-out;
      border-bottom: ${rem(1)} solid ${COLORS.CulturedWhite};
    }
    &:hover {
      background-color: ${COLORS.Grey100Transparent};
    }
    td:last-child {
      text-align: center;
    }
  }

  &.bordered th,
  &.bordered td {
    border-right: 0.5px solid rgba(166, 166, 166, 0.25);

    &:last-child {
      border-right: none;
    }
  }
`;

export const TableSorting = styled.div`
  display: flex;
  align-items: center;
  column-gap: ${rem(6)};

  span {
    font-weight: 500;
    color: ${COLORS.Silver};
  }

  .sorting-icon {
    > span {
      font-size: ${rem(20)};
      width: ${rem(8)};
      height: ${rem(8)};
      display: flex;
      align-items: center;
      justify-content: center;
      color: ${COLORS.SpanishGray};
      transition: all 0.2s linear;
      &.active {
        color: ${COLORS.Black};
      }
    }
  }
`;

export const Pagination = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  gap: ${rem(16)};

  padding: ${rem(26)} ${rem(32)} ${rem(24)} ${rem(32)};

  @media (min-width: 1024px) {
    flex-direction: row;
  }

  .totalItems {
    color: ${COLORS.Silver};
    line-height: ${rem(35)};
  }

  .pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 0;
    list-style: none;

    li {
      padding: ${rem(5)} ${rem(11)};
      border-radius: ${rem(6)};
      cursor: pointer;

      span {
        color: ${COLORS.Gray};
      }

      &.active,
      &:hover {
        background-color: ${COLORS.LightPurple};

        span {
          color: ${COLORS.Purple};
          font-weight: 600;
        }
      }
    }
  }

  .rowItems {
    display: flex;
    gap: ${rem(4)};
    .customSelect {
      /* position: relative;
      cursor: pointer; */
      position: relative;
      cursor: pointer;
      background-color: #f3f3f3;
      /* padding: 0.6rem 0rem 0.6rem 1rem; */
      border-radius: 6rem;
      display: flex;
      align-items: center;
      height: fit-content;
      z-index: 0;
      select {
        /* background-color: ${COLORS.OffWhite}; */
        /* padding: ${rem(7)} ${rem(20)} ${rem(7)} ${rem(10)}; */
        /* border-radius: ${rem(60)}; */
        font-size: ${rem(14)};
        line-height: ${rem(18)};
        font-weight: 500;
        -webkit-appearance: none;
        cursor: pointer;

        background-color: transparent;
        /* padding: 0.7rem 2rem 0.7rem 1rem; */
        /* border-radius: 6rem; */
        z-index: 1;
        position: relative;
        padding: ${rem(7)} ${rem(20)} ${rem(7)} ${rem(10)};
        &:focus,
        &:hover {
          outline: none;
          box-shadow: none;
        }
      }
      .material-symbols-rounded {
        position: absolute;
        right: 0px;
        top: 50%;
        transform: translateY(-50%);
        color: #595959;
      }
      /* &:not(*:root):after {
        content: '\\e5c5';
        font-family: 'Material Symbols Rounded';
        position: absolute;
        right: 10px;
        top: 46%;
        transform: translateY(-50%);
        color: ${COLORS.Gray};
        font-size: 22px;
        width: 7px;
        height: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
      } */
    }
  }
`;
