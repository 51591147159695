import { type UseQueryResult, useQuery } from 'react-query';
import {
  VendorUserApprovalProcessService,
  // eslint-disable-next-line camelcase
  type Wealthlane_AdministrationService_Dtos_VendorUserApproval_VendorApprovalProcessDto,
} from '../../services/wealthlane-administration-services';

const useGetAllVendorApprovalProcess = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>
): UseQueryResult<
  // eslint-disable-next-line camelcase
  Wealthlane_AdministrationService_Dtos_VendorUserApproval_VendorApprovalProcessDto[] | undefined,
  unknown
> => {
  const allVendorApprovalProcess = useQuery(['getAllVendorApprovalProcess'], async () => {
    return await handleRequest(
      VendorUserApprovalProcessService.getApiAdministrationApiAppVendorUserApprovalProcess()
    );
  });

  if (allVendorApprovalProcess.status === 'error') {
    console.log('Error occurred:', allVendorApprovalProcess.error);
  }

  return allVendorApprovalProcess;
};

export default useGetAllVendorApprovalProcess;
