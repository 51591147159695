import { type ReactNode, useState } from 'react';

import { Transition } from 'react-transition-group';
import { isEmpty } from 'lodash';
import { AlertPopupContainer } from '../../../components/alert-popup/alertPopup.styled';
import { Heading } from '../../../components';
import { FormWrapper } from '../../../components/input-text/input.styled';
import Button from '../../../components/button';
import moment from 'moment';
import { DATE_FORMAT } from '../../../constants/common';

interface DeactivatePopupType {
  isShow: boolean;
  closeModal: () => void;
  handleSubmit?: any;
  title?: string | ReactNode;
  modalStatus?: string | ReactNode;
  setRemark?: any;
  data?: any;
  loading?: boolean;
  customTitle?: string;
}

const DeactivatePopup = ({
  isShow,
  title,
  modalStatus,
  closeModal,
  handleSubmit,
  data,
  setRemark,
  loading,
  customTitle,
}: DeactivatePopupType): JSX.Element => {
  const [errorMessage, setErrorMessage] = useState('');

  return (
    <Transition in={isShow} timeout={250}>
      {(state) => (
        <AlertPopupContainer>
          <div className={`modalBackdrop modalBackdrop-${state}`}>
            <div className={`sideBar sideBar-${state} `}>
              <div className='popupDialog'>
                <div className='popupContent'>
                  <Heading
                    variant='h5'
                    className='mb-5'
                    title={
                      customTitle ??
                      (modalStatus === 'View Remarks'
                        ? 'Deactivate Reason'
                        : `Are you sure you want to ${
                            modalStatus === 'Deactivate' ? 'deactivate' : 'confirm'
                          } ${String(title)}?`)
                    }
                  />

                  <FormWrapper>
                    <textarea
                      name='remarks'
                      placeholder='Enter Reason'
                      className={!isEmpty(errorMessage) ? 'border-red-600' : 'input-view'}
                      style={{ minHeight: '100px', minWidth: '430px', resize: 'none' }}
                      defaultValue={data.remarks}
                      disabled={modalStatus === 'View Remarks'}
                      onChange={(e) => {
                        if (e.target.value !== '') {
                          setErrorMessage('');
                          setRemark(e.target.value);
                        }
                      }}
                    ></textarea>
                    {errorMessage !== '' && (
                      <Heading variant='body-xs' title={'Required Field'} className={'mt-2'} />
                    )}
                    {modalStatus === 'View Remarks' && (
                      <>
                        <div className='flex mb-1 gap-2 mt-4'>
                          <Heading variant='h6' title='Deactivated by:' />
                          <Heading
                            variant='body'
                            title={
                              data.deactivatedByUserFullName != null
                                ? data.deactivatedByUserFullName
                                : '-'
                            }
                            fontWeight={500}
                          />
                        </div>
                        <div className='flex gap-2'>
                          <Heading variant='h6' title='Deactivated date:' />
                          <Heading
                            variant='body'
                            title={
                              data.deactivatedDate
                                ? moment(data.deactivatedDate).format(DATE_FORMAT)
                                : '-'
                            }
                            fontWeight={500}
                          />
                        </div>
                      </>
                    )}
                  </FormWrapper>
                  <div className='popupButtons'>
                    <Button
                      variant='primary-dark'
                      title={modalStatus === 'View Remarks' ? 'Close' : 'No'}
                      onClick={() => {
                        closeModal();
                      }}
                    />
                    {modalStatus !== 'View Remarks' && (
                      <Button
                        variant='primary'
                        title='Confirm'
                        onClick={() => {
                          !isEmpty(data.remarks)
                            ? handleSubmit()
                            : setErrorMessage('Required Field');
                        }}
                        icon={<span className='material-symbols-outlined'>check_circle</span>}
                        loading={loading}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </AlertPopupContainer>
      )}
    </Transition>
  );
};

export default DeactivatePopup;
