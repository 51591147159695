import { type UseMutationResult, useMutation, useQueryClient } from 'react-query';
import { VendorCompanyService } from '../../services/wealthlane-customer-services';

const usePostCustomerStepTwo = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  stepperState: (step: number) => void
): UseMutationResult => {
  const queryClient = useQueryClient();
  return useMutation(
    async (payload: any) => {
      if (payload.isEdit !== null && payload.isEdit !== undefined && payload.isEdit === true) {
        return await handleRequest(
          VendorCompanyService.putApiCustomerApiAppVendorCompanyCompanyUserContact(payload)
        );
      }
      return await handleRequest(
        VendorCompanyService.postApiCustomerApiAppVendorCompanyCompanyUserContact(payload)
      );
    },
    {
      onSuccess: async (response: any) => {
        if (response !== null && response !== undefined) {
          stepperState(3);
          await queryClient.invalidateQueries('getVendorCompanyView');
        }
      },
    }
  );
};

export default usePostCustomerStepTwo;
