import { type UseQueryResult, useQuery } from 'react-query';
import { type CustomTableInfoType } from '../../pages/customer';
import {
  VendorCompanyService,
  // eslint-disable-next-line camelcase
  type Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_CustomerService_Dtos_VendorCompany_CompanyListDto_Wealthlane_CustomerService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null,
} from '../../services/wealthlane-customer-services';

const useGetCustomerListData = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  tableInfo: CustomTableInfoType
): UseQueryResult<
  // eslint-disable-next-line camelcase
  | Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_CustomerService_Dtos_VendorCompany_CompanyListDto_Wealthlane_CustomerService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null
  | undefined,
  unknown
> => {
  const {
    filter,
    sorting,
    skipCount,
    maxResultCount,
    fromOnboardedDate,
    toOnboardedDate,
    statusIds,
  } = tableInfo;

  const customerListData = useQuery(
    [
      'getCustomerList',
      filter,
      sorting,
      skipCount,
      maxResultCount,
      fromOnboardedDate,
      toOnboardedDate,
      statusIds,
    ],
    async () => {
      return await handleRequest(
        VendorCompanyService.getApiCustomerApiAppVendorCompany(
          fromOnboardedDate,
          toOnboardedDate,
          statusIds,
          filter,
          sorting,
          skipCount,
          maxResultCount
        )
      );
    }
  );

  return customerListData;
};

export default useGetCustomerListData;
