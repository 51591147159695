/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Wealthlane_TaxService_Dtos_JurisdictionLevel_JurisdictionLevelDto } from '../models/Wealthlane_TaxService_Dtos_JurisdictionLevel_JurisdictionLevelDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class JurisdictionLevelService {

    /**
     * @param jurisdictionLevelId 
     * @returns Wealthlane_TaxService_Dtos_JurisdictionLevel_JurisdictionLevelDto Success
     * @throws ApiError
     */
    public static getApiTaxApiAppJurisdictionLevelJurisdictionLevel(
jurisdictionLevelId: string,
): CancelablePromise<Wealthlane_TaxService_Dtos_JurisdictionLevel_JurisdictionLevelDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/tax/api/app/jurisdiction-level/jurisdiction-level/{JurisdictionLevelId}',
            path: {
                'JurisdictionLevelId': jurisdictionLevelId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_TaxService_Dtos_JurisdictionLevel_JurisdictionLevelDto Success
     * @throws ApiError
     */
    public static getApiTaxApiAppJurisdictionLevelJuridctionLevelListAync(): CancelablePromise<Array<Wealthlane_TaxService_Dtos_JurisdictionLevel_JurisdictionLevelDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/tax/api/app/jurisdiction-level/juridction-level-list-aync',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

}
