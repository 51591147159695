import axios from 'axios';
import { isEmpty } from 'lodash';

const requestArray: any = [];

export const axiosInstance = axios.create({
  url: process.env.REACT_APP_WEALTHLANE_API_ENDPOINT ?? '',
});
export const cancelAxios = axios.CancelToken;

let busyApiCall = false;

// Add a request interceptor
axiosInstance.interceptors.request.use(
  async function (config) {
    return config;
  },
  async function (error: any) {
    return await Promise.reject(error);
  }
);

// Add a response interceptor
axiosInstance.interceptors.response.use(
  function (response) {
    if (requestArray.length !== 0) {
      requestArray.forEach(function (x: any, i: any) {
        if (response.config.url === x.url) {
          requestArray.splice(i, 1);
        }
      });
    }
    return response;
  },
  async function (error: any) {
    const originalRequest = error;
    requestArray.push(originalRequest);
    if (error.response.status === 403 && isEmpty(error?.response?.data?.error)) {
      if (!busyApiCall) {
        busyApiCall = true;
        setTimeout(() => {
          window.dispatchEvent(new CustomEvent('PERMISSION_CHANGED', { detail: 'REFRESH' }));
        }, 1000);
      }
    }
    if (error.response.status === 401) {
      if (!busyApiCall) {
        busyApiCall = true;
        setTimeout(() => {
          window.dispatchEvent(new CustomEvent('PERMISSION_CHANGED', { detail: 'SIGN_IN' }));
        }, 1000);
      }
    }
    return await Promise.reject(error);
  }
);

// import axios from 'axios';
// import { useEffect } from 'react'
// import { useNavigate } from 'react-router-dom'
// import AuthService from './authService';

// const axiosInstance = axios.create()
// const cancelAxios = axios.CancelToken

// const AxiosInterceptor = ({ children }: any) => {

//     const navigate = useNavigate();
//     const authService = new AuthService();
//     let requestArray: any = []

//     useEffect(() => {

//         const resInterceptor = async (response: any) => {
//             return await response;
//         }

//         const errInterceptor = async (error: any) => {
//             const originalRequest = error.config;
//             requestArray.push(originalRequest)
//             return Promise.reject(error);
//         }

//         const interceptor = axiosInstance.interceptors.response.use(resInterceptor, errInterceptor);

//         return () => axiosInstance.interceptors.response.eject(interceptor);

//     }, [navigate])

//     return children;
// }

// export { cancelAxios, axiosInstance };
// export { AxiosInterceptor }
