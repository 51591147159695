import { useSelector } from 'react-redux';
import { getFilteredPermissionData } from '..';
import { selectAuthRoutesList } from '../../pages/configuration/routeSlice';

// Checks Field Level Permission
const CheckFieldLevelPermissions = (fieldName?: string, isEdit?: boolean, disabled?: boolean) => {
  const userAuthList = useSelector(selectAuthRoutesList);
  const { fieldLevelPermissionsGroups } = getFilteredPermissionData(
    userAuthList?.auth?.grantedPolicies ?? {}
  );

  const getAllPermission =
    fieldName && fieldLevelPermissionsGroups[fieldName]
      ? fieldLevelPermissionsGroups[fieldName].map((item: any) => item.text)
      : [];

  const isDisabled = disabled
    ? disabled
    : isEdit
    ? getAllPermission.includes('Edit')
      ? null
      : getAllPermission.includes('View')
      ? true
      : fieldName && getAllPermission.length === 0
      ? true
      : null
    : disabled || null;

  return isDisabled;
};

export default CheckFieldLevelPermissions;
