import { ErrorMessage, Field, type FormikErrors, type FormikTouched } from 'formik';
import React, { type ReactNode, useEffect, useState } from 'react';
import useMediaQuery from '../../hooks/useMediaQuery';
import Tag from '../lozenge/tags';
import Tooltip from '../tooltip';
import { FormWrapper, Label } from './input.styled';
import CheckFieldLevelPermissions from '../../utils/Permissions/checkFieldLevelPermission';

interface InputTypeProps {
  label?: string;
  name: string;
  type?: string | number;
  className?: string;
  labelClassName?: string;
  required?: boolean;
  disabled?: boolean;
  value?: string | number;
  avoidKey?: string | number | undefined;
  onKeyUp?: ((event: React.KeyboardEvent<HTMLInputElement>) => void) | undefined;
  defaultValue?: number | string;
  errors?: FormikErrors<any> | undefined;
  touched?: FormikTouched<any> | undefined;
  placeholder?: string;
  onPaste?: (event: React.ClipboardEvent<HTMLInputElement>) => void;
  width?: string;
  min?: number;
  setFieldValue?: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  onChange: any;
  onBlur?: any;
  onFocus?: any;
  onClick?: any;
  // change to boolean on all fields
  inputType?: boolean | string;
  errorValue?: string | FormikErrors<any> | Array<FormikErrors<any>> | string[] | undefined;
  touchedValue?: boolean | FormikTouched<any> | Array<FormikTouched<any>> | undefined;
  note?: string | ReactNode;
  tooltipText?: string;
  id?: string;
  errorsName?: string;
  fieldLabelName?: string;
  isEdit?: boolean;
}

const InputText = React.memo((props: InputTypeProps) => {
  const isMobile = useMediaQuery(512);
  const [show, setShow] = useState<boolean>(false);
  const {
    label,
    name,
    type,
    className,
    labelClassName,
    required,
    onKeyUp,
    avoidKey,
    value,
    defaultValue,
    setFieldValue,
    inputType,
    errors,
    touched,
    placeholder,
    min,
    width,
    errorValue,
    touchedValue,
    note,
    tooltipText,
    id,
    onPaste,
    errorsName,
    fieldLabelName,
    isEdit,
    disabled, onBlur,
    onFocus,
    onClick,
    ...rest
  } = props;

  useEffect(() => {
    setFieldValue?.(name, defaultValue);
  }, [defaultValue]);

  const showType = type === 'password' && show ? 'text' : type;
  const hasError =
    (errors != null && touched != null && errors[name] != null && touched[name] != null) ||
    (Boolean(errorValue) && Boolean(touchedValue))
      ? 'is-invalid'
      : '';

  const isTypePassword = type === 'password' ? 'input-password' : '';
  return (
    <FormWrapper className={className}>
      {label != null && (
        <Label
          htmlFor={name}
          className={`${labelClassName ?? ''} ${tooltipText != null ? 'tooltipView' : ''}`}
        >
          {label} {!(required ?? false) && <span>(Optional)</span>}{' '}
          {tooltipText != null && (
            <Tooltip tooltipText={tooltipText} variant='dark' size='sm'>
              <span className='material-symbols-sharp'>info</span>
            </Tooltip>
          )}
        </Label>
      )}
      <div className='relative'>
        <Field
          id={id}
          name={name}
          label={label}
          className={`${hasError} ${isTypePassword}`}
          type={showType ?? 'text'}
          autoComplete='off'
          as={'input'}
          min={min}
          onPaste={onPaste}
          onBlur={onBlur && onBlur}
          onFocus={onFocus && onFocus}
          disabled={
            fieldLabelName ? CheckFieldLevelPermissions(fieldLabelName, isEdit, disabled) : disabled
          }
          onKeyUp={onKeyUp}
          placeholder={placeholder}
          defaultValue={defaultValue}
          style={{ width: isMobile ? '100%' : width ?? '100%' }}
          {...(Boolean(avoidKey) && { avoidKey })}
          {...(Boolean(value) && { value })}
          {...rest}
        />
        {type === 'password' && (
          <span
            className='material-symbols-outlined password-icon'
            onClick={() => {
              setShow(!show);
            }}
          >
            {`visibility${show ? '' : '_off'}`}
          </span>
        )}
        {type === 'days' && <Tag variant='default' title='DAYS' className='year' />}
        {type === 'year' && <Tag variant='default' title='YEAR' className='year' />}
        {type === 'percent' && <Tag variant='default' title='%' className='year' />}
        {Boolean(note) && <div className='inputNote'>{note}</div>}
      </div>
      {errors != null && (
        <ErrorMessage name={errorsName ?? name} component='div' className='error-message' />
      )}
    </FormWrapper>
  );
});

export default InputText;
