import { type TypographyType } from '../../types/common';
import { SkeletonWrapper, SkeletonLineMain } from './skeleton.styled';

interface SkeletonLineType {
  size?: TypographyType | 'table';
  width?: string;
  className?: string;
  containerClassName?: string;
}

const SkeletonLine = ({
  size = 'table',
  width,
  className,
  containerClassName,
}: SkeletonLineType): JSX.Element => {
  return (
    <SkeletonWrapper className={containerClassName ?? ''}>
      <SkeletonLineMain size={size} width={width ?? '100%'} className={className} />
    </SkeletonWrapper>
  );
};

export default SkeletonLine;
