import { type UseMutationResult, useMutation, useQueryClient } from 'react-query';
import { type NavigateFunction } from 'react-router-dom';
import { VendorCompanyService } from '../../services/wealthlane-customer-services';

const usePostCustomerStepOne = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  setStep: (step: number) => void,
  navigate: NavigateFunction,
  id?: string,
  state?: unknown
): UseMutationResult => {
  const queryClient = useQueryClient();
  return useMutation(
    async (payload: any) => {
      if (id !== null && id !== undefined) {
        return await handleRequest(
          VendorCompanyService.putApiCustomerApiAppVendorCompanyCompanyProfile(payload)
        );
      }
      return await handleRequest(
        VendorCompanyService.postApiCustomerApiAppVendorCompanyCompanyProfile(payload)
      );
    },
    {
      onSuccess: async (response: any) => {
        if (response !== null && response !== undefined) {
          setStep(2);
          await queryClient.invalidateQueries('getVendorCompanyView');
          navigate(`/customer-list/create/${String(response.id)}`, { replace: true, state });
        }
      },
    }
  );
};

export default usePostCustomerStepOne;
