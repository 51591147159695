/* eslint-disable camelcase */
import { type UseMutationResult, useMutation } from 'react-query';
import {
  VendorTaxItemService,
  type Wealthlane_TaxService_Dtos_TaxItemDto_TaxItemDto,
} from '../../services/wealthlane-tax-services';

import { CommaFormatted } from '../../utils/global';
import InitialValuesForm from '../../constants/initialValues';

const useGetTaxSetupViewByTaxId = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  setInitialData: any
): UseMutationResult<
  Wealthlane_TaxService_Dtos_TaxItemDto_TaxItemDto | undefined,
  unknown,
  string,
  unknown
> => {
  return useMutation(
    async (id: string) => {
      return await handleRequest(VendorTaxItemService.getApiTaxApiAppVendorTaxItemTaxItemById(id));
    },
    {
      onSuccess: (response) => {
        if (response != null) {
          const sortedTaxBracket = response?.taxBrackets?.sort(
            (a: any, b: any) => Number(a.lowerLimit) - Number(b.lowerLimit)
          );
          const userDefined =
            response?.userDefinedCriteria !== null
              ? JSON.parse(String(response?.userDefinedCriteria?.replaceAll('/', '')))
              : null;

          // const userData = {
          //   ...userDefined,
          //   Conditions: userDefined?.Conditions?.map((item: any, i: number) => ({
          //     ...item,
          //     SubCriteria: item?.SubCriteria !== null && {
          //       ...item?.SubCriteria,
          //       Conditions: [
          //         {
          //           Hash: null,
          //           EqualityOperatorType: item?.EqualityOperatorType,
          //           Field: item?.Field,
          //           Value: item?.Value,
          //           Name: item?.Name,
          //           SubCriteria: null,
          //         },
          //         ...item?.SubCriteria?.Conditions,
          //       ],
          //     },
          //   })),
          // };

          const payload = {
            ...response,
            taxBrackets: sortedTaxBracket?.map((a: any, i: any) => ({
              ...a,
              lowerLimit: CommaFormatted(a?.lowerLimit),
              upperLimit:
                sortedTaxBracket[i + 1]?.lowerLimit !== undefined
                  ? CommaFormatted(sortedTaxBracket[i + 1]?.lowerLimit)
                  : '',
            })),
            userDefinedCriteria:
              response?.userDefinedCriteria !== null
                ? userDefined
                : InitialValuesForm?.taxSetup?.userDefinedCriteria,
            // userDefinedCriteria: response?.userDefinedCriteria?.map(
            //   (udf: Wealthlane_TaxService_Dtos_TaxItemDto_UserDefinedDto) => ({
            //     ...udf,
            //     conditions: udf?.conditions?.map(
            //       (item: Wealthlane_TaxService_Dtos_TaxItemDto_ConditionDto) => ({
            //         ...item,
            //         value:
            //           item?.field?.includes('Country') === true
            //             ? countryOptions?.filter(
            //                 (country: OptionType) => country.abbreviation === item?.value
            //               )[0]?.value
            //             : item?.field?.includes('State') === true
            //             ? stateOptions?.filter((state) => state.abbreviation === item?.value)[0]
            //                 .value
            //             : item?.field?.includes('City') === true
            //             ? cityOptions?.filter((city: OptionType) => city.label === item?.value)[0]
            //                 .value
            //             : item?.field?.includes('Status') === true
            //             ? residentStatus?.filter(
            //                 (status: OptionType) => status.label === status?.value
            //               )[0].value
            //             : item?.value,
            //       })
            //     ),
            //   })
            // ),
          };
          setInitialData(payload);
        }
      },
      onError: (err) => {
        console.log('error', err);
      },
    }
  );
};

export default useGetTaxSetupViewByTaxId;
