/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Wealthlane_Dtos_LoginSecurityOptions_LoginSecurityOptionsDto } from '../models/Wealthlane_Dtos_LoginSecurityOptions_LoginSecurityOptionsDto';
import type { Wealthlane_Dtos_LoginSecurityOptions_LoginSecurityOptionsForLoggedInUserDto } from '../models/Wealthlane_Dtos_LoginSecurityOptions_LoginSecurityOptionsForLoggedInUserDto';
import type { Wealthlane_Dtos_UserLogInAndSecurity_ChangeUserLogInPasswordDto } from '../models/Wealthlane_Dtos_UserLogInAndSecurity_ChangeUserLogInPasswordDto';
import type { Wealthlane_Dtos_UserLogInAndSecurity_UserMultiPurposeOTPVerificationDto } from '../models/Wealthlane_Dtos_UserLogInAndSecurity_UserMultiPurposeOTPVerificationDto';
import type { Wealthlane_Shared_Hosting_Dtos_UserLogInAndSecurity_UserLogInAndSecurityResponseDto } from '../models/Wealthlane_Shared_Hosting_Dtos_UserLogInAndSecurity_UserLogInAndSecurityResponseDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UserLogInAndSecurityService {

    /**
     * @param requestBody 
     * @returns Wealthlane_Shared_Hosting_Dtos_UserLogInAndSecurity_UserLogInAndSecurityResponseDto Success
     * @throws ApiError
     */
    public static postApiIdentityApiAppUserLogInAndSecurityChangeUserPassword(
requestBody?: Wealthlane_Dtos_UserLogInAndSecurity_ChangeUserLogInPasswordDto,
): CancelablePromise<Wealthlane_Shared_Hosting_Dtos_UserLogInAndSecurity_UserLogInAndSecurityResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/identity/api/app/user-log-in-and-security/change-user-password',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_Shared_Hosting_Dtos_UserLogInAndSecurity_UserLogInAndSecurityResponseDto Success
     * @throws ApiError
     */
    public static postApiIdentityApiAppUserLogInAndSecuritySendUserMultiPurposeOTp(): CancelablePromise<Wealthlane_Shared_Hosting_Dtos_UserLogInAndSecurity_UserLogInAndSecurityResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/identity/api/app/user-log-in-and-security/send-user-multi-purpose-oTP',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns boolean Success
     * @throws ApiError
     */
    public static postApiIdentityApiAppUserLogInAndSecurityValidateMultiPurposeToken(): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/identity/api/app/user-log-in-and-security/validate-multi-purpose-token',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Wealthlane_Shared_Hosting_Dtos_UserLogInAndSecurity_UserLogInAndSecurityResponseDto Success
     * @throws ApiError
     */
    public static postApiIdentityApiAppUserLogInAndSecurityVerifyUserMultiPurposeOTp(
requestBody?: Wealthlane_Dtos_UserLogInAndSecurity_UserMultiPurposeOTPVerificationDto,
): CancelablePromise<Wealthlane_Shared_Hosting_Dtos_UserLogInAndSecurity_UserLogInAndSecurityResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/identity/api/app/user-log-in-and-security/verify-user-multi-purpose-oTP',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param optionId 
     * @returns Wealthlane_Shared_Hosting_Dtos_UserLogInAndSecurity_UserLogInAndSecurityResponseDto Success
     * @throws ApiError
     */
    public static postApiIdentityApiAppUserLogInAndSecurityChangeLoginSecurityOption(
optionId: number,
): CancelablePromise<Wealthlane_Shared_Hosting_Dtos_UserLogInAndSecurity_UserLogInAndSecurityResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/identity/api/app/user-log-in-and-security/change-login-security-option/{OptionId}',
            path: {
                'OptionId': optionId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_Dtos_LoginSecurityOptions_LoginSecurityOptionsForLoggedInUserDto Success
     * @throws ApiError
     */
    public static getApiIdentityApiAppUserLogInAndSecurityUserLoginSecurityOptions(): CancelablePromise<Array<Wealthlane_Dtos_LoginSecurityOptions_LoginSecurityOptionsForLoggedInUserDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/identity/api/app/user-log-in-and-security/user-login-security-options',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_Dtos_LoginSecurityOptions_LoginSecurityOptionsDto Success
     * @throws ApiError
     */
    public static getApiIdentityApiAppUserLogInAndSecurityTwoFactorAuthenticationOptions(): CancelablePromise<Array<Wealthlane_Dtos_LoginSecurityOptions_LoginSecurityOptionsDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/identity/api/app/user-log-in-and-security/two-factor-authentication-options',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_Dtos_LoginSecurityOptions_LoginSecurityOptionsDto Success
     * @throws ApiError
     */
    public static getApiIdentityApiAppUserLogInAndSecurityLoginOptions(): CancelablePromise<Array<Wealthlane_Dtos_LoginSecurityOptions_LoginSecurityOptionsDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/identity/api/app/user-log-in-and-security/login-options',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_Shared_Hosting_Dtos_UserLogInAndSecurity_UserLogInAndSecurityResponseDto Success
     * @throws ApiError
     */
    public static postApiIdentityApiAppUserLogInAndSecurityDisableTwoFactorAuthentication(): CancelablePromise<Wealthlane_Shared_Hosting_Dtos_UserLogInAndSecurity_UserLogInAndSecurityResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/identity/api/app/user-log-in-and-security/disable-two-factor-authentication',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

}
