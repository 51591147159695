// Global font size
export const DEFAULT_FONT_SIZE = 10;

// Heading Tags
export const HEADING_TAGS = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p'];

// Supported Images Extension
export const SUPPORTED_IMAGE_FORMATS = ['jpg', 'jpeg', 'gif', 'png'];

// Roles Permission List
export const ROLES_PERMISSION_LIST = ['View', 'Create', 'Edit', 'Delete', 'Deactivate'];

export const initialHeight = window.innerHeight;

export const NO_PERMISSION = 'NoPermission';
export const OTHER_FIELDS = ['Need Help?', 'Legal Policies'];

export const DATE_FORMAT = 'MM/DD/YYYY';
export const INPUT_DATE_FORMAT = 'YYYY-MM-DD';
export const DATE_FORMAT_WITH_TIME = 'MM-DD-YYYY LT';

// Tax User Defined Status
export const TAX_TOGGLE_OPTIONS = [
  {
    label: 'INCLUDE',
    value: 'And',
  },
  {
    label: 'EXCLUDE',
    value: 'Not',
  },
  {
    label: 'EITHER',
    value: 'Or',
  },
];

export const EqualityOperatorOptions = [
  {
    label: 'Equals',
    value: 'Equals',
  },
  {
    label: 'Not Equals',
    value: 'NotEquals',
  },
];

export const YesNoOptions = [
  {
    label: 'Yes',
    value: 'Yes',
  },
  {
    label: 'No',
    value: 'No',
  },
];

export const UserStatusOptions = [
  {
    label: 'Active',
    value: 'active',
  },
  {
    label: 'Deactivated',
    value: 'deactivated',
  },
];

export const STEP_OPTIONS = [
  {
    label: 'Company Details',
    value: 1,
  },
  {
    label: 'Company & Wealthlane Contacts',
    value: 2,
  },
  {
    label: 'Review',
    value: 3,
  },
];

export const SORTED_MODULE_PERMISSION = [
  'Companies',
  'VendorRoles',
  'VendorUsers',
  'VendorApprovers',
  'VendorDesignations',
  'VendorTax',
];

export const SORTED_FIELD_PERMISSION = [
  'FirstMiddleLastName',
  'EmployeeId',
  'EmployeeEmail',
  'PhoneNumber',
  'JobTitle',
  'Department',
];

export const SIDEBAR_LISTS = [
  { name: 'Vendor Users & Roles', icon: 'groups' },
  { name: 'Configuration', icon: 'handyman' },
];

export const RELOAD_PERMISSION_MESSAGE =
  'Your permission has changed. The current page will be refreshed to load your new permissions.';
export const REDIRECT_PERMISSION_MESSAGE =
  'Your permission has changed. You currently do not have permission to this features. You will be redirected to the overview page. Please work with your access control team if you would like to have access to this feature.';
