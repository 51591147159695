import { type ReactNode } from 'react';
import { COLORS } from '../../constants/style/color';
import { type ModalStatusType } from '../../types/common';
import CheckPermissions from '../../utils/checkPermission';
import Spinners from '../spinner';
import { Btn } from './button.styled';

interface ButtonType {
  variant:
    | 'primary'
    | 'primary-outline'
    | 'primary-dark'
    | 'secondary'
    | 'secondary-dark'
    | 'secondary-outline'
    | 'links'
    | 'rounded'
    | 'secondary-red'
    | 'primary-danger'
    | 'approve'
    | string;
  icon?: string | ReactNode;
  title: string | ReactNode;
  onClick?: () => void;
  btnClassName?: string;
  loading?: boolean;
  disabled?: boolean;
  as?: ModalStatusType;
  hasId?: string;
  type?: 'button' | 'submit' | 'reset';
  fontColor?: string;
  borderColor?: string;
}

const Button = ({
  variant,
  icon,
  title,
  onClick,
  btnClassName,
  loading,
  type,
  disabled,
  hasId,
  as,
  fontColor,
  borderColor,
  ...rest
}: ButtonType): JSX.Element => {
  return (
    <CheckPermissions type={as} urlName={hasId}>
      <Btn
        className={btnClassName}
        type={type}
        variant={variant}
        onClick={onClick}
        fontColor={fontColor}
        borderColor={borderColor}
        as='button'
        disabled={disabled === true || loading === true}
        {...rest}
      >
        {Boolean(icon) && (
          <span className={`material-symbols-outlined ${title != null ? '' : 'pr-0'}`}>{icon}</span>
        )}
        {loading === true ? <Spinners borderColor={COLORS.White} size='sm' /> : title}
      </Btn>
    </CheckPermissions>
  );
};

export default Button;
