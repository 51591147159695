import moment from 'moment';

import { DATE_FORMAT } from '../common';
import { PopoverModelWrapper } from '../../components/modal/modal.styled';
import { Heading } from '../../components';
import { TableBodyCellColumn } from '../style/table.styled';
import Tag from '../../components/lozenge/tags';

export const companyTaxSetupListHeader = () => {
  return [
    {
      Header: 'Ticker',
      accessor: 'ticker',
      width: 200,
      Cell: ({ row }: any) => (
        <PopoverModelWrapper className='roles-popovers'>
          <Heading variant='body-s' title={row.original.ticker || '-'} />
        </PopoverModelWrapper>
      ),
    },
    {
      Header: 'Name',
      accessor: 'name',
      width: 200,
      Cell: ({ row }: any) => (
        <PopoverModelWrapper className='roles-popovers'>
          <Heading variant='body-s' title={row.original.name || '-'} />
        </PopoverModelWrapper>
      ),
    },
    {
      Header: 'This Year Tax',
      accessor: 'thisYearTax',
      sortable: false,
      width: 200,
      Cell: ({ row }: any) => (
        <PopoverModelWrapper className='roles-popovers'>
          <Heading
            variant='body-s'
            title={row.original.thisYearTax === false ? 'Tax not created' : 'Tax created' || '-'}
          />
        </PopoverModelWrapper>
      ),
    },
    {
      Header: 'Next Year Tax',
      accessor: 'nextYearTax',
      sortable: false,
      width: 200,
      Cell: ({ row }: any) => (
        <PopoverModelWrapper className='roles-popovers'>
          <Heading
            variant='body-s'
            title={row.original.nextYearTax === false ? 'Tax not created' : 'Tax created' || '-'}
          />
        </PopoverModelWrapper>
      ),
    },
    // {
    //   Header: 'Date Range',
    //   accessor: 'effectiveFrom',
    //   //   width: 500,
    //   sortable: false,
    //   Cell: ({ row }: any) => {
    //     return (
    //       <TableBodyCellColumn>
    //         {`${moment(row.original.effectiveFrom).format(DATE_FORMAT)} - ${moment(
    //           row.original.effectiveTill
    //         ).format(DATE_FORMAT)}` || '-'}
    //       </TableBodyCellColumn>
    //     );
    //   },
    // },

    // {
    //   Header: '',
    //   accessor: 'actions',
    //   width: 80,
    //   sortable: false,
    //   Cell: ({ row }: any) => {
    //     return (
    //       <TableBodyCellColumn alignItems='end'>
    //         {isHovering === row?.id && (
    //           <div onClick={() => action(row?.original)}>
    //             <Tag variant='default-outline' title={'Edit'} className={`cursor-pointer`} />
    //           </div>
    //         )}
    //       </TableBodyCellColumn>
    //     );
    //   },
    // },
  ];
};
