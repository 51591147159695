import { useState, useEffect } from 'react';
import Button from '../../../../../components/button';
import FormSelect from '../../../../../components/dropdown/formSelect';
import SearchWithFilter from '../../../../../components/filter/searchWithFilter';
import InputText from '../../../../../components/input-text/inputText';
import { OptionType } from '../../../../../types/common';

interface CityComponentTypes {
  errors: any;
  setOptionSelectedState: any;
  optionSelectedState: any;
  touched: any;
  countryOptions: any;
  values: any;
  setFieldValue: any;
  index: number;
  ruleIndex: number;
  handleChange: any;
  stateArrayOptions: any;
  cityArrayOptions?: any;
  stateOptions?: any;
  modalStatus: string;
  jurisdictionOptions?: any;
  setJurisdictionId?: any;
  setUdcData?: any;
  udcData?: any;
  setUdcDescription?: any;
  udcDescription?: any;
  findData?: any;
  descriptionData?: any;
}

const StateComponent = ({
  errors,
  setOptionSelectedState,
  optionSelectedState,
  touched,
  countryOptions,
  values,
  setFieldValue,
  index,
  ruleIndex,
  handleChange,
  stateArrayOptions,
  stateOptions,
  modalStatus,
  jurisdictionOptions,
  setJurisdictionId,
  setUdcData,
  udcData,
  setUdcDescription,
  udcDescription,
  findData,
  descriptionData,
}: CityComponentTypes): JSX.Element => {
  const [openFilter, setOpenFilter] = useState<boolean>(false);
  return (
    <>
      <SearchWithFilter
        name={`userDefinedCriteria.Conditions[${index}].SubCriteria.Conditions[${ruleIndex}]`}
        value={
          values?.userDefinedCriteria?.Conditions[index]?.SubCriteria?.Conditions[ruleIndex]?.Name
        }
        error={
          errors?.userDefinedCriteria?.Conditions[index]?.SubCriteria?.Conditions[ruleIndex]?.Value
        }
        touched={
          touched?.userDefinedCriteria?.Conditions[index]?.SubCriteria?.Conditions[ruleIndex]?.Value
        }
        modalStatus={modalStatus}
        openFilter={openFilter}
        setOpenFilter={setOpenFilter}
        renderFunction={() => {
          setUdcData({
            ...udcData,
            udcIndex: `userDefinedCriteria.Conditions[${index}].SubCriteria.Conditions[${ruleIndex}].Field`,
            countryInitial: values?.userDefinedCriteria.Conditions[index].SubCriteria.Conditions[
              ruleIndex
            ].Field?.includes('Country')
              ? 'countryOptions'
              : values?.userDefinedCriteria.Conditions[index].SubCriteria.Conditions[
                ruleIndex
              ].Field?.includes('State')
                ? `userDefinedCriteria.Conditions[${index}].SubCriteria.Conditions[${ruleIndex}]?.countryOptions`
                : '',
            stateInitial: values?.userDefinedCriteria.Conditions[index].SubCriteria.Conditions[
              ruleIndex
            ].Field?.includes('State')
              ? `userDefinedCriteria.Conditions[${index}].SubCriteria.Conditions[${ruleIndex}]?.stateOptions`
              : '',
          });
          setJurisdictionId({
            [`userDefinedCriteria.Conditions[${index}].SubCriteria.Conditions[${ruleIndex}].Field`]:
              values?.userDefinedCriteria?.Conditions[index]?.SubCriteria?.Conditions[
                ruleIndex
              ]?.Field?.includes('State')
                ? jurisdictionOptions?.find((item: OptionType) => item?.label == 'State').value
                : '',
          });
        }}
        height='400px'>
        <div className='grid lg:grid-cols-3 sm:grid-cols-1 gap-x-3'>
          <FormSelect
            type='text'
            label='Country'
            name={`udc.userDefinedCriteria[${index}].conditions[${ruleIndex}].countryId`}
            viewName='workCountry'
            containerClassName='mb-0'
            onChange={(event: any) => {
              setOptionSelectedState({
                ...optionSelectedState,
                countryId: event,
                stateArrayIndex: `userDefinedCriteria.Conditions[${index}].SubCriteria.Conditions[${ruleIndex}]`,
              });
              setFieldValue(
                `udc.userDefinedCriteria[${index}].conditions[${ruleIndex}].stateId`,
                null
              );
            }}
            errors={errors}
            touched={touched}
            options={countryOptions}
            values={values}
            arrValue={values?.udc?.userDefinedCriteria[index]?.conditions[ruleIndex]?.countryId}
            setFieldValue={setFieldValue}
            required
          />

          <FormSelect
            type='text'
            label='State'
            name={`udc.userDefinedCriteria[${index}].conditions[${ruleIndex}].stateId`}
            containerClassName='mb-0'
            onChange={handleChange}
            errors={errors}
            touched={touched}
            options={
              stateArrayOptions !== null
                ? stateArrayOptions[
                  `userDefinedCriteria.Conditions[${index}].SubCriteria.Conditions[${ruleIndex}]`
                ] !== undefined
                  ? stateArrayOptions[
                  `userDefinedCriteria.Conditions[${index}].SubCriteria.Conditions[${ruleIndex}]`
                  ]
                  : stateOptions
                : stateOptions
            }
            values={values}
            arrValue={values?.udc?.userDefinedCriteria[index]?.conditions[ruleIndex]?.stateId}
            setFieldValue={setFieldValue}
            required
          />
        </div>
        <div className='flex justify-end m-4'>
          <Button
            title='OK'
            variant='primary'
            btnClassName='w-[100px]'
            onClick={() => {
              const stateValue = stateArrayOptions[
                `userDefinedCriteria.Conditions[${index}].SubCriteria.Conditions[${ruleIndex}]`
              ]?.filter(
                (item: OptionType) =>
                  item.value ===
                  values?.udc.userDefinedCriteria[index].conditions[ruleIndex].stateId
              )[0];
              setFieldValue(
                `userDefinedCriteria.Conditions[${index}].SubCriteria.Conditions[${ruleIndex}].Value`,
                stateValue?.abbreviation
              );

              setFieldValue(
                `userDefinedCriteria.Conditions[${index}].SubCriteria.Conditions[${ruleIndex}].Name`,
                stateValue?.label
              );
              setUdcDescription &&
                setUdcDescription({
                  groupLogicalOperator:
                    values?.userDefinedCriteria.Conditions[index].SubCriteria?.LogicalOperatorType,
                  name: stateValue?.label as string,
                  id: `${index}${ruleIndex}`,
                  equalityOperator: findData(descriptionData, index, ruleIndex)
                    ? findData(descriptionData, index, ruleIndex)?.equalityOperator
                    : '',
                  field: findData(descriptionData, index, ruleIndex)
                    ? findData(descriptionData, index, ruleIndex)?.field
                    : '',
                });
              setOpenFilter(false);
            }}
          />
        </div>
      </SearchWithFilter>
    </>
  );
};

export default StateComponent;
