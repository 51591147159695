import { configureStore } from '@reduxjs/toolkit';
import authReducer from '../auth/authSlice';
import notifyReducer from '../auth/notify';
import routeReducer from '../pages/configuration/routeSlice';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    route: routeReducer,
    notify: notifyReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
