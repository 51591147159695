import { getRgbaValue , rem } from '../../utils/style';
import styled from 'styled-components';
import { COLORS } from '../../constants/style/color';

interface ToggleWrapperType {
  label?: string;
}

export const ToggleWrapper = styled.div<ToggleWrapperType>`
  position: relative;
  display: flex;
  gap: 10px;
  align-items: center;
  min-width: ${rem(30)};

  input {
    display: none;
  }

  input + label {
    background: ${COLORS.LightGray};
    border-radius: ${rem(30)};
    padding: ${rem(2)};
    transition: all 0.4s ease;
    outline: 0;
    display: block;
    width: ${rem(44)};
    height: ${rem(24)};
    position: relative;
    cursor: pointer;
    user-select: none;
  }

  input + label:after {
    position: relative;
    display: block;
    content: '';
    width: ${rem(20)};
    height: 100%;
    box-shadow: ${rem(0)} ${rem(0)} ${rem(2)} ${getRgbaValue('#0000001a', 0.1)},
      inset ${rem(0)} ${rem(1)} ${rem(3)} ${getRgbaValue('#f2f2f2b8', 0.72)};
  }

  input + label:after {
    border-radius: 50%;
    background: ${COLORS.White};
    transition: all 0.2s ease;
  }

  input + label:after {
    left: 0;
  }

  input:checked + label {
    background: ${COLORS.Green};
  }

  input:checked + label:after {
    left: 50%;
  }
`;
