import { type UseMutationResult, useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import ToastComponent from '../../components/toast';
import { VendorUserService } from '../../services/wealthlane-administration-services';
import { isEmpty } from 'lodash';

const usePostSystemUsers = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  closeModal: () => void,
  id?: string
): UseMutationResult => {
  const queryClient = useQueryClient();
  return useMutation(
    async (payload: any) => {
      if (!isEmpty(id)) {
        return await handleRequest(
          VendorUserService.putApiAdministrationApiAppVendorUserVendorUser({
            ...payload,
            id,
          })
        );
      } else {
        return await handleRequest(
          VendorUserService.postApiAdministrationApiAppVendorUserVendorUser(payload)
        );
      }
    },
    {
      onSuccess: async (response: any) => {
        if (response !== null && response !== undefined) {
          closeModal();
          toast.success(
            <ToastComponent
              label='Success'
              message={`User ${id !== null && id !== undefined && id !== '' ? 'Edited' : 'Added'}`}
            />
          );
          await queryClient.invalidateQueries('getAllAdminUserList');

          if (id !== null && id !== undefined && id !== '') {
            await queryClient.invalidateQueries('getCustomerUserCustomerUserList');
          }
        }
      },
      onError: (err) => {
        console.log('error', err);
      },
    }
  );
};

export default usePostSystemUsers;
