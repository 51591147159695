import Heading from '../typography/index';
import { LozengeWrapper } from './lozenge.styled';

interface LozengeType {
  variant: 'default' | 'primary' | 'success' | 'error' | 'info';
  title: string | null | undefined;
  isActive?: boolean;
  className?: string;
  handleIconClick: () => void;
}

const Lozenge = ({
  variant,
  title,
  className,
  isActive,
  handleIconClick,
}: LozengeType): JSX.Element => {
  return (
    <LozengeWrapper variant={variant} className={className}>
      <Heading variant='body' title={title} />
      <span
        className={`material-symbols-outlined ${isActive ?? false ? 'active' : ''}`}
        onClick={() => {
          handleIconClick();
        }}
      >
        close
      </span>
    </LozengeWrapper>
  );
};

export default Lozenge;
