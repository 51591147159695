import * as React from 'react';
import { useLayer, useHover, Arrow } from 'react-laag';
import { COLORS } from '../../constants/style/color';
import Heading from '../typography/index';
import { TooltipComponentContainer, TooltipComponentWrapper } from './tooltip.styled';

export interface TooltipType {
  children: any;
  tooltipText: string | React.ReactNode;
  size?: 'lg' | 'md' | 'sm';
  variant?: 'dark' | 'light';
}

const Tooltip = ({ children, tooltipText, size, variant }: TooltipType): JSX.Element => {
  const [isOver, hoverProps] = useHover({ delayEnter: 100, delayLeave: 100 });
  const { triggerProps, layerProps, arrowProps, renderLayer } = useLayer({
    isOpen: isOver,
    placement: 'top-center',
    triggerOffset: 8,
  });

  const isReactText = (children: any): boolean => {
    return ['string', 'number'].includes(typeof children);
  };
  let trigger;
  if (isReactText(children)) {
    trigger = (
      <span className='tooltip-text-wrapper' {...triggerProps} {...hoverProps}>
        {children}
      </span>
    );
  } else {
    trigger = React.cloneElement(children, {
      ...triggerProps,
      ...hoverProps,
    });
  }

  return (
    <TooltipComponentContainer>
      <div className='tooltip-text'>{trigger}</div>
      {renderLayer(
        <>
          {isOver && (
            <TooltipComponentWrapper
              size={size ?? 'md'}
              variant={variant ?? 'light'}
              className='tooltip-box'
              {...layerProps}
            >
              <Heading variant='body-s' title={tooltipText} />
              <Arrow
                {...arrowProps}
                backgroundColor={variant === 'dark' ? COLORS.Black : COLORS.White}
                borderColor={variant === 'dark' ? COLORS.Black : COLORS.White}
                borderWidth={1}
                size={6}
              />
            </TooltipComponentWrapper>
          )}
        </>
      )}
    </TooltipComponentContainer>
  );
};

export default Tooltip;
