import { type UseQueryResult, useQuery } from 'react-query';
import { GrantService } from '../../services/wealthlane-award-services';
import { type OptionType } from '../../types/common';

const useGetTaxAllGrant = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  planId?: string,
  applicableData?: string | null
): UseQueryResult<
  // eslint-disable-next-line camelcase
  OptionType[] | undefined,
  unknown
> => {
  const taxAllGrantOptions = useQuery(
    ['useGetTaxAllGrantOptions', planId, applicableData],
    async () => {
      if (
        applicableData?.includes('Transaction') === true ||
        applicableData?.includes('Grant') === true
      ) {
        if (planId != null && planId !== '') {
          return await handleRequest(GrantService.getApiAwardApiAppGrantGrantsByPlanForTax(planId));
        }
      }
    },
    {
      select: (data) => {
        return data?.map((item) => {
          return {
            label: item.name ?? '',
            value: item.id ?? '',
          };
        });
      },
    }
  );

  return taxAllGrantOptions;
};

export default useGetTaxAllGrant;
