import { ErrorMessage, Field } from 'formik';
import { ErrorMessageWrapper } from '../../constants/style/common.styled';
import { FormWrapper, Label } from '../input-text/input.styled';

interface InputTypeProps {
  containerClassName?: string;
  label?: string;
  name: any;
  validate?: any;
  type?: any;
  placeholder?: any;
  onChange: any;
  className?: any;
  errors: any;
  touched: any;
  labelClassName?: string;
  required?: boolean;
  disabled?: boolean;
  value?: string | number;
  as?: string;
  min?: string;
  avoidKey?: any;
  onKeyUp?: any;
  mainClassName?: string;
  defaultValue?: number | string;
  sideText?: string;
  rows?: string;
  cols?: string;
  inputType?: 'View' | string;
}

const CustomTextArea = (props: InputTypeProps): JSX.Element => {
  const {
    containerClassName,
    label,
    name,
    type,
    className,
    errors,
    touched,
    labelClassName,
    required,
    onKeyUp,
    avoidKey,
    value,
    as,
    min,
    defaultValue,
    mainClassName,
    sideText,
    rows,
    inputType,
    cols,
    ...rest
  } = props;
  const hasError =
    Boolean(errors) && Boolean(touched) && Boolean(errors[name]) && Boolean(touched[name])
      ? 'is-invalid'
      : '';

  return (
    <div className={`${containerClassName ?? ''} flex flex-col`}>
      <Label
        htmlFor={name}
        className={`${labelClassName ?? ''} ${inputType === 'View' ? 'label-view' : ''}`}>
        {label} {!(required ?? false) && <span>(Optional)</span>}
      </Label>
      <FormWrapper
        className={`${containerClassName ?? ''} ${inputType === 'View' ? 'input-view' : ''}`}>
        <Field
          name={name}
          label={label}
          type='textarea'
          className={` ${String(className ?? '')} ${hasError}`}
          autoComplete='off'
          rows={rows}
          cols={cols}
          as={as ?? 'textarea'}
          min={min}
          onKeyUp={onKeyUp}
          defaultValue={defaultValue}
          {...(Boolean(avoidKey) && { avoidKey })}
          {...(value != null && { value })}
          {...rest}
        />
        {sideText != null && <span>{sideText}</span>}
        <ErrorMessageWrapper>
          <ErrorMessage name={name} component='div' className='field-error-message mt-1' />
        </ErrorMessageWrapper>
      </FormWrapper>
    </div>
  );
};

export default CustomTextArea;
