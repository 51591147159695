import { type ReactNode, useState } from 'react';
import Heading from '../typography/index';
import Button from '../button';
import { Transition } from 'react-transition-group';
import { FormWrapper } from '../input-text/input.styled';
import { isEmpty } from 'lodash';
import { AlertPopupContainer } from './alertPopup.styled';

interface AlertPopupType {
  isShow: boolean;
  closeModal?: () => void;
  handleSubmit?: any;
  title?: string;
  modalStatus?: string | ReactNode;
  ConfirmTitle?: string | ReactNode;
  setRemark?: (value: string) => void;
  remark?: string;
  loading?: boolean;
  customTitle?: string;
}

const AlertPopup = ({
  isShow,
  title,
  modalStatus,
  closeModal,
  handleSubmit,
  remark,
  setRemark,
  loading,
  customTitle,
  ConfirmTitle,
}: AlertPopupType): JSX.Element => {
  const [errorMessage, setErrorMessage] = useState('');
  return (
    <Transition in={isShow} timeout={250}>
      {(state) => (
        <AlertPopupContainer>
          <div className={`modalBackdrop modalBackdrop-${state}`}>
            <div className={`sideBar sideBar-${state} `}>
              <div className='popupDialog'>
                <div className='popupContent'>
                  <Heading
                    variant='h5'
                    className='mb-5'
                    title={
                      customTitle !== undefined || customTitle != null
                        ? customTitle
                        : modalStatus === 'View Remarks'
                        ? 'Rejection Reason'
                        : `Are you sure you want to ${
                            modalStatus === 'Approval'
                              ? 'approve'
                              : modalStatus === 'Reject'
                              ? 'reject'
                              : modalStatus === 'Delete'
                              ? 'delete'
                              : modalStatus === 'Deactivate'
                              ? 'deactivate'
                              : 'confirm'
                          } ${title ?? ''}?`
                    }
                  />

                  {(modalStatus === 'Reject' || modalStatus === 'View Remarks') && (
                    <FormWrapper>
                      <textarea
                        name='remarks'
                        placeholder='Enter Reason'
                        className={!isEmpty(errorMessage) ? 'border-red-600' : 'input-view'}
                        style={{ minHeight: '100px', minWidth: '430px', resize: 'none' }}
                        defaultValue={remark}
                        disabled={modalStatus === 'View Remarks'}
                        onChange={(e) => {
                          setRemark?.(e.target.value);
                          setErrorMessage('');
                        }}
                      ></textarea>
                      {errorMessage.length > 0 && (
                        <Heading variant='body-xs' title={'Required Field'} className={'mt-2'} />
                      )}
                    </FormWrapper>
                  )}
                  <div className='popupButtons'>
                    {closeModal && (
                      <Button
                        variant='primary-dark'
                        title={modalStatus === 'View Remarks' ? 'Close' : 'No'}
                        onClick={() => {
                          closeModal();
                        }}
                      />
                    )}
                    {modalStatus !== 'View Remarks' && (
                      <Button
                        variant='primary'
                        title={ConfirmTitle ?? 'Confirm'}
                        onClick={() => {
                          modalStatus === 'Reject'
                            ? !isEmpty(remark)
                              ? handleSubmit?.()
                              : setErrorMessage('Required Field')
                            : handleSubmit?.();
                        }}
                        icon={<span className='material-symbols-outlined'>check_circle</span>}
                        loading={loading}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </AlertPopupContainer>
      )}
    </Transition>
  );
};

export default AlertPopup;
