import Tag from '../../components/lozenge/tags';
import { ActionButton } from '../../components/vertical-dot/popper';
import { getActionList } from '../../utils';
import { TableBodyCellColumn } from '../style/table.styled';

export const TaxSetupHeader = (actions: any) => {
  const getActions = (rowData: { [key: string]: any }) => {
    let newActions = [];
    if (!rowData.hasApprover && rowData?.statusSystemName === 'Created') {
      newActions = getActionList(actions).filter((item: any) =>
        ['View', 'Edit', 'Make a Copy', 'Activate', 'Delete'].includes(item.type)
      );
    } else if (rowData.hasApprover && rowData?.statusSystemName === 'Created') {
      newActions = getActionList(actions).filter((item: any) =>
        ['View', 'Edit', 'Make a Copy', 'Request for Approval', 'Delete'].includes(item.type)
      );
    } else if (
      rowData.statusSystemName === 'Approved' &&
      rowData.equityCompensationLevelName === 'Company'
    ) {
      newActions = getActionList(actions).filter((item: any) =>
        ['View', 'Make a Copy', 'Override Tax', 'Deactivate'].includes(item.type)
      );
    } else if (rowData.statusSystemName === 'Approved') {
      newActions = getActionList(actions).filter((item: any) =>
        ['View', 'Make a Copy', 'Deactivate'].includes(item.type)
      );
    } else if (
      rowData.statusSystemName === 'Rejected' ||
      rowData.statusSystemName === 'Deactivated'
    ) {
      newActions = getActionList(actions).filter((item: any) =>
        ['View', 'Make a Copy', 'View Comment'].includes(item.action)
      );
    } else {
      newActions = getActionList(actions).filter((item: any) =>
        ['View', 'Make a Copy', 'Deactivate'].includes(item.type)
      );
    }
    return newActions;
  };

  return [
    {
      Header: 'Tax Period',
      accessor: 'taxPeriodName',
      width: 300,
      Cell: ({ row }: any) => {
        return <TableBodyCellColumn>{row.original.taxPeriodName || '-'}</TableBodyCellColumn>;
      },
    },
    {
      Header: 'Name',
      accessor: 'name',
      width: 300,
      Cell: ({ row }: any) => {
        return (
          <TableBodyCellColumn>
            <div className='cellTitle'>{row?.original?.name || '-'}</div>
          </TableBodyCellColumn>
        );
      },
    },

    {
      Header: 'Jurisdiction Level',
      accessor: 'jurisdictionName',
      width: 200,
      Cell: ({ row }: any) => {
        return <TableBodyCellColumn>{row.original.jurisdictionName || '-'}</TableBodyCellColumn>;
      },
    },
    {
      Header: 'Applicable To',
      accessor: 'equityCompensationLevelName',
      width: 200,
      Cell: ({ row }: any) => {
        return (
          <TableBodyCellColumn>
            {row.original.equityCompensationLevelName || '-'}
          </TableBodyCellColumn>
        );
      },
    },

    {
      Header: 'Parent Tax',
      accessor: 'parentTax',
      width: 200,
      Cell: ({ row }: any) => (
        <div className='cellTitle'>
          {row.original.isEnabled ? (
            <Tag variant={'info'} title={'Active' || '-'} />
          ) : row.original.isEnabled === false ? (
            <Tag variant={'error'} title={'Deactivated' || '-'} />
          ) : (
            '-'
          )}
        </div>
      ),
    },
    {
      Header: 'Status',
      accessor: 'statusDisplayName',
      width: 200,
      Cell: ({ row }: any) => (
        <Tag
          variant={
            row.original.statusDisplayName === 'Approved'
              ? 'success'
              : row.original.statusDisplayName === 'Rejected' ||
                row.original.statusDisplayName === 'Deactivated'
              ? 'error'
              : row.original.statusDisplayName === 'Created'
              ? 'primary'
              : 'info'
          }
          title={row.original.statusDisplayName.toUpperCase()}
        />
      ),
    },
    {
      Header: '',
      accessor: 'actions',
      width: 20,
      sortable: false,
      Cell: ({ row }: any) => (
        <ActionButton
          actions={getActions(row.original)}
          id={row.original.id}
          rowData={row.original}
          counter={parseInt(row?.id)}
        />
      ),
    },
  ];
};
