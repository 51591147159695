import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import Tag from '../../components/lozenge/tags';
import { PopoverModelWrapper } from '../../components/modal/modal.styled';
import Heading from '../../components/typography/index';
import { ActionButton } from '../../components/vertical-dot/popper';
import { TableBodyCellColumn } from '../style/table.styled';
import { type TableHeaderType } from '../../types/common';

export const SystemUserHeader = (
  actions: any,
  handleShow: (id: string, event: any) => void,
  hasMousePosition: boolean,
  resetMousePosition: () => void
): TableHeaderType[] => {
  const [title, setTitle] = useState('');
  useEffect(() => {
    function handleClickOutside(event: any): void {
      const targetElement = event.target as Element;
      if (!targetElement.classList.contains('roles-popovers')) {
        resetMousePosition();
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [resetMousePosition]);

  const getActions = (rowData: Record<string, string | boolean>): any => {
    let newActions = [];
    if (rowData.vendorStatus === 'Pending') {
      newActions = actions.filter(
        (item: any) =>
          item.action !== 'View Comments' &&
          item.action !== 'Resend Mail' &&
          item.action !== 'Deactivate'
      );
    } else if (rowData.vendorStatus === 'Approved') {
      newActions = actions.filter((item: any) =>
        ['Edit', 'View', 'Deactivate'].includes(item.action)
      );

      if (rowData?.reSendEmail) {
        newActions = actions.filter(
          (item: any) =>
            ['Edit', 'View', 'Deactivate', 'Resend Mail'].includes(item.action) ||
            ['Deactivate'].includes(item?.action?.props?.children)
        );
      }
    } else if (rowData.vendorStatus === 'Rejected') {
      newActions = actions.filter((item: any) => ['View Comments', 'View'].includes(item.action));
    } else {
      newActions = actions;
    }
    if (rowData.isDefaultAdmin?.toString().length > 0) {
      newActions = actions.filter((item: any) => ['View'].includes(item.action));
    }
    if (!isEmpty(rowData.isEnabled) || rowData.isEnabled === false) {
      newActions = actions.filter((item: any) => ['View Comments', 'View'].includes(item.action));
    }

    const filterAction = newActions.map((item: any) => {
      return {
        ...item,
        action:
          item.action === 'Deactivate' ? (
            <div className='delete-label'>Deactivate</div>
          ) : (
            item.action
          ),
      };
    });
    return filterAction;
  };

  return [
    {
      Header: 'Employee Details',
      accessor: 'fullName',
      width: 300,
      Cell: ({ row }: any) => {
        return (
          <TableBodyCellColumn>
            <div className='cellTitle'>
              {row.original.fullName !== null ? row.original.fullName : '-'}
            </div>
            <div className='cellSubtitle'>
              {row.original.workEmail !== null ? row.original.workEmail : '-'}
            </div>
          </TableBodyCellColumn>
        );
      },
    },
    {
      Header: 'Department',
      accessor: 'vendorDepartment',
      width: 200,
      Cell: ({ row }: any) => {
        return (
          <TableBodyCellColumn>
            {row.original.vendorDepartment ? row.original.vendorDepartment : '-'}
          </TableBodyCellColumn>
        );
      },
    },
    {
      Header: 'Job Title',
      accessor: 'vendorTitle',
      width: 200,
      Cell: ({ row }: any) => {
        return (
          <TableBodyCellColumn>
            {row.original.vendorTitle ? row.original.vendorTitle : '-'}
          </TableBodyCellColumn>
        );
      },
    },
    {
      Header: 'System Role',
      accessor: 'vendorRole',
      width: 200,
      Cell: ({ row }: any) => {
        return (
          <PopoverModelWrapper className='roles-popovers'>
            <Heading
              variant='body-s'
              title={row.original.vendorRole ?? '-'}
              onClick={(e: any) => {
                setTitle(
                  `${row.original.vendorRole !== null ? String(row.original.vendorRole) : '-'}-${
                    row.id !== null ? String(row.id) : '-'
                  }`
                );
                handleShow(row.original.vendorRoleId, e);
              }}
              className={`title roles-popovers ${
                hasMousePosition &&
                title ===
                  `${row.original.vendorRole !== null ? String(row.original.vendorRole) : '-'}-${
                    row.id !== null ? String(row.id) : '-'
                  }`
                  ? 'active'
                  : ''
              }`}
            />
          </PopoverModelWrapper>
        );
      },
    },
    {
      Header: 'User Status',
      accessor: 'isEnabled',
      width: 200,
      Cell: ({ row }: any) => (
        <div className='cellTitle'>
          {row.original.isEnabled === true ? (
            <Tag variant={'info'} title={'Active' ?? '-'} />
          ) : row.original.isEnabled === false ? (
            <Tag variant={'error'} title={'Deactivated' ?? '-'} />
          ) : (
            '-'
          )}
        </div>
      ),
    },
    {
      Header: 'Status',
      accessor: 'vendorStatusId',
      width: 200,
      Cell: ({ row }: any) => (
        <Tag
          variant={
            row.original.vendorStatus.toLowerCase() === 'approved'
              ? 'success'
              : row.original.vendorStatus.toLowerCase() === 'rejected'
              ? 'error'
              : 'info'
          }
          title={row.original.vendorStatus ?? '-'}
        />
      ),
    },
    {
      Header: '',
      accessor: 'actions',
      width: 20,
      sortable: false,
      Cell: ({ row }: any) => (
        <ActionButton
          actions={getActions(row.original)}
          id={row.original.id}
          rowData={row.original}
          counter={parseInt(row?.id)}
        />
      ),
    },
  ];
};
